import { useState, useEffect, useCallback } from 'react';

function useElementSize(elementRef) {
  const [size, setSize] = useState({ width: undefined, height: undefined });

  const updateWidth = useCallback(() => {
    const height = parseFloat(getComputedStyle(elementRef.current, null).height.replace('px', ''));
    const width = parseFloat(getComputedStyle(elementRef.current, null).width.replace('px', ''));

    if (elementRef && elementRef.current) {
      setSize({ height, width });
    }
  }, [elementRef]);

  useEffect(() => {
    updateWidth();
    window.addEventListener('resize', updateWidth);
    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, [updateWidth, elementRef, elementRef.current]);

  return [size];
}

export default useElementSize;

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// import qsLanguageDetector from './qs_language_detector';

import translationEN from "./translations/en/industrial.json";
import translationRU from "./translations/ru/industrial.json";
import translationFR from "./translations/fr/industrial.json";
import translationDE from "./translations/de/industrial.json";
import translationES from "./translations/es/industrial.json";
import translationNL from "./translations/nl/industrial.json";
// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
const resources = {
  ru: {
    translation: translationRU,
  },
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
  de: {
    translation: translationDE,
  },
  es: {
    translation: translationES,
  },
  nl: {
    translation: translationNL,
  }
};
const languageDetector = new LanguageDetector();
// languageDetector.addDetector(qsLanguageDetector);

i18n
  .use(languageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: "ru",
    detection: {
      order: ["querystring", "localStorage"],
      lookupQuerystring: "language",
      caches: ["cookies", "localStorage"],
    },

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;

import PropTypes from 'prop-types';

function IconDownload({ color }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10.981" viewBox="0 0 11 10.981">
      <path id="Path_56" data-name="Path 56" d="M6.376-3.9a.744.744,0,0,1-.544.229A.744.744,0,0,1,5.287-3.9L3.11-5.958a1.017,1.017,0,0,1-.329-.773,1.072,1.072,0,0,1,.329-.745,1.078,1.078,0,0,1,.788-.3,1.087,1.087,0,0,1,.759.329l.029.057V-9.969a1.269,1.269,0,0,1,.158-.487.953.953,0,0,1,.344-.358,1.156,1.156,0,0,1,.473-.158,1.093,1.093,0,0,1,.831.229A1.018,1.018,0,0,1,6.92-10v2.607l.057-.029a.99.99,0,0,1,.731-.329,1.078,1.078,0,0,1,.788.3.993.993,0,0,1,.344.759.989.989,0,0,1-.286.73.025.025,0,0,1-.029.029ZM4.227-6.99a.52.52,0,0,0-.372-.172.533.533,0,0,0-.344.115.418.418,0,0,0,0,.63L5.688-4.354a.179.179,0,0,0,.286,0l2.12-2.005a.394.394,0,0,0,0-.63.429.429,0,0,0-.688,0l-.573.516a.287.287,0,0,1-.344.057A.3.3,0,0,1,6.318-6.7V-9.969a.425.425,0,0,0-.2-.286.424.424,0,0,0-.372-.115.493.493,0,0,0-.458.458V-6.7a.275.275,0,0,1-.172.258.322.322,0,0,1-.344-.029ZM10.157,0H1.448a1.123,1.123,0,0,1-.8-.329A1.085,1.085,0,0,1,.3-1.146V-3.409a1.339,1.339,0,0,1,.344-.859,1.223,1.223,0,0,1,.859-.315h.573q.315,0,.315.286V-2.12H9.212V-4.3q0-.286.315-.286h.63a1.1,1.1,0,0,1,.8.344,1.132,1.132,0,0,1,.344.831v2.263a1.1,1.1,0,0,1-.344.8A1.1,1.1,0,0,1,10.157,0ZM1.448-3.953a.467.467,0,0,0-.344.115.549.549,0,0,0-.172.4v2.292a.5.5,0,0,0,.143.372.5.5,0,0,0,.372.143h8.708a.461.461,0,0,0,.372-.115.687.687,0,0,0,.143-.4V-3.409a.627.627,0,0,0-.115-.4.687.687,0,0,0-.4-.143H9.813V-1.8q0,.315-.286.315H2.079q-.286,0-.286-.315V-3.953Z" transform="translate(-0.303 10.981)" fill={color} />
    </svg>
  );
}

IconDownload.propTypes = {
  color: PropTypes.string,
};

IconDownload.defaultProps = {
  color: '#000',
};

export default IconDownload;

import { MachineContainer } from "shared/components";

import VerticalPackagingImage from "shared/assets/vertical-packaging-big.jpg";
import PartDefinitions from "./PartDefinitions";

import "./styles.scss";

const ImageBaseWidth = 3506;
const ImageBaseHeight = 2480;
const MachineTypeText = "Vertical_Packaging_Machine";

const VerticalPackaging = () => (
  <MachineContainer
    MachineType={MachineTypeText}
    PartDefinitions={PartDefinitions}
    MachineContainerImage={VerticalPackagingImage}
    ImageBaseHeight={ImageBaseHeight}
    ImageBaseWidth={ImageBaseWidth}
  />
);

export default VerticalPackaging;

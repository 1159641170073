/* eslint-disable */

const HumanMachineInterface = [
  {
    'id': 0,
    'fieldName': 'Screen Type',
    'title': 'Please select your screen type.',
    'options': [
      {
        'label': 'Single Touch',
        'value': 'Single Touch',
        'nextQuestions': [
          1
        ]
      },
      {
        'label': 'Multi Touch',
        'value': 'Multi Touch',
        'nextQuestions': [
          22
        ]
      }
    ]
  },
  {
    'id': 1,
    'fieldName': 'Screen Size',
    'title': 'Please select your screen size.',
    'options': [
      {
        'label': '7 inch',
        'value': '7 inch',
        'nextQuestions': [
          2
        ]
      },
      {
        'label': '10.1 inch',
        'value': '10.1 inch',
        'nextQuestions': [
          7
        ]
      },
      {
        'label': '12.1 inch',
        'value': '12.1 inch',
        'nextQuestions': [
          12
        ]
      },
      {
        'label': '15.6 inch',
        'value': '15.6 inch',
        'nextQuestions': [
          17
        ]
      },
      {
        'label': '4.3 inch',
        'value': '4.3 inch',
        'nextQuestions': [
          20
        ]
      }
    ]
  },
  {
    'id': 2,
    'fieldName': 'Memory Size',
    'title': 'Please select your memory size.',
    'options': [
      {
        'label': '1 GB',
        'value': '1 GB',
        'nextQuestions': [
          3
        ]
      },
      {
        'label': '512 MB',
        'value': '512 MB',
        'nextQuestions': [
          4
        ]
      },
      {
        'label': '1 GB (Expandable)',
        'value': '1 GB (Expandable)',
        'nextQuestions': [
          5
        ]
      },
      {
        'label': '32 GB (Expandable)',
        'value': '32 GB (Expandable)',
        'nextQuestions': [
          6
        ]
      }
    ]
  },
  {
    'id': 3,
    'fieldName': 'Development Software',
    'title': 'Please select your development software.',
    'options': [
      {
        'label': 'EcoStruxure Operator Terminal Expert',
        'value': 'EcoStruxure Operator Terminal Expert',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Vijeo Designer',
        'value': 'Vijeo Designer',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 4,
    'fieldName': 'Development Software',
    'title': 'Please select your development software.',
    'options': [
      {
        'label': 'Linux Browser HTML5',
        'value': 'Linux Browser HTML5',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 5,
    'fieldName': 'Development Software',
    'title': 'Please select your development software.',
    'options': [
      {
        'label': 'Vijeo Designer',
        'value': 'Vijeo Designer',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'EcoStruxure Operator Terminal Expert',
        'value': 'EcoStruxure Operator Terminal Expert',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 6,
    'fieldName': 'Development Software',
    'title': 'Please select your development software.',
    'options': [
      {
        'label': 'Vijeo Designer',
        'value': 'Vijeo Designer',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'EcoStruxure Operator Terminal Expert',
        'value': 'EcoStruxure Operator Terminal Expert',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 7,
    'fieldName': 'Memory Size',
    'title': 'Please select your memory size.',
    'options': [
      {
        'label': '1 GB',
        'value': '1 GB',
        'nextQuestions': [
          8
        ]
      },
      {
        'label': '512 MB',
        'value': '512 MB',
        'nextQuestions': [
          9
        ]
      },
      {
        'label': '1 GB (Expandable)',
        'value': '1 GB (Expandable)',
        'nextQuestions': [
          10
        ]
      },
      {
        'label': '32 GB (Expandable)',
        'value': '32 GB (Expandable)',
        'nextQuestions': [
          11
        ]
      }
    ]
  },
  {
    'id': 8,
    'fieldName': 'Development Software',
    'title': 'Please select your development software.',
    'options': [
      {
        'label': 'EcoStruxure Operator Terminal Expert',
        'value': 'EcoStruxure Operator Terminal Expert',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Vijeo Designer',
        'value': 'Vijeo Designer',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 9,
    'fieldName': 'Development Software',
    'title': 'Please select your development software.',
    'options': [
      {
        'label': 'Linux Browser HTML5',
        'value': 'Linux Browser HTML5',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 10,
    'fieldName': 'Development Software',
    'title': 'Please select your development software.',
    'options': [
      {
        'label': 'Vijeo Designer',
        'value': 'Vijeo Designer',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'EcoStruxure Operator Terminal Expert',
        'value': 'EcoStruxure Operator Terminal Expert',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 11,
    'fieldName': 'Development Software',
    'title': 'Please select your development software.',
    'options': [
      {
        'label': 'Vijeo Designer',
        'value': 'Vijeo Designer',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'EcoStruxure Operator Terminal Expert',
        'value': 'EcoStruxure Operator Terminal Expert',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 12,
    'fieldName': 'Memory Size',
    'title': 'Please select your memory size.',
    'options': [
      {
        'label': '1 GB',
        'value': '1 GB',
        'nextQuestions': [
          13
        ]
      },
      {
        'label': '512 MB',
        'value': '512 MB',
        'nextQuestions': [
          14
        ]
      },
      {
        'label': '1 GB (Expandable)',
        'value': '1 GB (Expandable)',
        'nextQuestions': [
          15
        ]
      },
      {
        'label': '32 GB (Expandable)',
        'value': '32 GB (Expandable)',
        'nextQuestions': [
          16
        ]
      }
    ]
  },
  {
    'id': 13,
    'fieldName': 'Development Software',
    'title': 'Please select your development software.',
    'options': [
      {
        'label': 'EcoStruxure Operator Terminal Expert',
        'value': 'EcoStruxure Operator Terminal Expert',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Vijeo Designer',
        'value': 'Vijeo Designer',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 14,
    'fieldName': 'Development Software',
    'title': 'Please select your development software.',
    'options': [
      {
        'label': 'Linux Browser HTML5',
        'value': 'Linux Browser HTML5',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 15,
    'fieldName': 'Development Software',
    'title': 'Please select your development software.',
    'options': [
      {
        'label': 'Vijeo Designer',
        'value': 'Vijeo Designer',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'EcoStruxure Operator Terminal Expert',
        'value': 'EcoStruxure Operator Terminal Expert',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 16,
    'fieldName': 'Development Software',
    'title': 'Please select your development software.',
    'options': [
      {
        'label': 'Vijeo Designer',
        'value': 'Vijeo Designer',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'EcoStruxure Operator Terminal Expert',
        'value': 'EcoStruxure Operator Terminal Expert',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 17,
    'fieldName': 'Memory Size',
    'title': 'Please select your memory size.',
    'options': [
      {
        'label': '1 GB',
        'value': '1 GB',
        'nextQuestions': [
          18
        ]
      },
      {
        'label': '512 MB',
        'value': '512 MB',
        'nextQuestions': [
          19
        ]
      }
    ]
  },
  {
    'id': 18,
    'fieldName': 'Development Software',
    'title': 'Please select your development software.',
    'options': [
      {
        'label': 'EcoStruxure Operator Terminal Expert',
        'value': 'EcoStruxure Operator Terminal Expert',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 19,
    'fieldName': 'Development Software',
    'title': 'Please select your development software.',
    'options': [
      {
        'label': 'Linux Browser HTML5',
        'value': 'Linux Browser HTML5',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 20,
    'fieldName': 'Memory Size',
    'title': 'Please select your memory size.',
    'options': [
      {
        'label': '512 MB',
        'value': '512 MB',
        'nextQuestions': [
          21
        ]
      }
    ]
  },
  {
    'id': 21,
    'fieldName': 'Development Software',
    'title': 'Please select your development software.',
    'options': [
      {
        'label': 'EcoStruxure Operator Terminal Expert',
        'value': 'EcoStruxure Operator Terminal Expert',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Linux Browser HTML5',
        'value': 'Linux Browser HTML5',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 22,
    'fieldName': 'Screen Size',
    'title': 'Please select your screen size.',
    'options': [
      {
        'label': '10.4 inch',
        'value': '10.4 inch',
        'nextQuestions': [
          23
        ]
      },
      {
        'label': '12.1 inch',
        'value': '12.1 inch',
        'nextQuestions': [
          27
        ]
      },
      {
        'label': '15 inch',
        'value': '15 inch',
        'nextQuestions': [
          31
        ]
      },
      {
        'label': '19 inch',
        'value': '19 inch',
        'nextQuestions': [
          35
        ]
      }
    ]
  },
  {
    'id': 23,
    'fieldName': 'Memory Size',
    'title': 'Please select your memory size.',
    'options': [
      {
        'label': '1 GB',
        'value': '1 GB',
        'nextQuestions': [
          24
        ]
      },
      {
        'label': '1 GB (Expandable)',
        'value': '1 GB (Expandable)',
        'nextQuestions': [
          25
        ]
      },
      {
        'label': '32 GB (Expandable)',
        'value': '32 GB (Expandable)',
        'nextQuestions': [
          26
        ]
      }
    ]
  },
  {
    'id': 24,
    'fieldName': 'Development Software',
    'title': 'Please select your development software.',
    'options': [
      {
        'label': 'Vijeo Designer',
        'value': 'Vijeo Designer',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 25,
    'fieldName': 'Development Software',
    'title': 'Please select your development software.',
    'options': [
      {
        'label': 'Vijeo Designer',
        'value': 'Vijeo Designer',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'EcoStruxure Operator Terminal Expert',
        'value': 'EcoStruxure Operator Terminal Expert',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 26,
    'fieldName': 'Development Software',
    'title': 'Please select your development software.',
    'options': [
      {
        'label': 'Vijeo Designer',
        'value': 'Vijeo Designer',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'EcoStruxure Operator Terminal Expert',
        'value': 'EcoStruxure Operator Terminal Expert',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 27,
    'fieldName': 'Memory Size',
    'title': 'Please select your memory size.',
    'options': [
      {
        'label': '1 GB',
        'value': '1 GB',
        'nextQuestions': [
          28
        ]
      },
      {
        'label': '1 GB (Expandable)',
        'value': '1 GB (Expandable)',
        'nextQuestions': [
          29
        ]
      },
      {
        'label': '32 GB (Expandable)',
        'value': '32 GB (Expandable)',
        'nextQuestions': [
          30
        ]
      }
    ]
  },
  {
    'id': 28,
    'fieldName': 'Development Software',
    'title': 'Please select your development software.',
    'options': [
      {
        'label': 'Vijeo Designer',
        'value': 'Vijeo Designer',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 29,
    'fieldName': 'Development Software',
    'title': 'Please select your development software.',
    'options': [
      {
        'label': 'Vijeo Designer',
        'value': 'Vijeo Designer',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'EcoStruxure Operator Terminal Expert',
        'value': 'EcoStruxure Operator Terminal Expert',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 30,
    'fieldName': 'Development Software',
    'title': 'Please select your development software.',
    'options': [
      {
        'label': 'Vijeo Designer',
        'value': 'Vijeo Designer',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'EcoStruxure Operator Terminal Expert',
        'value': 'EcoStruxure Operator Terminal Expert',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 31,
    'fieldName': 'Memory Size',
    'title': 'Please select your memory size.',
    'options': [
      {
        'label': '1 GB',
        'value': '1 GB',
        'nextQuestions': [
          32
        ]
      },
      {
        'label': '1 GB (Expandable)',
        'value': '1 GB (Expandable)',
        'nextQuestions': [
          33
        ]
      },
      {
        'label': '32 GB (Expandable)',
        'value': '32 GB (Expandable)',
        'nextQuestions': [
          34
        ]
      }
    ]
  },
  {
    'id': 32,
    'fieldName': 'Development Software',
    'title': 'Please select your development software.',
    'options': [
      {
        'label': 'Vijeo Designer',
        'value': 'Vijeo Designer',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 33,
    'fieldName': 'Development Software',
    'title': 'Please select your development software.',
    'options': [
      {
        'label': 'Vijeo Designer',
        'value': 'Vijeo Designer',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'EcoStruxure Operator Terminal Expert',
        'value': 'EcoStruxure Operator Terminal Expert',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 34,
    'fieldName': 'Development Software',
    'title': 'Please select your development software.',
    'options': [
      {
        'label': 'Vijeo Designer',
        'value': 'Vijeo Designer',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'EcoStruxure Operator Terminal Expert',
        'value': 'EcoStruxure Operator Terminal Expert',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 35,
    'fieldName': 'Memory Size',
    'title': 'Please select your memory size.',
    'options': [
      {
        'label': '1 GB (Expandable)',
        'value': '1 GB (Expandable)',
        'nextQuestions': [
          36
        ]
      },
      {
        'label': '32 GB (Expandable)',
        'value': '32 GB (Expandable)',
        'nextQuestions': [
          37
        ]
      }
    ]
  },
  {
    'id': 36,
    'fieldName': 'Development Software',
    'title': 'Please select your development software.',
    'options': [
      {
        'label': 'Vijeo Designer',
        'value': 'Vijeo Designer',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'EcoStruxure Operator Terminal Expert',
        'value': 'EcoStruxure Operator Terminal Expert',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 37,
    'fieldName': 'Development Software',
    'title': 'Please select your development software.',
    'options': [
      {
        'label': 'Vijeo Designer',
        'value': 'Vijeo Designer',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'EcoStruxure Operator Terminal Expert',
        'value': 'EcoStruxure Operator Terminal Expert',
        'nextQuestions': [
          -1
        ]
      }
    ]
  }
];

export default HumanMachineInterface;

import { useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import ReactGA from "react-ga4";
import { nextuser } from "shared/tracking/nextuser";
import { ToastContainer } from "react-toastify";
import { gaActions } from "shared/constants";
import AppContainer from "./AppContainer";
import CartProvider from "./cart/CartProvider";
import AppProvider from "./AppContainer/AppContext/AppProvider";

import "styles.scss";

function App() {
  useEffect(() => {
    ReactGA.initialize("G-T11B4BGYB9");

    const messageHandler = (event) => {
      if (event && event.data && event.data.type === "ScWidgetStartupEvent") {
        window.removeEventListener("message", messageHandler, false);

        if (
          !!event.data.hostname &&
          !!event.data.options &&
          (event.data.options.elbridgehostURL || event.data.options.source)
        ) {
          const elBridgeHost =
            event.data.options.elbridgehostURL || event.data.options.source;

          if (elBridgeHost) {
            ReactGA.event({
              category: "IndustrialTracker.set",
              action: "pageview",
            });

            ReactGA.event({
              category: "IndustrialTracker.set",
              action: "dimension1",
              label: elBridgeHost || "N/A",
            });
          }
        }
        window.appDomain = event.data.hostname;
      }
    };

    window.addEventListener("message", messageHandler, false);

    const { search } = window.location;
    const searchParams = new URLSearchParams(search);
    const options = searchParams.get("w_opts") || "";
    const elbridgeHost = decodeURIComponent(options).replace(
      '{"elbridgehostURL":"https://',
      ""
    );
    if (elbridgeHost) {
      ReactGA.event({
        category: "IndustrialTracker.set",
        action: "pageview",
      });

      ReactGA.event({
        category: "IndustrialTracker.set",
        action: "dimension1",
        label: elbridgeHost || "N/A",
      });
    }

    return () => {
      window.removeEventListener("message", messageHandler);
    };
  }, []);

  useEffect(() => {
    nextuser(`${gaActions.display}_configurator`);
    ReactGA.event({
      category: "IndustrialTracker",
      action: "display",
    });
  }, []);

  return (
    <AppProvider>
      <BrowserRouter>
        <CartProvider>
          <div className="App">
            <Switch>
              <Route path="/" component={AppContainer} />
            </Switch>
          </div>
          <ToastContainer />
        </CartProvider>
      </BrowserRouter>
    </AppProvider>
  );
}

export default App;

/* eslint-disable */

const AutomationControlModuleIO = [
  {
    "id": 0,
    "fieldName": "Type",
    "title": "Select your the type of I/O.",
    "options": [
      {
        "label": "Remote Transmitter Module",
        "value": "Remote Transmitter Module",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "I/O Expansion Module",
        "value": "I/O Expansion Module",
        "nextQuestions": [
          1
        ]
      },
      {
        "label": "Input Module",
        "value": "Input Module",
        "nextQuestions": [
          6
        ]
      },
      {
        "label": "Output Module",
        "value": "Output Module",
        "nextQuestions": [
          10
        ]
      }
    ]
  },
  {
    "id": 1,
    "fieldName": "Number of I/O",
    "title": "Select your number of I/O.",
    "options": [
      {
        "label": "8 I/O",
        "value": "8 I/O",
        "nextQuestions": [
          2
        ]
      },
      {
        "label": "24 I/O",
        "value": "24 I/O",
        "nextQuestions": [
          4
        ]
      }
    ]
  },
  {
    "id": 2,
    "fieldName": "Output Type",
    "title": "Select your output type.",
    "options": [
      {
        "label": "Relay Output",
        "value": "Relay Output",
        "nextQuestions": [
          3
        ]
      }
    ]
  },
  {
    "id": 3,
    "fieldName": "Connection Type",
    "title": "",
    "options": [
      {
        "label": "Screw Terminal",
        "value": "Screw Terminal",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "Spring Terminal",
        "value": "Spring Terminal",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 4,
    "fieldName": "Output Type",
    "title": "Select your output type.",
    "options": [
      {
        "label": "Relay Output",
        "value": "Relay Output",
        "nextQuestions": [
          5
        ]
      }
    ]
  },
  {
    "id": 5,
    "fieldName": "Connection Type",
    "title": "",
    "options": [
      {
        "label": "Screw Terminal",
        "value": "Screw Terminal",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "Spring Terminal",
        "value": "Spring Terminal",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 6,
    "fieldName": "Number of I/O",
    "title": "Select your number of I/O.",
    "options": [
      {
        "label": "8 I/O",
        "value": "8 I/O",
        "nextQuestions": [
          7
        ]
      },
      {
        "label": "16 I/O",
        "value": "16 I/O",
        "nextQuestions": [
          8
        ]
      },
      {
        "label": "32 I/O",
        "value": "32 I/O",
        "nextQuestions": [
          9
        ]
      }
    ]
  },
  {
    "id": 7,
    "fieldName": "Connection Type",
    "title": "",
    "options": [
      {
        "label": "Screw Terminal",
        "value": "Screw Terminal",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "Spring Terminal",
        "value": "Spring Terminal",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 8,
    "fieldName": "Connection Type",
    "title": "",
    "options": [
      {
        "label": "Screw Terminal",
        "value": "Screw Terminal",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "Spring Terminal",
        "value": "Spring Terminal",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "HE 10 Connector",
        "value": "HE 10 Connector",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 9,
    "fieldName": "Connection Type",
    "title": "",
    "options": [
      {
        "label": "HE 10 Connector",
        "value": "HE 10 Connector",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 10,
    "fieldName": "Number of I/O",
    "title": "Select your number of I/O.",
    "options": [
      {
        "label": "8 I/O",
        "value": "8 I/O",
        "nextQuestions": [
          11
        ]
      },
      {
        "label": "16 I/O",
        "value": "16 I/O",
        "nextQuestions": [
          15
        ]
      },
      {
        "label": "32 I/O",
        "value": "32 I/O",
        "nextQuestions": [
          19
        ]
      }
    ]
  },
  {
    "id": 11,
    "fieldName": "Output Type",
    "title": "Select your output type.",
    "options": [
      {
        "label": "Relay Output",
        "value": "Relay Output",
        "nextQuestions": [
          12
        ]
      },
      {
        "label": "Source Output",
        "value": "Source Output",
        "nextQuestions": [
          13
        ]
      },
      {
        "label": "Sink Output",
        "value": "Sink Output",
        "nextQuestions": [
          14
        ]
      }
    ]
  },
  {
    "id": 12,
    "fieldName": "Connection Type",
    "title": "",
    "options": [
      {
        "label": "Screw Terminal",
        "value": "Screw Terminal",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "Spring Terminal",
        "value": "Spring Terminal",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 13,
    "fieldName": "Connection Type",
    "title": "",
    "options": [
      {
        "label": "Screw Terminal",
        "value": "Screw Terminal",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "Spring Terminal",
        "value": "Spring Terminal",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 14,
    "fieldName": "Connection Type",
    "title": "",
    "options": [
      {
        "label": "Screw Terminal",
        "value": "Screw Terminal",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "Spring Terminal",
        "value": "Spring Terminal",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 15,
    "fieldName": "Output Type",
    "title": "Select your output type.",
    "options": [
      {
        "label": "Relay Output",
        "value": "Relay Output",
        "nextQuestions": [
          16
        ]
      },
      {
        "label": "Source Output",
        "value": "Source Output",
        "nextQuestions": [
          17
        ]
      },
      {
        "label": "Sink Output",
        "value": "Sink Output",
        "nextQuestions": [
          18
        ]
      }
    ]
  },
  {
    "id": 16,
    "fieldName": "Connection Type",
    "title": "",
    "options": [
      {
        "label": "Screw Terminal",
        "value": "Screw Terminal",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "Spring Terminal",
        "value": "Spring Terminal",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 17,
    "fieldName": "Connection Type",
    "title": "",
    "options": [
      {
        "label": "Screw Terminal",
        "value": "Screw Terminal",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "Spring Terminal",
        "value": "Spring Terminal",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "HE 10 Connector",
        "value": "HE 10 Connector",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 18,
    "fieldName": "Connection Type",
    "title": "",
    "options": [
      {
        "label": "Screw Terminal",
        "value": "Screw Terminal",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "Spring Terminal",
        "value": "Spring Terminal",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "HE 10 Connector",
        "value": "HE 10 Connector",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 19,
    "fieldName": "Output Type",
    "title": "Select your output type.",
    "options": [
      {
        "label": "Source Output",
        "value": "Source Output",
        "nextQuestions": [
          20
        ]
      },
      {
        "label": "Sink Output",
        "value": "Sink Output",
        "nextQuestions": [
          21
        ]
      }
    ]
  },
  {
    "id": 20,
    "fieldName": "Connection Type",
    "title": "",
    "options": [
      {
        "label": "HE 10 Connector",
        "value": "HE 10 Connector",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 21,
    "fieldName": "Connection Type",
    "title": "",
    "options": [
      {
        "label": "HE 10 Connector",
        "value": "HE 10 Connector",
        "nextQuestions": [
          -1
        ]
      }
    ]
  }
];

export default AutomationControlModuleIO;

import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import "./styles.scss";

function CustomToast() {
  const [t] = useTranslation();
  // const handleToastClick = () => {};

  return (
    <NavLink className="custom-toast" to="/cart">
      <span>{t("Successfully added to Bill of Materials.")}</span>
      <span>{t("Click here to view.")}</span>
    </NavLink>
  );
}

export default CustomToast;

/* eslint-disable */

const CapacitiveProximitySensor = [
  {
    "id": 0,
    "fieldName": "Sensing Distance",
    "title": "Please select the sensing distance.",
    "options": [
      {
        "label": "8 mm",
        "value": "8 mm",
        "nextQuestions": [
          1
        ]
      },
      {
        "label": "15 mm",
        "value": "15 mm",
        "nextQuestions": [
          6
        ]
      },
      {
        "label": "20 mm",
        "value": "20 mm",
        "nextQuestions": [
          12
        ]
      }
    ]
  },
  {
    "id": 1,
    "fieldName": "Output Function",
    "title": "Please select the output function.",
    "options": [
      {
        "label": "NO/NC , PNP",
        "value": "NO/NC , PNP",
        "nextQuestions": [
          2
        ]
      },
      {
        "label": "NO, PNP",
        "value": "NO, PNP",
        "nextQuestions": [
          3
        ]
      },
      {
        "label": "NO, NPN",
        "value": "NO, NPN",
        "nextQuestions": [
          4
        ]
      },
      {
        "label": "NO",
        "value": "NO",
        "nextQuestions": [
          5
        ]
      }
    ]
  },
  {
    "id": 2,
    "fieldName": "Connection",
    "title": "Please select the connection type.",
    "options": [
      {
        "label": "M12 Connector",
        "value": "M12 Connector",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 3,
    "fieldName": "Connection",
    "title": "Please select the connection type.",
    "options": [
      {
        "label": "2 M Pre-cabled",
        "value": "2 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 4,
    "fieldName": "Connection",
    "title": "Please select the connection type.",
    "options": [
      {
        "label": "2 M Pre-cabled",
        "value": "2 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 5,
    "fieldName": "Connection",
    "title": "Please select the connection type.",
    "options": [
      {
        "label": "2 M Pre-cabled",
        "value": "2 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 6,
    "fieldName": "Output Function",
    "title": "Please select the output function.",
    "options": [
      {
        "label": "NO/NC, PNP",
        "value": "NO/NC, PNP",
        "nextQuestions": [
          7
        ]
      },
      {
        "label": "NO, PNP",
        "value": "NO, PNP",
        "nextQuestions": [
          8
        ]
      },
      {
        "label": "NO, NPN",
        "value": "NO, NPN",
        "nextQuestions": [
          9
        ]
      },
      {
        "label": "NO",
        "value": "NO",
        "nextQuestions": [
          10
        ]
      },
      {
        "label": "NC",
        "value": "NC",
        "nextQuestions": [
          11
        ]
      }
    ]
  },
  {
    "id": 7,
    "fieldName": "Connection",
    "title": "Please select the connection type.",
    "options": [
      {
        "label": "M12 Connector",
        "value": "M12 Connector",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 8,
    "fieldName": "Connection",
    "title": "Please select the connection type.",
    "options": [
      {
        "label": "2 M Pre-cabled",
        "value": "2 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 9,
    "fieldName": "Connection",
    "title": "Please select the connection type.",
    "options": [
      {
        "label": "2 M Pre-cabled",
        "value": "2 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 10,
    "fieldName": "Connection",
    "title": "Please select the connection type.",
    "options": [
      {
        "label": "2 M Pre-cabled",
        "value": "2 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 11,
    "fieldName": "Connection",
    "title": "Please select the connection type.",
    "options": [
      {
        "label": "2 M Pre-cabled",
        "value": "2 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 12,
    "fieldName": "Output Function",
    "title": "Please select the output function.",
    "options": [
      {
        "label": "NO",
        "value": "NO",
        "nextQuestions": [
          13
        ]
      },
      {
        "label": "NC",
        "value": "NC",
        "nextQuestions": [
          14
        ]
      }
    ]
  },
  {
    "id": 13,
    "fieldName": "Connection",
    "title": "Please select the connection type.",
    "options": [
      {
        "label": "2 M Pre-cabled",
        "value": "2 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 14,
    "fieldName": "Connection",
    "title": "Please select the connection type.",
    "options": [
      {
        "label": "2 M Pre-cabled",
        "value": "2 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      }
    ]
  }
];

export default CapacitiveProximitySensor;

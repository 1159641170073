import PropTypes from 'prop-types';

function IconPage({ color }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="13.458" viewBox="0 0 11 13.458">
      <g id="Group_318" data-name="Group 318" transform="translate(0 55.114)">
        <path id="Path_54" data-name="Path 54" d="M10.931-42.722a1.029,1.029,0,0,1-.309.756.989.989,0,0,1-.722.309H1.031a.989.989,0,0,1-.722-.309A1.028,1.028,0,0,1,0-42.722V-53.894a1.029,1.029,0,0,1,.309-.756.989.989,0,0,1,.722-.309H5.706a.457.457,0,0,1,.275.1.428.428,0,0,1,.138.309.327.327,0,0,1-.138.275.4.4,0,0,1-.275.137H1.031q-.206,0-.206.241v11.172q0,.241.206.241H9.9q.206,0,.206-.241l.069-10L8.044-53.963l.069,1.65,1.581-.344a.43.43,0,0,1,.275.034.443.443,0,0,1,.206.275,1.092,1.092,0,0,1-.069.309.443.443,0,0,1-.275.206l-2.062.447a.557.557,0,0,1-.344-.1.362.362,0,0,1-.138-.309l-.069-2.888a.384.384,0,0,1,.206-.378.428.428,0,0,1,.412,0l2.956,1.753a.355.355,0,0,1,.206.344ZM8.869-49.082a.489.489,0,0,1-.069.275.466.466,0,0,1-.344.138H2.2a.277.277,0,0,1-.275-.138.327.327,0,0,1-.137-.275.427.427,0,0,1,.137-.309.457.457,0,0,1,.275-.1H8.456a.664.664,0,0,1,.344.1A.845.845,0,0,1,8.869-49.082Zm0,2.131a.489.489,0,0,1-.069.275.466.466,0,0,1-.344.138H2.2a.4.4,0,0,1-.275-.138.4.4,0,0,1-.137-.275.427.427,0,0,1,.137-.309.309.309,0,0,1,.275-.1H8.456a.663.663,0,0,1,.344.1A.845.845,0,0,1,8.869-46.95Zm0,2.1a.489.489,0,0,1-.069.275.466.466,0,0,1-.344.138H2.2a.277.277,0,0,1-.275-.138.327.327,0,0,1-.137-.275.427.427,0,0,1,.137-.309.309.309,0,0,1,.275-.1H8.456a.664.664,0,0,1,.344.1A.683.683,0,0,1,8.869-44.853Z" fill={color} />
      </g>
    </svg>

  );
}

IconPage.propTypes = {
  color: PropTypes.string,
};

IconPage.defaultProps = {
  color: '#000',
};

export default IconPage;

/* eslint-disable */

const UninterruptiblePowerSupply = [
  {
    'id': 0,
    'fieldName': 'UPS Voltage Type',
    'title': 'Please indicate your voltage type.',
    'options': [
      {
        'label': 'AC',
        'value': 'AC',
        'nextQuestions': [
          1
        ]
      },
      {
        'label': 'DC',
        'value': 'DC',
        'nextQuestions': [
          2
        ]
      }
    ]
  },
  {
    'id': 1,
    'fieldName': 'UPS Rated Capacity',
    'title': 'Please indicate your UPS Capacity.',
    'options': [
      {
        'label': '800 W',
        'value': '800 W',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '1600 W',
        'value': '1600 W',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '2400 W',
        'value': '2400 W',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '6000 W',
        'value': '6000 W',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '10000 W',
        'value': '10000 W',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 2,
    'fieldName': 'UPS Rated Capacity',
    'title': 'Please indicate your UPS Capacity.',
    'options': [
      {
        'label': '240 W',
        'value': '240 W',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '480 W',
        'value': '480 W',
        'nextQuestions': [
          -1
        ]
      }
    ]
  }
];

export default UninterruptiblePowerSupply;

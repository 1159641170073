/* eslint-disable */

const TemperatureControl = [
  {
    id: 0,
    fieldName: 'Temperature Control',
    title: 'Please select the supply voltage.',
    options: [
      {
        label: '100-240 VAC',
        value: '100-240 VAC',
        nextQuestions: [
          1
        ]
      },
      {
        label: '24 VAC/VDC',
        value: '24 VAC/VDC',
        nextQuestions: [
          62
        ]
      }
    ]
  },
  {
    id: 1,
    fieldName: 'Temperature Control',
    title: 'Do you need modbus communication?',
    options: [
      {
        label: 'Without Modbus',
        value: 'Without Modbus',
        nextQuestions: [
          2
        ]
      },
      {
        label: 'With Modbus',
        value: 'With Modbus',
        nextQuestions: [
          32
        ]
      }
    ]
  },
  {
    id: 2,
    fieldName: 'Temperature Control',
    title: 'Please select the output voltage.',
    options: [
      {
        label: '≤100 VDC',
        value: '≤100 VDC',
        nextQuestions: [
          3
        ]
      },
      {
        label: '110-150 VDC',
        value: '110-150 VDC',
        nextQuestions: [
          10
        ]
      },
      {
        label: '≤42 VAC',
        value: '≤42 VAC',
        nextQuestions: [
          16
        ]
      },
      {
        label: '48-600 VAC',
        value: '48-600 VAC',
        nextQuestions: [
          23
        ]
      }
    ]
  },
  {
    id: 3,
    fieldName: 'Temperature Control',
    title: 'Please select the load current.',
    options: [
      {
        label: '6 A',
        value: '6 A',
        nextQuestions: [
          4
        ]
      },
      {
        label: '12 A',
        value: '12 A',
        nextQuestions: [
          5
        ]
      },
      {
        label: '20 A',
        value: '20 A',
        nextQuestions: [
          6
        ]
      },
      {
        label: '30 A',
        value: '30 A',
        nextQuestions: [
          7
        ]
      },
      {
        label: '25 A',
        value: '25 A',
        nextQuestions: [
          8
        ]
      },
      {
        label: '40 A',
        value: '40 A',
        nextQuestions: [
          9
        ]
      }
    ]
  },
  {
    id: 4,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Embedded Heatsink',
        value: 'Embedded Heatsink',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 5,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Embedded Heatsink',
        value: 'Embedded Heatsink',
        nextQuestions: [
          -1
        ]
      },
      {
        label: 'Panel Mount',
        value: 'Panel Mount',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 6,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Embedded Heatsink',
        value: 'Embedded Heatsink',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 7,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Embedded Heatsink',
        value: 'Embedded Heatsink',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 8,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Panel Mount',
        value: 'Panel Mount',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 9,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Panel Mount',
        value: 'Panel Mount',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 10,
    fieldName: 'Temperature Control',
    title: 'Please select the load current.',
    options: [
      {
        label: '20 A',
        value: '20 A',
        nextQuestions: [
          11
        ]
      },
      {
        label: '30 A',
        value: '30 A',
        nextQuestions: [
          12
        ]
      },
      {
        label: '12 A',
        value: '12 A',
        nextQuestions: [
          13
        ]
      },
      {
        label: '25 A',
        value: '25 A',
        nextQuestions: [
          14
        ]
      },
      {
        label: '40 A',
        value: '40 A',
        nextQuestions: [
          15
        ]
      }
    ]
  },
  {
    id: 11,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Embedded Heatsink',
        value: 'Embedded Heatsink',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 12,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Embedded Heatsink',
        value: 'Embedded Heatsink',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 13,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Panel Mount',
        value: 'Panel Mount',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 14,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Panel Mount',
        value: 'Panel Mount',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 15,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Panel Mount',
        value: 'Panel Mount',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 16,
    fieldName: 'Temperature Control',
    title: 'Please select the load current.',
    options: [
      {
        label: '6 A',
        value: '6 A',
        nextQuestions: [
          17
        ]
      },
      {
        label: '12 A',
        value: '12 A',
        nextQuestions: [
          18
        ]
      },
      {
        label: '10 A',
        value: '10 A',
        nextQuestions: [
          19
        ]
      },
      {
        label: '25 A',
        value: '25 A',
        nextQuestions: [
          20
        ]
      },
      {
        label: '50 A',
        value: '50 A',
        nextQuestions: [
          21
        ]
      },
      {
        label: '75 A',
        value: '75 A',
        nextQuestions: [
          22
        ]
      }
    ]
  },
  {
    id: 17,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Embedded Heatsink',
        value: 'Embedded Heatsink',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 18,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Embedded Heatsink',
        value: 'Embedded Heatsink',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 19,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Panel Mount',
        value: 'Panel Mount',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 20,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Panel Mount',
        value: 'Panel Mount',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 21,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Panel Mount',
        value: 'Panel Mount',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 22,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Panel Mount',
        value: 'Panel Mount',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 23,
    fieldName: 'Temperature Control',
    title: 'Please select the load current.',
    options: [
      {
        label: '6 A',
        value: '6 A',
        nextQuestions: [
          24
        ]
      },
      {
        label: '12 A',
        value: '12 A',
        nextQuestions: [
          25
        ]
      },
      {
        label: '20 A',
        value: '20 A',
        nextQuestions: [
          26
        ]
      },
      {
        label: '35 A',
        value: '35 A',
        nextQuestions: [
          27
        ]
      },
      {
        label: '45 A',
        value: '45 A',
        nextQuestions: [
          28
        ]
      },
      {
        label: '60 A',
        value: '60 A',
        nextQuestions: [
          29
        ]
      },
      {
        label: '50 A',
        value: '50 A',
        nextQuestions: [
          30
        ]
      },
      {
        label: '75 A',
        value: '75 A',
        nextQuestions: [
          31
        ]
      }
    ]
  },
  {
    id: 24,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Embedded Heatsink',
        value: 'Embedded Heatsink',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 25,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Embedded Heatsink',
        value: 'Embedded Heatsink',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 26,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Embedded Heatsink',
        value: 'Embedded Heatsink',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 27,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Embedded Heatsink',
        value: 'Embedded Heatsink',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 28,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Embedded Heatsink',
        value: 'Embedded Heatsink',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 29,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Embedded Heatsink',
        value: 'Embedded Heatsink',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 30,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Panel Mount',
        value: 'Panel Mount',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 31,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Panel Mount',
        value: 'Panel Mount',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 32,
    fieldName: 'Temperature Control',
    title: 'Please select the output voltage.',
    options: [
      {
        label: '≤100 VDC',
        value: '≤100 VDC',
        nextQuestions: [
          33
        ]
      },
      {
        label: '110-150 VDC',
        value: '110-150 VDC',
        nextQuestions: [
          40
        ]
      },
      {
        label: '≤42 VAC',
        value: '≤42 VAC',
        nextQuestions: [
          46
        ]
      },
      {
        label: '48-600 VAC',
        value: '48-600 VAC',
        nextQuestions: [
          53
        ]
      }
    ]
  },
  {
    id: 33,
    fieldName: 'Temperature Control',
    title: 'Please select the load current.',
    options: [
      {
        label: '6 A',
        value: '6 A',
        nextQuestions: [
          34
        ]
      },
      {
        label: '12 A',
        value: '12 A',
        nextQuestions: [
          35
        ]
      },
      {
        label: '20 A',
        value: '20 A',
        nextQuestions: [
          36
        ]
      },
      {
        label: '30 A',
        value: '30 A',
        nextQuestions: [
          37
        ]
      },
      {
        label: '25 A',
        value: '25 A',
        nextQuestions: [
          38
        ]
      },
      {
        label: '40 A',
        value: '40 A',
        nextQuestions: [
          39
        ]
      }
    ]
  },
  {
    id: 34,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Embedded Heatsink',
        value: 'Embedded Heatsink',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 35,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Embedded Heatsink',
        value: 'Embedded Heatsink',
        nextQuestions: [
          -1
        ]
      },
      {
        label: 'Panel Mount',
        value: 'Panel Mount',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 36,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Embedded Heatsink',
        value: 'Embedded Heatsink',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 37,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Embedded Heatsink',
        value: 'Embedded Heatsink',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 38,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Panel Mount',
        value: 'Panel Mount',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 39,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Panel Mount',
        value: 'Panel Mount',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 40,
    fieldName: 'Temperature Control',
    title: 'Please select the load current.',
    options: [
      {
        label: '20 A',
        value: '20 A',
        nextQuestions: [
          41
        ]
      },
      {
        label: '30 A',
        value: '30 A',
        nextQuestions: [
          42
        ]
      },
      {
        label: '12 A',
        value: '12 A',
        nextQuestions: [
          43
        ]
      },
      {
        label: '25 A',
        value: '25 A',
        nextQuestions: [
          44
        ]
      },
      {
        label: '40 A',
        value: '40 A',
        nextQuestions: [
          45
        ]
      }
    ]
  },
  {
    id: 41,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Embedded Heatsink',
        value: 'Embedded Heatsink',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 42,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Embedded Heatsink',
        value: 'Embedded Heatsink',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 43,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Panel Mount',
        value: 'Panel Mount',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 44,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Panel Mount',
        value: 'Panel Mount',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 45,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Panel Mount',
        value: 'Panel Mount',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 46,
    fieldName: 'Temperature Control',
    title: 'Please select the load current.',
    options: [
      {
        label: '6 A',
        value: '6 A',
        nextQuestions: [
          47
        ]
      },
      {
        label: '12 A',
        value: '12 A',
        nextQuestions: [
          48
        ]
      },
      {
        label: '10 A',
        value: '10 A',
        nextQuestions: [
          49
        ]
      },
      {
        label: '25 A',
        value: '25 A',
        nextQuestions: [
          50
        ]
      },
      {
        label: '50 A',
        value: '50 A',
        nextQuestions: [
          51
        ]
      },
      {
        label: '75 A',
        value: '75 A',
        nextQuestions: [
          52
        ]
      }
    ]
  },
  {
    id: 47,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Embedded Heatsink',
        value: 'Embedded Heatsink',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 48,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Embedded Heatsink',
        value: 'Embedded Heatsink',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 49,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Panel Mount',
        value: 'Panel Mount',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 50,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Panel Mount',
        value: 'Panel Mount',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 51,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Panel Mount',
        value: 'Panel Mount',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 52,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Panel Mount',
        value: 'Panel Mount',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 53,
    fieldName: 'Temperature Control',
    title: 'Please select the load current.',
    options: [
      {
        label: '6 A',
        value: '6 A',
        nextQuestions: [
          54
        ]
      },
      {
        label: '12 A',
        value: '12 A',
        nextQuestions: [
          55
        ]
      },
      {
        label: '20 A',
        value: '20 A',
        nextQuestions: [
          56
        ]
      },
      {
        label: '35 A',
        value: '35 A',
        nextQuestions: [
          57
        ]
      },
      {
        label: '45 A',
        value: '45 A',
        nextQuestions: [
          58
        ]
      },
      {
        label: '60 A',
        value: '60 A',
        nextQuestions: [
          59
        ]
      },
      {
        label: '50 A',
        value: '50 A',
        nextQuestions: [
          60
        ]
      },
      {
        label: '75 A',
        value: '75 A',
        nextQuestions: [
          61
        ]
      }
    ]
  },
  {
    id: 54,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Embedded Heatsink',
        value: 'Embedded Heatsink',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 55,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Embedded Heatsink',
        value: 'Embedded Heatsink',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 56,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Embedded Heatsink',
        value: 'Embedded Heatsink',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 57,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Embedded Heatsink',
        value: 'Embedded Heatsink',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 58,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Embedded Heatsink',
        value: 'Embedded Heatsink',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 59,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Embedded Heatsink',
        value: 'Embedded Heatsink',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 60,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Panel Mount',
        value: 'Panel Mount',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 61,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Panel Mount',
        value: 'Panel Mount',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 62,
    fieldName: 'Temperature Control',
    title: 'Do you need modbus communication?',
    options: [
      {
        label: 'Without Modbus',
        value: 'Without Modbus',
        nextQuestions: [
          63
        ]
      },
      {
        label: 'With Modbus',
        value: 'With Modbus',
        nextQuestions: [
          93
        ]
      }
    ]
  },
  {
    id: 63,
    fieldName: 'Temperature Control',
    title: 'Please select the output voltage.',
    options: [
      {
        label: '≤100 VDC',
        value: '≤100 VDC',
        nextQuestions: [
          64
        ]
      },
      {
        label: '110-150 VDC',
        value: '110-150 VDC',
        nextQuestions: [
          71
        ]
      },
      {
        label: '≤42 VAC',
        value: '≤42 VAC',
        nextQuestions: [
          77
        ]
      },
      {
        label: '48-600 VAC',
        value: '48-600 VAC',
        nextQuestions: [
          84
        ]
      }
    ]
  },
  {
    id: 64,
    fieldName: 'Temperature Control',
    title: 'Please select the load current.',
    options: [
      {
        label: '6 A',
        value: '6 A',
        nextQuestions: [
          65
        ]
      },
      {
        label: '12 A',
        value: '12 A',
        nextQuestions: [
          66
        ]
      },
      {
        label: '20 A',
        value: '20 A',
        nextQuestions: [
          67
        ]
      },
      {
        label: '30 A',
        value: '30 A',
        nextQuestions: [
          68
        ]
      },
      {
        label: '25 A',
        value: '25 A',
        nextQuestions: [
          69
        ]
      },
      {
        label: '40 A',
        value: '40 A',
        nextQuestions: [
          70
        ]
      }
    ]
  },
  {
    id: 65,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Embedded Heatsink',
        value: 'Embedded Heatsink',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 66,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Embedded Heatsink',
        value: 'Embedded Heatsink',
        nextQuestions: [
          -1
        ]
      },
      {
        label: 'Panel Mount',
        value: 'Panel Mount',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 67,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Embedded Heatsink',
        value: 'Embedded Heatsink',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 68,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Embedded Heatsink',
        value: 'Embedded Heatsink',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 69,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Panel Mount',
        value: 'Panel Mount',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 70,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Panel Mount',
        value: 'Panel Mount',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 71,
    fieldName: 'Temperature Control',
    title: 'Please select the load current.',
    options: [
      {
        label: '20 A',
        value: '20 A',
        nextQuestions: [
          72
        ]
      },
      {
        label: '30 A',
        value: '30 A',
        nextQuestions: [
          73
        ]
      },
      {
        label: '12 A',
        value: '12 A',
        nextQuestions: [
          74
        ]
      },
      {
        label: '25 A',
        value: '25 A',
        nextQuestions: [
          75
        ]
      },
      {
        label: '40 A',
        value: '40 A',
        nextQuestions: [
          76
        ]
      }
    ]
  },
  {
    id: 72,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Embedded Heatsink',
        value: 'Embedded Heatsink',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 73,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Embedded Heatsink',
        value: 'Embedded Heatsink',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 74,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Panel Mount',
        value: 'Panel Mount',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 75,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Panel Mount',
        value: 'Panel Mount',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 76,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Panel Mount',
        value: 'Panel Mount',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 77,
    fieldName: 'Temperature Control',
    title: 'Please select the load current.',
    options: [
      {
        label: '6 A',
        value: '6 A',
        nextQuestions: [
          78
        ]
      },
      {
        label: '12 A',
        value: '12 A',
        nextQuestions: [
          79
        ]
      },
      {
        label: '10 A',
        value: '10 A',
        nextQuestions: [
          80
        ]
      },
      {
        label: '25 A',
        value: '25 A',
        nextQuestions: [
          81
        ]
      },
      {
        label: '50 A',
        value: '50 A',
        nextQuestions: [
          82
        ]
      },
      {
        label: '75 A',
        value: '75 A',
        nextQuestions: [
          83
        ]
      }
    ]
  },
  {
    id: 78,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Embedded Heatsink',
        value: 'Embedded Heatsink',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 79,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Embedded Heatsink',
        value: 'Embedded Heatsink',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 80,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Panel Mount',
        value: 'Panel Mount',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 81,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Panel Mount',
        value: 'Panel Mount',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 82,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Panel Mount',
        value: 'Panel Mount',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 83,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Panel Mount',
        value: 'Panel Mount',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 84,
    fieldName: 'Temperature Control',
    title: 'Please select the load current.',
    options: [
      {
        label: '6 A',
        value: '6 A',
        nextQuestions: [
          85
        ]
      },
      {
        label: '12 A',
        value: '12 A',
        nextQuestions: [
          86
        ]
      },
      {
        label: '20 A',
        value: '20 A',
        nextQuestions: [
          87
        ]
      },
      {
        label: '35 A',
        value: '35 A',
        nextQuestions: [
          88
        ]
      },
      {
        label: '45 A',
        value: '45 A',
        nextQuestions: [
          89
        ]
      },
      {
        label: '60 A',
        value: '60 A',
        nextQuestions: [
          90
        ]
      },
      {
        label: '50 A',
        value: '50 A',
        nextQuestions: [
          91
        ]
      },
      {
        label: '75 A',
        value: '75 A',
        nextQuestions: [
          92
        ]
      }
    ]
  },
  {
    id: 85,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Embedded Heatsink',
        value: 'Embedded Heatsink',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 86,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Embedded Heatsink',
        value: 'Embedded Heatsink',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 87,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Embedded Heatsink',
        value: 'Embedded Heatsink',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 88,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Embedded Heatsink',
        value: 'Embedded Heatsink',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 89,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Embedded Heatsink',
        value: 'Embedded Heatsink',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 90,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Embedded Heatsink',
        value: 'Embedded Heatsink',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 91,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Panel Mount',
        value: 'Panel Mount',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 92,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Panel Mount',
        value: 'Panel Mount',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 93,
    fieldName: 'Temperature Control',
    title: 'Please select the output voltage.',
    options: [
      {
        label: '≤100 VDC',
        value: '≤100 VDC',
        nextQuestions: [
          94
        ]
      },
      {
        label: '110-150 VDC',
        value: '110-150 VDC',
        nextQuestions: [
          101
        ]
      },
      {
        label: '≤42 VAC',
        value: '≤42 VAC',
        nextQuestions: [
          107
        ]
      },
      {
        label: '48-600 VAC',
        value: '48-600 VAC',
        nextQuestions: [
          114
        ]
      }
    ]
  },
  {
    id: 94,
    fieldName: 'Temperature Control',
    title: 'Please select the load current.',
    options: [
      {
        label: '6 A',
        value: '6 A',
        nextQuestions: [
          95
        ]
      },
      {
        label: '12 A',
        value: '12 A',
        nextQuestions: [
          96
        ]
      },
      {
        label: '20 A',
        value: '20 A',
        nextQuestions: [
          97
        ]
      },
      {
        label: '30 A',
        value: '30 A',
        nextQuestions: [
          98
        ]
      },
      {
        label: '25 A',
        value: '25 A',
        nextQuestions: [
          99
        ]
      },
      {
        label: '40 A',
        value: '40 A',
        nextQuestions: [
          100
        ]
      }
    ]
  },
  {
    id: 95,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Embedded Heatsink',
        value: 'Embedded Heatsink',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 96,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Embedded Heatsink',
        value: 'Embedded Heatsink',
        nextQuestions: [
          -1
        ]
      },
      {
        label: 'Panel Mount',
        value: 'Panel Mount',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 97,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Embedded Heatsink',
        value: 'Embedded Heatsink',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 98,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Embedded Heatsink',
        value: 'Embedded Heatsink',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 99,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Panel Mount',
        value: 'Panel Mount',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 100,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Panel Mount',
        value: 'Panel Mount',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 101,
    fieldName: 'Temperature Control',
    title: 'Please select the load current.',
    options: [
      {
        label: '20 A',
        value: '20 A',
        nextQuestions: [
          102
        ]
      },
      {
        label: '30 A',
        value: '30 A',
        nextQuestions: [
          103
        ]
      },
      {
        label: '12 A',
        value: '12 A',
        nextQuestions: [
          104
        ]
      },
      {
        label: '25 A',
        value: '25 A',
        nextQuestions: [
          105
        ]
      },
      {
        label: '40 A',
        value: '40 A',
        nextQuestions: [
          106
        ]
      }
    ]
  },
  {
    id: 102,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Embedded Heatsink',
        value: 'Embedded Heatsink',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 103,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Embedded Heatsink',
        value: 'Embedded Heatsink',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 104,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Panel Mount',
        value: 'Panel Mount',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 105,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Panel Mount',
        value: 'Panel Mount',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 106,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Panel Mount',
        value: 'Panel Mount',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 107,
    fieldName: 'Temperature Control',
    title: 'Please select the load current.',
    options: [
      {
        label: '6 A',
        value: '6 A',
        nextQuestions: [
          108
        ]
      },
      {
        label: '12 A',
        value: '12 A',
        nextQuestions: [
          109
        ]
      },
      {
        label: '10 A',
        value: '10 A',
        nextQuestions: [
          110
        ]
      },
      {
        label: '25 A',
        value: '25 A',
        nextQuestions: [
          111
        ]
      },
      {
        label: '50 A',
        value: '50 A',
        nextQuestions: [
          112
        ]
      },
      {
        label: '75 A',
        value: '75 A',
        nextQuestions: [
          113
        ]
      }
    ]
  },
  {
    id: 108,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Embedded Heatsink',
        value: 'Embedded Heatsink',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 109,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Embedded Heatsink',
        value: 'Embedded Heatsink',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 110,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Panel Mount',
        value: 'Panel Mount',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 111,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Panel Mount',
        value: 'Panel Mount',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 112,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Panel Mount',
        value: 'Panel Mount',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 113,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Panel Mount',
        value: 'Panel Mount',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 114,
    fieldName: 'Temperature Control',
    title: 'Please select the load current.',
    options: [
      {
        label: '6 A',
        value: '6 A',
        nextQuestions: [
          115
        ]
      },
      {
        label: '12 A',
        value: '12 A',
        nextQuestions: [
          116
        ]
      },
      {
        label: '20 A',
        value: '20 A',
        nextQuestions: [
          117
        ]
      },
      {
        label: '35 A',
        value: '35 A',
        nextQuestions: [
          118
        ]
      },
      {
        label: '45 A',
        value: '45 A',
        nextQuestions: [
          119
        ]
      },
      {
        label: '60 A',
        value: '60 A',
        nextQuestions: [
          120
        ]
      },
      {
        label: '50 A',
        value: '50 A',
        nextQuestions: [
          121
        ]
      },
      {
        label: '75 A',
        value: '75 A',
        nextQuestions: [
          122
        ]
      }
    ]
  },
  {
    id: 115,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Embedded Heatsink',
        value: 'Embedded Heatsink',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 116,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Embedded Heatsink',
        value: 'Embedded Heatsink',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 117,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Embedded Heatsink',
        value: 'Embedded Heatsink',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 118,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Embedded Heatsink',
        value: 'Embedded Heatsink',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 119,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Embedded Heatsink',
        value: 'Embedded Heatsink',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 120,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Embedded Heatsink',
        value: 'Embedded Heatsink',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 121,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Panel Mount',
        value: 'Panel Mount',
        nextQuestions: [
          -1
        ]
      }
    ]
  },
  {
    id: 122,
    fieldName: 'Temperature Control',
    title: 'Heat Dissipation',
    options: [
      {
        label: 'Panel Mount',
        value: 'Panel Mount',
        nextQuestions: [
          -1
        ]
      }
    ]
  }
];

export default TemperatureControl;

/* eslint-disable */

const AutomationControl = [
  {
    'id': 0,
    'fieldName': 'Funtionality',
    'title': 'Select your functionality.',
    'options': [
      {
        'label': 'Basic Application',
        'value': 'Basic Application',
        'nextQuestions': [
          1
        ]
      },
      {
        'label': 'Advance Application',
        'value': 'Advance Application',
        'nextQuestions': [
          88
        ]
      },
      {
        'label': 'Synchronized Motion or Embedded Safety',
        'value': 'Synchronized Motion or Embedded Safety',
        'nextQuestions': [
          133
        ]
      }
    ]
  },
  {
    'id': 1,
    'fieldName': 'Number of I/O',
    'title': 'Select your number of embedded I/O.',
    'options': [
      {
        'label': '16 I/O',
        'value': '16 I/O',
        'nextQuestions': [
          2
        ]
      },
      {
        'label': '24 I/O',
        'value': '24 I/O',
        'nextQuestions': [
          40
        ]
      },
      {
        'label': '40 I/O',
        'value': '40 I/O',
        'nextQuestions': [
          59
        ]
      },
      {
        'label': '32 I/O',
        'value': '32 I/O',
        'nextQuestions': [
          78
        ]
      }
    ]
  },
  {
    'id': 2,
    'fieldName': 'Output Type',
    'title': 'Select your output type.',
    'options': [
      {
        'label': 'Source Output',
        'value': 'Source Output',
        'nextQuestions': [
          3
        ]
      },
      {
        'label': 'Sink Output',
        'value': 'Sink Output',
        'nextQuestions': [
          18
        ]
      },
      {
        'label': 'Relay Output',
        'value': 'Relay Output',
        'nextQuestions': [
          27
        ]
      }
    ]
  },
  {
    'id': 3,
    'fieldName': 'Ethernet Port',
    'title': 'Would you like to have Ethernet Port?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          4
        ]
      },
      {
        'label': 'Yes',
        'value': 'Yes',
        'nextQuestions': [
          11
        ]
      }
    ]
  },
  {
    'id': 4,
    'fieldName': 'CANOpen',
    'title': 'Would you like to have CANopen?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          5
        ]
      }
    ]
  },
  {
    'id': 5,
    'fieldName': 'Connection Type',
    'title': 'Select your connection type.',
    'options': [
      {
        'label': 'Screw Terminal',
        'value': 'Screw Terminal',
        'nextQuestions': [
          6
        ]
      },
      {
        'label': 'Spring Terminal',
        'value': 'Spring Terminal',
        'nextQuestions': [
          9
        ]
      }
    ]
  },
  {
    'id': 6,
    'fieldName': 'Format',
    'title': 'Select your controller format.',
    'options': [
      {
        'label': 'Standard',
        'value': 'Standard',
        'nextQuestions': [
          7
        ]
      },
      {
        'label': 'Book',
        'value': 'Book',
        'nextQuestions': [
          8
        ]
      }
    ]
  },
  {
    'id': 7,
    'fieldName': 'Power Supply Output Current',
    'title': 'Select your Power Supply output current.',
    'options': [
      {
        'label': '2.1 A',
        'value': '2.1 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3.13 A',
        'value': '3.13 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3.8 A',
        'value': '3.8 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '5 A',
        'value': '5 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '10 A',
        'value': '10 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '20 A',
        'value': '20 A',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 8,
    'fieldName': 'Power Supply Output Current',
    'title': 'Select your Power Supply output current.',
    'options': [
      {
        'label': '2.1 A',
        'value': '2.1 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3.13 A',
        'value': '3.13 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3.8 A',
        'value': '3.8 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '5 A',
        'value': '5 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '10 A',
        'value': '10 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '20 A',
        'value': '20 A',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 9,
    'fieldName': 'Format',
    'title': 'Select your controller format.',
    'options': [
      {
        'label': 'Book',
        'value': 'Book',
        'nextQuestions': [
          10
        ]
      }
    ]
  },
  {
    'id': 10,
    'fieldName': 'Power Supply Output Current',
    'title': 'Select your Power Supply output current.',
    'options': [
      {
        'label': '2.1 A',
        'value': '2.1 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3.13 A',
        'value': '3.13 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3.8 A',
        'value': '3.8 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '5 A',
        'value': '5 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '10 A',
        'value': '10 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '20 A',
        'value': '20 A',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 11,
    'fieldName': 'CANOpen',
    'title': 'Would you like to have CANopen?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          12
        ]
      }
    ]
  },
  {
    'id': 12,
    'fieldName': 'Connection Type',
    'title': 'Select your connection type.',
    'options': [
      {
        'label': 'Screw Terminal',
        'value': 'Screw Terminal',
        'nextQuestions': [
          13
        ]
      },
      {
        'label': 'Spring Terminal',
        'value': 'Spring Terminal',
        'nextQuestions': [
          16
        ]
      }
    ]
  },
  {
    'id': 13,
    'fieldName': 'Format',
    'title': 'Select your controller format.',
    'options': [
      {
        'label': 'Standard',
        'value': 'Standard',
        'nextQuestions': [
          14
        ]
      },
      {
        'label': 'Book',
        'value': 'Book',
        'nextQuestions': [
          15
        ]
      }
    ]
  },
  {
    'id': 14,
    'fieldName': 'Power Supply Output Current',
    'title': 'Select your Power Supply output current.',
    'options': [
      {
        'label': '2.1 A',
        'value': '2.1 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3.13 A',
        'value': '3.13 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3.8 A',
        'value': '3.8 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '5 A',
        'value': '5 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '10 A',
        'value': '10 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '20 A',
        'value': '20 A',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 15,
    'fieldName': 'Power Supply Output Current',
    'title': 'Select your Power Supply output current.',
    'options': [
      {
        'label': '2.1 A',
        'value': '2.1 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3.13 A',
        'value': '3.13 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3.8 A',
        'value': '3.8 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '5 A',
        'value': '5 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '10 A',
        'value': '10 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '20 A',
        'value': '20 A',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 16,
    'fieldName': 'Format',
    'title': 'Select your controller format.',
    'options': [
      {
        'label': 'Book',
        'value': 'Book',
        'nextQuestions': [
          17
        ]
      }
    ]
  },
  {
    'id': 17,
    'fieldName': 'Power Supply Output Current',
    'title': 'Select your Power Supply output current.',
    'options': [
      {
        'label': '2.1 A',
        'value': '2.1 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3.13 A',
        'value': '3.13 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3.8 A',
        'value': '3.8 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '5 A',
        'value': '5 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '10 A',
        'value': '10 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '20 A',
        'value': '20 A',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 18,
    'fieldName': 'Ethernet Port',
    'title': 'Would you like to have Ethernet Port?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          19
        ]
      },
      {
        'label': 'Yes',
        'value': 'Yes',
        'nextQuestions': [
          23
        ]
      }
    ]
  },
  {
    'id': 19,
    'fieldName': 'CANOpen',
    'title': 'Would you like to have CANopen?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          20
        ]
      }
    ]
  },
  {
    'id': 20,
    'fieldName': 'Connection Type',
    'title': 'Select your connection type.',
    'options': [
      {
        'label': 'Screw Terminal',
        'value': 'Screw Terminal',
        'nextQuestions': [
          21
        ]
      }
    ]
  },
  {
    'id': 21,
    'fieldName': 'Format',
    'title': 'Select your controller format.',
    'options': [
      {
        'label': 'Standard',
        'value': 'Standard',
        'nextQuestions': [
          22
        ]
      }
    ]
  },
  {
    'id': 22,
    'fieldName': 'Power Supply Output Current',
    'title': 'Select your Power Supply output current.',
    'options': [
      {
        'label': '2.1 A',
        'value': '2.1 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3.13 A',
        'value': '3.13 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3.8 A',
        'value': '3.8 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '5 A',
        'value': '5 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '10 A',
        'value': '10 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '20 A',
        'value': '20 A',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 23,
    'fieldName': 'CANOpen',
    'title': 'Would you like to have CANopen?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          24
        ]
      }
    ]
  },
  {
    'id': 24,
    'fieldName': 'Connection Type',
    'title': 'Select your connection type.',
    'options': [
      {
        'label': 'Screw Terminal',
        'value': 'Screw Terminal',
        'nextQuestions': [
          25
        ]
      }
    ]
  },
  {
    'id': 25,
    'fieldName': 'Format',
    'title': 'Select your controller format.',
    'options': [
      {
        'label': 'Standard',
        'value': 'Standard',
        'nextQuestions': [
          26
        ]
      }
    ]
  },
  {
    'id': 26,
    'fieldName': 'Power Supply Output Current',
    'title': 'Select your Power Supply output current.',
    'options': [
      {
        'label': '2.1 A',
        'value': '2.1 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3.13 A',
        'value': '3.13 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3.8 A',
        'value': '3.8 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '5 A',
        'value': '5 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '10 A',
        'value': '10 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '20 A',
        'value': '20 A',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 27,
    'fieldName': 'Ethernet Port',
    'title': 'Would you like to have Ethernet Port?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          28
        ]
      },
      {
        'label': 'Yes',
        'value': 'Yes',
        'nextQuestions': [
          34
        ]
      }
    ]
  },
  {
    'id': 28,
    'fieldName': 'CANOpen',
    'title': 'Would you like to have CANopen?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          29
        ]
      }
    ]
  },
  {
    'id': 29,
    'fieldName': 'Connection Type',
    'title': 'Select your connection type.',
    'options': [
      {
        'label': 'Screw Terminal',
        'value': 'Screw Terminal',
        'nextQuestions': [
          30
        ]
      },
      {
        'label': 'Spring Terminal',
        'value': 'Spring Terminal',
        'nextQuestions': [
          32
        ]
      }
    ]
  },
  {
    'id': 30,
    'fieldName': 'Format',
    'title': 'Select your controller format.',
    'options': [
      {
        'label': 'Book',
        'value': 'Book',
        'nextQuestions': [
          31
        ]
      }
    ]
  },
  {
    'id': 31,
    'fieldName': 'Power Supply Output Current',
    'title': 'Select your Power Supply output current.',
    'options': [
      {
        'label': '2.1 A',
        'value': '2.1 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3.13 A',
        'value': '3.13 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3.8 A',
        'value': '3.8 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '5 A',
        'value': '5 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '10 A',
        'value': '10 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '20 A',
        'value': '20 A',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 32,
    'fieldName': 'Format',
    'title': 'Select your controller format.',
    'options': [
      {
        'label': 'Book',
        'value': 'Book',
        'nextQuestions': [
          33
        ]
      }
    ]
  },
  {
    'id': 33,
    'fieldName': 'Power Supply Output Current',
    'title': 'Select your Power Supply output current.',
    'options': [
      {
        'label': '2.1 A',
        'value': '2.1 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3.13 A',
        'value': '3.13 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3.8 A',
        'value': '3.8 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '5 A',
        'value': '5 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '10 A',
        'value': '10 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '20 A',
        'value': '20 A',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 34,
    'fieldName': 'CANOpen',
    'title': 'Would you like to have CANopen?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          35
        ]
      }
    ]
  },
  {
    'id': 35,
    'fieldName': 'Connection Type',
    'title': 'Select your connection type.',
    'options': [
      {
        'label': 'Screw Terminal',
        'value': 'Screw Terminal',
        'nextQuestions': [
          36
        ]
      },
      {
        'label': 'Spring Terminal',
        'value': 'Spring Terminal',
        'nextQuestions': [
          38
        ]
      }
    ]
  },
  {
    'id': 36,
    'fieldName': 'Format',
    'title': 'Select your controller format.',
    'options': [
      {
        'label': 'Book',
        'value': 'Book',
        'nextQuestions': [
          37
        ]
      }
    ]
  },
  {
    'id': 37,
    'fieldName': 'Power Supply Output Current',
    'title': 'Select your Power Supply output current.',
    'options': [
      {
        'label': '2.1 A',
        'value': '2.1 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3.13 A',
        'value': '3.13 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3.8 A',
        'value': '3.8 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '5 A',
        'value': '5 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '10 A',
        'value': '10 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '20 A',
        'value': '20 A',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 38,
    'fieldName': 'Format',
    'title': 'Select your controller format.',
    'options': [
      {
        'label': 'Book',
        'value': 'Book',
        'nextQuestions': [
          39
        ]
      }
    ]
  },
  {
    'id': 39,
    'fieldName': 'Power Supply Output Current',
    'title': 'Select your Power Supply output current.',
    'options': [
      {
        'label': '2.1 A',
        'value': '2.1 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3.13 A',
        'value': '3.13 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3.8 A',
        'value': '3.8 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '5 A',
        'value': '5 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '10 A',
        'value': '10 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '20 A',
        'value': '20 A',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 40,
    'fieldName': 'Output Type',
    'title': 'Select your output type.',
    'options': [
      {
        'label': 'Source Output',
        'value': 'Source Output',
        'nextQuestions': [
          41
        ]
      },
      {
        'label': 'Sink Output',
        'value': 'Sink Output',
        'nextQuestions': [
          50
        ]
      }
    ]
  },
  {
    'id': 41,
    'fieldName': 'Ethernet Port',
    'title': 'Would you like to have Ethernet Port?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          42
        ]
      },
      {
        'label': 'Yes',
        'value': 'Yes',
        'nextQuestions': [
          46
        ]
      }
    ]
  },
  {
    'id': 42,
    'fieldName': 'CANOpen',
    'title': 'Would you like to have CANopen?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          43
        ]
      }
    ]
  },
  {
    'id': 43,
    'fieldName': 'Connection Type',
    'title': 'Select your connection type.',
    'options': [
      {
        'label': 'Screw Terminal',
        'value': 'Screw Terminal',
        'nextQuestions': [
          44
        ]
      }
    ]
  },
  {
    'id': 44,
    'fieldName': 'Format',
    'title': 'Select your controller format.',
    'options': [
      {
        'label': 'Standard',
        'value': 'Standard',
        'nextQuestions': [
          45
        ]
      }
    ]
  },
  {
    'id': 45,
    'fieldName': 'Power Supply Output Current',
    'title': 'Select your Power Supply output current.',
    'options': [
      {
        'label': '2.1 A',
        'value': '2.1 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3.13 A',
        'value': '3.13 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3.8 A',
        'value': '3.8 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '5 A',
        'value': '5 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '10 A',
        'value': '10 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '20 A',
        'value': '20 A',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 46,
    'fieldName': 'CANOpen',
    'title': 'Would you like to have CANopen?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          47
        ]
      }
    ]
  },
  {
    'id': 47,
    'fieldName': 'Connection Type',
    'title': 'Select your connection type.',
    'options': [
      {
        'label': 'Screw Terminal',
        'value': 'Screw Terminal',
        'nextQuestions': [
          48
        ]
      }
    ]
  },
  {
    'id': 48,
    'fieldName': 'Format',
    'title': 'Select your controller format.',
    'options': [
      {
        'label': 'Standard',
        'value': 'Standard',
        'nextQuestions': [
          49
        ]
      }
    ]
  },
  {
    'id': 49,
    'fieldName': 'Power Supply Output Current',
    'title': 'Select your Power Supply output current.',
    'options': [
      {
        'label': '2.1 A',
        'value': '2.1 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3.13 A',
        'value': '3.13 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3.8 A',
        'value': '3.8 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '5 A',
        'value': '5 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '10 A',
        'value': '10 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '20 A',
        'value': '20 A',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 50,
    'fieldName': 'Ethernet Port',
    'title': 'Would you like to have Ethernet Port?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          51
        ]
      },
      {
        'label': 'Yes',
        'value': 'Yes',
        'nextQuestions': [
          55
        ]
      }
    ]
  },
  {
    'id': 51,
    'fieldName': 'CANOpen',
    'title': 'Would you like to have CANopen?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          52
        ]
      }
    ]
  },
  {
    'id': 52,
    'fieldName': 'Connection Type',
    'title': 'Select your connection type.',
    'options': [
      {
        'label': 'Screw Terminal',
        'value': 'Screw Terminal',
        'nextQuestions': [
          53
        ]
      }
    ]
  },
  {
    'id': 53,
    'fieldName': 'Format',
    'title': 'Select your controller format.',
    'options': [
      {
        'label': 'Standard',
        'value': 'Standard',
        'nextQuestions': [
          54
        ]
      }
    ]
  },
  {
    'id': 54,
    'fieldName': 'Power Supply Output Current',
    'title': 'Select your Power Supply output current.',
    'options': [
      {
        'label': '2.1 A',
        'value': '2.1 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3.13 A',
        'value': '3.13 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3.8 A',
        'value': '3.8 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '5 A',
        'value': '5 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '10 A',
        'value': '10 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '20 A',
        'value': '20 A',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 55,
    'fieldName': 'CANOpen',
    'title': 'Would you like to have CANopen?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          56
        ]
      }
    ]
  },
  {
    'id': 56,
    'fieldName': 'Connection Type',
    'title': 'Select your connection type.',
    'options': [
      {
        'label': 'Screw Terminal',
        'value': 'Screw Terminal',
        'nextQuestions': [
          57
        ]
      }
    ]
  },
  {
    'id': 57,
    'fieldName': 'Format',
    'title': 'Select your controller format.',
    'options': [
      {
        'label': 'Standard',
        'value': 'Standard',
        'nextQuestions': [
          58
        ]
      }
    ]
  },
  {
    'id': 58,
    'fieldName': 'Power Supply Output Current',
    'title': 'Select your Power Supply output current.',
    'options': [
      {
        'label': '2.1 A',
        'value': '2.1 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3.13 A',
        'value': '3.13 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3.8 A',
        'value': '3.8 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '5 A',
        'value': '5 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '10 A',
        'value': '10 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '20 A',
        'value': '20 A',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 59,
    'fieldName': 'Output Type',
    'title': 'Select your output type.',
    'options': [
      {
        'label': 'Source Output',
        'value': 'Source Output',
        'nextQuestions': [
          60
        ]
      },
      {
        'label': 'Sink Output',
        'value': 'Sink Output',
        'nextQuestions': [
          69
        ]
      }
    ]
  },
  {
    'id': 60,
    'fieldName': 'Ethernet Port',
    'title': 'Would you like to have Ethernet Port?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          61
        ]
      },
      {
        'label': 'Yes',
        'value': 'Yes',
        'nextQuestions': [
          65
        ]
      }
    ]
  },
  {
    'id': 61,
    'fieldName': 'CANOpen',
    'title': 'Would you like to have CANopen?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          62
        ]
      }
    ]
  },
  {
    'id': 62,
    'fieldName': 'Connection Type',
    'title': 'Select your connection type.',
    'options': [
      {
        'label': 'Screw Terminal',
        'value': 'Screw Terminal',
        'nextQuestions': [
          63
        ]
      }
    ]
  },
  {
    'id': 63,
    'fieldName': 'Format',
    'title': 'Select your controller format.',
    'options': [
      {
        'label': 'Standard',
        'value': 'Standard',
        'nextQuestions': [
          64
        ]
      }
    ]
  },
  {
    'id': 64,
    'fieldName': 'Power Supply Output Current',
    'title': 'Select your Power Supply output current.',
    'options': [
      {
        'label': '2.1 A',
        'value': '2.1 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3.13 A',
        'value': '3.13 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3.8 A',
        'value': '3.8 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '5 A',
        'value': '5 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '10 A',
        'value': '10 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '20 A',
        'value': '20 A',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 65,
    'fieldName': 'CANOpen',
    'title': 'Would you like to have CANopen?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          66
        ]
      }
    ]
  },
  {
    'id': 66,
    'fieldName': 'Connection Type',
    'title': 'Select your connection type.',
    'options': [
      {
        'label': 'Screw Terminal',
        'value': 'Screw Terminal',
        'nextQuestions': [
          67
        ]
      }
    ]
  },
  {
    'id': 67,
    'fieldName': 'Format',
    'title': 'Select your controller format.',
    'options': [
      {
        'label': 'Standard',
        'value': 'Standard',
        'nextQuestions': [
          68
        ]
      }
    ]
  },
  {
    'id': 68,
    'fieldName': 'Power Supply Output Current',
    'title': 'Select your Power Supply output current.',
    'options': [
      {
        'label': '2.1 A',
        'value': '2.1 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3.13 A',
        'value': '3.13 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3.8 A',
        'value': '3.8 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '5 A',
        'value': '5 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '10 A',
        'value': '10 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '20 A',
        'value': '20 A',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 69,
    'fieldName': 'Ethernet Port',
    'title': 'Would you like to have Ethernet Port?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          70
        ]
      },
      {
        'label': 'Yes',
        'value': 'Yes',
        'nextQuestions': [
          74
        ]
      }
    ]
  },
  {
    'id': 70,
    'fieldName': 'CANOpen',
    'title': 'Would you like to have CANopen?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          71
        ]
      }
    ]
  },
  {
    'id': 71,
    'fieldName': 'Connection Type',
    'title': 'Select your connection type.',
    'options': [
      {
        'label': 'Screw Terminal',
        'value': 'Screw Terminal',
        'nextQuestions': [
          72
        ]
      }
    ]
  },
  {
    'id': 72,
    'fieldName': 'Format',
    'title': 'Select your controller format.',
    'options': [
      {
        'label': 'Standard',
        'value': 'Standard',
        'nextQuestions': [
          73
        ]
      }
    ]
  },
  {
    'id': 73,
    'fieldName': 'Power Supply Output Current',
    'title': 'Select your Power Supply output current.',
    'options': [
      {
        'label': '2.1 A',
        'value': '2.1 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3.13 A',
        'value': '3.13 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3.8 A',
        'value': '3.8 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '5 A',
        'value': '5 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '10 A',
        'value': '10 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '20 A',
        'value': '20 A',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 74,
    'fieldName': 'CANOpen',
    'title': 'Would you like to have CANopen?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          75
        ]
      }
    ]
  },
  {
    'id': 75,
    'fieldName': 'Connection Type',
    'title': 'Select your connection type.',
    'options': [
      {
        'label': 'Screw Terminal',
        'value': 'Screw Terminal',
        'nextQuestions': [
          76
        ]
      }
    ]
  },
  {
    'id': 76,
    'fieldName': 'Format',
    'title': 'Select your controller format.',
    'options': [
      {
        'label': 'Standard',
        'value': 'Standard',
        'nextQuestions': [
          77
        ]
      }
    ]
  },
  {
    'id': 77,
    'fieldName': 'Power Supply Output Current',
    'title': 'Select your Power Supply output current.',
    'options': [
      {
        'label': '2.1 A',
        'value': '2.1 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3.13 A',
        'value': '3.13 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3.8 A',
        'value': '3.8 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '5 A',
        'value': '5 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '10 A',
        'value': '10 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '20 A',
        'value': '20 A',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 78,
    'fieldName': 'Output Type',
    'title': 'Select your output type.',
    'options': [
      {
        'label': 'Source Output',
        'value': 'Source Output',
        'nextQuestions': [
          79
        ]
      }
    ]
  },
  {
    'id': 79,
    'fieldName': 'Ethernet Port',
    'title': 'Would you like to have Ethernet Port?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          80
        ]
      },
      {
        'label': 'Yes',
        'value': 'Yes',
        'nextQuestions': [
          84
        ]
      }
    ]
  },
  {
    'id': 80,
    'fieldName': 'CANOpen',
    'title': 'Would you like to have CANopen?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          81
        ]
      }
    ]
  },
  {
    'id': 81,
    'fieldName': 'Connection Type',
    'title': 'Select your connection type.',
    'options': [
      {
        'label': 'HE 10 Connector',
        'value': 'HE 10 Connector',
        'nextQuestions': [
          82
        ]
      }
    ]
  },
  {
    'id': 82,
    'fieldName': 'Format',
    'title': 'Select your controller format.',
    'options': [
      {
        'label': 'Book',
        'value': 'Book',
        'nextQuestions': [
          83
        ]
      }
    ]
  },
  {
    'id': 83,
    'fieldName': 'Power Supply Output Current',
    'title': 'Select your Power Supply output current.',
    'options': [
      {
        'label': '2.1 A',
        'value': '2.1 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3.13 A',
        'value': '3.13 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3.8 A',
        'value': '3.8 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '5 A',
        'value': '5 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '10 A',
        'value': '10 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '20 A',
        'value': '20 A',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 84,
    'fieldName': 'CANOpen',
    'title': 'Would you like to have CANopen?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          85
        ]
      }
    ]
  },
  {
    'id': 85,
    'fieldName': 'Connection Type',
    'title': 'Select your connection type.',
    'options': [
      {
        'label': 'HE 10 Connector',
        'value': 'HE 10 Connector',
        'nextQuestions': [
          86
        ]
      }
    ]
  },
  {
    'id': 86,
    'fieldName': 'Format',
    'title': 'Select your controller format.',
    'options': [
      {
        'label': 'Book',
        'value': 'Book',
        'nextQuestions': [
          87
        ]
      }
    ]
  },
  {
    'id': 87,
    'fieldName': 'Power Supply Output Current',
    'title': 'Select your Power Supply output current.',
    'options': [
      {
        'label': '2.1 A',
        'value': '2.1 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3.13 A',
        'value': '3.13 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3.8 A',
        'value': '3.8 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '5 A',
        'value': '5 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '10 A',
        'value': '10 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '20 A',
        'value': '20 A',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 88,
    'fieldName': 'Number of I/O',
    'title': 'Select your number of embedded I/O.',
    'options': [
      {
        'label': '24 I/O',
        'value': '24 I/O',
        'nextQuestions': [
          89
        ]
      },
      {
        'label': '40 I/O',
        'value': '40 I/O',
        'nextQuestions': [
          114
        ]
      }
    ]
  },
  {
    'id': 89,
    'fieldName': 'Output Type',
    'title': 'Select your output type.',
    'options': [
      {
        'label': 'Source Output',
        'value': 'Source Output',
        'nextQuestions': [
          90
        ]
      },
      {
        'label': 'Sink Output',
        'value': 'Sink Output',
        'nextQuestions': [
          102
        ]
      }
    ]
  },
  {
    'id': 90,
    'fieldName': 'Ethernet Port',
    'title': 'Would you like to have Ethernet Port?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          91
        ]
      },
      {
        'label': 'Yes',
        'value': 'Yes',
        'nextQuestions': [
          95
        ]
      }
    ]
  },
  {
    'id': 91,
    'fieldName': 'CANOpen',
    'title': 'Would you like to have CANopen?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          92
        ]
      }
    ]
  },
  {
    'id': 92,
    'fieldName': 'Connection Type',
    'title': 'Select your connection type.',
    'options': [
      {
        'label': 'Screw Terminal',
        'value': 'Screw Terminal',
        'nextQuestions': [
          93
        ]
      }
    ]
  },
  {
    'id': 93,
    'fieldName': 'Format',
    'title': 'Select your controller format.',
    'options': [
      {
        'label': 'Standard',
        'value': 'Standard',
        'nextQuestions': [
          94
        ]
      }
    ]
  },
  {
    'id': 94,
    'fieldName': 'Power Supply Output Current',
    'title': 'Select your Power Supply output current.',
    'options': [
      {
        'label': '2.1 A',
        'value': '2.1 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3.13 A',
        'value': '3.13 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3.8 A',
        'value': '3.8 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '5 A',
        'value': '5 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '10 A',
        'value': '10 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '20 A',
        'value': '20 A',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 95,
    'fieldName': 'CANOpen',
    'title': 'Would you like to have CANopen?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          96
        ]
      },
      {
        'label': 'Yes',
        'value': 'Yes',
        'nextQuestions': [
          99
        ]
      }
    ]
  },
  {
    'id': 96,
    'fieldName': 'Connection Type',
    'title': 'Select your connection type.',
    'options': [
      {
        'label': 'Screw Terminal',
        'value': 'Screw Terminal',
        'nextQuestions': [
          97
        ]
      }
    ]
  },
  {
    'id': 97,
    'fieldName': 'Format',
    'title': 'Select your controller format.',
    'options': [
      {
        'label': 'Standard',
        'value': 'Standard',
        'nextQuestions': [
          98
        ]
      }
    ]
  },
  {
    'id': 98,
    'fieldName': 'Power Supply Output Current',
    'title': 'Select your Power Supply output current.',
    'options': [
      {
        'label': '2.1 A',
        'value': '2.1 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3.13 A',
        'value': '3.13 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3.8 A',
        'value': '3.8 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '5 A',
        'value': '5 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '10 A',
        'value': '10 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '20 A',
        'value': '20 A',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 99,
    'fieldName': 'Connection Type',
    'title': 'Select your connection type.',
    'options': [
      {
        'label': 'Screw Terminal',
        'value': 'Screw Terminal',
        'nextQuestions': [
          100
        ]
      }
    ]
  },
  {
    'id': 100,
    'fieldName': 'Format',
    'title': 'Select your controller format.',
    'options': [
      {
        'label': 'Standard',
        'value': 'Standard',
        'nextQuestions': [
          101
        ]
      }
    ]
  },
  {
    'id': 101,
    'fieldName': 'Power Supply Output Current',
    'title': 'Select your Power Supply output current.',
    'options': [
      {
        'label': '2.1 A',
        'value': '2.1 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3.13 A',
        'value': '3.13 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3.8 A',
        'value': '3.8 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '5 A',
        'value': '5 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '10 A',
        'value': '10 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '20 A',
        'value': '20 A',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 102,
    'fieldName': 'Ethernet Port',
    'title': 'Would you like to have Ethernet Port?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          103
        ]
      },
      {
        'label': 'Yes',
        'value': 'Yes',
        'nextQuestions': [
          107
        ]
      }
    ]
  },
  {
    'id': 103,
    'fieldName': 'CANOpen',
    'title': 'Would you like to have CANopen?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          104
        ]
      }
    ]
  },
  {
    'id': 104,
    'fieldName': 'Connection Type',
    'title': 'Select your connection type.',
    'options': [
      {
        'label': 'Screw Terminal',
        'value': 'Screw Terminal',
        'nextQuestions': [
          105
        ]
      }
    ]
  },
  {
    'id': 105,
    'fieldName': 'Format',
    'title': 'Select your controller format.',
    'options': [
      {
        'label': 'Standard',
        'value': 'Standard',
        'nextQuestions': [
          106
        ]
      }
    ]
  },
  {
    'id': 106,
    'fieldName': 'Power Supply Output Current',
    'title': 'Select your Power Supply output current.',
    'options': [
      {
        'label': '2.1 A',
        'value': '2.1 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3.13 A',
        'value': '3.13 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3.8 A',
        'value': '3.8 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '5 A',
        'value': '5 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '10 A',
        'value': '10 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '20 A',
        'value': '20 A',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 107,
    'fieldName': 'CANOpen',
    'title': 'Would you like to have CANopen?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          108
        ]
      },
      {
        'label': 'Yes',
        'value': 'Yes',
        'nextQuestions': [
          111
        ]
      }
    ]
  },
  {
    'id': 108,
    'fieldName': 'Connection Type',
    'title': 'Select your connection type.',
    'options': [
      {
        'label': 'Screw Terminal',
        'value': 'Screw Terminal',
        'nextQuestions': [
          109
        ]
      }
    ]
  },
  {
    'id': 109,
    'fieldName': 'Format',
    'title': 'Select your controller format.',
    'options': [
      {
        'label': 'Standard',
        'value': 'Standard',
        'nextQuestions': [
          110
        ]
      }
    ]
  },
  {
    'id': 110,
    'fieldName': 'Power Supply Output Current',
    'title': 'Select your Power Supply output current.',
    'options': [
      {
        'label': '2.1 A',
        'value': '2.1 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3.13 A',
        'value': '3.13 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3.8 A',
        'value': '3.8 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '5 A',
        'value': '5 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '10 A',
        'value': '10 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '20 A',
        'value': '20 A',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 111,
    'fieldName': 'Connection Type',
    'title': 'Select your connection type.',
    'options': [
      {
        'label': 'Screw Terminal',
        'value': 'Screw Terminal',
        'nextQuestions': [
          112
        ]
      }
    ]
  },
  {
    'id': 112,
    'fieldName': 'Format',
    'title': 'Select your controller format.',
    'options': [
      {
        'label': 'Standard',
        'value': 'Standard',
        'nextQuestions': [
          113
        ]
      }
    ]
  },
  {
    'id': 113,
    'fieldName': 'Power Supply Output Current',
    'title': 'Select your Power Supply output current.',
    'options': [
      {
        'label': '2.1 A',
        'value': '2.1 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3.13 A',
        'value': '3.13 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3.8 A',
        'value': '3.8 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '5 A',
        'value': '5 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '10 A',
        'value': '10 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '20 A',
        'value': '20 A',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 114,
    'fieldName': 'Output Type',
    'title': 'Select your output type.',
    'options': [
      {
        'label': 'Source Output',
        'value': 'Source Output',
        'nextQuestions': [
          115
        ]
      },
      {
        'label': 'Sink Output',
        'value': 'Sink Output',
        'nextQuestions': [
          124
        ]
      }
    ]
  },
  {
    'id': 115,
    'fieldName': 'Ethernet Port',
    'title': 'Would you like to have Ethernet Port?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          116
        ]
      },
      {
        'label': 'Yes',
        'value': 'Yes',
        'nextQuestions': [
          120
        ]
      }
    ]
  },
  {
    'id': 116,
    'fieldName': 'CANOpen',
    'title': 'Would you like to have CANopen?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          117
        ]
      }
    ]
  },
  {
    'id': 117,
    'fieldName': 'Connection Type',
    'title': 'Select your connection type.',
    'options': [
      {
        'label': 'Screw Terminal',
        'value': 'Screw Terminal',
        'nextQuestions': [
          118
        ]
      }
    ]
  },
  {
    'id': 118,
    'fieldName': 'Format',
    'title': 'Select your controller format.',
    'options': [
      {
        'label': 'Standard',
        'value': 'Standard',
        'nextQuestions': [
          119
        ]
      }
    ]
  },
  {
    'id': 119,
    'fieldName': 'Power Supply Output Current',
    'title': 'Select your Power Supply output current.',
    'options': [
      {
        'label': '2.1 A',
        'value': '2.1 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3.13 A',
        'value': '3.13 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3.8 A',
        'value': '3.8 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '5 A',
        'value': '5 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '10 A',
        'value': '10 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '20 A',
        'value': '20 A',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 120,
    'fieldName': 'CANOpen',
    'title': 'Would you like to have CANopen?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          121
        ]
      }
    ]
  },
  {
    'id': 121,
    'fieldName': 'Connection Type',
    'title': 'Select your connection type.',
    'options': [
      {
        'label': 'Screw Terminal',
        'value': 'Screw Terminal',
        'nextQuestions': [
          122
        ]
      }
    ]
  },
  {
    'id': 122,
    'fieldName': 'Format',
    'title': 'Select your controller format.',
    'options': [
      {
        'label': 'Standard',
        'value': 'Standard',
        'nextQuestions': [
          123
        ]
      }
    ]
  },
  {
    'id': 123,
    'fieldName': 'Power Supply Output Current',
    'title': 'Select your Power Supply output current.',
    'options': [
      {
        'label': '2.1 A',
        'value': '2.1 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3.13 A',
        'value': '3.13 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3.8 A',
        'value': '3.8 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '5 A',
        'value': '5 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '10 A',
        'value': '10 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '20 A',
        'value': '20 A',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 124,
    'fieldName': 'Ethernet Port',
    'title': 'Would you like to have Ethernet Port?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          125
        ]
      },
      {
        'label': 'Yes',
        'value': 'Yes',
        'nextQuestions': [
          129
        ]
      }
    ]
  },
  {
    'id': 125,
    'fieldName': 'CANOpen',
    'title': 'Would you like to have CANopen?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          126
        ]
      }
    ]
  },
  {
    'id': 126,
    'fieldName': 'Connection Type',
    'title': 'Select your connection type.',
    'options': [
      {
        'label': 'Screw Terminal',
        'value': 'Screw Terminal',
        'nextQuestions': [
          127
        ]
      }
    ]
  },
  {
    'id': 127,
    'fieldName': 'Format',
    'title': 'Select your controller format.',
    'options': [
      {
        'label': 'Standard',
        'value': 'Standard',
        'nextQuestions': [
          128
        ]
      }
    ]
  },
  {
    'id': 128,
    'fieldName': 'Power Supply Output Current',
    'title': 'Select your Power Supply output current.',
    'options': [
      {
        'label': '2.1 A',
        'value': '2.1 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3.13 A',
        'value': '3.13 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3.8 A',
        'value': '3.8 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '5 A',
        'value': '5 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '10 A',
        'value': '10 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '20 A',
        'value': '20 A',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 129,
    'fieldName': 'CANOpen',
    'title': 'Would you like to have CANopen?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          130
        ]
      }
    ]
  },
  {
    'id': 130,
    'fieldName': 'Connection Type',
    'title': 'Select your connection type.',
    'options': [
      {
        'label': 'Screw Terminal',
        'value': 'Screw Terminal',
        'nextQuestions': [
          131
        ]
      }
    ]
  },
  {
    'id': 131,
    'fieldName': 'Format',
    'title': 'Select your controller format.',
    'options': [
      {
        'label': 'Standard',
        'value': 'Standard',
        'nextQuestions': [
          132
        ]
      }
    ]
  },
  {
    'id': 132,
    'fieldName': 'Power Supply Output Current',
    'title': 'Select your Power Supply output current.',
    'options': [
      {
        'label': '2.1 A',
        'value': '2.1 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3.13 A',
        'value': '3.13 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3.8 A',
        'value': '3.8 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '5 A',
        'value': '5 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '10 A',
        'value': '10 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '20 A',
        'value': '20 A',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 133,
    'fieldName': 'Number of I/O',
    'title': 'Select your number of embedded I/O.',
    'options': [
      {
        'label': '8 I/O',
        'value': '8 I/O',
        'nextQuestions': [
          134
        ]
      }
    ]
  },
  {
    'id': 134,
    'fieldName': 'Output Type',
    'title': 'Select your output type.',
    'options': [
      {
        'label': 'Source Output',
        'value': 'Source Output',
        'nextQuestions': [
          135
        ]
      }
    ]
  },
  {
    'id': 135,
    'fieldName': 'Ethernet Port',
    'title': 'Would you like to have Ethernet Port?',
    'options': [
      {
        'label': 'Yes',
        'value': 'Yes',
        'nextQuestions': [
          136
        ]
      }
    ]
  },
  {
    'id': 136,
    'fieldName': 'CANOpen',
    'title': 'Would you like to have CANopen?',
    'options': [
      {
        'label': 'Yes',
        'value': 'Yes',
        'nextQuestions': [
          137
        ]
      }
    ]
  },
  {
    'id': 137,
    'fieldName': 'Connection Type',
    'title': 'Select your connection type.',
    'options': [
      {
        'label': 'Screw Terminal',
        'value': 'Screw Terminal',
        'nextQuestions': [
          138
        ]
      }
    ]
  },
  {
    'id': 138,
    'fieldName': 'Format',
    'title': 'Select your controller format.',
    'options': [
      {
        'label': 'Book',
        'value': 'Book',
        'nextQuestions': [
          139
        ]
      }
    ]
  },
  {
    'id': 139,
    'fieldName': 'Axis',
    'title': 'Select your number of axes to synchronize.',
    'options': [
      {
        'label': '4 Axis',
        'value': '4 Axis',
        'nextQuestions': [
          140
        ]
      },
      {
        'label': '8 Axis',
        'value': '8 Axis',
        'nextQuestions': [
          141
        ]
      },
      {
        'label': '16 Axis',
        'value': '16 Axis',
        'nextQuestions': [
          142
        ]
      }
    ]
  },
  {
    'id': 140,
    'fieldName': 'Power Supply Output Current',
    'title': 'Select your Power Supply output current.',
    'options': [
      {
        'label': '2.1 A',
        'value': '2.1 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3.13 A',
        'value': '3.13 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3.8 A',
        'value': '3.8 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '5 A',
        'value': '5 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '10 A',
        'value': '10 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '20 A',
        'value': '20 A',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 141,
    'fieldName': 'Power Supply Output Current',
    'title': 'Select your Power Supply output current.',
    'options': [
      {
        'label': '2.1 A',
        'value': '2.1 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3.13 A',
        'value': '3.13 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3.8 A',
        'value': '3.8 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '5 A',
        'value': '5 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '10 A',
        'value': '10 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '20 A',
        'value': '20 A',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 142,
    'fieldName': 'Power Supply Output Current',
    'title': 'Select your Power Supply output current.',
    'options': [
      {
        'label': '2.1 A',
        'value': '2.1 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3.13 A',
        'value': '3.13 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3.8 A',
        'value': '3.8 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '5 A',
        'value': '5 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '10 A',
        'value': '10 A',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '20 A',
        'value': '20 A',
        'nextQuestions': [
          -1
        ]
      }
    ]
  }
];

export default AutomationControl;

import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import './styles.scss';

const IconClose = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#333333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
    <line x1="18" y1="6" x2="6" y2="18" />
    <line x1="6" y1="6" x2="18" y2="18" />
  </svg>
);

ReactModal.setAppElement('#root');

const styles = {
  overlay: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    // top: '0',
    // bottom: '0',
    // right: '0',
    // left: '0',
    // height: '100%',
    // width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    zIndex: '999999999',
    overflowY: 'auto',
    boxSizing: 'border-box',
  },
  content: {
    position: 'relative',
  },
};

const Modal = ({
  children, closeCb, isOpen, size, ...rest
}) => (
  <ReactModal
    isOpen={isOpen}
    style={styles}
    onRequestClose={closeCb}
    closeTimeoutMS={150}
    {...rest}
    className={`Modal Modal-size-${size}`}
  >
    <div className="Modal-body">{children}</div>
    <button type="button" className="Modal-dismiss" onClick={closeCb}>
      <IconClose />
    </button>
  </ReactModal>
);

Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.array,
  ]).isRequired,
  closeCb: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl', 'full']),
};

Modal.defaultProps = {
  isOpen: false,
  size: 'full',
};

export default Modal;

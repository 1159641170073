import { useState, useRef } from "react";
import PropTypes from "prop-types";
import ReactGA from "react-ga4";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import { nextuser } from "shared/tracking/nextuser";
import Icon from "../Icon";

const ConfiguratorDropdown = ({
  title,
  top,
  left,
  menuItems,
  activatePart,
  partKey,
  isVisible,
  onHover,
}) => {
  const [isOpen, setOpen] = useState(true);
  const [isHovered, setHovered] = useState(true);
  const dropdownRef = useRef(null);

  const handleTitleClick = () => {
    nextuser(`title_click`);
    ReactGA.event({
      category: "IndustrialTracker.configuratorDropdown",
      action: "click",
      label: "title",
    });
    if (menuItems.length === 0) {
      activatePart(partKey);
      setOpen(false);
    } else {
      setOpen(!isOpen);
    }
  };

  const handleDotClick = () => {
    nextuser(`dot_click`);
    ReactGA.event({
      category: "IndustrialTracker.configuratorDropdown",
      action: "click",
      label: "dot",
    });
    onHover(!isVisible);
  };

  const handleItemClick = (mi) => {
    const miLabelFormatted = mi.label.replace(/ /g, "_").toLowerCase();
    nextuser(`item_click_${miLabelFormatted}`);
    ReactGA.event({
      category: "IndustrialTracker.configuratorDropdown",
      action: "click",
      label: `item_${miLabelFormatted}`,
    });

    activatePart(mi.key);
    onHover(false);
    setOpen(false);
  };

  const handleMouseLeave = () => {
    onHover(false);
    setOpen(false);
  };

  const { t } = useTranslation();

  return (
    <div
      style={{ top, left }}
      ref={dropdownRef}
      className={`ConfiguratorDropdown ${
        isVisible && menuItems.length > 0 ? "ConfiguratorDropdown-expanded" : ""
      } ${isVisible ? "ConfiguratorDropdown-active" : ""}
      ${isHovered ? "ConfiguratorDropdown-hovered" : ""}
      `}
      onMouseLeave={handleMouseLeave}
    >
      <div className="ConfiguratorDropdown-main">
        <button
          className="btn"
          type="button"
          onClick={() => {
            handleDotClick(dropdownRef.current);
          }}
          onMouseLeave={() => setHovered(false)}
        >
          <div
            className="ConfiguratorDropdown__Dot"
            onMouseEnter={() => setHovered(false)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              onMouseOver={() => onHover(true)}
              onClick={() => onHover(true)}
              style={{
                cursor: "pointer",
              }}
            >
              <g
                id="Ellipse_7"
                data-name="Ellipse 7"
                fill="#42b4e6"
                stroke="#fff"
                strokeWidth="2"
              >
                <circle cx="10" cy="10" r="10" stroke="none" />
                <circle cx="10" cy="10" r="8" fill="none" />
                <circle cx="10" cy="10" r="8" fill="none" className="pulse" />
              </g>
            </svg>
          </div>
        </button>
        <div
          className="title"
          style={{ display: isVisible ? "flex" : "none" }}
          onMouseEnter={() => setHovered(true)}
        >
          <span style={{ marginLeft: 10 }}>
            <button
              type="button"
              onClick={() => {
                if (menuItems?.length === 0) {
                  handleTitleClick();
                }
              }}
              className={`ConfiguratorDropdown__Icon-Add ${
                isHovered ? "ConfiguratorDropdown__Icon-Add__Active" : ""
              }`}
            >
              <Icon type="add" color="#fff" />
            </button>
          </span>
          <button type="button" onClick={handleTitleClick}>
            {t(title)}
          </button>
        </div>
      </div>
      {isVisible && menuItems?.length > 0 && (
        <div className="ConfiguratorDropdown-body">
          {menuItems.map((mi) => (
            <button
              key={mi.key}
              type="button"
              onClick={() => handleItemClick(mi)}
            >
              {/* <div className="ConfiguratorDropdown__Icon-Add">
                <Icon  type="add" color="#fff" />
              </div> */}

              <span>{t(mi.label)}</span>
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

ConfiguratorDropdown.propTypes = {
  title: PropTypes.string,
  isVisible: PropTypes.bool,
  top: PropTypes.number,
  left: PropTypes.number,
  menuItems: PropTypes.array,
  activatePart: PropTypes.func,
  partKey: PropTypes.string,
  onHover: PropTypes.func,
};

ConfiguratorDropdown.defaultProps = {
  MachineType: "Machine_type",
  FeatureType: "Feature_type",
  activatePart: () => {},
  isVisible: false,
  left: 0,
  menuItems: [],
  top: 0,
  title: "",
  partKey: null,
  onHover: () => {},
};

export default ConfiguratorDropdown;

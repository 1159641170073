function CloseIcon() {
  return (
    <svg
      width="25px"
      height="25px"
      viewBox="0 0 9 9"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlSpace="preserve"
      style={{
        "fill-rule": "evenodd",
        "clip-rule": "evenodd",
        "stroke-linejoin": "round",
        "stroke-miterlimit": "2",
      }}
    >
      <g transform="matrix(1,0,0,1,-369.438,-15.1888)">
        <g transform="matrix(1,0,0,1,-219,-134)">
          <g transform="matrix(12,0,0,12,586.938,158.177)">
            <path
              d="M0.576,-0.375L0.859,-0.092C0.87,-0.081 0.875,-0.069 0.875,-0.054C0.875,-0.039 0.87,-0.026 0.859,-0.016C0.848,-0.007 0.835,-0.002 0.822,-0.002C0.808,-0.002 0.794,-0.007 0.781,-0.016L0.502,-0.299L0.219,-0.016C0.206,-0.007 0.193,-0.002 0.18,-0.002C0.165,-0.002 0.152,-0.007 0.141,-0.016C0.13,-0.026 0.125,-0.039 0.125,-0.054C0.125,-0.069 0.13,-0.081 0.141,-0.092L0.424,-0.375L0.141,-0.658C0.13,-0.669 0.125,-0.681 0.125,-0.696C0.125,-0.711 0.13,-0.724 0.141,-0.734C0.145,-0.738 0.149,-0.741 0.154,-0.743C0.16,-0.745 0.165,-0.747 0.171,-0.748C0.177,-0.749 0.182,-0.749 0.188,-0.748C0.193,-0.747 0.198,-0.745 0.203,-0.743C0.208,-0.741 0.214,-0.738 0.219,-0.734L0.502,-0.453L0.781,-0.734C0.793,-0.743 0.806,-0.748 0.821,-0.748C0.836,-0.748 0.849,-0.743 0.859,-0.734C0.87,-0.724 0.875,-0.711 0.875,-0.696C0.875,-0.681 0.87,-0.669 0.859,-0.658L0.576,-0.375Z"
              style={{ fill: "rgb(24,203,99)", "fill-rule": "nonzero" }}
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default CloseIcon;

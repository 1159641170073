// import PropTypes from "prop-types";

function Return() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 74.8 95"
    >
      <g id="Group_1" data-name="Group 1" transform="translate(-12.6 -2.5)">
        <path
          id="Path_2"
          data-name="Path 2"
          d="M85.9,72.3,62.2,96A5.091,5.091,0,0,1,55,88.8L70.1,73.7H34.4A21.851,21.851,0,0,1,12.6,51.9V7.6a5.1,5.1,0,0,1,10.2,0V52A11.715,11.715,0,0,0,34.5,63.7H70.2L55,48.5a5.091,5.091,0,1,1,7.2-7.2L85.9,65A5.19,5.19,0,0,1,85.9,72.3Z"
        />
      </g>
    </svg>
  );
}

Return.propTypes = {
  // color: PropTypes.string,
};

Return.defaultProps = {
  // color: "#000",
};

export default Return;

import PropTypes from 'prop-types';

function Line({
  width,
  height,
  x1,
  y1,
  x2,
  y2,
}) {
  return (
    <svg
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width,
        height,
      }}
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox={`0 0 ${width} ${height}`}
    >
      <line x1={x1} y1={y1} x2={x2} y2={y2} fill="none" stroke="#42b4e6" strokeWidth="2" />
    </svg>
  );
}

Line.defaultProps = {
  x1: 0,
  y1: 0,
  x2: 0,
  y2: 0,
  width: 0,
  height: 0,
};

Line.propTypes = {
  x1: PropTypes.number,
  y1: PropTypes.number,
  x2: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  y2: PropTypes.number,
};

export default Line;

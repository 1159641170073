import PropTypes from "prop-types";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import "./styles.scss";
// const IconClose = () => (
//   <svg width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='#333' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round'>
//     <line x1='18' y1='6' x2='6' y2='18' />
//     <line x1='6' y1='6' x2='18' y2='18' />
//   </svg>
// );
import { useTranslation } from "react-i18next";

const CloseIcon = () => (
  <div className="svg-container">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="62.439"
      viewBox="0 0 40 62.439"
    >
      <g id="Group_1" data-name="Group 1" transform="translate(0 10)">
        <path
          id="Path_1"
          data-name="Path 1"
          d="M40-.244V.731a8.2,8.2,0,0,1-3.171,5.854L21.951,21.219l15.61,15.61A8.273,8.273,0,0,1,40,42.927a8.273,8.273,0,0,1-2.439,6.1q-3.17,3.415-6.829,3.415A7.459,7.459,0,0,1,25.366,50L5.609,32.195A14.657,14.657,0,0,1,0,21.463,14.069,14.069,0,0,1,5.854,10.487L25.61-7.561A7.318,7.318,0,0,1,30.732-10q3.415,0,6.829,3.415l.732.975A8.476,8.476,0,0,1,40-.244ZM16.586,21.463a7.732,7.732,0,0,1,.488-2.2,3.815,3.815,0,0,1,.732-.976l15.61-15.61A4.5,4.5,0,0,0,35.122.244a4.541,4.541,0,0,0-.975-2.926.7.7,0,0,0-.488-.244Q32.2-4.878,30.732-4.634l-1.464.488-20,18.536a9.791,9.791,0,0,0-4.147,7.074,9.716,9.716,0,0,0,3.9,6.585L29.268,46.585a2.109,2.109,0,0,0,1.464.732,4.021,4.021,0,0,0,2.926-1.707q2.683-2.683.244-5.122L17.8,24.39a2.831,2.831,0,0,1-.488-.732A3.566,3.566,0,0,1,16.586,21.463Z"
          transform="translate(0 0)"
          fill="#18ca63"
        />
      </g>
    </svg>
  </div>
);

const CloseButton = () => {
  const [t] = useTranslation();

  return (
    <div className="CloseButton">
      <CloseIcon />
      {t("Back")}
    </div>
  );
};

// const FooterHeight = 60;

const getWidth = (size) => {
  switch (size) {
    case "sidebar":
      return "300px";
    case "sm":
      return "40vw";
    case "md":
      return "55vw";
    case "lg":
      return "100%";
    default:
      return "40vw";
  }
};

const SlidePane = ({
  children,
  closeCb,
  disabled,
  isOpen,
  size,
  from,
  title,
  subtitle,
  footer,
  showCloseIcon,
  hideArrowClassname,
  className,
}) => (
  <SlidingPane
    className={`SlidePane ${hideArrowClassname} ${
      size === "sidebar" ? "sidebar" : ""
    } ${className}`}
    overlayClassName={`SlidePane-overlay ${showCloseIcon ? "" : "initial"}`}
    from={from}
    closeIcon={<CloseButton />}
    isOpen={isOpen}
    title={title}
    subtitle={subtitle}
    onRequestClose={() => {
      if (disabled) {
        return;
      }
      closeCb();
    }}
    width={getWidth(size)}
  >
    <div
      style={
        {
          // height: `calc(100% - ${footer ? FooterHeight : 0}px`,
        }
      }
      className="SlidePane-body"
    >
      {children}
    </div>
    {!!footer && <div className="SlidePane-footer">{footer}</div>}
  </SlidingPane>
);

SlidePane.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  closeCb: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  footer: PropTypes.element,
  isOpen: PropTypes.bool,
  hideArrowClassname: PropTypes.string,
  className: PropTypes.string,
  from: PropTypes.string,
  size: PropTypes.oneOf(["sidebar", "sm", "md", "lg"]),
  showCloseIcon: PropTypes.bool,
  subtitle: PropTypes.string,
  title: PropTypes.string,
};

SlidePane.defaultProps = {
  children: null,
  disabled: false,
  footer: null,
  from: "right",
  hideClose: false,
  className: "",
  isOpen: true,
  showCloseIcon: true,
  hideArrowClassname: "",
  size: "md",
  subtitle: "",
  title: "",
  closeIcon: null,
};

export default SlidePane;

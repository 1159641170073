export function nextuser(eventNameNu) {
  try {
    const NextuserScript = window.nextuser;
    const eventPrefix = "Industrial_Configurator";
    if (!NextuserScript) {
      return;
    }
    NextuserScript.track(`${eventPrefix}_${eventNameNu}`);

    // eslint-disable-next-line no-empty
  } catch (error) {}
}

export default { nextuser };

import PropTypes from 'prop-types';
import './styles.scss';
import { useTranslation } from 'react-i18next';

function SingleCategoryModal({ product }) {

  const { t } = useTranslation();

  return (
    <div className="single-category-modal-content">
      <div className="single-category-modal-image">
        <img src={product.imageUrl || 'https://picsum.photos/535/535'} alt={t(product.description)} />
      </div>
      <div className="single-category-modal-description">
        {t(product.description)}
      </div>
    </div>
  );
}

SingleCategoryModal.defaultProps = {
  product: null,
};

SingleCategoryModal.propTypes = {
  product: PropTypes.object,
};

export default SingleCategoryModal;

/* eslint-disable */

const PhotoelectricSensor = [
  {
    "id": 0,
    "fieldName": "Object Material",
    "title": "Please select the object material you would like to detect.",
    "options": [
      {
        "label": "Transparent",
        "value": "Transparent",
        "nextQuestions": [
          1
        ]
      },
      {
        "label": "Standard",
        "value": "Standard",
        "nextQuestions": [
          34
        ]
      }
    ]
  },
  {
    "id": 1,
    "fieldName": "Detection System",
    "title": "",
    "options": [
      {
        "label": "Polarised Reflex",
        "value": "Polarised Reflex",
        "nextQuestions": [
          2
        ]
      },
      {
        "label": "Reflex",
        "value": "Reflex",
        "nextQuestions": [
          21
        ]
      }
    ]
  },
  {
    "id": 2,
    "fieldName": "Sensor Shape & Material",
    "title": "Please select the sensor shape and material.",
    "options": [
      {
        "label": "Cylindrical Plastic",
        "value": "Cylindrical Plastic",
        "nextQuestions": [
          3
        ]
      },
      {
        "label": "Cylindrical Stainless Steel",
        "value": "Cylindrical Stainless Steel",
        "nextQuestions": [
          12
        ]
      }
    ]
  },
  {
    "id": 3,
    "fieldName": "Sensing Distance",
    "title": "Please select the sensing distance.",
    "options": [
      {
        "label": "1.4 M",
        "value": "1.4 M",
        "nextQuestions": [
          4
        ]
      },
      {
        "label": "0.8 M",
        "value": "0.8 M",
        "nextQuestions": [
          8
        ]
      }
    ]
  },
  {
    "id": 4,
    "fieldName": "Line of Sight",
    "title": "Please select the line of sight.",
    "options": [
      {
        "label": "Along case axis",
        "value": "Along case axis",
        "nextQuestions": [
          5
        ]
      }
    ]
  },
  {
    "id": 5,
    "fieldName": "Output Function",
    "title": "Please select the output function.",
    "options": [
      {
        "label": "NO/NC, PNP",
        "value": "NO/NC, PNP",
        "nextQuestions": [
          6
        ]
      },
      {
        "label": "NO/NC, NPN",
        "value": "NO/NC, NPN",
        "nextQuestions": [
          7
        ]
      }
    ]
  },
  {
    "id": 6,
    "fieldName": "Connection",
    "title": "Please select the connection type.",
    "options": [
      {
        "label": "2 M Pre-cabled",
        "value": "2 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "5 M Pre-cabled",
        "value": "5 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "M12 Connector",
        "value": "M12 Connector",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 7,
    "fieldName": "Connection",
    "title": "Please select the connection type.",
    "options": [
      {
        "label": "2 M Pre-cabled",
        "value": "2 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "5 M Pre-cabled",
        "value": "5 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "M12 Connector",
        "value": "M12 Connector",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 8,
    "fieldName": "Line of Sight",
    "title": "Please select the line of sight.",
    "options": [
      {
        "label": "90° to case axis",
        "value": "90° to case axis",
        "nextQuestions": [
          9
        ]
      }
    ]
  },
  {
    "id": 9,
    "fieldName": "Output Function",
    "title": "Please select the output function.",
    "options": [
      {
        "label": "NO/NC, PNP",
        "value": "NO/NC, PNP",
        "nextQuestions": [
          10
        ]
      },
      {
        "label": "NO/NC, NPN",
        "value": "NO/NC, NPN",
        "nextQuestions": [
          11
        ]
      }
    ]
  },
  {
    "id": 10,
    "fieldName": "Connection",
    "title": "Please select the connection type.",
    "options": [
      {
        "label": "2 M Pre-cabled",
        "value": "2 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "5 M Pre-cabled",
        "value": "5 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "M12 Connector",
        "value": "M12 Connector",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 11,
    "fieldName": "Connection",
    "title": "Please select the connection type.",
    "options": [
      {
        "label": "2 M Pre-cabled",
        "value": "2 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "5 M Pre-cabled",
        "value": "5 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "M12 Connector",
        "value": "M12 Connector",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 12,
    "fieldName": "Sensing Distance",
    "title": "Please select the sensing distance.",
    "options": [
      {
        "label": "1.4 M",
        "value": "1.4 M",
        "nextQuestions": [
          13
        ]
      },
      {
        "label": "0.8 M",
        "value": "0.8 M",
        "nextQuestions": [
          17
        ]
      }
    ]
  },
  {
    "id": 13,
    "fieldName": "Line of Sight",
    "title": "Please select the line of sight.",
    "options": [
      {
        "label": "Along case axis",
        "value": "Along case axis",
        "nextQuestions": [
          14
        ]
      }
    ]
  },
  {
    "id": 14,
    "fieldName": "Output Function",
    "title": "Please select the output function.",
    "options": [
      {
        "label": "NO/NC, PNP",
        "value": "NO/NC, PNP",
        "nextQuestions": [
          15
        ]
      },
      {
        "label": "NO/NC, NPN",
        "value": "NO/NC, NPN",
        "nextQuestions": [
          16
        ]
      }
    ]
  },
  {
    "id": 15,
    "fieldName": "Connection",
    "title": "Please select the connection type.",
    "options": [
      {
        "label": "2 M Pre-cabled",
        "value": "2 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "5 M Pre-cabled",
        "value": "5 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "M12 Connector",
        "value": "M12 Connector",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 16,
    "fieldName": "Connection",
    "title": "Please select the connection type.",
    "options": [
      {
        "label": "2 M Pre-cabled",
        "value": "2 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "5 M Pre-cabled",
        "value": "5 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "M12 Connector",
        "value": "M12 Connector",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 17,
    "fieldName": "Line of Sight",
    "title": "Please select the line of sight.",
    "options": [
      {
        "label": "90° to case axis",
        "value": "90° to case axis",
        "nextQuestions": [
          18
        ]
      }
    ]
  },
  {
    "id": 18,
    "fieldName": "Output Function",
    "title": "Please select the output function.",
    "options": [
      {
        "label": "NO/NC, PNP",
        "value": "NO/NC, PNP",
        "nextQuestions": [
          19
        ]
      },
      {
        "label": "NO/NC, NPN",
        "value": "NO/NC, NPN",
        "nextQuestions": [
          20
        ]
      }
    ]
  },
  {
    "id": 19,
    "fieldName": "Connection",
    "title": "Please select the connection type.",
    "options": [
      {
        "label": "2 M Pre-cabled",
        "value": "2 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "5 M Pre-cabled",
        "value": "5 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "M12 Connector",
        "value": "M12 Connector",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 20,
    "fieldName": "Connection",
    "title": "Please select the connection type.",
    "options": [
      {
        "label": "2 M Pre-cabled",
        "value": "2 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "5 M Pre-cabled",
        "value": "5 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "M12 Connector",
        "value": "M12 Connector",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 21,
    "fieldName": "Sensor Shape & Material",
    "title": "Please select the sensor shape and material.",
    "options": [
      {
        "label": "Cylindrical Plastic",
        "value": "Cylindrical Plastic",
        "nextQuestions": [
          22
        ]
      },
      {
        "label": "Miniature Plastic",
        "value": "Miniature Plastic",
        "nextQuestions": [
          27
        ]
      }
    ]
  },
  {
    "id": 22,
    "fieldName": "Sensing Distance",
    "title": "Please select the sensing distance.",
    "options": [
      {
        "label": "0.8 M",
        "value": "0.8 M",
        "nextQuestions": [
          23
        ]
      }
    ]
  },
  {
    "id": 23,
    "fieldName": "Line of Sight",
    "title": "Please select the line of sight.",
    "options": [
      {
        "label": "Along case axis",
        "value": "Along case axis",
        "nextQuestions": [
          24
        ]
      }
    ]
  },
  {
    "id": 24,
    "fieldName": "Output Function",
    "title": "Please select the output function.",
    "options": [
      {
        "label": "NO/NC, PNP",
        "value": "NO/NC, PNP",
        "nextQuestions": [
          25
        ]
      },
      {
        "label": "NO/NC, NPN",
        "value": "NO/NC, NPN",
        "nextQuestions": [
          26
        ]
      }
    ]
  },
  {
    "id": 25,
    "fieldName": "Connection",
    "title": "Please select the connection type.",
    "options": [
      {
        "label": "2 M Pre-cabled",
        "value": "2 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "5 M Pre-cabled",
        "value": "5 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "M12 Connector",
        "value": "M12 Connector",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 26,
    "fieldName": "Connection",
    "title": "Please select the connection type.",
    "options": [
      {
        "label": "2 M Pre-cabled",
        "value": "2 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "5 M Pre-cabled",
        "value": "5 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "M12 Connector",
        "value": "M12 Connector",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 27,
    "fieldName": "Sensing Distance",
    "title": "Please select the sensing distance.",
    "options": [
      {
        "label": "2 M",
        "value": "2 M",
        "nextQuestions": [
          28
        ]
      },
      {
        "label": "1 M",
        "value": "1 M",
        "nextQuestions": [
          31
        ]
      }
    ]
  },
  {
    "id": 28,
    "fieldName": "Output Function",
    "title": "Please select the output function.",
    "options": [
      {
        "label": "NO/NC, PNP",
        "value": "NO/NC, PNP",
        "nextQuestions": [
          29
        ]
      },
      {
        "label": "NO/NC, NPN",
        "value": "NO/NC, NPN",
        "nextQuestions": [
          30
        ]
      }
    ]
  },
  {
    "id": 29,
    "fieldName": "Connection",
    "title": "Please select the connection type.",
    "options": [
      {
        "label": "2 M Pre-cabled",
        "value": "2 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "M8 Connector",
        "value": "M8 Connector",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "M12 Connector",
        "value": "M12 Connector",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 30,
    "fieldName": "Connection",
    "title": "Please select the connection type.",
    "options": [
      {
        "label": "2 M Pre-cabled",
        "value": "2 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "M8 Connector",
        "value": "M8 Connector",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 31,
    "fieldName": "Output Function",
    "title": "Please select the output function.",
    "options": [
      {
        "label": "NO/NC, PNP",
        "value": "NO/NC, PNP",
        "nextQuestions": [
          32
        ]
      },
      {
        "label": "NO/NC, NPN",
        "value": "NO/NC, NPN",
        "nextQuestions": [
          33
        ]
      }
    ]
  },
  {
    "id": 32,
    "fieldName": "Connection",
    "title": "Please select the connection type.",
    "options": [
      {
        "label": "2 M Pre-cabled",
        "value": "2 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "M8 Connector",
        "value": "M8 Connector",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "M12 Connector",
        "value": "M12 Connector",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 33,
    "fieldName": "Connection",
    "title": "Please select the connection type.",
    "options": [
      {
        "label": "2 M Pre-cabled",
        "value": "2 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "M8 Connector",
        "value": "M8 Connector",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 34,
    "fieldName": "Detection System",
    "title": "",
    "options": [
      {
        "label": "Diffuse with adjustable sensitivity",
        "value": "Diffuse with adjustable sensitivity",
        "nextQuestions": [
          35
        ]
      },
      {
        "label": "Polarised Reflex",
        "value": "Polarised Reflex",
        "nextQuestions": [
          64
        ]
      }
    ]
  },
  {
    "id": 35,
    "fieldName": "Sensor Shape & Material",
    "title": "Please select the sensor shape and material.",
    "options": [
      {
        "label": "Cylindrical Plastic",
        "value": "Cylindrical Plastic",
        "nextQuestions": [
          36
        ]
      },
      {
        "label": "Miniature Plastic",
        "value": "Miniature Plastic",
        "nextQuestions": [
          48
        ]
      },
      {
        "label": "Cylindrical Metal",
        "value": "Cylindrical Metal",
        "nextQuestions": [
          52
        ]
      }
    ]
  },
  {
    "id": 36,
    "fieldName": "Sensing Distance",
    "title": "Please select the sensing distance.",
    "options": [
      {
        "label": "0.6 M",
        "value": "0.6 M",
        "nextQuestions": [
          37
        ]
      }
    ]
  },
  {
    "id": 37,
    "fieldName": "Line of Sight",
    "title": "Please select the line of sight.",
    "options": [
      {
        "label": "Along case axis",
        "value": "Along case axis",
        "nextQuestions": [
          38
        ]
      },
      {
        "label": "90° to case axis",
        "value": "90° to case axis",
        "nextQuestions": [
          43
        ]
      }
    ]
  },
  {
    "id": 38,
    "fieldName": "Output Function",
    "title": "Please select the output function.",
    "options": [
      {
        "label": "NO, PNP",
        "value": "NO, PNP",
        "nextQuestions": [
          39
        ]
      },
      {
        "label": "NO, NPN",
        "value": "NO, NPN",
        "nextQuestions": [
          40
        ]
      },
      {
        "label": "NC, PNP",
        "value": "NC, PNP",
        "nextQuestions": [
          41
        ]
      },
      {
        "label": "NC, NPN",
        "value": "NC, NPN",
        "nextQuestions": [
          42
        ]
      }
    ]
  },
  {
    "id": 39,
    "fieldName": "Connection",
    "title": "Please select the connection type.",
    "options": [
      {
        "label": "M12 Connector",
        "value": "M12 Connector",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "2 M Pre-cabled",
        "value": "2 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "5 M Pre-cabled",
        "value": "5 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 40,
    "fieldName": "Connection",
    "title": "Please select the connection type.",
    "options": [
      {
        "label": "M12 Connector",
        "value": "M12 Connector",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "2 M Pre-cabled",
        "value": "2 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "5 M Pre-cabled",
        "value": "5 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 41,
    "fieldName": "Connection",
    "title": "Please select the connection type.",
    "options": [
      {
        "label": "M12 Connector",
        "value": "M12 Connector",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "2 M Pre-cabled",
        "value": "2 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "5 M Pre-cabled",
        "value": "5 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 42,
    "fieldName": "Connection",
    "title": "Please select the connection type.",
    "options": [
      {
        "label": "M12 Connector",
        "value": "M12 Connector",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "2 M Pre-cabled",
        "value": "2 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "5 M Pre-cabled",
        "value": "5 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 43,
    "fieldName": "Output Function",
    "title": "Please select the output function.",
    "options": [
      {
        "label": "NO, PNP",
        "value": "NO, PNP",
        "nextQuestions": [
          44
        ]
      },
      {
        "label": "NO, NPN",
        "value": "NO, NPN",
        "nextQuestions": [
          45
        ]
      },
      {
        "label": "NC, PNP",
        "value": "NC, PNP",
        "nextQuestions": [
          46
        ]
      },
      {
        "label": "NC, NPN",
        "value": "NC, NPN",
        "nextQuestions": [
          47
        ]
      }
    ]
  },
  {
    "id": 44,
    "fieldName": "Connection",
    "title": "Please select the connection type.",
    "options": [
      {
        "label": "M12 Connector",
        "value": "M12 Connector",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "2 M Pre-cabled",
        "value": "2 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "5 M Pre-cabled",
        "value": "5 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 45,
    "fieldName": "Connection",
    "title": "Please select the connection type.",
    "options": [
      {
        "label": "M12 Connector",
        "value": "M12 Connector",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "2 M Pre-cabled",
        "value": "2 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "5 M Pre-cabled",
        "value": "5 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 46,
    "fieldName": "Connection",
    "title": "Please select the connection type.",
    "options": [
      {
        "label": "M12 Connector",
        "value": "M12 Connector",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "2 M Pre-cabled",
        "value": "2 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "5 M Pre-cabled",
        "value": "5 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 47,
    "fieldName": "Connection",
    "title": "Please select the connection type.",
    "options": [
      {
        "label": "M12 Connector",
        "value": "M12 Connector",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "2 M Pre-cabled",
        "value": "2 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "5 M Pre-cabled",
        "value": "5 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 48,
    "fieldName": "Sensing Distance",
    "title": "Please select the sensing distance.",
    "options": [
      {
        "label": "1 M",
        "value": "1 M",
        "nextQuestions": [
          49
        ]
      }
    ]
  },
  {
    "id": 49,
    "fieldName": "Output Function",
    "title": "Please select the output function.",
    "options": [
      {
        "label": "NO/NC, PNP",
        "value": "NO/NC, PNP",
        "nextQuestions": [
          50
        ]
      },
      {
        "label": "NO/NC, NPN",
        "value": "NO/NC, NPN",
        "nextQuestions": [
          51
        ]
      }
    ]
  },
  {
    "id": 50,
    "fieldName": "Connection",
    "title": "Please select the connection type.",
    "options": [
      {
        "label": "2 M Pre-cabled",
        "value": "2 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "M8 Connector",
        "value": "M8 Connector",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 51,
    "fieldName": "Connection",
    "title": "Please select the connection type.",
    "options": [
      {
        "label": "2 M Pre-cabled",
        "value": "2 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "M8 Connector",
        "value": "M8 Connector",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 52,
    "fieldName": "Sensing Distance",
    "title": "Please select the sensing distance.",
    "options": [
      {
        "label": "0.6 M",
        "value": "0.6 M",
        "nextQuestions": [
          53
        ]
      }
    ]
  },
  {
    "id": 53,
    "fieldName": "Line of Sight",
    "title": "Please select the line of sight.",
    "options": [
      {
        "label": "Along case axis",
        "value": "Along case axis",
        "nextQuestions": [
          54
        ]
      },
      {
        "label": "90° to case axis",
        "value": "90° to case axis",
        "nextQuestions": [
          59
        ]
      }
    ]
  },
  {
    "id": 54,
    "fieldName": "Output Function",
    "title": "Please select the output function.",
    "options": [
      {
        "label": "NO, PNP",
        "value": "NO, PNP",
        "nextQuestions": [
          55
        ]
      },
      {
        "label": "NO, NPN",
        "value": "NO, NPN",
        "nextQuestions": [
          56
        ]
      },
      {
        "label": "NC, PNP",
        "value": "NC, PNP",
        "nextQuestions": [
          57
        ]
      },
      {
        "label": "NC, NPN",
        "value": "NC, NPN",
        "nextQuestions": [
          58
        ]
      }
    ]
  },
  {
    "id": 55,
    "fieldName": "Connection",
    "title": "Please select the connection type.",
    "options": [
      {
        "label": "M12 Connector",
        "value": "M12 Connector",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "2 M Pre-cabled",
        "value": "2 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "5 M Pre-cabled",
        "value": "5 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 56,
    "fieldName": "Connection",
    "title": "Please select the connection type.",
    "options": [
      {
        "label": "M12 Connector",
        "value": "M12 Connector",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "2 M Pre-cabled",
        "value": "2 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "5 M Pre-cabled",
        "value": "5 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 57,
    "fieldName": "Connection",
    "title": "Please select the connection type.",
    "options": [
      {
        "label": "M12 Connector",
        "value": "M12 Connector",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "2 M Pre-cabled",
        "value": "2 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "5 M Pre-cabled",
        "value": "5 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 58,
    "fieldName": "Connection",
    "title": "Please select the connection type.",
    "options": [
      {
        "label": "M12 Connector",
        "value": "M12 Connector",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "2 M Pre-cabled",
        "value": "2 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "5 M Pre-cabled",
        "value": "5 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 59,
    "fieldName": "Output Function",
    "title": "Please select the output function.",
    "options": [
      {
        "label": "NO, PNP",
        "value": "NO, PNP",
        "nextQuestions": [
          60
        ]
      },
      {
        "label": "NO, NPN",
        "value": "NO, NPN",
        "nextQuestions": [
          61
        ]
      },
      {
        "label": "NC, PNP",
        "value": "NC, PNP",
        "nextQuestions": [
          62
        ]
      },
      {
        "label": "NC, NPN",
        "value": "NC, NPN",
        "nextQuestions": [
          63
        ]
      }
    ]
  },
  {
    "id": 60,
    "fieldName": "Connection",
    "title": "Please select the connection type.",
    "options": [
      {
        "label": "M12 Connector",
        "value": "M12 Connector",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "2 M Pre-cabled",
        "value": "2 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "5 M Pre-cabled",
        "value": "5 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 61,
    "fieldName": "Connection",
    "title": "Please select the connection type.",
    "options": [
      {
        "label": "M12 Connector",
        "value": "M12 Connector",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "2 M Pre-cabled",
        "value": "2 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "5 M Pre-cabled",
        "value": "5 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 62,
    "fieldName": "Connection",
    "title": "Please select the connection type.",
    "options": [
      {
        "label": "M12 Connector",
        "value": "M12 Connector",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "2 M Pre-cabled",
        "value": "2 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "5 M Pre-cabled",
        "value": "5 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 63,
    "fieldName": "Connection",
    "title": "Please select the connection type.",
    "options": [
      {
        "label": "M12 Connector",
        "value": "M12 Connector",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "2 M Pre-cabled",
        "value": "2 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "5 M Pre-cabled",
        "value": "5 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 64,
    "fieldName": "Sensor Shape & Material",
    "title": "Please select the sensor shape and material.",
    "options": [
      {
        "label": "Cylindrical Plastic",
        "value": "Cylindrical Plastic",
        "nextQuestions": [
          65
        ]
      },
      {
        "label": "Miniature Plastic",
        "value": "Miniature Plastic",
        "nextQuestions": [
          77
        ]
      },
      {
        "label": "Cylindrical Metal",
        "value": "Cylindrical Metal",
        "nextQuestions": [
          84
        ]
      }
    ]
  },
  {
    "id": 65,
    "fieldName": "Sensing Distance",
    "title": "Please select the sensing distance.",
    "options": [
      {
        "label": "2 M",
        "value": "2 M",
        "nextQuestions": [
          66
        ]
      }
    ]
  },
  {
    "id": 66,
    "fieldName": "Line of Sight",
    "title": "Please select the line of sight.",
    "options": [
      {
        "label": "Along case axis",
        "value": "Along case axis",
        "nextQuestions": [
          67
        ]
      },
      {
        "label": "90° to case axis",
        "value": "90° to case axis",
        "nextQuestions": [
          72
        ]
      }
    ]
  },
  {
    "id": 67,
    "fieldName": "Output Function",
    "title": "Please select the output function.",
    "options": [
      {
        "label": "NO, PNP",
        "value": "NO, PNP",
        "nextQuestions": [
          68
        ]
      },
      {
        "label": "NO, NPN",
        "value": "NO, NPN",
        "nextQuestions": [
          69
        ]
      },
      {
        "label": "NC, PNP",
        "value": "NC, PNP",
        "nextQuestions": [
          70
        ]
      },
      {
        "label": "NC, NPN",
        "value": "NC, NPN",
        "nextQuestions": [
          71
        ]
      }
    ]
  },
  {
    "id": 68,
    "fieldName": "Connection",
    "title": "Please select the connection type.",
    "options": [
      {
        "label": "M12 Connector",
        "value": "M12 Connector",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "2 M Pre-cabled",
        "value": "2 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "5 M Pre-cabled",
        "value": "5 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 69,
    "fieldName": "Connection",
    "title": "Please select the connection type.",
    "options": [
      {
        "label": "M12 Connector",
        "value": "M12 Connector",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "2 M Pre-cabled",
        "value": "2 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "5 M Pre-cabled",
        "value": "5 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 70,
    "fieldName": "Connection",
    "title": "Please select the connection type.",
    "options": [
      {
        "label": "M12 Connector",
        "value": "M12 Connector",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "2 M Pre-cabled",
        "value": "2 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "5 M Pre-cabled",
        "value": "5 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 71,
    "fieldName": "Connection",
    "title": "Please select the connection type.",
    "options": [
      {
        "label": "M12 Connector",
        "value": "M12 Connector",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "2 M Pre-cabled",
        "value": "2 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "5 M Pre-cabled",
        "value": "5 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 72,
    "fieldName": "Output Function",
    "title": "Please select the output function.",
    "options": [
      {
        "label": "NO, PNP",
        "value": "NO, PNP",
        "nextQuestions": [
          73
        ]
      },
      {
        "label": "NO, NPN",
        "value": "NO, NPN",
        "nextQuestions": [
          74
        ]
      },
      {
        "label": "NC, PNP",
        "value": "NC, PNP",
        "nextQuestions": [
          75
        ]
      },
      {
        "label": "NC, NPN",
        "value": "NC, NPN",
        "nextQuestions": [
          76
        ]
      }
    ]
  },
  {
    "id": 73,
    "fieldName": "Connection",
    "title": "Please select the connection type.",
    "options": [
      {
        "label": "M12 Connector",
        "value": "M12 Connector",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "2 M Pre-cabled",
        "value": "2 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "5 M Pre-cabled",
        "value": "5 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 74,
    "fieldName": "Connection",
    "title": "Please select the connection type.",
    "options": [
      {
        "label": "M12 Connector",
        "value": "M12 Connector",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "2 M Pre-cabled",
        "value": "2 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "5 M Pre-cabled",
        "value": "5 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 75,
    "fieldName": "Connection",
    "title": "Please select the connection type.",
    "options": [
      {
        "label": "M12 Connector",
        "value": "M12 Connector",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "2 M Pre-cabled",
        "value": "2 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "5 M Pre-cabled",
        "value": "5 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 76,
    "fieldName": "Connection",
    "title": "Please select the connection type.",
    "options": [
      {
        "label": "M12 Connector",
        "value": "M12 Connector",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "2 M Pre-cabled",
        "value": "2 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "5 M Pre-cabled",
        "value": "5 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 77,
    "fieldName": "Sensing Distance",
    "title": "Please select the sensing distance.",
    "options": [
      {
        "label": "5 M",
        "value": "5 M",
        "nextQuestions": [
          78
        ]
      },
      {
        "label": "2 M",
        "value": "2 M",
        "nextQuestions": [
          81
        ]
      }
    ]
  },
  {
    "id": 78,
    "fieldName": "Output Function",
    "title": "Please select the output function.",
    "options": [
      {
        "label": "NO/NC, PNP",
        "value": "NO/NC, PNP",
        "nextQuestions": [
          79
        ]
      },
      {
        "label": "NO/NC, NPN",
        "value": "NO/NC, NPN",
        "nextQuestions": [
          80
        ]
      }
    ]
  },
  {
    "id": 79,
    "fieldName": "Connection",
    "title": "Please select the connection type.",
    "options": [
      {
        "label": "2 M Pre-cabled",
        "value": "2 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "M8 Connector",
        "value": "M8 Connector",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 80,
    "fieldName": "Connection",
    "title": "Please select the connection type.",
    "options": [
      {
        "label": "2 M Pre-cabled",
        "value": "2 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "M8 Connector",
        "value": "M8 Connector",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 81,
    "fieldName": "Output Function",
    "title": "Please select the output function.",
    "options": [
      {
        "label": "NO/NC, PNP",
        "value": "NO/NC, PNP",
        "nextQuestions": [
          82
        ]
      },
      {
        "label": "NO/NC, NPN",
        "value": "NO/NC, NPN",
        "nextQuestions": [
          83
        ]
      }
    ]
  },
  {
    "id": 82,
    "fieldName": "Connection",
    "title": "Please select the connection type.",
    "options": [
      {
        "label": "2 M Pre-cabled",
        "value": "2 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "M8 Connector",
        "value": "M8 Connector",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 83,
    "fieldName": "Connection",
    "title": "Please select the connection type.",
    "options": [
      {
        "label": "2 M Pre-cabled",
        "value": "2 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "M8 Connector",
        "value": "M8 Connector",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 84,
    "fieldName": "Sensing Distance",
    "title": "Please select the sensing distance.",
    "options": [
      {
        "label": "2 M",
        "value": "2 M",
        "nextQuestions": [
          85
        ]
      }
    ]
  },
  {
    "id": 85,
    "fieldName": "Line of Sight",
    "title": "Please select the line of sight.",
    "options": [
      {
        "label": "Along case axis",
        "value": "Along case axis",
        "nextQuestions": [
          86
        ]
      },
      {
        "label": "90° to case axis",
        "value": "90° to case axis",
        "nextQuestions": [
          91
        ]
      }
    ]
  },
  {
    "id": 86,
    "fieldName": "Output Function",
    "title": "Please select the output function.",
    "options": [
      {
        "label": "NO, PNP",
        "value": "NO, PNP",
        "nextQuestions": [
          87
        ]
      },
      {
        "label": "NO, NPN",
        "value": "NO, NPN",
        "nextQuestions": [
          88
        ]
      },
      {
        "label": "NC, PNP",
        "value": "NC, PNP",
        "nextQuestions": [
          89
        ]
      },
      {
        "label": "NC, NPN",
        "value": "NC, NPN",
        "nextQuestions": [
          90
        ]
      }
    ]
  },
  {
    "id": 87,
    "fieldName": "Connection",
    "title": "Please select the connection type.",
    "options": [
      {
        "label": "M12 Connector",
        "value": "M12 Connector",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "2 M Pre-cabled",
        "value": "2 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "5 M Pre-cabled",
        "value": "5 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 88,
    "fieldName": "Connection",
    "title": "Please select the connection type.",
    "options": [
      {
        "label": "M12 Connector",
        "value": "M12 Connector",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "2 M Pre-cabled",
        "value": "2 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "5 M Pre-cabled",
        "value": "5 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 89,
    "fieldName": "Connection",
    "title": "Please select the connection type.",
    "options": [
      {
        "label": "M12 Connector",
        "value": "M12 Connector",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "2 M Pre-cabled",
        "value": "2 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "5 M Pre-cabled",
        "value": "5 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 90,
    "fieldName": "Connection",
    "title": "Please select the connection type.",
    "options": [
      {
        "label": "M12 Connector",
        "value": "M12 Connector",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "2 M Pre-cabled",
        "value": "2 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "5 M Pre-cabled",
        "value": "5 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 91,
    "fieldName": "Output Function",
    "title": "Please select the output function.",
    "options": [
      {
        "label": "NO, PNP",
        "value": "NO, PNP",
        "nextQuestions": [
          92
        ]
      },
      {
        "label": "NO, NPN",
        "value": "NO, NPN",
        "nextQuestions": [
          93
        ]
      },
      {
        "label": "NC, PNP",
        "value": "NC, PNP",
        "nextQuestions": [
          94
        ]
      },
      {
        "label": "NC, NPN",
        "value": "NC, NPN",
        "nextQuestions": [
          95
        ]
      }
    ]
  },
  {
    "id": 92,
    "fieldName": "Connection",
    "title": "Please select the connection type.",
    "options": [
      {
        "label": "M12 Connector",
        "value": "M12 Connector",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "2 M Pre-cabled",
        "value": "2 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "5 M Pre-cabled",
        "value": "5 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 93,
    "fieldName": "Connection",
    "title": "Please select the connection type.",
    "options": [
      {
        "label": "M12 Connector",
        "value": "M12 Connector",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "2 M Pre-cabled",
        "value": "2 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "5 M Pre-cabled",
        "value": "5 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 94,
    "fieldName": "Connection",
    "title": "Please select the connection type.",
    "options": [
      {
        "label": "M12 Connector",
        "value": "M12 Connector",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "2 M Pre-cabled",
        "value": "2 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "5 M Pre-cabled",
        "value": "5 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 95,
    "fieldName": "Connection",
    "title": "Please select the connection type.",
    "options": [
      {
        "label": "M12 Connector",
        "value": "M12 Connector",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "2 M Pre-cabled",
        "value": "2 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "5 M Pre-cabled",
        "value": "5 M Pre-cabled",
        "nextQuestions": [
          -1
        ]
      }
    ]
  }
];

export default PhotoelectricSensor;

import { Route, Switch } from 'react-router-dom';
import Header from 'Header';
import {
  FillingAndCapping,
  HorizontalPackaging,
  InitialView,
  VerticalPackaging,
} from 'configuratorContainers';

import { CartContainer } from 'cart';

import './styles.scss';
import 'react-toastify/dist/ReactToastify.css';

const AppContainer = () => (
  <div className="AppContainer">
    <Header />
    <Switch>
      <Route exact path="/" component={InitialView} />
      <Route exact path="/filling-and-capping" component={FillingAndCapping} />
      <Route exact path="/horizontal-packaging" component={HorizontalPackaging} />
      <Route exact path="/vertical-packaging" component={VerticalPackaging} />
      <Route exact path="/cart" component={CartContainer} />
    </Switch>
  </div>
);

export default AppContainer;

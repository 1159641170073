import PropTypes from "prop-types";

import { ProductItemContainer } from "shared/components";

function MultiCategoryModal({
  MachineType,
  FeatureType,
  products,
  cartEnabled,
}) {
  return (
    <ProductItemContainer
      MachineType={MachineType}
      FeatureType={FeatureType}
      products={products}
      cartEnabled={cartEnabled}
    />
  );
}

MultiCategoryModal.propTypes = {
  MachineType: PropTypes.string,
  FeatureType: PropTypes.string,
  products: PropTypes.array,
  cartEnabled: PropTypes.bool,
};

MultiCategoryModal.defaultProps = {
  MachineType: "Machine_type",
  FeatureType: "Feature_type",
  products: [],
  cartEnabled: false,
};

export default MultiCategoryModal;

export const ADD_PRODUCT = 'ADD_PRODUCT';
export const REMOVE_PRODUCT = 'REMOVE_PRODUCT';
export const CLEAR_CART = 'CLEAR_CART';

const addProductToCart = (id, quantity, available, state) => {
  const updatedCart = [...state.cart];
  const updatedItemIndex = updatedCart.findIndex((item) => item.id === id);

  if (updatedItemIndex < 0) {
    updatedCart.push({ id, quantity, available });
  } else {
    const updatedItem = { ...updatedCart[updatedItemIndex] };
    updatedItem.quantity += quantity;
    updatedCart[updatedItemIndex] = updatedItem;
    if (updatedItem.quantity === 0) {
      updatedCart.splice(updatedItemIndex, 1);
    }
  }
  return { ...state, cart: updatedCart };
};

const removeProductFromCart = (productId, state) => {
  const updatedCart = [...state.cart];
  const updatedItemIndex = updatedCart.findIndex(
    (item) => item.id === productId
  );

  if (updatedItemIndex < 0) {
    return state;
  }

  updatedCart.splice(updatedItemIndex, 1);
  return { ...state, cart: updatedCart };
};

const initialState = { cart: [] };

export const shopReducer = (state, action) => {
  switch (action.type) {
    case ADD_PRODUCT:
      return addProductToCart(
        action.productId,
        action.quantity,
        action.available,
        state
      );
    case REMOVE_PRODUCT:
      return removeProductFromCart(action.productId, state);
    case CLEAR_CART:
      return initialState;
    default:
      return state;
  }
};

import PropTypes from 'prop-types';
import { useEffect } from 'react';
import {
  useTable,
  useExpanded,
} from 'react-table';
import './styles.scss';

function Table({ columns, data, getRowProps }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    toggleAllRowsExpanded,
    // state: { groupBy, expanded },
  } = useTable(
    {
      columns,
      data,
    },
    useExpanded,
  );

  useEffect(() => {
    toggleAllRowsExpanded(true);
  }, [JSON.stringify(data)]);

  // Render the UI for your table
  return (
    <table className="cart-table" {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);

          return (
            <tr {...row.getRowProps(getRowProps(row))}>
              {
                row.cells.map((cell, index) => {
                  if (row.canExpand && index > 0) {
                    return null;
                  }

                  return (
                    <td {...cell.getCellProps()} colSpan={row.canExpand && index === 0 ? '10' : '1'}>
                      {
                        cell.isGrouped &&
                        (
                          <>
                            <span {...row.getToggleRowExpandedProps()}>
                              {
                                row.isExpanded ? '👇' : '👉'
                              }
                            </span>
                            {
                              ' '
                            }
                            {
                              cell.render('Cell')
                            }
                            (
                            {
                              row.subRows.length
                            }
                            )
                          </>
                        )
                      }
                      {
                        !cell.isGrouped &&
                        cell.render('Cell')
                      }
                    </td>
                  );
                })
              }
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

Table.propTypes = {
  columns: PropTypes.any,
  data: PropTypes.any,
  getRowProps: PropTypes.func,
};

Table.defaultProps = {
  columns: [],
  data: [],
  getRowProps: () => { },
};

export default Table;

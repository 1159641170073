import HorizontalPackagingMachine from "shared/assets/horizontal-packaging-big.jpg";
import { MachineContainer } from "shared/components";

import PartDefinitions from "./PartDefinitions";

import "./styles.scss";

const ImageBaseWidth = 3506;
const ImageBaseHeight = 2480;

const MachineTypeText = "Horizontal_Packaging_Machine";

const HorizontalPackaging = () => (
  <MachineContainer
    MachineType={MachineTypeText}
    PartDefinitions={PartDefinitions}
    MachineContainerImage={HorizontalPackagingMachine}
    ImageBaseWidth={ImageBaseWidth}
    ImageBaseHeight={ImageBaseHeight}
  />
);

export default HorizontalPackaging;

export const SET_DIMENSIONS = 'SET_DIMENSIONS';
export const SET_PARAMS = 'SET_PARAMS';

const setDimensions = (width, height, state) => ({ ...state, width, height });

const setParams = (params, state) => ({
  ...state,
  ...params,
  ...{ country: state.country || params.country },
});

export const appReducer = (state, action) => {
  switch (action.type) {
    case SET_DIMENSIONS:
      return setDimensions(action.width, action.height, state);
    case SET_PARAMS:
      return setParams(action.payload, state);
    default:
      return state;
  }
};

/* eslint-disable */

const MiniatureCircuitBreaker = [
  {
    'id': 0,
    'fieldName': 'Certificate',
    'title': 'Please select the required certificate.',
    'options': [
      {
        'label': 'UL/IEC',
        'value': 'UL/IEC',
        'nextQuestions': [
          1
        ]
      },
      {
        'label': 'IEC',
        'value': 'IEC',
        'nextQuestions': [
          195
        ]
      }
    ]
  },
  {
    'id': 1,
    'fieldName': 'Current Rating',
    'title': 'Please select the rated current.',
    'options': [
      {
        'label': '0.5 A',
        'value': '0.5 A',
        'nextQuestions': [
          2
        ]
      },
      {
        'label': '1 A',
        'value': '1 A',
        'nextQuestions': [
          7
        ]
      },
      {
        'label': '2 A',
        'value': '2 A',
        'nextQuestions': [
          18
        ]
      },
      {
        'label': '3 A',
        'value': '3 A',
        'nextQuestions': [
          29
        ]
      },
      {
        'label': '4 A',
        'value': '4 A',
        'nextQuestions': [
          40
        ]
      },
      {
        'label': '5 A',
        'value': '5 A',
        'nextQuestions': [
          51
        ]
      },
      {
        'label': '6 A',
        'value': '6 A',
        'nextQuestions': [
          62
        ]
      },
      {
        'label': '8 A',
        'value': '8 A',
        'nextQuestions': [
          73
        ]
      },
      {
        'label': '10 A',
        'value': '10 A',
        'nextQuestions': [
          84
        ]
      },
      {
        'label': '15 A',
        'value': '15 A',
        'nextQuestions': [
          95
        ]
      },
      {
        'label': '20 A',
        'value': '20 A',
        'nextQuestions': [
          106
        ]
      },
      {
        'label': '25 A',
        'value': '25 A',
        'nextQuestions': [
          117
        ]
      },
      {
        'label': '30 A',
        'value': '30 A',
        'nextQuestions': [
          128
        ]
      },
      {
        'label': '35 A',
        'value': '35 A',
        'nextQuestions': [
          139
        ]
      },
      {
        'label': '40 A',
        'value': '40 A',
        'nextQuestions': [
          150
        ]
      },
      {
        'label': '45 A',
        'value': '45 A',
        'nextQuestions': [
          161
        ]
      },
      {
        'label': '50 A',
        'value': '50 A',
        'nextQuestions': [
          169
        ]
      },
      {
        'label': '63 A',
        'value': '63 A',
        'nextQuestions': [
          177
        ]
      },
      {
        'label': '7 A',
        'value': '7 A',
        'nextQuestions': [
          185
        ]
      },
      {
        'label': '13 A',
        'value': '13 A',
        'nextQuestions': [
          190
        ]
      }
    ]
  },
  {
    'id': 2,
    'fieldName': 'No of Poles',
    'title': 'Please select the no of poles.',
    'options': [
      {
        'label': '1 Pole',
        'value': '1 Pole',
        'nextQuestions': [
          3
        ]
      }
    ]
  },
  {
    'id': 3,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'Z Curve',
        'value': 'Z Curve',
        'nextQuestions': [
          4
        ]
      },
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          5
        ]
      },
      {
        'label': 'D/K Curve',
        'value': 'D/K Curve',
        'nextQuestions': [
          6
        ]
      }
    ]
  },
  {
    'id': 4,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 5,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 6,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 7,
    'fieldName': 'No of Poles',
    'title': 'Please select the no of poles.',
    'options': [
      {
        'label': '1 Pole',
        'value': '1 Pole',
        'nextQuestions': [
          8
        ]
      },
      {
        'label': '2 Poles',
        'value': '2 Poles',
        'nextQuestions': [
          12
        ]
      },
      {
        'label': '3 Poles',
        'value': '3 Poles',
        'nextQuestions': [
          15
        ]
      }
    ]
  },
  {
    'id': 8,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'Z Curve',
        'value': 'Z Curve',
        'nextQuestions': [
          9
        ]
      },
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          10
        ]
      },
      {
        'label': 'D/K Curve',
        'value': 'D/K Curve',
        'nextQuestions': [
          11
        ]
      }
    ]
  },
  {
    'id': 9,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 10,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 11,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 12,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          13
        ]
      },
      {
        'label': 'D/K Curve',
        'value': 'D/K Curve',
        'nextQuestions': [
          14
        ]
      }
    ]
  },
  {
    'id': 13,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 14,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 15,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          16
        ]
      },
      {
        'label': 'D/K Curve',
        'value': 'D/K Curve',
        'nextQuestions': [
          17
        ]
      }
    ]
  },
  {
    'id': 16,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 17,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 18,
    'fieldName': 'No of Poles',
    'title': 'Please select the no of poles.',
    'options': [
      {
        'label': '1 Pole',
        'value': '1 Pole',
        'nextQuestions': [
          19
        ]
      },
      {
        'label': '2 Poles',
        'value': '2 Poles',
        'nextQuestions': [
          23
        ]
      },
      {
        'label': '3 Poles',
        'value': '3 Poles',
        'nextQuestions': [
          26
        ]
      }
    ]
  },
  {
    'id': 19,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'Z Curve',
        'value': 'Z Curve',
        'nextQuestions': [
          20
        ]
      },
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          21
        ]
      },
      {
        'label': 'D/K Curve',
        'value': 'D/K Curve',
        'nextQuestions': [
          22
        ]
      }
    ]
  },
  {
    'id': 20,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 21,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 22,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 23,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          24
        ]
      },
      {
        'label': 'D/K Curve',
        'value': 'D/K Curve',
        'nextQuestions': [
          25
        ]
      }
    ]
  },
  {
    'id': 24,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 25,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 26,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          27
        ]
      },
      {
        'label': 'D/K Curve',
        'value': 'D/K Curve',
        'nextQuestions': [
          28
        ]
      }
    ]
  },
  {
    'id': 27,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 28,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 29,
    'fieldName': 'No of Poles',
    'title': 'Please select the no of poles.',
    'options': [
      {
        'label': '1 Pole',
        'value': '1 Pole',
        'nextQuestions': [
          30
        ]
      },
      {
        'label': '2 Poles',
        'value': '2 Poles',
        'nextQuestions': [
          34
        ]
      },
      {
        'label': '3 Poles',
        'value': '3 Poles',
        'nextQuestions': [
          37
        ]
      }
    ]
  },
  {
    'id': 30,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'Z Curve',
        'value': 'Z Curve',
        'nextQuestions': [
          31
        ]
      },
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          32
        ]
      },
      {
        'label': 'D/K Curve',
        'value': 'D/K Curve',
        'nextQuestions': [
          33
        ]
      }
    ]
  },
  {
    'id': 31,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 32,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 33,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 34,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          35
        ]
      },
      {
        'label': 'D/K Curve',
        'value': 'D/K Curve',
        'nextQuestions': [
          36
        ]
      }
    ]
  },
  {
    'id': 35,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 36,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 37,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          38
        ]
      },
      {
        'label': 'D/K Curve',
        'value': 'D/K Curve',
        'nextQuestions': [
          39
        ]
      }
    ]
  },
  {
    'id': 38,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 39,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 40,
    'fieldName': 'No of Poles',
    'title': 'Please select the no of poles.',
    'options': [
      {
        'label': '1 Pole',
        'value': '1 Pole',
        'nextQuestions': [
          41
        ]
      },
      {
        'label': '2 Poles',
        'value': '2 Poles',
        'nextQuestions': [
          45
        ]
      },
      {
        'label': '3 Poles',
        'value': '3 Poles',
        'nextQuestions': [
          48
        ]
      }
    ]
  },
  {
    'id': 41,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'Z Curve',
        'value': 'Z Curve',
        'nextQuestions': [
          42
        ]
      },
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          43
        ]
      },
      {
        'label': 'D/K Curve',
        'value': 'D/K Curve',
        'nextQuestions': [
          44
        ]
      }
    ]
  },
  {
    'id': 42,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 43,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 44,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 45,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          46
        ]
      },
      {
        'label': 'D/K Curve',
        'value': 'D/K Curve',
        'nextQuestions': [
          47
        ]
      }
    ]
  },
  {
    'id': 46,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 47,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 48,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          49
        ]
      },
      {
        'label': 'D/K Curve',
        'value': 'D/K Curve',
        'nextQuestions': [
          50
        ]
      }
    ]
  },
  {
    'id': 49,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 50,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 51,
    'fieldName': 'No of Poles',
    'title': 'Please select the no of poles.',
    'options': [
      {
        'label': '1 Pole',
        'value': '1 Pole',
        'nextQuestions': [
          52
        ]
      },
      {
        'label': '2 Poles',
        'value': '2 Poles',
        'nextQuestions': [
          56
        ]
      },
      {
        'label': '3 Poles',
        'value': '3 Poles',
        'nextQuestions': [
          59
        ]
      }
    ]
  },
  {
    'id': 52,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'Z Curve',
        'value': 'Z Curve',
        'nextQuestions': [
          53
        ]
      },
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          54
        ]
      },
      {
        'label': 'D/K Curve',
        'value': 'D/K Curve',
        'nextQuestions': [
          55
        ]
      }
    ]
  },
  {
    'id': 53,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 54,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 55,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 56,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          57
        ]
      },
      {
        'label': 'D/K Curve',
        'value': 'D/K Curve',
        'nextQuestions': [
          58
        ]
      }
    ]
  },
  {
    'id': 57,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 58,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 59,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          60
        ]
      },
      {
        'label': 'D/K Curve',
        'value': 'D/K Curve',
        'nextQuestions': [
          61
        ]
      }
    ]
  },
  {
    'id': 60,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 61,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 62,
    'fieldName': 'No of Poles',
    'title': 'Please select the no of poles.',
    'options': [
      {
        'label': '1 Pole',
        'value': '1 Pole',
        'nextQuestions': [
          63
        ]
      },
      {
        'label': '2 Poles',
        'value': '2 Poles',
        'nextQuestions': [
          67
        ]
      },
      {
        'label': '3 Poles',
        'value': '3 Poles',
        'nextQuestions': [
          70
        ]
      }
    ]
  },
  {
    'id': 63,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'Z Curve',
        'value': 'Z Curve',
        'nextQuestions': [
          64
        ]
      },
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          65
        ]
      },
      {
        'label': 'D/K Curve',
        'value': 'D/K Curve',
        'nextQuestions': [
          66
        ]
      }
    ]
  },
  {
    'id': 64,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 65,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 66,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 67,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          68
        ]
      },
      {
        'label': 'D/K Curve',
        'value': 'D/K Curve',
        'nextQuestions': [
          69
        ]
      }
    ]
  },
  {
    'id': 68,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 69,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 70,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          71
        ]
      },
      {
        'label': 'D/K Curve',
        'value': 'D/K Curve',
        'nextQuestions': [
          72
        ]
      }
    ]
  },
  {
    'id': 71,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 72,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 73,
    'fieldName': 'No of Poles',
    'title': 'Please select the no of poles.',
    'options': [
      {
        'label': '1 Pole',
        'value': '1 Pole',
        'nextQuestions': [
          74
        ]
      },
      {
        'label': '2 Poles',
        'value': '2 Poles',
        'nextQuestions': [
          78
        ]
      },
      {
        'label': '3 Poles',
        'value': '3 Poles',
        'nextQuestions': [
          81
        ]
      }
    ]
  },
  {
    'id': 74,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'Z Curve',
        'value': 'Z Curve',
        'nextQuestions': [
          75
        ]
      },
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          76
        ]
      },
      {
        'label': 'D/K Curve',
        'value': 'D/K Curve',
        'nextQuestions': [
          77
        ]
      }
    ]
  },
  {
    'id': 75,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 76,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 77,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 78,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          79
        ]
      },
      {
        'label': 'D/K Curve',
        'value': 'D/K Curve',
        'nextQuestions': [
          80
        ]
      }
    ]
  },
  {
    'id': 79,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 80,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 81,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          82
        ]
      },
      {
        'label': 'D/K Curve',
        'value': 'D/K Curve',
        'nextQuestions': [
          83
        ]
      }
    ]
  },
  {
    'id': 82,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 83,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 84,
    'fieldName': 'No of Poles',
    'title': 'Please select the no of poles.',
    'options': [
      {
        'label': '1 Pole',
        'value': '1 Pole',
        'nextQuestions': [
          85
        ]
      },
      {
        'label': '2 Poles',
        'value': '2 Poles',
        'nextQuestions': [
          89
        ]
      },
      {
        'label': '3 Poles',
        'value': '3 Poles',
        'nextQuestions': [
          92
        ]
      }
    ]
  },
  {
    'id': 85,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'Z Curve',
        'value': 'Z Curve',
        'nextQuestions': [
          86
        ]
      },
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          87
        ]
      },
      {
        'label': 'D/K Curve',
        'value': 'D/K Curve',
        'nextQuestions': [
          88
        ]
      }
    ]
  },
  {
    'id': 86,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 87,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 88,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 89,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          90
        ]
      },
      {
        'label': 'D/K Curve',
        'value': 'D/K Curve',
        'nextQuestions': [
          91
        ]
      }
    ]
  },
  {
    'id': 90,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 91,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 92,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          93
        ]
      },
      {
        'label': 'D/K Curve',
        'value': 'D/K Curve',
        'nextQuestions': [
          94
        ]
      }
    ]
  },
  {
    'id': 93,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 94,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 95,
    'fieldName': 'No of Poles',
    'title': 'Please select the no of poles.',
    'options': [
      {
        'label': '1 Pole',
        'value': '1 Pole',
        'nextQuestions': [
          96
        ]
      },
      {
        'label': '2 Poles',
        'value': '2 Poles',
        'nextQuestions': [
          100
        ]
      },
      {
        'label': '3 Poles',
        'value': '3 Poles',
        'nextQuestions': [
          103
        ]
      }
    ]
  },
  {
    'id': 96,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'Z Curve',
        'value': 'Z Curve',
        'nextQuestions': [
          97
        ]
      },
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          98
        ]
      },
      {
        'label': 'D/K Curve',
        'value': 'D/K Curve',
        'nextQuestions': [
          99
        ]
      }
    ]
  },
  {
    'id': 97,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 98,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 99,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 100,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          101
        ]
      },
      {
        'label': 'D/K Curve',
        'value': 'D/K Curve',
        'nextQuestions': [
          102
        ]
      }
    ]
  },
  {
    'id': 101,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 102,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 103,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          104
        ]
      },
      {
        'label': 'D/K Curve',
        'value': 'D/K Curve',
        'nextQuestions': [
          105
        ]
      }
    ]
  },
  {
    'id': 104,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 105,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 106,
    'fieldName': 'No of Poles',
    'title': 'Please select the no of poles.',
    'options': [
      {
        'label': '1 Pole',
        'value': '1 Pole',
        'nextQuestions': [
          107
        ]
      },
      {
        'label': '2 Poles',
        'value': '2 Poles',
        'nextQuestions': [
          111
        ]
      },
      {
        'label': '3 Poles',
        'value': '3 Poles',
        'nextQuestions': [
          114
        ]
      }
    ]
  },
  {
    'id': 107,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'Z Curve',
        'value': 'Z Curve',
        'nextQuestions': [
          108
        ]
      },
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          109
        ]
      },
      {
        'label': 'D/K Curve',
        'value': 'D/K Curve',
        'nextQuestions': [
          110
        ]
      }
    ]
  },
  {
    'id': 108,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 109,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 110,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 111,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          112
        ]
      },
      {
        'label': 'D/K Curve',
        'value': 'D/K Curve',
        'nextQuestions': [
          113
        ]
      }
    ]
  },
  {
    'id': 112,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 113,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 114,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          115
        ]
      },
      {
        'label': 'D/K Curve',
        'value': 'D/K Curve',
        'nextQuestions': [
          116
        ]
      }
    ]
  },
  {
    'id': 115,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 116,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 117,
    'fieldName': 'No of Poles',
    'title': 'Please select the no of poles.',
    'options': [
      {
        'label': '1 Pole',
        'value': '1 Pole',
        'nextQuestions': [
          118
        ]
      },
      {
        'label': '2 Poles',
        'value': '2 Poles',
        'nextQuestions': [
          122
        ]
      },
      {
        'label': '3 Poles',
        'value': '3 Poles',
        'nextQuestions': [
          125
        ]
      }
    ]
  },
  {
    'id': 118,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'Z Curve',
        'value': 'Z Curve',
        'nextQuestions': [
          119
        ]
      },
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          120
        ]
      },
      {
        'label': 'D/K Curve',
        'value': 'D/K Curve',
        'nextQuestions': [
          121
        ]
      }
    ]
  },
  {
    'id': 119,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 120,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 121,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 122,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          123
        ]
      },
      {
        'label': 'D/K Curve',
        'value': 'D/K Curve',
        'nextQuestions': [
          124
        ]
      }
    ]
  },
  {
    'id': 123,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 124,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 125,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          126
        ]
      },
      {
        'label': 'D/K Curve',
        'value': 'D/K Curve',
        'nextQuestions': [
          127
        ]
      }
    ]
  },
  {
    'id': 126,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 127,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 128,
    'fieldName': 'No of Poles',
    'title': 'Please select the no of poles.',
    'options': [
      {
        'label': '1 Pole',
        'value': '1 Pole',
        'nextQuestions': [
          129
        ]
      },
      {
        'label': '2 Poles',
        'value': '2 Poles',
        'nextQuestions': [
          133
        ]
      },
      {
        'label': '3 Poles',
        'value': '3 Poles',
        'nextQuestions': [
          136
        ]
      }
    ]
  },
  {
    'id': 129,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'Z Curve',
        'value': 'Z Curve',
        'nextQuestions': [
          130
        ]
      },
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          131
        ]
      },
      {
        'label': 'D/K Curve',
        'value': 'D/K Curve',
        'nextQuestions': [
          132
        ]
      }
    ]
  },
  {
    'id': 130,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 131,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 132,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 133,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          134
        ]
      },
      {
        'label': 'D/K Curve',
        'value': 'D/K Curve',
        'nextQuestions': [
          135
        ]
      }
    ]
  },
  {
    'id': 134,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 135,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 136,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          137
        ]
      },
      {
        'label': 'D/K Curve',
        'value': 'D/K Curve',
        'nextQuestions': [
          138
        ]
      }
    ]
  },
  {
    'id': 137,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 138,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 139,
    'fieldName': 'No of Poles',
    'title': 'Please select the no of poles.',
    'options': [
      {
        'label': '1 Pole',
        'value': '1 Pole',
        'nextQuestions': [
          140
        ]
      },
      {
        'label': '2 Poles',
        'value': '2 Poles',
        'nextQuestions': [
          144
        ]
      },
      {
        'label': '3 Poles',
        'value': '3 Poles',
        'nextQuestions': [
          147
        ]
      }
    ]
  },
  {
    'id': 140,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'Z Curve',
        'value': 'Z Curve',
        'nextQuestions': [
          141
        ]
      },
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          142
        ]
      },
      {
        'label': 'D/K Curve',
        'value': 'D/K Curve',
        'nextQuestions': [
          143
        ]
      }
    ]
  },
  {
    'id': 141,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 142,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 143,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 144,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          145
        ]
      },
      {
        'label': 'D/K Curve',
        'value': 'D/K Curve',
        'nextQuestions': [
          146
        ]
      }
    ]
  },
  {
    'id': 145,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 146,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 147,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          148
        ]
      },
      {
        'label': 'D/K Curve',
        'value': 'D/K Curve',
        'nextQuestions': [
          149
        ]
      }
    ]
  },
  {
    'id': 148,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 149,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 150,
    'fieldName': 'No of Poles',
    'title': 'Please select the no of poles.',
    'options': [
      {
        'label': '1 Pole',
        'value': '1 Pole',
        'nextQuestions': [
          151
        ]
      },
      {
        'label': '2 Poles',
        'value': '2 Poles',
        'nextQuestions': [
          155
        ]
      },
      {
        'label': '3 Poles',
        'value': '3 Poles',
        'nextQuestions': [
          158
        ]
      }
    ]
  },
  {
    'id': 151,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'Z Curve',
        'value': 'Z Curve',
        'nextQuestions': [
          152
        ]
      },
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          153
        ]
      },
      {
        'label': 'D/K Curve',
        'value': 'D/K Curve',
        'nextQuestions': [
          154
        ]
      }
    ]
  },
  {
    'id': 152,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 153,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 154,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 155,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          156
        ]
      },
      {
        'label': 'D/K Curve',
        'value': 'D/K Curve',
        'nextQuestions': [
          157
        ]
      }
    ]
  },
  {
    'id': 156,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 157,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 158,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          159
        ]
      },
      {
        'label': 'D/K Curve',
        'value': 'D/K Curve',
        'nextQuestions': [
          160
        ]
      }
    ]
  },
  {
    'id': 159,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 160,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 161,
    'fieldName': 'No of Poles',
    'title': 'Please select the no of poles.',
    'options': [
      {
        'label': '1 Pole',
        'value': '1 Pole',
        'nextQuestions': [
          162
        ]
      },
      {
        'label': '2 Poles',
        'value': '2 Poles',
        'nextQuestions': [
          165
        ]
      },
      {
        'label': '3 Poles',
        'value': '3 Poles',
        'nextQuestions': [
          167
        ]
      }
    ]
  },
  {
    'id': 162,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'Z Curve',
        'value': 'Z Curve',
        'nextQuestions': [
          163
        ]
      },
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          164
        ]
      }
    ]
  },
  {
    'id': 163,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 164,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 165,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          166
        ]
      }
    ]
  },
  {
    'id': 166,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 167,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          168
        ]
      }
    ]
  },
  {
    'id': 168,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 169,
    'fieldName': 'No of Poles',
    'title': 'Please select the no of poles.',
    'options': [
      {
        'label': '1 Pole',
        'value': '1 Pole',
        'nextQuestions': [
          170
        ]
      },
      {
        'label': '2 Poles',
        'value': '2 Poles',
        'nextQuestions': [
          173
        ]
      },
      {
        'label': '3 Poles',
        'value': '3 Poles',
        'nextQuestions': [
          175
        ]
      }
    ]
  },
  {
    'id': 170,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'Z Curve',
        'value': 'Z Curve',
        'nextQuestions': [
          171
        ]
      },
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          172
        ]
      }
    ]
  },
  {
    'id': 171,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 172,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 173,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          174
        ]
      }
    ]
  },
  {
    'id': 174,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 175,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          176
        ]
      }
    ]
  },
  {
    'id': 176,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 177,
    'fieldName': 'No of Poles',
    'title': 'Please select the no of poles.',
    'options': [
      {
        'label': '1 Pole',
        'value': '1 Pole',
        'nextQuestions': [
          178
        ]
      },
      {
        'label': '2 Poles',
        'value': '2 Poles',
        'nextQuestions': [
          181
        ]
      },
      {
        'label': '3 Poles',
        'value': '3 Poles',
        'nextQuestions': [
          183
        ]
      }
    ]
  },
  {
    'id': 178,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'Z Curve',
        'value': 'Z Curve',
        'nextQuestions': [
          179
        ]
      },
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          180
        ]
      }
    ]
  },
  {
    'id': 179,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 180,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 181,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          182
        ]
      }
    ]
  },
  {
    'id': 182,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 183,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          184
        ]
      }
    ]
  },
  {
    'id': 184,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Ring-tongue Terminal',
        'value': 'Ring-tongue Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 185,
    'fieldName': 'No of Poles',
    'title': 'Please select the no of poles.',
    'options': [
      {
        'label': '1 Pole',
        'value': '1 Pole',
        'nextQuestions': [
          186
        ]
      },
      {
        'label': '2 Poles',
        'value': '2 Poles',
        'nextQuestions': [
          188
        ]
      }
    ]
  },
  {
    'id': 186,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          187
        ]
      }
    ]
  },
  {
    'id': 187,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 188,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          189
        ]
      }
    ]
  },
  {
    'id': 189,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 190,
    'fieldName': 'No of Poles',
    'title': 'Please select the no of poles.',
    'options': [
      {
        'label': '1 Pole',
        'value': '1 Pole',
        'nextQuestions': [
          191
        ]
      },
      {
        'label': '2 Poles',
        'value': '2 Poles',
        'nextQuestions': [
          193
        ]
      }
    ]
  },
  {
    'id': 191,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          192
        ]
      }
    ]
  },
  {
    'id': 192,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 193,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          194
        ]
      }
    ]
  },
  {
    'id': 194,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 195,
    'fieldName': 'Current Rating',
    'title': 'Please select the rated current.',
    'options': [
      {
        'label': '1 A',
        'value': '1 A',
        'nextQuestions': [
          196
        ]
      },
      {
        'label': '2 A',
        'value': '2 A',
        'nextQuestions': [
          213
        ]
      },
      {
        'label': '3 A',
        'value': '3 A',
        'nextQuestions': [
          230
        ]
      },
      {
        'label': '4 A',
        'value': '4 A',
        'nextQuestions': [
          247
        ]
      },
      {
        'label': '6 A',
        'value': '6 A',
        'nextQuestions': [
          264
        ]
      },
      {
        'label': '10 A',
        'value': '10 A',
        'nextQuestions': [
          281
        ]
      },
      {
        'label': '13 A',
        'value': '13 A',
        'nextQuestions': [
          298
        ]
      },
      {
        'label': '16 A',
        'value': '16 A',
        'nextQuestions': [
          315
        ]
      },
      {
        'label': '20 A',
        'value': '20 A',
        'nextQuestions': [
          332
        ]
      },
      {
        'label': '25 A',
        'value': '25 A',
        'nextQuestions': [
          349
        ]
      },
      {
        'label': '32 A',
        'value': '32 A',
        'nextQuestions': [
          366
        ]
      },
      {
        'label': '40 A',
        'value': '40 A',
        'nextQuestions': [
          383
        ]
      },
      {
        'label': '50 A',
        'value': '50 A',
        'nextQuestions': [
          400
        ]
      },
      {
        'label': '63 A',
        'value': '63 A',
        'nextQuestions': [
          413
        ]
      }
    ]
  },
  {
    'id': 196,
    'fieldName': 'No of Poles',
    'title': 'Please select the no of poles.',
    'options': [
      {
        'label': '1 Pole',
        'value': '1 Pole',
        'nextQuestions': [
          197
        ]
      },
      {
        'label': '2 Poles',
        'value': '2 Poles',
        'nextQuestions': [
          201
        ]
      },
      {
        'label': '3 Poles',
        'value': '3 Poles',
        'nextQuestions': [
          205
        ]
      },
      {
        'label': '4 Poles',
        'value': '4 Poles',
        'nextQuestions': [
          209
        ]
      }
    ]
  },
  {
    'id': 197,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'B Curve',
        'value': 'B Curve',
        'nextQuestions': [
          198
        ]
      },
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          199
        ]
      },
      {
        'label': 'D Curve',
        'value': 'D Curve',
        'nextQuestions': [
          200
        ]
      }
    ]
  },
  {
    'id': 198,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 199,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 200,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 201,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'B Curve',
        'value': 'B Curve',
        'nextQuestions': [
          202
        ]
      },
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          203
        ]
      },
      {
        'label': 'D Curve',
        'value': 'D Curve',
        'nextQuestions': [
          204
        ]
      }
    ]
  },
  {
    'id': 202,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 203,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 204,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 205,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'B Curve',
        'value': 'B Curve',
        'nextQuestions': [
          206
        ]
      },
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          207
        ]
      },
      {
        'label': 'D Curve',
        'value': 'D Curve',
        'nextQuestions': [
          208
        ]
      }
    ]
  },
  {
    'id': 206,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 207,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 208,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 209,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'B Curve',
        'value': 'B Curve',
        'nextQuestions': [
          210
        ]
      },
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          211
        ]
      },
      {
        'label': 'D Curve',
        'value': 'D Curve',
        'nextQuestions': [
          212
        ]
      }
    ]
  },
  {
    'id': 210,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 211,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 212,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 213,
    'fieldName': 'No of Poles',
    'title': 'Please select the no of poles.',
    'options': [
      {
        'label': '1 Pole',
        'value': '1 Pole',
        'nextQuestions': [
          214
        ]
      },
      {
        'label': '2 Poles',
        'value': '2 Poles',
        'nextQuestions': [
          218
        ]
      },
      {
        'label': '3 Poles',
        'value': '3 Poles',
        'nextQuestions': [
          222
        ]
      },
      {
        'label': '4 Poles',
        'value': '4 Poles',
        'nextQuestions': [
          226
        ]
      }
    ]
  },
  {
    'id': 214,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'B Curve',
        'value': 'B Curve',
        'nextQuestions': [
          215
        ]
      },
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          216
        ]
      },
      {
        'label': 'D Curve',
        'value': 'D Curve',
        'nextQuestions': [
          217
        ]
      }
    ]
  },
  {
    'id': 215,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 216,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 217,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 218,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'B Curve',
        'value': 'B Curve',
        'nextQuestions': [
          219
        ]
      },
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          220
        ]
      },
      {
        'label': 'D Curve',
        'value': 'D Curve',
        'nextQuestions': [
          221
        ]
      }
    ]
  },
  {
    'id': 219,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 220,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 221,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 222,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'B Curve',
        'value': 'B Curve',
        'nextQuestions': [
          223
        ]
      },
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          224
        ]
      },
      {
        'label': 'D Curve',
        'value': 'D Curve',
        'nextQuestions': [
          225
        ]
      }
    ]
  },
  {
    'id': 223,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 224,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 225,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 226,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'B Curve',
        'value': 'B Curve',
        'nextQuestions': [
          227
        ]
      },
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          228
        ]
      },
      {
        'label': 'D Curve',
        'value': 'D Curve',
        'nextQuestions': [
          229
        ]
      }
    ]
  },
  {
    'id': 227,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 228,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 229,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 230,
    'fieldName': 'No of Poles',
    'title': 'Please select the no of poles.',
    'options': [
      {
        'label': '1 Pole',
        'value': '1 Pole',
        'nextQuestions': [
          231
        ]
      },
      {
        'label': '2 Poles',
        'value': '2 Poles',
        'nextQuestions': [
          235
        ]
      },
      {
        'label': '3 Poles',
        'value': '3 Poles',
        'nextQuestions': [
          239
        ]
      },
      {
        'label': '4 Poles',
        'value': '4 Poles',
        'nextQuestions': [
          243
        ]
      }
    ]
  },
  {
    'id': 231,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'B Curve',
        'value': 'B Curve',
        'nextQuestions': [
          232
        ]
      },
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          233
        ]
      },
      {
        'label': 'D Curve',
        'value': 'D Curve',
        'nextQuestions': [
          234
        ]
      }
    ]
  },
  {
    'id': 232,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 233,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 234,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 235,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'B Curve',
        'value': 'B Curve',
        'nextQuestions': [
          236
        ]
      },
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          237
        ]
      },
      {
        'label': 'D Curve',
        'value': 'D Curve',
        'nextQuestions': [
          238
        ]
      }
    ]
  },
  {
    'id': 236,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 237,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 238,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 239,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'B Curve',
        'value': 'B Curve',
        'nextQuestions': [
          240
        ]
      },
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          241
        ]
      },
      {
        'label': 'D Curve',
        'value': 'D Curve',
        'nextQuestions': [
          242
        ]
      }
    ]
  },
  {
    'id': 240,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 241,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 242,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 243,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'B Curve',
        'value': 'B Curve',
        'nextQuestions': [
          244
        ]
      },
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          245
        ]
      },
      {
        'label': 'D Curve',
        'value': 'D Curve',
        'nextQuestions': [
          246
        ]
      }
    ]
  },
  {
    'id': 244,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 245,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 246,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 247,
    'fieldName': 'No of Poles',
    'title': 'Please select the no of poles.',
    'options': [
      {
        'label': '1 Pole',
        'value': '1 Pole',
        'nextQuestions': [
          248
        ]
      },
      {
        'label': '2 Poles',
        'value': '2 Poles',
        'nextQuestions': [
          252
        ]
      },
      {
        'label': '3 Poles',
        'value': '3 Poles',
        'nextQuestions': [
          256
        ]
      },
      {
        'label': '4 Poles',
        'value': '4 Poles',
        'nextQuestions': [
          260
        ]
      }
    ]
  },
  {
    'id': 248,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'B Curve',
        'value': 'B Curve',
        'nextQuestions': [
          249
        ]
      },
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          250
        ]
      },
      {
        'label': 'D Curve',
        'value': 'D Curve',
        'nextQuestions': [
          251
        ]
      }
    ]
  },
  {
    'id': 249,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 250,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 251,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 252,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'B Curve',
        'value': 'B Curve',
        'nextQuestions': [
          253
        ]
      },
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          254
        ]
      },
      {
        'label': 'D Curve',
        'value': 'D Curve',
        'nextQuestions': [
          255
        ]
      }
    ]
  },
  {
    'id': 253,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 254,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 255,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 256,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'B Curve',
        'value': 'B Curve',
        'nextQuestions': [
          257
        ]
      },
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          258
        ]
      },
      {
        'label': 'D Curve',
        'value': 'D Curve',
        'nextQuestions': [
          259
        ]
      }
    ]
  },
  {
    'id': 257,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 258,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 259,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 260,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'B Curve',
        'value': 'B Curve',
        'nextQuestions': [
          261
        ]
      },
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          262
        ]
      },
      {
        'label': 'D Curve',
        'value': 'D Curve',
        'nextQuestions': [
          263
        ]
      }
    ]
  },
  {
    'id': 261,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 262,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 263,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 264,
    'fieldName': 'No of Poles',
    'title': 'Please select the no of poles.',
    'options': [
      {
        'label': '1 Pole',
        'value': '1 Pole',
        'nextQuestions': [
          265
        ]
      },
      {
        'label': '2 Poles',
        'value': '2 Poles',
        'nextQuestions': [
          269
        ]
      },
      {
        'label': '3 Poles',
        'value': '3 Poles',
        'nextQuestions': [
          273
        ]
      },
      {
        'label': '4 Poles',
        'value': '4 Poles',
        'nextQuestions': [
          277
        ]
      }
    ]
  },
  {
    'id': 265,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'B Curve',
        'value': 'B Curve',
        'nextQuestions': [
          266
        ]
      },
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          267
        ]
      },
      {
        'label': 'D Curve',
        'value': 'D Curve',
        'nextQuestions': [
          268
        ]
      }
    ]
  },
  {
    'id': 266,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 267,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 268,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 269,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'B Curve',
        'value': 'B Curve',
        'nextQuestions': [
          270
        ]
      },
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          271
        ]
      },
      {
        'label': 'D Curve',
        'value': 'D Curve',
        'nextQuestions': [
          272
        ]
      }
    ]
  },
  {
    'id': 270,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 271,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 272,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 273,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'B Curve',
        'value': 'B Curve',
        'nextQuestions': [
          274
        ]
      },
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          275
        ]
      },
      {
        'label': 'D Curve',
        'value': 'D Curve',
        'nextQuestions': [
          276
        ]
      }
    ]
  },
  {
    'id': 274,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 275,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 276,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 277,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'B Curve',
        'value': 'B Curve',
        'nextQuestions': [
          278
        ]
      },
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          279
        ]
      },
      {
        'label': 'D Curve',
        'value': 'D Curve',
        'nextQuestions': [
          280
        ]
      }
    ]
  },
  {
    'id': 278,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 279,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 280,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 281,
    'fieldName': 'No of Poles',
    'title': 'Please select the no of poles.',
    'options': [
      {
        'label': '1 Pole',
        'value': '1 Pole',
        'nextQuestions': [
          282
        ]
      },
      {
        'label': '2 Poles',
        'value': '2 Poles',
        'nextQuestions': [
          286
        ]
      },
      {
        'label': '3 Poles',
        'value': '3 Poles',
        'nextQuestions': [
          290
        ]
      },
      {
        'label': '4 Poles',
        'value': '4 Poles',
        'nextQuestions': [
          294
        ]
      }
    ]
  },
  {
    'id': 282,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'B Curve',
        'value': 'B Curve',
        'nextQuestions': [
          283
        ]
      },
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          284
        ]
      },
      {
        'label': 'D Curve',
        'value': 'D Curve',
        'nextQuestions': [
          285
        ]
      }
    ]
  },
  {
    'id': 283,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 284,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 285,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 286,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'B Curve',
        'value': 'B Curve',
        'nextQuestions': [
          287
        ]
      },
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          288
        ]
      },
      {
        'label': 'D Curve',
        'value': 'D Curve',
        'nextQuestions': [
          289
        ]
      }
    ]
  },
  {
    'id': 287,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 288,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 289,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 290,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'B Curve',
        'value': 'B Curve',
        'nextQuestions': [
          291
        ]
      },
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          292
        ]
      },
      {
        'label': 'D Curve',
        'value': 'D Curve',
        'nextQuestions': [
          293
        ]
      }
    ]
  },
  {
    'id': 291,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 292,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 293,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 294,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'B Curve',
        'value': 'B Curve',
        'nextQuestions': [
          295
        ]
      },
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          296
        ]
      },
      {
        'label': 'D Curve',
        'value': 'D Curve',
        'nextQuestions': [
          297
        ]
      }
    ]
  },
  {
    'id': 295,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 296,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 297,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 298,
    'fieldName': 'No of Poles',
    'title': 'Please select the no of poles.',
    'options': [
      {
        'label': '1 Pole',
        'value': '1 Pole',
        'nextQuestions': [
          299
        ]
      },
      {
        'label': '2 Poles',
        'value': '2 Poles',
        'nextQuestions': [
          303
        ]
      },
      {
        'label': '3 Poles',
        'value': '3 Poles',
        'nextQuestions': [
          307
        ]
      },
      {
        'label': '4 Poles',
        'value': '4 Poles',
        'nextQuestions': [
          311
        ]
      }
    ]
  },
  {
    'id': 299,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'B Curve',
        'value': 'B Curve',
        'nextQuestions': [
          300
        ]
      },
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          301
        ]
      },
      {
        'label': 'D Curve',
        'value': 'D Curve',
        'nextQuestions': [
          302
        ]
      }
    ]
  },
  {
    'id': 300,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 301,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 302,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 303,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'B Curve',
        'value': 'B Curve',
        'nextQuestions': [
          304
        ]
      },
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          305
        ]
      },
      {
        'label': 'D Curve',
        'value': 'D Curve',
        'nextQuestions': [
          306
        ]
      }
    ]
  },
  {
    'id': 304,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 305,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 306,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 307,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'B Curve',
        'value': 'B Curve',
        'nextQuestions': [
          308
        ]
      },
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          309
        ]
      },
      {
        'label': 'D Curve',
        'value': 'D Curve',
        'nextQuestions': [
          310
        ]
      }
    ]
  },
  {
    'id': 308,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 309,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 310,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 311,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'B Curve',
        'value': 'B Curve',
        'nextQuestions': [
          312
        ]
      },
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          313
        ]
      },
      {
        'label': 'D Curve',
        'value': 'D Curve',
        'nextQuestions': [
          314
        ]
      }
    ]
  },
  {
    'id': 312,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 313,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 314,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 315,
    'fieldName': 'No of Poles',
    'title': 'Please select the no of poles.',
    'options': [
      {
        'label': '1 Pole',
        'value': '1 Pole',
        'nextQuestions': [
          316
        ]
      },
      {
        'label': '2 Poles',
        'value': '2 Poles',
        'nextQuestions': [
          320
        ]
      },
      {
        'label': '3 Poles',
        'value': '3 Poles',
        'nextQuestions': [
          324
        ]
      },
      {
        'label': '4 Poles',
        'value': '4 Poles',
        'nextQuestions': [
          328
        ]
      }
    ]
  },
  {
    'id': 316,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'B Curve',
        'value': 'B Curve',
        'nextQuestions': [
          317
        ]
      },
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          318
        ]
      },
      {
        'label': 'D Curve',
        'value': 'D Curve',
        'nextQuestions': [
          319
        ]
      }
    ]
  },
  {
    'id': 317,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 318,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 319,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 320,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'B Curve',
        'value': 'B Curve',
        'nextQuestions': [
          321
        ]
      },
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          322
        ]
      },
      {
        'label': 'D Curve',
        'value': 'D Curve',
        'nextQuestions': [
          323
        ]
      }
    ]
  },
  {
    'id': 321,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 322,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 323,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 324,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'B Curve',
        'value': 'B Curve',
        'nextQuestions': [
          325
        ]
      },
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          326
        ]
      },
      {
        'label': 'D Curve',
        'value': 'D Curve',
        'nextQuestions': [
          327
        ]
      }
    ]
  },
  {
    'id': 325,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 326,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 327,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 328,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'B Curve',
        'value': 'B Curve',
        'nextQuestions': [
          329
        ]
      },
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          330
        ]
      },
      {
        'label': 'D Curve',
        'value': 'D Curve',
        'nextQuestions': [
          331
        ]
      }
    ]
  },
  {
    'id': 329,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 330,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 331,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 332,
    'fieldName': 'No of Poles',
    'title': 'Please select the no of poles.',
    'options': [
      {
        'label': '1 Pole',
        'value': '1 Pole',
        'nextQuestions': [
          333
        ]
      },
      {
        'label': '2 Poles',
        'value': '2 Poles',
        'nextQuestions': [
          337
        ]
      },
      {
        'label': '3 Poles',
        'value': '3 Poles',
        'nextQuestions': [
          341
        ]
      },
      {
        'label': '4 Poles',
        'value': '4 Poles',
        'nextQuestions': [
          345
        ]
      }
    ]
  },
  {
    'id': 333,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'B Curve',
        'value': 'B Curve',
        'nextQuestions': [
          334
        ]
      },
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          335
        ]
      },
      {
        'label': 'D Curve',
        'value': 'D Curve',
        'nextQuestions': [
          336
        ]
      }
    ]
  },
  {
    'id': 334,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 335,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 336,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 337,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'B Curve',
        'value': 'B Curve',
        'nextQuestions': [
          338
        ]
      },
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          339
        ]
      },
      {
        'label': 'D Curve',
        'value': 'D Curve',
        'nextQuestions': [
          340
        ]
      }
    ]
  },
  {
    'id': 338,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 339,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 340,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 341,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'B Curve',
        'value': 'B Curve',
        'nextQuestions': [
          342
        ]
      },
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          343
        ]
      },
      {
        'label': 'D Curve',
        'value': 'D Curve',
        'nextQuestions': [
          344
        ]
      }
    ]
  },
  {
    'id': 342,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 343,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 344,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 345,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'B Curve',
        'value': 'B Curve',
        'nextQuestions': [
          346
        ]
      },
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          347
        ]
      },
      {
        'label': 'D Curve',
        'value': 'D Curve',
        'nextQuestions': [
          348
        ]
      }
    ]
  },
  {
    'id': 346,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 347,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 348,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 349,
    'fieldName': 'No of Poles',
    'title': 'Please select the no of poles.',
    'options': [
      {
        'label': '1 Pole',
        'value': '1 Pole',
        'nextQuestions': [
          350
        ]
      },
      {
        'label': '2 Poles',
        'value': '2 Poles',
        'nextQuestions': [
          354
        ]
      },
      {
        'label': '3 Poles',
        'value': '3 Poles',
        'nextQuestions': [
          358
        ]
      },
      {
        'label': '4 Poles',
        'value': '4 Poles',
        'nextQuestions': [
          362
        ]
      }
    ]
  },
  {
    'id': 350,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'B Curve',
        'value': 'B Curve',
        'nextQuestions': [
          351
        ]
      },
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          352
        ]
      },
      {
        'label': 'D Curve',
        'value': 'D Curve',
        'nextQuestions': [
          353
        ]
      }
    ]
  },
  {
    'id': 351,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 352,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 353,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 354,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'B Curve',
        'value': 'B Curve',
        'nextQuestions': [
          355
        ]
      },
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          356
        ]
      },
      {
        'label': 'D Curve',
        'value': 'D Curve',
        'nextQuestions': [
          357
        ]
      }
    ]
  },
  {
    'id': 355,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 356,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 357,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 358,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'B Curve',
        'value': 'B Curve',
        'nextQuestions': [
          359
        ]
      },
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          360
        ]
      },
      {
        'label': 'D Curve',
        'value': 'D Curve',
        'nextQuestions': [
          361
        ]
      }
    ]
  },
  {
    'id': 359,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 360,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 361,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 362,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'B Curve',
        'value': 'B Curve',
        'nextQuestions': [
          363
        ]
      },
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          364
        ]
      },
      {
        'label': 'D Curve',
        'value': 'D Curve',
        'nextQuestions': [
          365
        ]
      }
    ]
  },
  {
    'id': 363,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 364,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 365,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 366,
    'fieldName': 'No of Poles',
    'title': 'Please select the no of poles.',
    'options': [
      {
        'label': '1 Pole',
        'value': '1 Pole',
        'nextQuestions': [
          367
        ]
      },
      {
        'label': '2 Poles',
        'value': '2 Poles',
        'nextQuestions': [
          371
        ]
      },
      {
        'label': '3 Poles',
        'value': '3 Poles',
        'nextQuestions': [
          375
        ]
      },
      {
        'label': '4 Poles',
        'value': '4 Poles',
        'nextQuestions': [
          379
        ]
      }
    ]
  },
  {
    'id': 367,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'B Curve',
        'value': 'B Curve',
        'nextQuestions': [
          368
        ]
      },
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          369
        ]
      },
      {
        'label': 'D Curve',
        'value': 'D Curve',
        'nextQuestions': [
          370
        ]
      }
    ]
  },
  {
    'id': 368,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 369,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 370,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 371,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'B Curve',
        'value': 'B Curve',
        'nextQuestions': [
          372
        ]
      },
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          373
        ]
      },
      {
        'label': 'D Curve',
        'value': 'D Curve',
        'nextQuestions': [
          374
        ]
      }
    ]
  },
  {
    'id': 372,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 373,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 374,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 375,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'B Curve',
        'value': 'B Curve',
        'nextQuestions': [
          376
        ]
      },
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          377
        ]
      },
      {
        'label': 'D Curve',
        'value': 'D Curve',
        'nextQuestions': [
          378
        ]
      }
    ]
  },
  {
    'id': 376,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 377,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 378,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 379,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'B Curve',
        'value': 'B Curve',
        'nextQuestions': [
          380
        ]
      },
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          381
        ]
      },
      {
        'label': 'D Curve',
        'value': 'D Curve',
        'nextQuestions': [
          382
        ]
      }
    ]
  },
  {
    'id': 380,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 381,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 382,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 383,
    'fieldName': 'No of Poles',
    'title': 'Please select the no of poles.',
    'options': [
      {
        'label': '1 Pole',
        'value': '1 Pole',
        'nextQuestions': [
          384
        ]
      },
      {
        'label': '2 Poles',
        'value': '2 Poles',
        'nextQuestions': [
          388
        ]
      },
      {
        'label': '3 Poles',
        'value': '3 Poles',
        'nextQuestions': [
          392
        ]
      },
      {
        'label': '4 Poles',
        'value': '4 Poles',
        'nextQuestions': [
          396
        ]
      }
    ]
  },
  {
    'id': 384,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'B Curve',
        'value': 'B Curve',
        'nextQuestions': [
          385
        ]
      },
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          386
        ]
      },
      {
        'label': 'D Curve',
        'value': 'D Curve',
        'nextQuestions': [
          387
        ]
      }
    ]
  },
  {
    'id': 385,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 386,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 387,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 388,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'B Curve',
        'value': 'B Curve',
        'nextQuestions': [
          389
        ]
      },
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          390
        ]
      },
      {
        'label': 'D Curve',
        'value': 'D Curve',
        'nextQuestions': [
          391
        ]
      }
    ]
  },
  {
    'id': 389,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 390,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 391,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 392,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'B Curve',
        'value': 'B Curve',
        'nextQuestions': [
          393
        ]
      },
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          394
        ]
      },
      {
        'label': 'D Curve',
        'value': 'D Curve',
        'nextQuestions': [
          395
        ]
      }
    ]
  },
  {
    'id': 393,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 394,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 395,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 396,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'B Curve',
        'value': 'B Curve',
        'nextQuestions': [
          397
        ]
      },
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          398
        ]
      },
      {
        'label': 'D Curve',
        'value': 'D Curve',
        'nextQuestions': [
          399
        ]
      }
    ]
  },
  {
    'id': 397,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 398,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 399,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 400,
    'fieldName': 'No of Poles',
    'title': 'Please select the no of poles.',
    'options': [
      {
        'label': '1 Pole',
        'value': '1 Pole',
        'nextQuestions': [
          401
        ]
      },
      {
        'label': '2 Poles',
        'value': '2 Poles',
        'nextQuestions': [
          404
        ]
      },
      {
        'label': '3 Poles',
        'value': '3 Poles',
        'nextQuestions': [
          407
        ]
      },
      {
        'label': '4 Poles',
        'value': '4 Poles',
        'nextQuestions': [
          410
        ]
      }
    ]
  },
  {
    'id': 401,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'B Curve',
        'value': 'B Curve',
        'nextQuestions': [
          402
        ]
      },
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          403
        ]
      }
    ]
  },
  {
    'id': 402,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 403,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 404,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'B Curve',
        'value': 'B Curve',
        'nextQuestions': [
          405
        ]
      },
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          406
        ]
      }
    ]
  },
  {
    'id': 405,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 406,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 407,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'B Curve',
        'value': 'B Curve',
        'nextQuestions': [
          408
        ]
      },
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          409
        ]
      }
    ]
  },
  {
    'id': 408,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 409,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 410,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'B Curve',
        'value': 'B Curve',
        'nextQuestions': [
          411
        ]
      },
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          412
        ]
      }
    ]
  },
  {
    'id': 411,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 412,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 413,
    'fieldName': 'No of Poles',
    'title': 'Please select the no of poles.',
    'options': [
      {
        'label': '1 Pole',
        'value': '1 Pole',
        'nextQuestions': [
          414
        ]
      },
      {
        'label': '2 Poles',
        'value': '2 Poles',
        'nextQuestions': [
          417
        ]
      },
      {
        'label': '3 Poles',
        'value': '3 Poles',
        'nextQuestions': [
          420
        ]
      },
      {
        'label': '4 Poles',
        'value': '4 Poles',
        'nextQuestions': [
          423
        ]
      }
    ]
  },
  {
    'id': 414,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'B Curve',
        'value': 'B Curve',
        'nextQuestions': [
          415
        ]
      },
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          416
        ]
      }
    ]
  },
  {
    'id': 415,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 416,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 417,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'B Curve',
        'value': 'B Curve',
        'nextQuestions': [
          418
        ]
      },
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          419
        ]
      }
    ]
  },
  {
    'id': 418,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 419,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 420,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'B Curve',
        'value': 'B Curve',
        'nextQuestions': [
          421
        ]
      },
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          422
        ]
      }
    ]
  },
  {
    'id': 421,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 422,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 423,
    'fieldName': 'Breaking Curve',
    'title': 'Please select the breaking curve.',
    'options': [
      {
        'label': 'B Curve',
        'value': 'B Curve',
        'nextQuestions': [
          424
        ]
      },
      {
        'label': 'C Curve',
        'value': 'C Curve',
        'nextQuestions': [
          425
        ]
      }
    ]
  },
  {
    'id': 424,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 425,
    'fieldName': 'Connection Type',
    'title': 'Please select the connection type.',
    'options': [
      {
        'label': 'Tunnel Terminal',
        'value': 'Tunnel Terminal',
        'nextQuestions': [
          -1
        ]
      }
    ]
  }
];

export default MiniatureCircuitBreaker;

import automationControl from "./AutomationControl";
import capacitiveProximitySensor from "./CapacitiveProximitySensor";
import doorAndGateSafetyControl from "./DoorAndGateSafetyControl";
import emergencyPushButton from "./EmergencyPushButton";
import enclosure from "./Enclosure";
import humanMachineInterface from "./HumanMachineInterface";
import InductiveProximitySensor from "./InductiveProximitySensor";
import miniatureCircuitBreaker from "./MiniatureCircuitBreaker";
import moldedCaseCircuitBreaker from "./MoldedCaseCircuitBreaker";
import motionControlSolution from "./MotionControlSolution";
import motorControlSolution from "./MotorControlSolution";
import photoelectricSensor from "./PhotoelectricSensor";
import pushButton from "./PushButton";
import temperatureControl from "./TemperatureControl";
import towerLight from "./TowerLight";
import ultrasonicSensor from "./UltrasonicSensor";
import uninterruptiblePowerSupply from "./UninterruptiblePowerSupply";
import automationControlModuleIO from "./AutomationControlModuleIO";

const photoelectricSensorProductPassage = photoelectricSensor;
const photoelectricSensorSealingPositioning = photoelectricSensor;
const InductiveProximitySensorFilmTension = InductiveProximitySensor;
const InductiveProximitySensorCutterPositioning = InductiveProximitySensor;

const PartQuizDefinitions = {
  automationControl,
  automationControlModuleIO,
  capacitiveProximitySensor,
  doorAndGateSafetyControl,
  emergencyPushButton,
  enclosure,
  photoelectricSensorProductPassage,
  photoelectricSensorSealingPositioning,
  InductiveProximitySensorFilmTension,
  InductiveProximitySensorCutterPositioning,
  humanMachineInterface,
  miniatureCircuitBreaker,
  moldedCaseCircuitBreaker,
  motionControlSolution,
  motorControlSolution,
  pushButton,
  temperatureControl,
  towerLight,
  ultrasonicSensor,
  uninterruptiblePowerSupply,
};

export default PartQuizDefinitions;

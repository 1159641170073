import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useState, useContext } from "react";
import ReactGA from "react-ga4";
import { Button, ItemQuantity } from "shared/components";
import { toast } from "react-toastify";
import CustomToast from "shared/components/QuizContainer/components/CustomToast";
import "./styles.scss";

import ShopContext from "cart/context";
import { gaActions, MachineTypeShorts } from "shared/constants";
import { nextuser } from "shared/tracking/nextuser";

function ProductItemRow({
  MachineType,
  FeatureType,
  description,
  imageUrl,
  title,
  showCartOption,
  id,
  available,
}) {
  const context = useContext(ShopContext);
  const [quantity, setQuantity] = useState(1);
  const { t } = useTranslation();

  const getFeatureType = () =>
    FeatureType.toLowerCase().replace(/ /g, "_") || "";

  const handleAddToCart = () => {
    const featureType = getFeatureType();
    const selectedOptionsString = `${gaActions.clickFeatureTypeCart}_${MachineTypeShorts[MachineType]}_${featureType}`;
    nextuser(`add_to_cart_${selectedOptionsString}`);
    ReactGA.event({
      category: "IndustrialTracker.productItemContainer",
      action: "add_to_cart",
      label: selectedOptionsString,
    });

    context.addProductToCart(id, quantity, available);
    toast.success(<CustomToast />, {
      position: toast.POSITION.BOTTOM_LEFT,
    });
    setQuantity(1);
  };

  const handleDecrement = () => {
    if (quantity === 0) {
      return;
    }
    setQuantity(quantity - 1);
  };

  return (
    <div className="product-item-row">
      <div className="product-item-row__image">
        <img alt={title} src={imageUrl} />
      </div>
      <div className="product-item-meta">
        <div className="product-item-title">{t(title)}</div>
        <div className="product-item-description">{t(description)}</div>
      </div>
      {showCartOption && (
        <div className="product-item-quantity-container">
          <div className="product-item-quantity-input">
            <ItemQuantity
              onIncrement={() => setQuantity(quantity + 1)}
              onDecrement={handleDecrement}
              value={quantity}
            />
          </div>
          <div className="product-item-quantity-control">
            <Button disabled={quantity === 0} onClick={handleAddToCart}>
              {t("Add")}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

ProductItemRow.propTypes = {
  MachineType: PropTypes.string,
  FeatureType: PropTypes.string,
  description: PropTypes.string,
  imageUrl: PropTypes.string,
  title: PropTypes.string,
  showCartOption: PropTypes.bool,
  id: PropTypes.string,
  available: PropTypes.bool,
};

ProductItemRow.defaultProps = {
  MachineType: "Machine_type",
  FeatureType: "Feature_type",
  description: "",
  imageUrl: "",
  title: "",
  available: false,
  showCartOption: false,
  id: null,
};

export default ProductItemRow;

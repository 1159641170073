import React from "react";

export default React.createContext({
  value: {
    width: "100%",
    height: "100%",
    lang: "RU",
    country: "RUSSIA",
    project: "ANY",
  },
  setDimensions: () => {},
});

import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { gaActions } from "shared/constants";
import ReactGA from "react-ga4";
import Cookies from "js-cookie";
import axios from "axios";

import Checkmark from "shared/components/svg/Checkmark/index";
import CloseIcon from "shared/components/svg/Close/index";
import { ItemQuantity, Icon, Modal } from "shared/components";
import ShopContext from "cart/context";
import AppContext from "AppContainer/AppContext/context";
import { nextuser } from "shared/tracking/nextuser";
import { Table } from "./components";
import ButtonCheckmark from "./components/ButtonCheckmark";
import "./styles.scss";

const MissingPriceValue = 0.01;

const countryMapper = {
  RUS: "RUSSIA",
  USA: "US",
  CAN: "CANADA",
  ESP: "SPAIN",
  GER: "GERMANY",
  DEU: "GERMANY",
  NL: "NL",
};

const DocumentTypes = {
  certificate: "CERTIFICATE",
  instructionSheet: "INSTRUCTION_SHEET",
  userGuide: "USER_GUIDE",
  productDataSheet: "PRODUCT_DATA_SHEET",
};

const ArrowDown = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="11"
    viewBox="0 0 13 11"
  >
    <path
      id="Polygon_2"
      data-name="Polygon 2"
      d="M6.5,0,13,11H0Z"
      transform="translate(13 11) rotate(180)"
      fill="#3ab85d"
    />
  </svg>
);

const ArrowUp = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16.758"
    height="16.026"
    viewBox="0 0 16.758 16.026"
  >
    <path
      id="Polygon_2"
      data-name="Polygon 2"
      d="M6.5,0,13,11H0Z"
      transform="matrix(-0.866, -0.5, 0.5, -0.866, 11.258, 16.026)"
      fill="#3ab85d"
    />
  </svg>
);

function CartContainer() {
  const context = useContext(ShopContext);
  const appContext = useContext(AppContext);
  const { priceMapping } = appContext;
  const [t] = useTranslation();
  const [tableData, setTableData] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalPriceDecimal, setTotalPriceDecimal] = useState("0");
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [currency, setCurrency] = useState("");
  const [certificatesAvailable, setHasCertificates] = useState(false);
  const [dataSheetsAvailable, setHasDataSheets] = useState(false);
  const [instructionManualsAvailable, setHasInstructionManuals] =
    useState(false);
  const [userGuidesAvailable, setHasUserGuides] = useState(false);

  const [isDownloadModalOpen, setDownloadModalOpen] = useState(false);
  const { cart, clearCart } = context;

  const saveBlob = (blob, fileName) => {
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, fileName);
    } else {
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(blob);
      a.download = fileName;
      // eslint-disable-next-line no-undef
      a.dispatchEvent(new MouseEvent("click"));
    }
    setDownloadModalOpen(false);
  };

  const exportProductDocument = (type) => {
    setDownloadModalOpen(true);
    axios
      .post(
        "https://industrial.sesmartconfig.com/report/documents/zip",
        {
          documentType: type,
          language: appContext.state.language,
          references: cart.map((cartItem) => cartItem.id),
        },
        {
          responseType: "blob",
        }
      )
      .then((res) => {
        const blob = new Blob([res.data], { type: "application/zip" });
        saveBlob(blob, "export.zip");
      })
      .catch(() => {});
  };

  const exportExcel = () => {
    axios
      .post(
        "https://industrial.sesmartconfig.com/report/xlsx",
        {
          cityCode: appContext.state.project,
          country: appContext.state.country,
          language: appContext.state.language,
          references: cart.map((cartItem) => ({
            id: cartItem.id,
            quantity: cartItem.quantity,
          })),
        },
        {
          responseType: "blob",
        }
      )
      .then((res) => {
        const blob = new Blob([res.data], { type: "application/vnd.ms-excel" });
        saveBlob(blob, "export.xlsx");

        nextuser(`${gaActions.exportBom}_excel`);
        ReactGA.event({
          category: "IndustrialTracker.cartContainer",
          action: "export",
          label: "excel",
        });
      })
      .catch(() => {});
  };

  useEffect(() => {
    axios
      .post("https://industrial.sesmartconfig.com/report/bom", {
        cityCode: appContext.state.project,
        country: appContext.state.country,
        language: appContext.state.language,
        references: cart.map((cartItem) => ({
          id: cartItem.id,
          quantity: cartItem.quantity,
        })),
      })
      .then((res) => {
        const {
          totalPrice: priceTotal,
          totalPriceDecimal: priceTotalDecimal,
          results,
          totalQuantity: quantityTotal,
          hasCertificates,
          hasDataSheets,
          hasInstructionManuals,
          hasUserGuides,
          currency: curr,
        } = res.data;
        setHasCertificates(hasCertificates);
        setHasDataSheets(hasDataSheets);
        setHasInstructionManuals(hasInstructionManuals);
        setHasUserGuides(hasUserGuides);

        setTotalQuantity(quantityTotal);
        setTotalPrice(priceTotal);
        setTotalPriceDecimal(priceTotalDecimal);
        setCurrency(curr);
        const groupedData = {};
        results.forEach((result) => {
          groupedData[result.category] = groupedData[result.category] || [];
          groupedData[result.category].push({
            ...result,
            drNumber: result.id,
            availability: result.available,
          });
        });

        const resultMapping = Object.keys(groupedData).map((key) => ({
          category: key,
          subRows: groupedData[key],
        }));
        setTableData(resultMapping);
      })
      .catch(() => {
        setTableData([]);
      });

    return null;
  }, [JSON.stringify(cart)]);

  const expanderCell = (rowData) =>
    rowData.row.canExpand && (
      <span
        {...rowData.row.getToggleRowExpandedProps({
          style: {
            paddingLeft: `${rowData.row.depth * 2}rem`,
          },
        })}
      >
        {rowData.row.isExpanded ? ArrowUp : ArrowDown}
        {t(rowData.row.values.category)}
      </span>
    );

  const availabilityCell = (rowData) =>
    rowData.row.depth !== 0 &&
    (rowData.row.values.available ? <Checkmark /> : <CloseIcon />);

  const imageCell = (rowData) =>
    rowData.row.depth !== 0 &&
    (rowData.row.values.imageUrl ? (
      <img
        className="cart-row-image-cell"
        src={rowData.row.values.imageUrl}
        alt={rowData.row.values.id}
      />
    ) : (
      <span>-</span>
    ));

  const quantityCell = (rowData) =>
    rowData.row.depth !== 0 && (
      <ItemQuantity
        showLabel={false}
        value={rowData.row.values.quantity}
        onIncrement={() =>
          context.addProductToCart(
            rowData.row.values.id,
            1,
            rowData.row.values.available
          )
        }
        onDecrement={() =>
          context.addProductToCart(
            rowData.row.values.id,
            -1,
            rowData.row.values.available
          )
        }
      />
    );

  const actionCell = (rowData) =>
    rowData.row.depth !== 0 && (
      <button
        className="remove-item-from-cart"
        type="button"
        onClick={() => context.removeProductFromCart(rowData.row.values.id)}
      >
        <Icon type="delete" color="#F45E5E" />
      </button>
    );

  const categoryCell = (rowData) =>
    rowData.row.depth !== 0 && t(rowData.row.values.category);

  const { project } = appContext.state;
  const isPriceVisible = !!project && project !== "ANY";

  const tableColumns = useMemo(
    () =>
      [
        {
          // Build our expander column
          id: "expander", // Make sure it has an ID
          Cell: expanderCell,
          width: 40,
        },
        isPriceVisible
          ? {
              Header: t("Availability"),
              accessor: "available",
              Cell: availabilityCell,
              width: 120,
            }
          : undefined,
        {
          Header: t("Image"),
          accessor: "imageUrl",
          Cell: imageCell,
        },
        {
          Header: t("Manufacturer reference"),
          accessor: "id",
        },
        {
          Header: t("Category"),
          accessor: "category",
          Cell: categoryCell,
        },
        isPriceVisible
          ? {
              Header: t("cart.table.distributorStockNr"),
              accessor: "distributorId",
            }
          : undefined,
        isPriceVisible
          ? {
              Header: t("Price"),
              accessor: "price",
            }
          : undefined,
        {
          Header: t("Qty"),
          accessor: "quantity",
          Cell: quantityCell,
        },
        isPriceVisible
          ? {
              Header: t("Total"),
              accessor: "totalPrice",
            }
          : undefined,
        {
          Header: t("Actions"),
          accessor: "actions",
          Cell: actionCell,
        },
      ].filter((x) => !!x),
    []
  );

  const hasItemsInCart = cart.length > 0;

  const getPrice = (item) => {
    if (item.priceDecimal) {
      return item.priceDecimal;
    }

    if (
      [countryMapper.NL, countryMapper.DEU, countryMapper.ESP].indexOf(
        appContext.state.country
      ) < 0
    ) {
      return MissingPriceValue;
    }

    const match = priceMapping.find((x) => x.id === item.id);
    if (match) {
      return match.price;
    }

    return MissingPriceValue;
  };

  const trackAddToCartAsPurchase = () => {
    if (!tableData || tableData.length === 0) {
      return;
    }
    const flatTable = tableData.reduce(
      (prev, curr) => [...prev, ...curr.subRows],
      []
    );
    const transactionId = `${Date.now()}-${flatTable.length}`;
    const items = flatTable.filter((x) => getPrice(x) > 0);
    if (items.length === 0) {
      return;
    }

    items.forEach((item) => {
      const price = getPrice(item);
      ReactGA.event({
        category: "IndustrialTracker.ecommerce",
        action: "addItem",
        label: `id_${item.id}_qty_${item.quantity}_price_${price}`,
      });
    });

    const productsArray = items.map((item) => ({
      item_id: item.id,
      quantity: Number(item.quantity),
    }));

    ReactGA.event({
      category: "IndustrialTracker.purchase",
      action: "complete",
      label: totalPrice,
    });

    ReactGA.event("purchase", {
      transaction_id: transactionId,
      value: totalPriceDecimal,
      currency: `${currency}`,
      items: productsArray,
    });
  };

  const handleAddToCart = (qty, price) => () => {
    nextuser(`${gaActions.addToCartBom}_${qty}_${parseInt(price, 10)}`);
    ReactGA.event({
      category: "IndustrialTracker.cartContainer",
      action: "add_to_cart",
      label: `${parseInt(price, 10)}`,
    });
    const gid = Cookies.get("_gid");
    trackAddToCartAsPurchase(tableData);

    const data = {
      gid,
      options: {
        id: uuidv4(), // unique id for each "add to cart"
        products: cart.map((x) => ({ reference: x.id, quantity: x.quantity })),
        quantity: 1, // number of times we should add the configuration
        extras: {
          pluginId: "Industrial Configurator", // name of the plugin which has to be set by plugin
        },
      },
      type: "ScWidgetSaveConfigurationEvent",
    };

    if (!window.parent) {
      return;
    }

    window.parent.postMessage(data, "*");
    clearCart();
  };

  const cartButtonClassname = "Button Button-size-md Button-theme-success";

  return (
    <div className="cart-container">
      <div className="cart-header">
        <div className="cart-header-title">{t("Bill of Materials")}</div>
        <div className="right">
          {hasItemsInCart && (
            <button
              onClick={() => {
                setDownloadModalOpen(true);
                exportExcel();
              }}
              type="button"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="29.948"
                viewBox="0 0 30 29.948"
              >
                <path
                  id="Path_56"
                  data-name="Path 56"
                  d="M16.865,8.342a2.075,2.075,0,0,1-2.969,0L7.959,2.717a2.774,2.774,0,0,1-.9-2.11,2.923,2.923,0,0,1,.9-2.031,2.939,2.939,0,0,1,2.149-.82,2.964,2.964,0,0,1,2.07.9l.078.156V-8.221a3.46,3.46,0,0,1,.43-1.328,2.6,2.6,0,0,1,.937-.977,3.152,3.152,0,0,1,1.289-.43,2.98,2.98,0,0,1,2.266.625A2.777,2.777,0,0,1,18.35-8.3v7.109l.156-.078a2.7,2.7,0,0,1,1.992-.9,2.939,2.939,0,0,1,2.148.82,2.709,2.709,0,0,1,.938,2.07A2.7,2.7,0,0,1,22.8,2.717a.069.069,0,0,1-.078.078ZM11.006-.1A1.418,1.418,0,0,0,9.99-.564a1.454,1.454,0,0,0-.938.313,1.14,1.14,0,0,0,0,1.719L14.99,7.092a.488.488,0,0,0,.781,0l5.781-5.469a1.074,1.074,0,0,0,0-1.719,1.171,1.171,0,0,0-1.875,0L18.115,1.311a.783.783,0,0,1-.938.156.807.807,0,0,1-.469-.781V-8.221A1.159,1.159,0,0,0,16.162-9a1.155,1.155,0,0,0-1.016-.313,1.344,1.344,0,0,0-1.25,1.25V.686a.751.751,0,0,1-.469.7.879.879,0,0,1-.937-.078ZM27.178,18.967H3.428a3.062,3.062,0,0,1-2.187-.9A2.96,2.96,0,0,1,.3,15.842V9.67A3.651,3.651,0,0,1,1.24,7.326a3.336,3.336,0,0,1,2.344-.859H5.146q.86,0,.86.781v5.938H24.6V7.248q0-.781.859-.781h1.719a3,3,0,0,1,2.188.937A3.087,3.087,0,0,1,30.3,9.67v6.172a3.2,3.2,0,0,1-3.125,3.125ZM3.428,8.186A1.273,1.273,0,0,0,2.49,8.5a1.5,1.5,0,0,0-.469,1.094v6.25a1.339,1.339,0,0,0,1.406,1.406h23.75a1.256,1.256,0,0,0,1.016-.313,1.874,1.874,0,0,0,.391-1.094V9.67a1.709,1.709,0,0,0-.313-1.094,1.873,1.873,0,0,0-1.094-.391H26.24v5.859q0,.859-.781.859H5.146q-.781,0-.781-.859V8.186Z"
                  transform="translate(-0.303 10.981)"
                  fill="#18ca63"
                />
              </svg>
              {t("Export")}
            </button>
          )}
          {dataSheetsAvailable && (
            <button
              onClick={() => {
                exportProductDocument(DocumentTypes.productDataSheet);
              }}
              type="button"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="29.948"
                viewBox="0 0 30 29.948"
              >
                <path
                  id="Path_56"
                  data-name="Path 56"
                  d="M16.865,8.342a2.075,2.075,0,0,1-2.969,0L7.959,2.717a2.774,2.774,0,0,1-.9-2.11,2.923,2.923,0,0,1,.9-2.031,2.939,2.939,0,0,1,2.149-.82,2.964,2.964,0,0,1,2.07.9l.078.156V-8.221a3.46,3.46,0,0,1,.43-1.328,2.6,2.6,0,0,1,.937-.977,3.152,3.152,0,0,1,1.289-.43,2.98,2.98,0,0,1,2.266.625A2.777,2.777,0,0,1,18.35-8.3v7.109l.156-.078a2.7,2.7,0,0,1,1.992-.9,2.939,2.939,0,0,1,2.148.82,2.709,2.709,0,0,1,.938,2.07A2.7,2.7,0,0,1,22.8,2.717a.069.069,0,0,1-.078.078ZM11.006-.1A1.418,1.418,0,0,0,9.99-.564a1.454,1.454,0,0,0-.938.313,1.14,1.14,0,0,0,0,1.719L14.99,7.092a.488.488,0,0,0,.781,0l5.781-5.469a1.074,1.074,0,0,0,0-1.719,1.171,1.171,0,0,0-1.875,0L18.115,1.311a.783.783,0,0,1-.938.156.807.807,0,0,1-.469-.781V-8.221A1.159,1.159,0,0,0,16.162-9a1.155,1.155,0,0,0-1.016-.313,1.344,1.344,0,0,0-1.25,1.25V.686a.751.751,0,0,1-.469.7.879.879,0,0,1-.937-.078ZM27.178,18.967H3.428a3.062,3.062,0,0,1-2.187-.9A2.96,2.96,0,0,1,.3,15.842V9.67A3.651,3.651,0,0,1,1.24,7.326a3.336,3.336,0,0,1,2.344-.859H5.146q.86,0,.86.781v5.938H24.6V7.248q0-.781.859-.781h1.719a3,3,0,0,1,2.188.937A3.087,3.087,0,0,1,30.3,9.67v6.172a3.2,3.2,0,0,1-3.125,3.125ZM3.428,8.186A1.273,1.273,0,0,0,2.49,8.5a1.5,1.5,0,0,0-.469,1.094v6.25a1.339,1.339,0,0,0,1.406,1.406h23.75a1.256,1.256,0,0,0,1.016-.313,1.874,1.874,0,0,0,.391-1.094V9.67a1.709,1.709,0,0,0-.313-1.094,1.873,1.873,0,0,0-1.094-.391H26.24v5.859q0,.859-.781.859H5.146q-.781,0-.781-.859V8.186Z"
                  transform="translate(-0.303 10.981)"
                  fill="#18ca63"
                />
              </svg>
              {t("Datasheets")}
            </button>
          )}
          {instructionManualsAvailable && (
            <button
              onClick={() => {
                exportProductDocument(DocumentTypes.instructionSheet);
              }}
              type="button"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="29.948"
                viewBox="0 0 30 29.948"
              >
                <path
                  id="Path_56"
                  data-name="Path 56"
                  d="M16.865,8.342a2.075,2.075,0,0,1-2.969,0L7.959,2.717a2.774,2.774,0,0,1-.9-2.11,2.923,2.923,0,0,1,.9-2.031,2.939,2.939,0,0,1,2.149-.82,2.964,2.964,0,0,1,2.07.9l.078.156V-8.221a3.46,3.46,0,0,1,.43-1.328,2.6,2.6,0,0,1,.937-.977,3.152,3.152,0,0,1,1.289-.43,2.98,2.98,0,0,1,2.266.625A2.777,2.777,0,0,1,18.35-8.3v7.109l.156-.078a2.7,2.7,0,0,1,1.992-.9,2.939,2.939,0,0,1,2.148.82,2.709,2.709,0,0,1,.938,2.07A2.7,2.7,0,0,1,22.8,2.717a.069.069,0,0,1-.078.078ZM11.006-.1A1.418,1.418,0,0,0,9.99-.564a1.454,1.454,0,0,0-.938.313,1.14,1.14,0,0,0,0,1.719L14.99,7.092a.488.488,0,0,0,.781,0l5.781-5.469a1.074,1.074,0,0,0,0-1.719,1.171,1.171,0,0,0-1.875,0L18.115,1.311a.783.783,0,0,1-.938.156.807.807,0,0,1-.469-.781V-8.221A1.159,1.159,0,0,0,16.162-9a1.155,1.155,0,0,0-1.016-.313,1.344,1.344,0,0,0-1.25,1.25V.686a.751.751,0,0,1-.469.7.879.879,0,0,1-.937-.078ZM27.178,18.967H3.428a3.062,3.062,0,0,1-2.187-.9A2.96,2.96,0,0,1,.3,15.842V9.67A3.651,3.651,0,0,1,1.24,7.326a3.336,3.336,0,0,1,2.344-.859H5.146q.86,0,.86.781v5.938H24.6V7.248q0-.781.859-.781h1.719a3,3,0,0,1,2.188.937A3.087,3.087,0,0,1,30.3,9.67v6.172a3.2,3.2,0,0,1-3.125,3.125ZM3.428,8.186A1.273,1.273,0,0,0,2.49,8.5a1.5,1.5,0,0,0-.469,1.094v6.25a1.339,1.339,0,0,0,1.406,1.406h23.75a1.256,1.256,0,0,0,1.016-.313,1.874,1.874,0,0,0,.391-1.094V9.67a1.709,1.709,0,0,0-.313-1.094,1.873,1.873,0,0,0-1.094-.391H26.24v5.859q0,.859-.781.859H5.146q-.781,0-.781-.859V8.186Z"
                  transform="translate(-0.303 10.981)"
                  fill="#18ca63"
                />
              </svg>
              {t("Instruction Sheets")}
            </button>
          )}
          {userGuidesAvailable && (
            <button
              onClick={() => {
                nextuser(`${gaActions.exportBom}_user_guides`);
                ReactGA.event({
                  category: "IndustrialTracker.cartContainer",
                  action: "export",
                  label: "user_guides",
                });
                exportProductDocument(DocumentTypes.userGuide);
              }}
              type="button"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="29.948"
                viewBox="0 0 30 29.948"
              >
                <path
                  id="Path_56"
                  data-name="Path 56"
                  d="M16.865,8.342a2.075,2.075,0,0,1-2.969,0L7.959,2.717a2.774,2.774,0,0,1-.9-2.11,2.923,2.923,0,0,1,.9-2.031,2.939,2.939,0,0,1,2.149-.82,2.964,2.964,0,0,1,2.07.9l.078.156V-8.221a3.46,3.46,0,0,1,.43-1.328,2.6,2.6,0,0,1,.937-.977,3.152,3.152,0,0,1,1.289-.43,2.98,2.98,0,0,1,2.266.625A2.777,2.777,0,0,1,18.35-8.3v7.109l.156-.078a2.7,2.7,0,0,1,1.992-.9,2.939,2.939,0,0,1,2.148.82,2.709,2.709,0,0,1,.938,2.07A2.7,2.7,0,0,1,22.8,2.717a.069.069,0,0,1-.078.078ZM11.006-.1A1.418,1.418,0,0,0,9.99-.564a1.454,1.454,0,0,0-.938.313,1.14,1.14,0,0,0,0,1.719L14.99,7.092a.488.488,0,0,0,.781,0l5.781-5.469a1.074,1.074,0,0,0,0-1.719,1.171,1.171,0,0,0-1.875,0L18.115,1.311a.783.783,0,0,1-.938.156.807.807,0,0,1-.469-.781V-8.221A1.159,1.159,0,0,0,16.162-9a1.155,1.155,0,0,0-1.016-.313,1.344,1.344,0,0,0-1.25,1.25V.686a.751.751,0,0,1-.469.7.879.879,0,0,1-.937-.078ZM27.178,18.967H3.428a3.062,3.062,0,0,1-2.187-.9A2.96,2.96,0,0,1,.3,15.842V9.67A3.651,3.651,0,0,1,1.24,7.326a3.336,3.336,0,0,1,2.344-.859H5.146q.86,0,.86.781v5.938H24.6V7.248q0-.781.859-.781h1.719a3,3,0,0,1,2.188.937A3.087,3.087,0,0,1,30.3,9.67v6.172a3.2,3.2,0,0,1-3.125,3.125ZM3.428,8.186A1.273,1.273,0,0,0,2.49,8.5a1.5,1.5,0,0,0-.469,1.094v6.25a1.339,1.339,0,0,0,1.406,1.406h23.75a1.256,1.256,0,0,0,1.016-.313,1.874,1.874,0,0,0,.391-1.094V9.67a1.709,1.709,0,0,0-.313-1.094,1.873,1.873,0,0,0-1.094-.391H26.24v5.859q0,.859-.781.859H5.146q-.781,0-.781-.859V8.186Z"
                  transform="translate(-0.303 10.981)"
                  fill="#18ca63"
                />
              </svg>
              {t("User Guides")}
            </button>
          )}
          {certificatesAvailable && (
            <button
              onClick={() => {
                nextuser(`${gaActions.exportBom}_certificates`);
                ReactGA.event({
                  category: "IndustrialTracker.cartContainer",
                  action: "export",
                  label: "certificates",
                });
                exportProductDocument(DocumentTypes.certificate);
              }}
              type="button"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="29.948"
                viewBox="0 0 30 29.948"
              >
                <path
                  id="Path_56"
                  data-name="Path 56"
                  d="M16.865,8.342a2.075,2.075,0,0,1-2.969,0L7.959,2.717a2.774,2.774,0,0,1-.9-2.11,2.923,2.923,0,0,1,.9-2.031,2.939,2.939,0,0,1,2.149-.82,2.964,2.964,0,0,1,2.07.9l.078.156V-8.221a3.46,3.46,0,0,1,.43-1.328,2.6,2.6,0,0,1,.937-.977,3.152,3.152,0,0,1,1.289-.43,2.98,2.98,0,0,1,2.266.625A2.777,2.777,0,0,1,18.35-8.3v7.109l.156-.078a2.7,2.7,0,0,1,1.992-.9,2.939,2.939,0,0,1,2.148.82,2.709,2.709,0,0,1,.938,2.07A2.7,2.7,0,0,1,22.8,2.717a.069.069,0,0,1-.078.078ZM11.006-.1A1.418,1.418,0,0,0,9.99-.564a1.454,1.454,0,0,0-.938.313,1.14,1.14,0,0,0,0,1.719L14.99,7.092a.488.488,0,0,0,.781,0l5.781-5.469a1.074,1.074,0,0,0,0-1.719,1.171,1.171,0,0,0-1.875,0L18.115,1.311a.783.783,0,0,1-.938.156.807.807,0,0,1-.469-.781V-8.221A1.159,1.159,0,0,0,16.162-9a1.155,1.155,0,0,0-1.016-.313,1.344,1.344,0,0,0-1.25,1.25V.686a.751.751,0,0,1-.469.7.879.879,0,0,1-.937-.078ZM27.178,18.967H3.428a3.062,3.062,0,0,1-2.187-.9A2.96,2.96,0,0,1,.3,15.842V9.67A3.651,3.651,0,0,1,1.24,7.326a3.336,3.336,0,0,1,2.344-.859H5.146q.86,0,.86.781v5.938H24.6V7.248q0-.781.859-.781h1.719a3,3,0,0,1,2.188.937A3.087,3.087,0,0,1,30.3,9.67v6.172a3.2,3.2,0,0,1-3.125,3.125ZM3.428,8.186A1.273,1.273,0,0,0,2.49,8.5a1.5,1.5,0,0,0-.469,1.094v6.25a1.339,1.339,0,0,0,1.406,1.406h23.75a1.256,1.256,0,0,0,1.016-.313,1.874,1.874,0,0,0,.391-1.094V9.67a1.709,1.709,0,0,0-.313-1.094,1.873,1.873,0,0,0-1.094-.391H26.24v5.859q0,.859-.781.859H5.146q-.781,0-.781-.859V8.186Z"
                  transform="translate(-0.303 10.981)"
                  fill="#18ca63"
                />
              </svg>
              {t("Certificates")}
            </button>
          )}
        </div>
      </div>
      {!hasItemsInCart && (
        <div className="cart-items-empty">
          {t("Select a product and add it to the cart to make it appear here")}
        </div>
      )}
      {hasItemsInCart && (
        <>
          <div className="cart-items">
            <Table
              columns={tableColumns}
              data={tableData}
              getRowProps={(row) => {
                const payload = {};
                if (row.depth === 0) {
                  payload.className = "category";
                }

                return payload;
              }}
            />
          </div>
          <div className="cart-footer">
            <div className="cart-footer-title">{t("Total")}</div>
            <div className="cart-footer-right">
              <div className="cart-footer-quantity">{totalQuantity}</div>
              {isPriceVisible && (
                <div className="cart-footer-total">{totalPrice}</div>
              )}
            </div>
          </div>
          <div className="cart-controls">
            <ButtonCheckmark
              additionalClass={cartButtonClassname}
              onClickAction={handleAddToCart(totalQuantity, totalPrice)}
            />
          </div>
        </>
      )}
      <Modal
        size="sm"
        isOpen={isDownloadModalOpen}
        closeCb={() => setDownloadModalOpen(false)}
      >
        <div className="download-modal-body">
          {t("Your download will start soon")}
        </div>
      </Modal>
    </div>
  );
}

export default CartContainer;

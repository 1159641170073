import { useTranslation } from "react-i18next";
import { useState } from "react";
import "./styles.scss";

// eslint-disable-next-line react/prop-types
function ButtonCheckmark({ additionalClass, onClickAction }) {
  const buttonCheckmarkClassname = `${additionalClass} checkmark-btn`;
  const buttonCheckmarkContainerClassname = "checkmark-container";
  const [t] = useTranslation();

  const [buttonText, setButtonText] = useState(t("Add to cart"));
  const changeText = (text) => setButtonText(text);

  const handleButtonClick = () => {
    onClickAction();
    const $container = document.querySelector(".checkmark-container");
    const $btn = $container.querySelector(".checkmark-btn");
    $btn.classList.add("clicked");
    $btn.setAttribute("disabled", true);
    setTimeout(() => {
      $btn.classList.remove("clicked");
      $btn.classList.add("validate");
      changeText("");
      setTimeout(() => {
        $btn.classList.remove("validate");
        $btn.removeAttribute("disabled");
        changeText(t("Add to cart"));
      }, 2500);
    }, 2000);
  };

  return (
    <div className={`${buttonCheckmarkContainerClassname}`}>
      <button
        type="button"
        onClick={handleButtonClick}
        className={`${buttonCheckmarkClassname}`}
      >
        {buttonText}
      </button>
    </div>
  );
}

export default ButtonCheckmark;

/* eslint-disable */

const MotorControlSolution = [
  {
    "id": 0,
    "fieldName": "Voltage Phase",
    "title": "Select voltage phase.",
    "options": [
      {
        "label": "1-Phase",
        "value": "1-Phase",
        "nextQuestions": [
          1
        ]
      },
      {
        "label": "3-Phase",
        "value": "3-Phase",
        "nextQuestions": [
          24
        ]
      }
    ]
  },
  {
    "id": 1,
    "fieldName": "Operating Voltage",
    "title": "Select your operating voltage.",
    "options": [
      {
        "label": "200-240 VAC",
        "value": "200-240 VAC",
        "nextQuestions": [
          2
        ]
      }
    ]
  },
  {
    "id": 2,
    "fieldName": "Motor Power (kW)",
    "title": "Select your motor power.",
    "options": [
      {
        "label": "0.18 kW",
        "value": "0.18 kW",
        "nextQuestions": [
          3
        ]
      },
      {
        "label": "0.37 kW",
        "value": "0.37 kW",
        "nextQuestions": [
          6
        ]
      },
      {
        "label": "0.55 kW",
        "value": "0.55 kW",
        "nextQuestions": [
          9
        ]
      },
      {
        "label": "0.75 kW",
        "value": "0.75 kW",
        "nextQuestions": [
          12
        ]
      },
      {
        "label": "1.1 kW",
        "value": "1.1 kW",
        "nextQuestions": [
          15
        ]
      },
      {
        "label": "1.5 kW",
        "value": "1.5 kW",
        "nextQuestions": [
          18
        ]
      },
      {
        "label": "2.2 kW",
        "value": "2.2 kW",
        "nextQuestions": [
          21
        ]
      }
    ]
  },
  {
    "id": 3,
    "fieldName": "Protection Level",
    "title": "Select your protection level.",
    "options": [
      {
        "label": "IP 20",
        "value": "IP 20",
        "nextQuestions": [
          4
        ]
      },
      {
        "label": "IP 65 / 66",
        "value": "IP 65 / 66",
        "nextQuestions": [
          5
        ]
      }
    ]
  },
  {
    "id": 4,
    "fieldName": "Type",
    "title": "Select your drive format.",
    "options": [
      {
        "label": "Book Format",
        "value": "Book Format",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "Compact Format",
        "value": "Compact Format",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 5,
    "fieldName": "Type",
    "title": "Select your drive format.",
    "options": [
      {
        "label": "Without Safety Switch Disconnector",
        "value": "Without Safety Switch Disconnector",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "With Safety Switch Disconnector",
        "value": "With Safety Switch Disconnector",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 6,
    "fieldName": "Protection Level",
    "title": "Select your protection level.",
    "options": [
      {
        "label": "IP 20",
        "value": "IP 20",
        "nextQuestions": [
          7
        ]
      },
      {
        "label": "IP 65 / 66",
        "value": "IP 65 / 66",
        "nextQuestions": [
          8
        ]
      }
    ]
  },
  {
    "id": 7,
    "fieldName": "Type",
    "title": "Select your drive format.",
    "options": [
      {
        "label": "Book Format",
        "value": "Book Format",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "Compact Format",
        "value": "Compact Format",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 8,
    "fieldName": "Type",
    "title": "Select your drive format.",
    "options": [
      {
        "label": "Without Safety Switch Disconnector",
        "value": "Without Safety Switch Disconnector",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "With Safety Switch Disconnector",
        "value": "With Safety Switch Disconnector",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 9,
    "fieldName": "Protection Level",
    "title": "Select your protection level.",
    "options": [
      {
        "label": "IP 20",
        "value": "IP 20",
        "nextQuestions": [
          10
        ]
      },
      {
        "label": "IP 65 / 66",
        "value": "IP 65 / 66",
        "nextQuestions": [
          11
        ]
      }
    ]
  },
  {
    "id": 10,
    "fieldName": "Type",
    "title": "Select your drive format.",
    "options": [
      {
        "label": "Book Format",
        "value": "Book Format",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "Compact Format",
        "value": "Compact Format",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 11,
    "fieldName": "Type",
    "title": "Select your drive format.",
    "options": [
      {
        "label": "Without Safety Switch Disconnector",
        "value": "Without Safety Switch Disconnector",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "With Safety Switch Disconnector",
        "value": "With Safety Switch Disconnector",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 12,
    "fieldName": "Protection Level",
    "title": "Select your protection level.",
    "options": [
      {
        "label": "IP 20",
        "value": "IP 20",
        "nextQuestions": [
          13
        ]
      },
      {
        "label": "IP 65 / 66",
        "value": "IP 65 / 66",
        "nextQuestions": [
          14
        ]
      }
    ]
  },
  {
    "id": 13,
    "fieldName": "Type",
    "title": "Select your drive format.",
    "options": [
      {
        "label": "Book Format",
        "value": "Book Format",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "Compact Format",
        "value": "Compact Format",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 14,
    "fieldName": "Type",
    "title": "Select your drive format.",
    "options": [
      {
        "label": "Without Safety Switch Disconnector",
        "value": "Without Safety Switch Disconnector",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "With Safety Switch Disconnector",
        "value": "With Safety Switch Disconnector",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 15,
    "fieldName": "Protection Level",
    "title": "Select your protection level.",
    "options": [
      {
        "label": "IP 20",
        "value": "IP 20",
        "nextQuestions": [
          16
        ]
      },
      {
        "label": "IP 65 / 66",
        "value": "IP 65 / 66",
        "nextQuestions": [
          17
        ]
      }
    ]
  },
  {
    "id": 16,
    "fieldName": "Type",
    "title": "Select your drive format.",
    "options": [
      {
        "label": "Book Format",
        "value": "Book Format",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "Compact Format",
        "value": "Compact Format",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 17,
    "fieldName": "Type",
    "title": "Select your drive format.",
    "options": [
      {
        "label": "Without Safety Switch Disconnector",
        "value": "Without Safety Switch Disconnector",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "With Safety Switch Disconnector",
        "value": "With Safety Switch Disconnector",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 18,
    "fieldName": "Protection Level",
    "title": "Select your protection level.",
    "options": [
      {
        "label": "IP 20",
        "value": "IP 20",
        "nextQuestions": [
          19
        ]
      },
      {
        "label": "IP 65 / 66",
        "value": "IP 65 / 66",
        "nextQuestions": [
          20
        ]
      }
    ]
  },
  {
    "id": 19,
    "fieldName": "Type",
    "title": "Select your drive format.",
    "options": [
      {
        "label": "Book Format",
        "value": "Book Format",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "Compact Format",
        "value": "Compact Format",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 20,
    "fieldName": "Type",
    "title": "Select your drive format.",
    "options": [
      {
        "label": "Without Safety Switch Disconnector",
        "value": "Without Safety Switch Disconnector",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "With Safety Switch Disconnector",
        "value": "With Safety Switch Disconnector",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 21,
    "fieldName": "Protection Level",
    "title": "Select your protection level.",
    "options": [
      {
        "label": "IP 20",
        "value": "IP 20",
        "nextQuestions": [
          22
        ]
      },
      {
        "label": "IP 65 / 66",
        "value": "IP 65 / 66",
        "nextQuestions": [
          23
        ]
      }
    ]
  },
  {
    "id": 22,
    "fieldName": "Type",
    "title": "Select your drive format.",
    "options": [
      {
        "label": "Book Format",
        "value": "Book Format",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "Compact Format",
        "value": "Compact Format",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 23,
    "fieldName": "Type",
    "title": "Select your drive format.",
    "options": [
      {
        "label": "Without Safety Switch Disconnector",
        "value": "Without Safety Switch Disconnector",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "With Safety Switch Disconnector",
        "value": "With Safety Switch Disconnector",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 24,
    "fieldName": "Operating Voltage",
    "title": "Select your operating voltage.",
    "options": [
      {
        "label": "200-240 VAC",
        "value": "200-240 VAC",
        "nextQuestions": [
          25
        ]
      },
      {
        "label": "380-500 VAC",
        "value": "380-500 VAC",
        "nextQuestions": [
          52
        ]
      },
      {
        "label": "525-600 VAC",
        "value": "525-600 VAC",
        "nextQuestions": [
          87
        ]
      }
    ]
  },
  {
    "id": 25,
    "fieldName": "Motor Power (kW)",
    "title": "Select your motor power.",
    "options": [
      {
        "label": "0.18 kW",
        "value": "0.18 kW",
        "nextQuestions": [
          26
        ]
      },
      {
        "label": "0.37 kW",
        "value": "0.37 kW",
        "nextQuestions": [
          28
        ]
      },
      {
        "label": "0.55 kW",
        "value": "0.55 kW",
        "nextQuestions": [
          30
        ]
      },
      {
        "label": "0.75 kW",
        "value": "0.75 kW",
        "nextQuestions": [
          32
        ]
      },
      {
        "label": "1.1 kW",
        "value": "1.1 kW",
        "nextQuestions": [
          34
        ]
      },
      {
        "label": "1.5 kW",
        "value": "1.5 kW",
        "nextQuestions": [
          36
        ]
      },
      {
        "label": "2.2 kW",
        "value": "2.2 kW",
        "nextQuestions": [
          38
        ]
      },
      {
        "label": "3 kW",
        "value": "3 kW",
        "nextQuestions": [
          40
        ]
      },
      {
        "label": "4 kW",
        "value": "4 kW",
        "nextQuestions": [
          42
        ]
      },
      {
        "label": "5.5 kW",
        "value": "5.5 kW",
        "nextQuestions": [
          44
        ]
      },
      {
        "label": "7.5 kW",
        "value": "7.5 kW",
        "nextQuestions": [
          46
        ]
      },
      {
        "label": "11 kW",
        "value": "11 kW",
        "nextQuestions": [
          48
        ]
      },
      {
        "label": "15 kW",
        "value": "15 kW",
        "nextQuestions": [
          50
        ]
      }
    ]
  },
  {
    "id": 26,
    "fieldName": "Protection Level",
    "title": "Select your protection level.",
    "options": [
      {
        "label": "IP 20",
        "value": "IP 20",
        "nextQuestions": [
          27
        ]
      }
    ]
  },
  {
    "id": 27,
    "fieldName": "Type",
    "title": "Select your drive format.",
    "options": [
      {
        "label": "Compact Format",
        "value": "Compact Format",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 28,
    "fieldName": "Protection Level",
    "title": "Select your protection level.",
    "options": [
      {
        "label": "IP 20",
        "value": "IP 20",
        "nextQuestions": [
          29
        ]
      }
    ]
  },
  {
    "id": 29,
    "fieldName": "Type",
    "title": "Select your drive format.",
    "options": [
      {
        "label": "Compact Format",
        "value": "Compact Format",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 30,
    "fieldName": "Protection Level",
    "title": "Select your protection level.",
    "options": [
      {
        "label": "IP 20",
        "value": "IP 20",
        "nextQuestions": [
          31
        ]
      }
    ]
  },
  {
    "id": 31,
    "fieldName": "Type",
    "title": "Select your drive format.",
    "options": [
      {
        "label": "Compact Format",
        "value": "Compact Format",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 32,
    "fieldName": "Protection Level",
    "title": "Select your protection level.",
    "options": [
      {
        "label": "IP 20",
        "value": "IP 20",
        "nextQuestions": [
          33
        ]
      }
    ]
  },
  {
    "id": 33,
    "fieldName": "Type",
    "title": "Select your drive format.",
    "options": [
      {
        "label": "Compact Format",
        "value": "Compact Format",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 34,
    "fieldName": "Protection Level",
    "title": "Select your protection level.",
    "options": [
      {
        "label": "IP 20",
        "value": "IP 20",
        "nextQuestions": [
          35
        ]
      }
    ]
  },
  {
    "id": 35,
    "fieldName": "Type",
    "title": "Select your drive format.",
    "options": [
      {
        "label": "Compact Format",
        "value": "Compact Format",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 36,
    "fieldName": "Protection Level",
    "title": "Select your protection level.",
    "options": [
      {
        "label": "IP 20",
        "value": "IP 20",
        "nextQuestions": [
          37
        ]
      }
    ]
  },
  {
    "id": 37,
    "fieldName": "Type",
    "title": "Select your drive format.",
    "options": [
      {
        "label": "Compact Format",
        "value": "Compact Format",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 38,
    "fieldName": "Protection Level",
    "title": "Select your protection level.",
    "options": [
      {
        "label": "IP 20",
        "value": "IP 20",
        "nextQuestions": [
          39
        ]
      }
    ]
  },
  {
    "id": 39,
    "fieldName": "Type",
    "title": "Select your drive format.",
    "options": [
      {
        "label": "Compact Format",
        "value": "Compact Format",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 40,
    "fieldName": "Protection Level",
    "title": "Select your protection level.",
    "options": [
      {
        "label": "IP 20",
        "value": "IP 20",
        "nextQuestions": [
          41
        ]
      }
    ]
  },
  {
    "id": 41,
    "fieldName": "Type",
    "title": "Select your drive format.",
    "options": [
      {
        "label": "Compact Format",
        "value": "Compact Format",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 42,
    "fieldName": "Protection Level",
    "title": "Select your protection level.",
    "options": [
      {
        "label": "IP 20",
        "value": "IP 20",
        "nextQuestions": [
          43
        ]
      }
    ]
  },
  {
    "id": 43,
    "fieldName": "Type",
    "title": "Select your drive format.",
    "options": [
      {
        "label": "Compact Format",
        "value": "Compact Format",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 44,
    "fieldName": "Protection Level",
    "title": "Select your protection level.",
    "options": [
      {
        "label": "IP 20",
        "value": "IP 20",
        "nextQuestions": [
          45
        ]
      }
    ]
  },
  {
    "id": 45,
    "fieldName": "Type",
    "title": "Select your drive format.",
    "options": [
      {
        "label": "Compact Format",
        "value": "Compact Format",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 46,
    "fieldName": "Protection Level",
    "title": "Select your protection level.",
    "options": [
      {
        "label": "IP 20",
        "value": "IP 20",
        "nextQuestions": [
          47
        ]
      }
    ]
  },
  {
    "id": 47,
    "fieldName": "Type",
    "title": "Select your drive format.",
    "options": [
      {
        "label": "Compact Format",
        "value": "Compact Format",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 48,
    "fieldName": "Protection Level",
    "title": "Select your protection level.",
    "options": [
      {
        "label": "IP 20",
        "value": "IP 20",
        "nextQuestions": [
          49
        ]
      }
    ]
  },
  {
    "id": 49,
    "fieldName": "Type",
    "title": "Select your drive format.",
    "options": [
      {
        "label": "Compact Format",
        "value": "Compact Format",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 50,
    "fieldName": "Protection Level",
    "title": "Select your protection level.",
    "options": [
      {
        "label": "IP 20",
        "value": "IP 20",
        "nextQuestions": [
          51
        ]
      }
    ]
  },
  {
    "id": 51,
    "fieldName": "Type",
    "title": "Select your drive format.",
    "options": [
      {
        "label": "Compact Format",
        "value": "Compact Format",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 52,
    "fieldName": "Motor Power (kW)",
    "title": "Select your motor power.",
    "options": [
      {
        "label": "0.37 kW",
        "value": "0.37 kW",
        "nextQuestions": [
          53
        ]
      },
      {
        "label": "0.55 kW",
        "value": "0.55 kW",
        "nextQuestions": [
          56
        ]
      },
      {
        "label": "0.75 kW",
        "value": "0.75 kW",
        "nextQuestions": [
          59
        ]
      },
      {
        "label": "1.1 kW",
        "value": "1.1 kW",
        "nextQuestions": [
          62
        ]
      },
      {
        "label": "1.5 kW",
        "value": "1.5 kW",
        "nextQuestions": [
          65
        ]
      },
      {
        "label": "2.2 kW",
        "value": "2.2 kW",
        "nextQuestions": [
          68
        ]
      },
      {
        "label": "3 kW",
        "value": "3 kW",
        "nextQuestions": [
          71
        ]
      },
      {
        "label": "4 kW",
        "value": "4 kW",
        "nextQuestions": [
          74
        ]
      },
      {
        "label": "5.5 kW",
        "value": "5.5 kW",
        "nextQuestions": [
          77
        ]
      },
      {
        "label": "7.5 kW",
        "value": "7.5 kW",
        "nextQuestions": [
          80
        ]
      },
      {
        "label": "11 kW",
        "value": "11 kW",
        "nextQuestions": [
          83
        ]
      },
      {
        "label": "15 kW",
        "value": "15 kW",
        "nextQuestions": [
          85
        ]
      }
    ]
  },
  {
    "id": 53,
    "fieldName": "Protection Level",
    "title": "Select your protection level.",
    "options": [
      {
        "label": "IP 20",
        "value": "IP 20",
        "nextQuestions": [
          54
        ]
      },
      {
        "label": "IP 65 / 66",
        "value": "IP 65 / 66",
        "nextQuestions": [
          55
        ]
      }
    ]
  },
  {
    "id": 54,
    "fieldName": "Type",
    "title": "Select your drive format.",
    "options": [
      {
        "label": "Book Format",
        "value": "Book Format",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "Compact Format",
        "value": "Compact Format",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 55,
    "fieldName": "Type",
    "title": "Select your drive format.",
    "options": [
      {
        "label": "Without Safety Switch Disconnector",
        "value": "Without Safety Switch Disconnector",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "With Safety Switch Disconnector",
        "value": "With Safety Switch Disconnector",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 56,
    "fieldName": "Protection Level",
    "title": "Select your protection level.",
    "options": [
      {
        "label": "IP 20",
        "value": "IP 20",
        "nextQuestions": [
          57
        ]
      },
      {
        "label": "IP 65 / 66",
        "value": "IP 65 / 66",
        "nextQuestions": [
          58
        ]
      }
    ]
  },
  {
    "id": 57,
    "fieldName": "Type",
    "title": "Select your drive format.",
    "options": [
      {
        "label": "Book Format",
        "value": "Book Format",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "Compact Format",
        "value": "Compact Format",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 58,
    "fieldName": "Type",
    "title": "Select your drive format.",
    "options": [
      {
        "label": "Without Safety Switch Disconnector",
        "value": "Without Safety Switch Disconnector",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "With Safety Switch Disconnector",
        "value": "With Safety Switch Disconnector",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 59,
    "fieldName": "Protection Level",
    "title": "Select your protection level.",
    "options": [
      {
        "label": "IP 20",
        "value": "IP 20",
        "nextQuestions": [
          60
        ]
      },
      {
        "label": "IP 65 / 66",
        "value": "IP 65 / 66",
        "nextQuestions": [
          61
        ]
      }
    ]
  },
  {
    "id": 60,
    "fieldName": "Type",
    "title": "Select your drive format.",
    "options": [
      {
        "label": "Book Format",
        "value": "Book Format",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "Compact Format",
        "value": "Compact Format",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 61,
    "fieldName": "Type",
    "title": "Select your drive format.",
    "options": [
      {
        "label": "Without Safety Switch Disconnector",
        "value": "Without Safety Switch Disconnector",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "With Safety Switch Disconnector",
        "value": "With Safety Switch Disconnector",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 62,
    "fieldName": "Protection Level",
    "title": "Select your protection level.",
    "options": [
      {
        "label": "IP 20",
        "value": "IP 20",
        "nextQuestions": [
          63
        ]
      },
      {
        "label": "IP 65 / 66",
        "value": "IP 65 / 66",
        "nextQuestions": [
          64
        ]
      }
    ]
  },
  {
    "id": 63,
    "fieldName": "Type",
    "title": "Select your drive format.",
    "options": [
      {
        "label": "Book Format",
        "value": "Book Format",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "Compact Format",
        "value": "Compact Format",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 64,
    "fieldName": "Type",
    "title": "Select your drive format.",
    "options": [
      {
        "label": "Without Safety Switch Disconnector",
        "value": "Without Safety Switch Disconnector",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "With Safety Switch Disconnector",
        "value": "With Safety Switch Disconnector",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 65,
    "fieldName": "Protection Level",
    "title": "Select your protection level.",
    "options": [
      {
        "label": "IP 20",
        "value": "IP 20",
        "nextQuestions": [
          66
        ]
      },
      {
        "label": "IP 65 / 66",
        "value": "IP 65 / 66",
        "nextQuestions": [
          67
        ]
      }
    ]
  },
  {
    "id": 66,
    "fieldName": "Type",
    "title": "Select your drive format.",
    "options": [
      {
        "label": "Book Format",
        "value": "Book Format",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "Compact Format",
        "value": "Compact Format",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 67,
    "fieldName": "Type",
    "title": "Select your drive format.",
    "options": [
      {
        "label": "Without Safety Switch Disconnector",
        "value": "Without Safety Switch Disconnector",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "With Safety Switch Disconnector",
        "value": "With Safety Switch Disconnector",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 68,
    "fieldName": "Protection Level",
    "title": "Select your protection level.",
    "options": [
      {
        "label": "IP 20",
        "value": "IP 20",
        "nextQuestions": [
          69
        ]
      },
      {
        "label": "IP 65 / 66",
        "value": "IP 65 / 66",
        "nextQuestions": [
          70
        ]
      }
    ]
  },
  {
    "id": 69,
    "fieldName": "Type",
    "title": "Select your drive format.",
    "options": [
      {
        "label": "Book Format",
        "value": "Book Format",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "Compact Format",
        "value": "Compact Format",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 70,
    "fieldName": "Type",
    "title": "Select your drive format.",
    "options": [
      {
        "label": "Without Safety Switch Disconnector",
        "value": "Without Safety Switch Disconnector",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "With Safety Switch Disconnector",
        "value": "With Safety Switch Disconnector",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 71,
    "fieldName": "Protection Level",
    "title": "Select your protection level.",
    "options": [
      {
        "label": "IP 20",
        "value": "IP 20",
        "nextQuestions": [
          72
        ]
      },
      {
        "label": "IP 65 / 66",
        "value": "IP 65 / 66",
        "nextQuestions": [
          73
        ]
      }
    ]
  },
  {
    "id": 72,
    "fieldName": "Type",
    "title": "Select your drive format.",
    "options": [
      {
        "label": "Book Format",
        "value": "Book Format",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "Compact Format",
        "value": "Compact Format",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 73,
    "fieldName": "Type",
    "title": "Select your drive format.",
    "options": [
      {
        "label": "Without Safety Switch Disconnector",
        "value": "Without Safety Switch Disconnector",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "With Safety Switch Disconnector",
        "value": "With Safety Switch Disconnector",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 74,
    "fieldName": "Protection Level",
    "title": "Select your protection level.",
    "options": [
      {
        "label": "IP 20",
        "value": "IP 20",
        "nextQuestions": [
          75
        ]
      },
      {
        "label": "IP 65 / 66",
        "value": "IP 65 / 66",
        "nextQuestions": [
          76
        ]
      }
    ]
  },
  {
    "id": 75,
    "fieldName": "Type",
    "title": "Select your drive format.",
    "options": [
      {
        "label": "Book Format",
        "value": "Book Format",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "Compact Format",
        "value": "Compact Format",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 76,
    "fieldName": "Type",
    "title": "Select your drive format.",
    "options": [
      {
        "label": "Without Safety Switch Disconnector",
        "value": "Without Safety Switch Disconnector",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "With Safety Switch Disconnector",
        "value": "With Safety Switch Disconnector",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 77,
    "fieldName": "Protection Level",
    "title": "Select your protection level.",
    "options": [
      {
        "label": "IP 20",
        "value": "IP 20",
        "nextQuestions": [
          78
        ]
      },
      {
        "label": "IP 65 / 66",
        "value": "IP 65 / 66",
        "nextQuestions": [
          79
        ]
      }
    ]
  },
  {
    "id": 78,
    "fieldName": "Type",
    "title": "Select your drive format.",
    "options": [
      {
        "label": "Book Format",
        "value": "Book Format",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "Compact Format",
        "value": "Compact Format",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 79,
    "fieldName": "Type",
    "title": "Select your drive format.",
    "options": [
      {
        "label": "Without Safety Switch Disconnector",
        "value": "Without Safety Switch Disconnector",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "With Safety Switch Disconnector",
        "value": "With Safety Switch Disconnector",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 80,
    "fieldName": "Protection Level",
    "title": "Select your protection level.",
    "options": [
      {
        "label": "IP 20",
        "value": "IP 20",
        "nextQuestions": [
          81
        ]
      },
      {
        "label": "IP 65 / 66",
        "value": "IP 65 / 66",
        "nextQuestions": [
          82
        ]
      }
    ]
  },
  {
    "id": 81,
    "fieldName": "Type",
    "title": "Select your drive format.",
    "options": [
      {
        "label": "Book Format",
        "value": "Book Format",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "Compact Format",
        "value": "Compact Format",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 82,
    "fieldName": "Type",
    "title": "Select your drive format.",
    "options": [
      {
        "label": "Without Safety Switch Disconnector",
        "value": "Without Safety Switch Disconnector",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "With Safety Switch Disconnector",
        "value": "With Safety Switch Disconnector",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 83,
    "fieldName": "Protection Level",
    "title": "Select your protection level.",
    "options": [
      {
        "label": "IP 20",
        "value": "IP 20",
        "nextQuestions": [
          84
        ]
      }
    ]
  },
  {
    "id": 84,
    "fieldName": "Type",
    "title": "Select your drive format.",
    "options": [
      {
        "label": "Book Format",
        "value": "Book Format",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "Compact Format",
        "value": "Compact Format",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 85,
    "fieldName": "Protection Level",
    "title": "Select your protection level.",
    "options": [
      {
        "label": "IP 20",
        "value": "IP 20",
        "nextQuestions": [
          86
        ]
      }
    ]
  },
  {
    "id": 86,
    "fieldName": "Type",
    "title": "Select your drive format.",
    "options": [
      {
        "label": "Book Format",
        "value": "Book Format",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "Compact Format",
        "value": "Compact Format",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 87,
    "fieldName": "Motor Power (kW)",
    "title": "Select your motor power.",
    "options": [
      {
        "label": "0.75 kW",
        "value": "0.75 kW",
        "nextQuestions": [
          88
        ]
      },
      {
        "label": "1.5 kW",
        "value": "1.5 kW",
        "nextQuestions": [
          90
        ]
      },
      {
        "label": "2.2 kW",
        "value": "2.2 kW",
        "nextQuestions": [
          92
        ]
      },
      {
        "label": "4 kW",
        "value": "4 kW",
        "nextQuestions": [
          94
        ]
      },
      {
        "label": "5.5 kW",
        "value": "5.5 kW",
        "nextQuestions": [
          96
        ]
      },
      {
        "label": "7.5 kW",
        "value": "7.5 kW",
        "nextQuestions": [
          98
        ]
      },
      {
        "label": "11 kW",
        "value": "11 kW",
        "nextQuestions": [
          100
        ]
      },
      {
        "label": "15 kW",
        "value": "15 kW",
        "nextQuestions": [
          102
        ]
      }
    ]
  },
  {
    "id": 88,
    "fieldName": "Protection Level",
    "title": "Select your protection level.",
    "options": [
      {
        "label": "IP 20",
        "value": "IP 20",
        "nextQuestions": [
          89
        ]
      }
    ]
  },
  {
    "id": 89,
    "fieldName": "Type",
    "title": "Select your drive format.",
    "options": [
      {
        "label": "Compact Format",
        "value": "Compact Format",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 90,
    "fieldName": "Protection Level",
    "title": "Select your protection level.",
    "options": [
      {
        "label": "IP 20",
        "value": "IP 20",
        "nextQuestions": [
          91
        ]
      }
    ]
  },
  {
    "id": 91,
    "fieldName": "Type",
    "title": "Select your drive format.",
    "options": [
      {
        "label": "Compact Format",
        "value": "Compact Format",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 92,
    "fieldName": "Protection Level",
    "title": "Select your protection level.",
    "options": [
      {
        "label": "IP 20",
        "value": "IP 20",
        "nextQuestions": [
          93
        ]
      }
    ]
  },
  {
    "id": 93,
    "fieldName": "Type",
    "title": "Select your drive format.",
    "options": [
      {
        "label": "Compact Format",
        "value": "Compact Format",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 94,
    "fieldName": "Protection Level",
    "title": "Select your protection level.",
    "options": [
      {
        "label": "IP 20",
        "value": "IP 20",
        "nextQuestions": [
          95
        ]
      }
    ]
  },
  {
    "id": 95,
    "fieldName": "Type",
    "title": "Select your drive format.",
    "options": [
      {
        "label": "Compact Format",
        "value": "Compact Format",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 96,
    "fieldName": "Protection Level",
    "title": "Select your protection level.",
    "options": [
      {
        "label": "IP 20",
        "value": "IP 20",
        "nextQuestions": [
          97
        ]
      }
    ]
  },
  {
    "id": 97,
    "fieldName": "Type",
    "title": "Select your drive format.",
    "options": [
      {
        "label": "Compact Format",
        "value": "Compact Format",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 98,
    "fieldName": "Protection Level",
    "title": "Select your protection level.",
    "options": [
      {
        "label": "IP 20",
        "value": "IP 20",
        "nextQuestions": [
          99
        ]
      }
    ]
  },
  {
    "id": 99,
    "fieldName": "Type",
    "title": "Select your drive format.",
    "options": [
      {
        "label": "Compact Format",
        "value": "Compact Format",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 100,
    "fieldName": "Protection Level",
    "title": "Select your protection level.",
    "options": [
      {
        "label": "IP 20",
        "value": "IP 20",
        "nextQuestions": [
          101
        ]
      }
    ]
  },
  {
    "id": 101,
    "fieldName": "Type",
    "title": "Select your drive format.",
    "options": [
      {
        "label": "Compact Format",
        "value": "Compact Format",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 102,
    "fieldName": "Protection Level",
    "title": "Select your protection level.",
    "options": [
      {
        "label": "IP 20",
        "value": "IP 20",
        "nextQuestions": [
          103
        ]
      }
    ]
  },
  {
    "id": 103,
    "fieldName": "Type",
    "title": "Select your drive format.",
    "options": [
      {
        "label": "Compact Format",
        "value": "Compact Format",
        "nextQuestions": [
          -1
        ]
      }
    ]
  }
];

export default MotorControlSolution;

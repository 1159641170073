import PropTypes from 'prop-types';

import './styles.scss';

function Point({ top, left, onHover, onClick }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      onMouseOver={() => onHover(true)}
      onMouseLeave={() => onHover(false)}
      onClick={onClick}
      style={{
        top,
        left,
        position: 'absolute',
        zIndex: 10,
        cursor: 'pointer',
      }}
    >
      <g
        id="Ellipse_7"
        data-name="Ellipse 7"
        fill="#42b4e6"
        stroke="#fff"
        strokeWidth="2"
      >
        <circle cx="10" cy="10" r="10" stroke="none" />
        <circle cx="10" cy="10" r="8" fill="none" />
        <circle cx="10" cy="10" r="8" fill="none" className="pulse" />
      </g>
    </svg>
  );
}

Point.propTypes = {
  top: PropTypes.number,
  left: PropTypes.number,
  onHover: PropTypes.func,
  onClick: PropTypes.func,
};

Point.defaultProps = {
  top: 0,
  left: 0,
  onHover: () => {},
  onClick: () => {},
};

export default Point;

import React, { useReducer, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga4';
import axios from 'axios';
import AppContext from './context';
import { appReducer, SET_DIMENSIONS, SET_PARAMS } from './reducers';

const countryMapper = {
  RUS: "RUSSIA",
  USA: "US",
  CAN: "CANADA",
  ESP: "SPAIN",
  GER: "GERMANY",
  DEU: "GERMANY",
  NL: "NL",
};

const GlobalState = ({ children }) => {
  const { i18n } = useTranslation();
  const [priceMapping, setPriceMapping] = useState([]);
  const [appState, dispatch] = useReducer(appReducer, {
    width: "100%",
    height: "100%",
    country: localStorage.getItem("country"),
  });

  const setDimensions = (width, height) => {
    dispatch({ type: SET_DIMENSIONS, width, height });
  };

  const setParams = (params) => {
    dispatch({ type: SET_PARAMS, payload: params });
  };

  const { language, country } = appState;

  useEffect(() => {
    if (!language) {
      return;
    }

    i18n.changeLanguage(language.toLocaleLowerCase());
  }, [language]);

  const getPricingUrl = (ctry) => {
    switch (ctry) {
      case countryMapper.NL:
        return "https://storage.googleapis.com/schneider_cable/SE_prices_NL.json?ignoreCache=1";
      case countryMapper.ESP:
        return "https://storage.googleapis.com/schneider_cable/SE_prices_ES.json?ignoreCache=1";
      case countryMapper.DEU:
        return "https://storage.googleapis.com/schneider_cable/SE_prices_DE.json?ignoreCache=1";
      default:
        return "";
    }
  };

  useEffect(() => {
    const loadPrices = () => {
      if (!country) {
        return;
      }
      const pricingUrl = getPricingUrl(country);
      if (!pricingUrl) {
        return;
      }
      axios
        .get(pricingUrl, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          setPriceMapping(res.data);
        });
    };

    loadPrices();
  }, [country]);

  useEffect(() => {
    const { search } = window.location;
    const searchParams = new URLSearchParams(search);
    const payload = {};

    const lang = searchParams.get("language");
    if (lang) {
      payload.language = lang.toLocaleUpperCase();
    }
    const project = searchParams.get("project");
    if (project && project !== "ANY") {
      payload.project = project;
    }
    const countryQs = searchParams.get("country");
    if (countryQs) {
      const countryVal = countryMapper[countryQs] || countryMapper.RUS;
      payload.country = countryVal;
      localStorage.setItem("country", countryVal);
      ReactGA.event({
        category: "IndustrialTracker.seTracker",
        action: "create",
        label: `country_${countryVal}` || "N/A",
      });
    }
    setParams(payload);
  }, []);

  return (
    <AppContext.Provider
      value={{
        state: appState,
        priceMapping,
        setDimensions,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

GlobalState.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default GlobalState;

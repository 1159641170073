import React, { useReducer } from 'react';
import PropTypes from 'prop-types';

import ShopContext from './context';
import {
  shopReducer,
  ADD_PRODUCT,
  REMOVE_PRODUCT,
  CLEAR_CART,
} from './reducers';

const GlobalState = ({ children }) => {
  const products = [];

  const [cartState, dispatch] = useReducer(shopReducer, { cart: [] });

  const addProductToCart = (productId, quantity, available) => {
    dispatch({ type: ADD_PRODUCT, productId, quantity, available });
  };

  const clearCart = () => {
    dispatch({ type: CLEAR_CART });
  };

  const removeProductFromCart = (productId) => {
    dispatch({ type: REMOVE_PRODUCT, productId });
  };

  return (
    <ShopContext.Provider
      value={{
        products,
        cart: cartState.cart,
        addProductToCart,
        removeProductFromCart,
        clearCart,
      }}
    >
      {children}
    </ShopContext.Provider>
  );
};

GlobalState.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default GlobalState;

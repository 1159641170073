import PropTypes from 'prop-types';

function IconUnchecked({ color }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11">
      <g id="Rectangle_67" data-name="Rectangle 67" fill="#fff" stroke={color} strokeWidth="0.9">
        <rect width="11" height="11" rx="1" stroke="none" />
        <rect x="0.45" y="0.45" width="10.1" height="10.1" rx="0.55" fill="none" />
      </g>
    </svg>
  );
}

IconUnchecked.propTypes = {
  color: PropTypes.string,
};

IconUnchecked.defaultProps = {
  color: '#000',
};

export default IconUnchecked;

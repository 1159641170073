import FillingAndCappingImageBig from "shared/assets/filling-and-capping-big.png";
import MachineContainer from "shared/components/MachineContainer";
import PartDefinitions from "./PartDefinitions";

import "./styles.scss";

const ImageBaseWidth = 1200;
const ImageBaseHeight = 785;
const MachineTypeText = "Filling_and_Capping_Machine";

const FillingAndCapping = () => (
  <MachineContainer
    MachineType={MachineTypeText}
    MachineContainerImage={FillingAndCappingImageBig}
    ImageBaseWidth={ImageBaseWidth}
    ImageBaseHeight={ImageBaseHeight}
    PartDefinitions={PartDefinitions}
  />
);

export default FillingAndCapping;

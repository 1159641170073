/* eslint-disable */

const EmergencyPushButton = [
  {
    'id': 0,
    'fieldName': 'Bazel Material',
    'title': 'Select your bezel material.',
    'options': [
      {
        'label': 'Metal',
        'value': 'Metal',
        'nextQuestions': [
          1
        ]
      },
      {
        'label': 'Plastic',
        'value': 'Plastic',
        'nextQuestions': [
          5
        ]
      }
    ]
  },
  {
    'id': 1,
    'fieldName': 'Reset Method',
    'title': 'Select your reset method.',
    'options': [
      {
        'label': 'Turn to Release',
        'value': 'Turn to Release',
        'nextQuestions': [
          2
        ]
      },
      {
        'label': 'Key Release',
        'value': 'Key Release',
        'nextQuestions': [
          3
        ]
      },
      {
        'label': 'Push Pull',
        'value': 'Push Pull',
        'nextQuestions': [
          4
        ]
      }
    ]
  },
  {
    'id': 2,
    'fieldName': 'Safety Module Connection Type',
    'title': 'Please select the safety module connection type.',
    'options': [
      {
        'label': 'Integrated Terminal Block',
        'value': 'Integrated Terminal Block',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Removable Terminal Block',
        'value': 'Removable Terminal Block',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 3,
    'fieldName': 'Safety Module Connection Type',
    'title': 'Please select the safety module connection type.',
    'options': [
      {
        'label': 'Integrated Terminal Block',
        'value': 'Integrated Terminal Block',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Removable Terminal Block',
        'value': 'Removable Terminal Block',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 4,
    'fieldName': 'Safety Module Connection Type',
    'title': 'Please select the safety module connection type.',
    'options': [
      {
        'label': 'Integrated Terminal Block',
        'value': 'Integrated Terminal Block',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Removable Terminal Block',
        'value': 'Removable Terminal Block',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 5,
    'fieldName': 'Reset Method',
    'title': 'Select your reset method.',
    'options': [
      {
        'label': 'Turn to Release',
        'value': 'Turn to Release',
        'nextQuestions': [
          6
        ]
      },
      {
        'label': 'Key Release',
        'value': 'Key Release',
        'nextQuestions': [
          7
        ]
      },
      {
        'label': 'Push Pull',
        'value': 'Push Pull',
        'nextQuestions': [
          8
        ]
      }
    ]
  },
  {
    'id': 6,
    'fieldName': 'Safety Module Connection Type',
    'title': 'Please select the safety module connection type.',
    'options': [
      {
        'label': 'Integrated Terminal Block',
        'value': 'Integrated Terminal Block',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Removable Terminal Block',
        'value': 'Removable Terminal Block',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 7,
    'fieldName': 'Safety Module Connection Type',
    'title': 'Please select the safety module connection type.',
    'options': [
      {
        'label': 'Integrated Terminal Block',
        'value': 'Integrated Terminal Block',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Removable Terminal Block',
        'value': 'Removable Terminal Block',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 8,
    'fieldName': 'Safety Module Connection Type',
    'title': 'Please select the safety module connection type.',
    'options': [
      {
        'label': 'Integrated Terminal Block',
        'value': 'Integrated Terminal Block',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Removable Terminal Block',
        'value': 'Removable Terminal Block',
        'nextQuestions': [
          -1
        ]
      }
    ]
  }
];

export default EmergencyPushButton;

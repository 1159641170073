export const ModalTypes = {
  single: "single",
  multi: "multi",
  cart: "cart",
};

export const gaActions = {
  display: "display",
  addToCartBom: "add_to_cart_bom",
  exportBom: "export_bom",
  clickMachineType: "click_machine_type",
  clickHeader: "header",
  clickFeatureType: "click_feature_type",
  clickFeatureTypeLaunch: "click_feature_modal",
  clickFeatureTypeCart: "click_feature_type_cart",
};

export const MachineTypeShorts = {
  Horizontal_Packaging_Machine: "hpm",
  Vertical_Packaging_Machine: "vpm",
  Filling_and_Capping_Machine: "fcm",
};

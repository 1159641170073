import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";
import { useHistory, useLocation } from "react-router-dom";
import FillingAndCappingImage from "shared/assets/filling-and-capping.png";
import { nextuser } from "shared/tracking/nextuser";
import HorizontalPackagingImage from "shared/assets/horizontal-packaging.png";
import VerticalPackagingImage from "shared/assets/vertical-packaging.png";
import { gaActions, MachineTypeShorts } from "shared/constants";
import "./styles.scss";

const SidebarMenu = ({ closeCb }) => {
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const { t } = useTranslation();

  return (
    <nav className="SidebarMenu">
      <button
        type="button"
        className={`item ${pathname === "/" ? "active" : ""}`}
        onClick={() => {
          closeCb();
          history.push("/filling-and-capping");
          const selectedOption = `${gaActions.clickMachineType}_${MachineTypeShorts.Filling_and_Capping_Machine}`;
          nextuser(selectedOption);
          ReactGA.event({
            category: "IndustrialTracker.sidebarMenu",
            action: "click",
            label: selectedOption,
          });
        }}
      >
        <img src={FillingAndCappingImage} alt="filling and capping machine" />
        <div className="item-text">{t("Filling and Capping Machine")}</div>
      </button>
      <button
        type="button"
        className={`item ${pathname === "/" ? "active" : ""}`}
        onClick={() => {
          closeCb();
          history.push("/horizontal-packaging");
          const selectedOption = `${gaActions.clickMachineType}_${MachineTypeShorts.Horizontal_Packaging_Machine}`;
          nextuser(selectedOption);
          ReactGA.event({
            category: "IndustrialTracker.sidebarMenu",
            action: "click",
            label: selectedOption,
          });
        }}
      >
        <img
          src={HorizontalPackagingImage}
          alt="horizontal packaging machine"
        />
        <div className="item-text">{t("Horizontal Packaging Machine")}</div>
      </button>
      <button
        type="button"
        className={`item ${pathname === "/" ? "active" : ""}`}
        onClick={() => {
          closeCb();
          history.push("/vertical-packaging");
          const selectedOption = `${gaActions.clickMachineType}_${MachineTypeShorts.Vertical_Packaging_Machine}`;
          nextuser(selectedOption);
          ReactGA.event({
            category: "IndustrialTracker.sidebarMenu",
            action: "click",
            label: selectedOption,
          });
        }}
      >
        <img src={VerticalPackagingImage} alt="vertical packaging machine" />
        <div className="item-text">{t("Vertical Packaging Machine")}</div>
      </button>
    </nav>
  );
};

SidebarMenu.propTypes = {
  closeCb: PropTypes.func.isRequired,
};

export default SidebarMenu;

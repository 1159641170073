import PropTypes from 'prop-types';

function IconDelete({ color }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="12.138" viewBox="0 0 11 12.138">
      <path id="Path_55" data-name="Path 55" d="M10.684-29.138a.279.279,0,0,1,.316.316v1.644a.463.463,0,0,1-.032.174.257.257,0,0,1-.111.126.332.332,0,0,1-.174.047H9.925v6.67a1.438,1.438,0,0,1-.458,1.075,1.479,1.479,0,0,1-1.059.443H2.592a1.463,1.463,0,0,1-1.075-.443,1.463,1.463,0,0,1-.442-1.075v-6.67H.316a.3.3,0,0,1-.221-.095A.342.342,0,0,1,0-27.178v-1.644a.279.279,0,0,1,.316-.316h2.94v-1.328q0-.316.348-.316H7.4q.348,0,.348.316v1.328Zm-6.764,0H7.08v-.948H3.92ZM.632-27.494h9.736v-.98H.632Zm8.629,7.333v-6.67H1.738v6.67a.846.846,0,0,0,.285.6.77.77,0,0,0,.569.221H8.408a.849.849,0,0,0,.6-.237A.77.77,0,0,0,9.262-20.161ZM3.6-20.288q-.348,0-.348-.316v-4.931a.332.332,0,0,1,.047-.174.256.256,0,0,1,.126-.111.463.463,0,0,1,.174-.032.3.3,0,0,1,.221.095.3.3,0,0,1,.095.221V-20.6A.279.279,0,0,1,3.6-20.288ZM5.816-20.6a.279.279,0,0,1-.316.316.279.279,0,0,1-.316-.316v-4.931a.3.3,0,0,1,.095-.221.3.3,0,0,1,.221-.095.3.3,0,0,1,.221.095.3.3,0,0,1,.095.221Zm1.58.316a.279.279,0,0,1-.316-.316v-4.931a.3.3,0,0,1,.095-.221.322.322,0,0,1,.237-.095.322.322,0,0,1,.237.095.3.3,0,0,1,.095.221V-20.6Q7.744-20.288,7.4-20.288Z" transform="translate(0 30.782)" fill={color} />
    </svg>
  );
}

IconDelete.propTypes = {
  color: PropTypes.string,
};

IconDelete.defaultProps = {
  color: '#000',
};

export default IconDelete;

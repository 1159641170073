/* eslint-disable */

const UltrasonicSensor = [
  {
    'id': 0,
    'fieldName': 'Output Function',
    'title': 'Please select the output function.',
    'options': [
      {
        'label': 'NO, NPN',
        'value': 'NO, NPN',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'NO, PNP',
        'value': 'NO, PNP',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '4-20 mA',
        'value': '4-20 mA',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '0-10 V',
        'value': '0-10 V',
        'nextQuestions': [
          -1
        ]
      }
    ]
  }
];

export default UltrasonicSensor;

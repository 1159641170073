import PropTypes from 'prop-types';
import './styles.scss';
import { useTranslation } from "react-i18next";



function ItemQuantity({
  onIncrement,
  onDecrement,
  value,
  showLabel,
}) {
const { t } = useTranslation();

  return (
    <div className="item-quantity">
      {showLabel && <div className="item-quantity-label">{t('Qty')}</div>}
      <div className="item-quantity-form">
        <button type="button" className="item-quantity-button decrement" onClick={onDecrement}>-</button>
        <div className="item-quantity-value">{value}</div>
        <button type="button" className="item-quantity-button increment" onClick={onIncrement}>+</button>
      </div>
    </div>
  );
}

ItemQuantity.defaultProps = {
  onDecrement: () => { },
  onIncrement: () => { },
  showLabel: true,
  value: 0,
};

ItemQuantity.propTypes = {
  onDecrement: PropTypes.func,
  onIncrement: PropTypes.func,
  showLabel: PropTypes.bool,
  value: PropTypes.number,
};

export default ItemQuantity;

import PropTypes from "prop-types";
import { ItemQuantity, Icon } from "shared/components";
import { IconChecked, IconUnchecked } from "shared/components/Icon/icons";
import "./styles.scss";
import { useTranslation } from "react-i18next";

const ProductDataSheet = "Product Data Sheet";

function RecommendedProduct({
  id,
  category,
  description,
  imageUrl,
  documents,
  selected,
  title,
  quantity,
  onIncreaseQuantity,
  onDecreaseQuantity,
  toggleSelection,
}) {
  const { t } = useTranslation();

  const tryGetDataSheetLink = () => {
    const docs = documents || [];
    const productDataSheet = docs.find((x) => x.type === ProductDataSheet);
    return productDataSheet?.url;
  };

  return (
    <div className={`recommended-product ${selected ? " selected" : ""}`}>
      {selected && (
        <button
          className="selection-icon"
          type="button"
          onClick={() => toggleSelection(id)}
        >
          <IconChecked color="#68c883" />
        </button>
      )}
      {!selected && (
        <button
          className="selection-icon"
          type="button"
          onClick={() => toggleSelection(id)}
        >
          <IconUnchecked color="#68c883" />
        </button>
      )}
      <div className="recommended-product-image">
        {imageUrl && <img alt={title} src={imageUrl} />}
      </div>
      <div className="recommended-product-content">
        <div className="recommended-product-category">{t(category)}</div>
        <div className="recommended-product-title">{id}</div>
        <div className="recommended-product-description">{t(description)}</div>
        <div className="recommended-product-controls">
          {tryGetDataSheetLink() && (
            <a
              href={tryGetDataSheetLink()}
              alt={id}
              className="recommended-product-datasheet"
              target="_blank"
              rel="noreferrer"
            >
              <Icon type="page" />
              {t("Datasheet")}
            </a>
          )}
          <div className="recommended-product-quantity">
            <ItemQuantity
              onDecrement={() => onDecreaseQuantity(id)}
              onIncrement={() => onIncreaseQuantity(id)}
              value={quantity}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

RecommendedProduct.propTypes = {
  category: PropTypes.string,
  id: PropTypes.string,
  description: PropTypes.string,
  imageUrl: PropTypes.string,
  title: PropTypes.string,
  quantity: PropTypes.number,
  onIncreaseQuantity: PropTypes.func,
  onDecreaseQuantity: PropTypes.func,
  selected: PropTypes.bool,
  toggleSelection: PropTypes.func,
  documents: PropTypes.array,
};

RecommendedProduct.defaultProps = {
  id: "N/A",
  category: "Category",
  documents: [],
  description: "Description Description Description",
  imageUrl: "",
  title: "Title",
  onDecreaseQuantity: () => {},
  onIncreaseQuantity: () => {},
  toggleSelection: () => {},
  quantity: 0,
  selected: false,
};

export default RecommendedProduct;

import { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useLocation, NavLink, useHistory } from "react-router-dom";
import ReactGA from "react-ga4";
import { SlidePane, Icon } from "shared/components";
import ShopContext from "cart/context";
import AppContext from "AppContainer/AppContext/context";
import SidebarMenu from "SidebarMenu";
import { nextuser } from "shared/tracking/nextuser";
import Logo from "./schneider-logo.png";
import "./styles.scss";

const IconSidebarMenu = ({ isActive }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="26"
    viewBox="0 0 34 34"
    className={isActive ? "active" : ""}
  >
    <g id="Group_282" data-name="Group 282" transform="translate(-36 -35)">
      {/* <g id="Rectangle_58" data-name="Rectangle 58" transform="translate(36 35)" fill="rgba(0,150,77,0.11)" stroke="#00964d" strokeWidth="1">
        <rect width="34" height="34" rx="3" stroke="none" />
        <rect x="0.5" y="0.5" width="33" height="33" rx="2.5" fill="none" />
      </g> */}
      <g id="menu_2_" data-name="menu (2)" transform="translate(42 44)">
        <path
          id="Path_35"
          data-name="Path 35"
          d="M21.083,124.408H.917a.871.871,0,1,1,0-1.74H21.083a.871.871,0,1,1,0,1.74Zm0,0"
          transform="translate(0 -115.594)"
          fill="#858585"
        />
        <path
          id="Path_36"
          data-name="Path 36"
          d="M21.083,1.74H.917A.9.9,0,0,1,0,.87.9.9,0,0,1,.917,0H21.083A.9.9,0,0,1,22,.87.9.9,0,0,1,21.083,1.74Zm0,0"
          fill="#858585"
        />
        <path
          id="Path_37"
          data-name="Path 37"
          d="M21.083,247.072H.917a.871.871,0,1,1,0-1.74H21.083a.871.871,0,1,1,0,1.74Zm0,0"
          transform="translate(0 -231.184)"
          fill="#858585"
        />
      </g>
    </g>
  </svg>
);

IconSidebarMenu.propTypes = {
  isActive: PropTypes.bool,
};

IconSidebarMenu.defaultProps = {
  isActive: false,
};

const Header = () => {
  const location = useLocation();
  const { cart } = useContext(ShopContext);
  const appContext = useContext(AppContext);

  const { t, i18n } = useTranslation();

  let productCount = 0;
  cart.forEach((cartItem) => {
    productCount += cartItem.quantity;
  });

  const { pathname } = location;

  const history = useHistory();

  const getMenuState = () => pathname === "/";
  const [menuOpenState, setMenuOpenState] = useState(getMenuState());

  useEffect(() => {
    setMenuOpenState(getMenuState());
  }, [pathname]);

  const getTitle = () => {
    switch (pathname) {
      case "/":
        return "Packaging Automation Selection Tool";
      case "/filling-and-capping":
        return "Filling and Capping Machine";
      case "/horizontal-packaging":
        return "Horizontal Packaging Machine";
      case "/vertical-packaging":
        return "Vertical Packaging Machine";
      case "/cart":
        return "Bill of Materials";
      default:
        return "";
    }
  };

  const isInitial = pathname === "" || pathname === "/";

  return (
    <div className="Header" id="Header">
      <div
        className="Header__Content"
        style={{ maxWidth: appContext?.state?.width }}
      >
        <div className="left">
          <button
            style={{ visibility: isInitial ? "hidden" : "visible" }}
            className="menu-btn"
            type="button"
            onClick={() => {
              if (menuOpenState) {
                nextuser(`machine_sidebar_close`);
                ReactGA.event({
                  category: "IndustrialTracker.header",
                  action: "close",
                  label: "machine_sidebar",
                });
              } else {
                nextuser(`machine_sidebar_open`);
                ReactGA.event({
                  category: "IndustrialTracker.header",
                  action: "open",
                  label: "machine_sidebar",
                });
              }
              setMenuOpenState(!menuOpenState);
            }}
          >
            <IconSidebarMenu isActive={menuOpenState} />
            <span>{t("Machine")}</span>
          </button>
          <NavLink to="/" className="page-logo">
            <img src={Logo} alt="schneider logo" />
          </NavLink>
        </div>
        <div className="center">
          <div className="page-title">{t(getTitle())}</div>
        </div>
        <div className="right">
          {false && (
            <select onChange={(e) => i18n.changeLanguage(e.target.value)}>
              <option value="en">{t("English")}</option>
              <option value="ru">{t("русский")}</option>
            </select>
          )}
          <NavLink
            style={{ visibility: isInitial ? "hidden" : "visible" }}
            replace={location.pathname === "/cart"}
            to="/cart"
            onClick={() => {
              nextuser(`display_bom`);
              ReactGA.event({
                category: "IndustrialTracker.header",
                action: "display",
                label: "bom",
              });
              setMenuOpenState(!menuOpenState);
            }}
            className={`cart-navmenu ${pathname === "/cart" ? "active" : ""}`}
          >
            <Icon type="page" />
            <span className="cart-text">{t("header.cart.title")}</span>
            <span className="cart-count">{productCount}</span>
          </NavLink>
          <NavLink
            to=""
            onClick={() => {
              nextuser(`close_bom`);
              ReactGA.event({
                category: "IndustrialTracker.header",
                action: "close",
                label: "bom",
              });
              setMenuOpenState(!menuOpenState);
              history.goBack();
            }}
            className=""
          >
            <svg
              style={{
                visibility: pathname === "/cart" ? "visible" : "hidden",
              }}
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path
                id="close_1_"
                data-name="close (1)"
                d="M14.2,12.136,23.544,2.79a1.555,1.555,0,0,0-2.2-2.2L12,9.937,2.655.592a1.555,1.555,0,1,0-2.2,2.2L9.8,12.136.456,21.481a1.555,1.555,0,1,0,2.2,2.2L12,14.334l9.345,9.345a1.555,1.555,0,0,0,2.2-2.2Zm0,0"
                transform="translate(0 -0.136)"
                fill="#393e3c"
              />
            </svg>
          </NavLink>
        </div>
      </div>
      <SlidePane
        closeCb={() => {
          nextuser(`machine_sidebar_close`);
          ReactGA.event({
            category: "IndustrialTracker.header",
            action: "close",
            label: "machine_sidebar",
          });
          setMenuOpenState(!menuOpenState);
        }}
        hideClose
        disabled={false}
        isOpen={menuOpenState}
        size="sidebar"
        from="left"
        showCloseIcon={!isInitial}
        shouldCloseOnEsc={false}
        hideArrowClassname="hide-arrow-container"
      >
        <SidebarMenu closeCb={() => setMenuOpenState(false)} />
      </SlidePane>
    </div>
  );
};

export default Header;

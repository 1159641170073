import './styles.scss';

import FillingAndCappingImageBig from 'shared/assets/filling-and-capping-big.png';
import { useTranslation } from 'react-i18next';

function InitialView() {
  const [t] = useTranslation();
   return (<div className="InitialView">
    <img src={FillingAndCappingImageBig} alt="Base background" />
    <div className="text">
      <svg xmlns="http://www.w3.org/2000/svg" width="55.525" height="37.248" viewBox="0 0 55.525 37.248">
        <g id="Group_320" data-name="Group 320" transform="translate(149 -205.875)">
          <path id="Path_57" data-name="Path 57" d="M18.479,17.123a4.684,4.684,0,0,1-3.2-1.309L3.346,4.9A7.971,7.971,0,0,1,0-1.355,8.524,8.524,0,0,1,3.492-8.048L15.278-18.961a5.009,5.009,0,0,1,3.2-1.164,5.533,5.533,0,0,1,4.074,2.037l.291.291a5.6,5.6,0,0,1,1.164,3.856,4.648,4.648,0,0,1-1.746,3.419l-2.91,2.91H49.906a5.043,5.043,0,0,1,1.455.218,4.152,4.152,0,0,1,1.309.655q.582.437,1.091.873a4.411,4.411,0,0,1,.873,1.019,5.361,5.361,0,0,1,.582,1.309,5.043,5.043,0,0,1,.218,1.455,5.351,5.351,0,0,1-.364,3.128,6.339,6.339,0,0,1-1.964,2.546,5.625,5.625,0,0,1-3.056,1.164H25.026a1.529,1.529,0,0,1-1.091-.509,1.529,1.529,0,0,1-.509-1.091,1.309,1.309,0,0,1,.509-1.019A1.649,1.649,0,0,1,25.026,1.7h24.88A3.552,3.552,0,0,0,51.871.391a2.856,2.856,0,0,0,.655-2.183,2.926,2.926,0,0,0-2.91-2.765H15.859A1.347,1.347,0,0,1,14.4-5.575a1.276,1.276,0,0,1,.291-1.6l5.384-5.674q1.6-1.455.437-2.91a3.113,3.113,0,0,0-2.037-1.309l-.873.436L5.384-5.72A6.156,6.156,0,0,0,3.056-1.355,5.5,5.5,0,0,0,5.384,2.573L17.46,13.631a3.232,3.232,0,0,0,1.019.436,2.4,2.4,0,0,0,1.746-1.019,2.063,2.063,0,0,0,.145-3.2L20.079,9.7,14.7,4.319a1.455,1.455,0,0,1,0-2.328,1.585,1.585,0,0,1,2.328,0L22.261,7.52l.291.291a4.935,4.935,0,0,1,1.455,3.637,4.935,4.935,0,0,1-1.455,3.637A5.234,5.234,0,0,1,18.479,17.123Z" transform="translate(-149 226)" fill="#fff" />
        </g>
      </svg>

      <span>{t('Select a Machine Type')}</span>
    </div>
  </div>
  );
}

export default InitialView;

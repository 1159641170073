function Checkmark() {
  return (
    <svg
      width="25px"
      height="25px"
      viewBox="0 0 11 11"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlSpace="preserve"
      style={{
        "fill-rule": "evenodd",
        "clip-rule": "evenodd",
        "stroke-linejoin": "round",
        "stroke-miterlimit": "2",
      }}
    >
      <g transform="matrix(1,0,0,1,-349.866,-14.1712)">
        <g transform="matrix(1,0,0,1,-219,-134)">
          <g transform="matrix(18.7733,0,0,18.7733,564.979,160.711)">
            <path
              d="M0.771,-0.082L0.23,-0.082C0.224,-0.082 0.218,-0.084 0.214,-0.089C0.209,-0.093 0.207,-0.099 0.207,-0.105L0.207,-0.645C0.207,-0.651 0.209,-0.657 0.214,-0.661C0.218,-0.666 0.224,-0.668 0.23,-0.668L0.547,-0.668C0.552,-0.668 0.557,-0.666 0.561,-0.661C0.564,-0.657 0.566,-0.651 0.566,-0.645C0.566,-0.639 0.564,-0.635 0.561,-0.631C0.557,-0.627 0.552,-0.625 0.547,-0.625L0.25,-0.625L0.25,-0.125L0.75,-0.125L0.75,-0.605C0.589,-0.352 0.497,-0.225 0.477,-0.225C0.467,-0.225 0.46,-0.228 0.453,-0.235C0.447,-0.243 0.441,-0.25 0.438,-0.258C0.434,-0.266 0.428,-0.279 0.422,-0.297C0.401,-0.349 0.382,-0.38 0.363,-0.391C0.353,-0.397 0.35,-0.406 0.355,-0.418C0.363,-0.43 0.374,-0.432 0.387,-0.426C0.41,-0.411 0.435,-0.374 0.461,-0.313C0.467,-0.292 0.473,-0.279 0.477,-0.273C0.499,-0.302 0.535,-0.353 0.586,-0.429C0.626,-0.489 0.676,-0.565 0.734,-0.656C0.738,-0.664 0.744,-0.668 0.752,-0.668L0.771,-0.668C0.786,-0.668 0.793,-0.66 0.793,-0.645L0.793,-0.105C0.793,-0.09 0.786,-0.082 0.771,-0.082Z"
              style={{ fill: "rgb(24,203,99)", "fill-rule": "nonzero" }}
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Checkmark;

import PropTypes from "prop-types";
import { useEffect, useState, useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import { gaActions, MachineTypeShorts } from "shared/constants";
import axios from "axios";
import ReactGA from "react-ga4";
import { toast } from "react-toastify";
import PulseLoader from "react-spinners/PulseLoader";
import ShopContext from "cart/context";
import AppContext from "AppContainer/AppContext/context";
import { Button, SlidePane, Icon } from "shared/components";
import { nextuser } from "shared/tracking/nextuser";
import { RecommendedProduct, QuizQuestion } from "./components";
import CustomToast from "./components/CustomToast";

import PartQuizDefitions from "./data/PartQuizDefitions";

import "./styles.scss";

const getQuestion = (key, questionId) => {
  const question = PartQuizDefitions[key].find((pqd) => pqd.id === questionId);
  return question;
};

const getInitialQuestions = (key) =>
  PartQuizDefitions[key]
    .filter((question) => question.isInitial)
    .map((question) => question.id);

const getQuestionProperyValuesWithinGroup = (
  key,
  groupKey,
  propertyKey = "id"
) => {
  const questions = PartQuizDefitions[key] || [];
  return questions
    .filter((question) => !!groupKey && question.group === groupKey)
    .map((question) => question[propertyKey]);
};

const mapToPayload = (results) => {
  const mappedResults = {};
  Object.keys(results).forEach((k) => {
    mappedResults[k] = results[k].value;
  });

  return mappedResults;
};

const isGroupAnswered = (key, groupKey, results = {}) => {
  if (!groupKey) {
    return true;
  }

  const fieldNames = getQuestionProperyValuesWithinGroup(
    key,
    groupKey,
    "fieldName"
  );

  for (let i = 0; i < fieldNames.length; i++) {
    if (!mapToPayload(results)[fieldNames[i]]) {
      return false;
    }
  }

  return true;
};

const mapLenientToPayload = (lenientFilters) => {
  let filtered = [];
  if (lenientFilters.length === 0) {
    return lenientFilters;
  }

  lenientFilters.forEach((selection) => {
    const newSelection = {};
    Object.keys(selection).forEach((key) => {
      newSelection[key] = selection[key].value;
    });
    filtered = [...filtered, newSelection];
  });
  return filtered;
};

function QuizContainer({
  MachineType,
  isOpen,
  onClose,
  selectedPart,
  showLinkedModal,
  mapping = {},
}) {
  const context = useContext(ShopContext);
  const appContext = useContext(AppContext);
  const [t] = useTranslation();

  const [strictFilters, setStrictFilters] = useState({});
  const [lenientFilters, setLenientFilters] = useState([]);
  const [recommendedProducts, setRecommendedProducts] = useState([]);
  const [activeQuestions, setActiveQuestions] = useState([]);

  const [selectedProducts, setSelectedProducts] = useState([]);
  const [containerCart, setContainerCart] = useState([]);
  const [loopIndex, setLoopIndex] = useState(0);

  const [isFetchingResult, setFetchingResults] = useState(false);

  const quizRef = useRef();
  const quizResultsRef = useRef();

  const getRecommendedProducts = (fileName) => {
    setFetchingResults(true);

    axios
      .post("https://industrial.sesmartconfig.com/filter", {
        cityCode: appContext.state.project,
        country: appContext.state.country,
        language: appContext.state.language,
        sourceFile: fileName,
        strictFilters: mapToPayload(strictFilters),
        lenientFilters: mapLenientToPayload(lenientFilters),
      })
      .then((x) => new Promise((resolve) => setTimeout(() => resolve(x), 1000)))
      .then((res) => {
        const { results } = res.data;
        const finalProducts =
          results[0]?.items[0]?.references
            .filter((x) => x !== null)
            .filter((x) => x.inScope) || [];
        setFetchingResults(false);
        setRecommendedProducts(finalProducts);
        setSelectedProducts(finalProducts.map((fp) => fp.id));
        const cartItems = finalProducts.map((fp) => ({
          id: fp.id,
          quantity: 1,
          available: fp.available,
        }));
        setContainerCart([...cartItems]);

        quizResultsRef.current.scrollIntoView();
      })
      .catch(() => {
        setFetchingResults(false);
      });
  };

  const increaseProductCount = (id) => {
    const index = containerCart.findIndex((item) => item.id === id);
    if (index < 0) {
      containerCart.push({
        id,
        quantity: 1,
      });
    } else {
      containerCart[index].quantity++;
    }
    setContainerCart([...containerCart]);
  };

  const getFeatureType = (selPartLabel) =>
    selPartLabel.toLowerCase().replace(/ /g, "_") || "";

  const addSelectedItemsToCart = (selectedPartLabel) => {
    const featureType = getFeatureType(selectedPartLabel);
    const selectedOptionsString = `${gaActions.clickFeatureTypeCart}_${MachineTypeShorts[MachineType]}_${featureType}`;
    nextuser(`add_to_cart_${selectedOptionsString}`);

    ReactGA.event({
      category: "IndustrialTracker.quizContainer",
      action: "add_to_cart",
      label: selectedOptionsString,
    });

    containerCart
      .filter((cartItem) => selectedProducts.indexOf(cartItem.id) >= 0)
      .forEach((cartItem) => {
        context.addProductToCart(
          cartItem.id,
          cartItem.quantity,
          cartItem.available
        );
      });

    toast.success(<CustomToast />, {
      position: toast.POSITION.BOTTOM_LEFT,
    });

    setSelectedProducts([]);
    const cartItems = recommendedProducts.map((fp) => ({
      id: fp.id,
      quantity: 1,
    }));
    setContainerCart([...cartItems]);

    if (selectedPart?.linkedQuiz) {
      showLinkedModal(selectedPart.linkedQuiz.key);
    }
  };

  const decreaseProductCount = (id) => {
    const index = containerCart.findIndex((cartItem) => cartItem.id === id);
    if (index < 0) {
      return;
    }
    if (containerCart[index].quantity === 1) {
      containerCart.splice(index, 1);
    } else {
      containerCart[index].quantity--;
    }
    setContainerCart([...containerCart]);
  };

  const handleSelectionToggle = (id) => {
    const index = selectedProducts.indexOf(id);
    if (index >= 0) {
      selectedProducts.splice(index, 1);
    } else {
      selectedProducts.push(id);
    }
    setSelectedProducts([...selectedProducts]);
  };

  const onQuestionAnswered = ({ id: questionId, ts }, value) => {
    const {
      options,
      nextQuestions,
      fieldName,
      group,
      isLoopStart,
      isLoopEnd,
      isLoopFinished,
      loopStartId,
    } = getQuestion(selectedPart.key, questionId);
    let activeLoopIndex = loopIndex;

    let filteredActiveQuestions = activeQuestions;

    if (isLoopStart) {
      activeLoopIndex = loopIndex + 1;
      setLoopIndex(activeLoopIndex);
    }

    const questionIndex = filteredActiveQuestions.findIndex(
      (aq) => aq.id === questionId && aq.ts === ts
    );

    if (questionIndex >= 0) {
      filteredActiveQuestions = filteredActiveQuestions.slice(
        0,
        questionIndex + 1
      );
      const filtered = {};
      Object.keys(strictFilters).forEach((key) => {
        if (strictFilters[key].ts <= ts) {
          filtered[key] = strictFilters[key];
        } else {
          delete strictFilters[key];
        }
      });

      setStrictFilters({ ...filtered });

      const filteredLenient = [];
      for (let i = 0; i < lenientFilters.length; i++) {
        const lenientFilter = lenientFilters[i];
        Object.keys(lenientFilter).forEach((key) => {
          if (lenientFilter[key].ts > ts) {
            delete lenientFilter[key];
          }
        });
        if (Object.keys(lenientFilter).length > 0) {
          filteredLenient.push(lenientFilter);
        }
      }
      setLenientFilters([...filteredLenient]);
    }

    const shouldLoopExit =
      isLoopEnd && isLoopFinished({ strictFilters, lenientFilters, loopIndex });

    if (shouldLoopExit) {
      setLoopIndex(0);
    }

    if (activeLoopIndex !== 0) {
      const currentDepthIndex = activeLoopIndex - 1;
      lenientFilters[currentDepthIndex] =
        lenientFilters[currentDepthIndex] || {};

      lenientFilters[currentDepthIndex][fieldName] = { value, ts };

      setLenientFilters([...lenientFilters]);
    } else {
      strictFilters[fieldName] = { value, ts };
      setStrictFilters(strictFilters);
    }

    // is lenient
    // depth levle?!?!?
    // is lenient entry?
    if (!isGroupAnswered(selectedPart.key, group, strictFilters)) {
      return;
    }

    let newQuestions = nextQuestions || [];
    const answeredOption = options.find((o) => o.value === value);
    if (answeredOption && answeredOption.nextQuestions) {
      newQuestions = answeredOption.nextQuestions;
    }
    const newQuestionObjects = [];
    if (activeLoopIndex > 0 && isLoopEnd && !shouldLoopExit) {
      // extend with group fetching
      newQuestions = [loopStartId];
    }

    newQuestions.forEach((nq) => {
      const questionDefition = getQuestion(selectedPart.key, nq);
      if (nq !== -1) {
        newQuestionObjects.push({ ...questionDefition, ts: Date.now() });
      }
    });

    if (newQuestionObjects.length === 0) {
      getRecommendedProducts(selectedPart.fileName || selectedPart.label);
    } else {
      setSelectedProducts([]);
      setRecommendedProducts([]);
      setContainerCart([]);

      const resultQuestions = [
        ...filteredActiveQuestions,
        ...newQuestionObjects,
      ];
      setActiveQuestions([...resultQuestions]);
    }
  };

  useEffect(() => {
    if (activeQuestions.length > 0) {
      quizRef?.current
        .querySelector(".quiz-question:last-child")
        ?.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
    }
  }, [JSON.stringify(activeQuestions)]);

  const selectAllProducts = () => {
    const products = [];
    if (selectedProducts.length === 0) {
      recommendedProducts.forEach((rp) => {
        products.push(rp.id);
      });
    }

    setSelectedProducts(products);
  };

  useEffect(() => {
    const initialQuestionsDefined = getInitialQuestions(selectedPart.key);
    let initialQuestions = [0];
    if (initialQuestionsDefined.length > 0) {
      initialQuestions = initialQuestionsDefined;
    }

    // setCurrentQuestions(initialQuestions);
    setActiveQuestions([]);
    setRecommendedProducts([]);
    setStrictFilters({});
    setLenientFilters([]);

    const newQuestions = initialQuestions.map((questionId) => ({
      ...getQuestion(selectedPart.key, questionId),
      ts: Date.now(),
    }));

    setActiveQuestions([...newQuestions]);

    return null;
  }, [selectedPart]);

  const getValidOptions = (mappings = {}, part, aq) => {
    const key = part.fileName || part.label;

    if (!mappings || !mappings[key] || !mappings[key][aq.fieldName]) {
      return aq.options;
    }
    const validOptions = mappings[key][aq.fieldName] || [];
    if (!validOptions || !validOptions.length) {
      return aq.options;
    }

    return aq.options.filter((o) => validOptions.indexOf(o.value) >= 0);
  };

  return (
    <SlidePane
      size="lg"
      isOpen={isOpen}
      closeCb={onClose}
      title={t(selectedPart?.modal.title)}
      subtitle={t(selectedPart?.modal.description)}
      className="QuizContainer"
    >
      <div className="quiz-container">
        <div ref={quizRef} className="quiz-container-questions">
          {activeQuestions.map((aq, qIndex) => (
            <QuizQuestion
              index={qIndex}
              {...aq}
              validOptions={getValidOptions(mapping, selectedPart, aq)}
              onQuestionAnswered={onQuestionAnswered}
              key={`question-id-${aq.id}-${aq.ts}`}
            />
          ))}
        </div>
        {!isFetchingResult &&
          (!recommendedProducts || recommendedProducts.length === 0) && (
            <div className="quiz-container-incomplete">
              <div>
                {t(
                  "Your configuration is not yet complete. Please select your options on the left."
                )}
              </div>
            </div>
          )}

        {isFetchingResult && (
          <div className="loading-container">
            <PulseLoader color="#3ab85d" loading={isFetchingResult} />
          </div>
        )}
        {!isFetchingResult &&
          !!recommendedProducts &&
          recommendedProducts.length > 0 && (
            <div ref={quizResultsRef} className="quiz-container-results">
              <div className="recommended-product-container">
                <div>
                  <div className="recommended-product-header">
                    <div className="recommended-product-title">
                      {t("Recommended solutions")}
                    </div>
                    <div className="recommended-product-controls">
                      <button onClick={selectAllProducts} type="button">
                        <Icon type="checked" />
                        {t("Select all")}
                      </button>
                    </div>
                  </div>
                  <div className="recommended-product-items">
                    {recommendedProducts.map((item) => {
                      const quantity =
                        containerCart.find((x) => x.id === item.id)?.quantity ||
                        0;
                      return (
                        <RecommendedProduct
                          key={`product-${item.id}`}
                          selected={
                            selectedProducts.findIndex(
                              (id) => id === item.id
                            ) >= 0
                          }
                          id={item.id}
                          title={item.id}
                          category={item.category}
                          imageUrl={item.imageUrl}
                          description={item.family}
                          onIncreaseQuantity={increaseProductCount}
                          onDecreaseQuantity={decreaseProductCount}
                          quantity={quantity}
                          documents={item.documents}
                          toggleSelection={handleSelectionToggle}
                        />
                      );
                    })}
                  </div>
                </div>
                <div className="recommended-product-footer">
                  <Button
                    type="button"
                    onClick={() => addSelectedItemsToCart(selectedPart.label)}
                    disabled={selectedProducts.length === 0}
                  >
                    {t("recommendedProducts.addToCart")}
                  </Button>
                </div>
              </div>
            </div>
          )}
      </div>
    </SlidePane>
  );
}

QuizContainer.propTypes = {
  MachineType: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  selectedPart: PropTypes.object,
  showLinkedModal: PropTypes.func,
  mapping: PropTypes.func,
};

QuizContainer.defaultProps = {
  MachineType: "Machine_type",
  isOpen: "",
  onClose: () => {},
  selectedPart: null,
  showLinkedModal: () => {},
  mapping: {},
};

export default QuizContainer;

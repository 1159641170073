import { ModalTypes } from "shared/constants";

const PartDefitions = [
  {
    key: "automationControlModuleIO",
    label: "Automation Control IO Module",
    hidden: true,
    fileName: "Automation Solution",
    modal: {
      title: "Automation Control IO MOdule",
      description:
        "As pioneers in smart automation solutions, we make it easier for you to offer your customers safer, better connected, more flexible, and more efficient machines, through advanced digital technologies and open standards.",
      type: ModalTypes.multi,
      content: [
        {
          title: "Logic/Motion Controller",
          description:
            "The Modicon M221, M241 & M262 offer a full range of innovative solutions to meet your automation needs. From small automated machines to advanced machine automation, our robust range of trusted automation solutions improve machines and processes across manufacturing industries.",
          imageUrl:
            "https://nu-platform-prod-2.storage.googleapis.com/gallery/2084b09cfe8d490db53cd1d3f6d2dc71.jpeg",
        },
        {
          title: "I/O Module",
          description:
            "Boost the performance of your controller with the Modicon TM3 I/O system specially designed for the Modicon M221, M241, M251 and M262 logic controllers!",
          imageUrl:
            "https://nu-platform-prod-2.storage.googleapis.com/gallery/d6823f31cdb9473286856b0262328a3b.jpeg",
        },
        {
          title: "Power Supply",
          description:
            "The Modicon ABLS Optimized are regulated power supplies designed to supply control circuits in industrial applications from 50W up to 480W.",
          imageUrl:
            "https://nu-platform-prod-2.storage.googleapis.com/gallery/06d86501c4444d32befcf7f1205eed70.jpeg",
        },
      ],
    },
  },
  {
    key: "operatorInterface",
    label: "Operator Interface",
    menuItems: [
      {
        key: "humanMachineInterface",
        label: "Human Machine Interface",
        fileName: "HMI",
        modal: {
          title: "Human Machine Interface",
          description:
            "Our HMI panels are ideal for performing diagnostics, precision control, and being able to easily adjust system settings on simple or compact applications. Additionally, our HMI panels utilize powerful software to reduce your implementation time, as well as offering a compact size to fit into tight spaces.",
          type: ModalTypes.single,
          content: [
            {
              title: "Human Machine Interface",
              description:
                "Magelis ST6 & GTU are easy to install, set up, and operate – providing users with a simple and effective means of connecting systems, collecting data, and presenting information in a meaningful format.",
              imageUrl:
                "https://nu-platform-prod-2.storage.googleapis.com/gallery/eafb915357684a2a88a9756b46072e4e.jpeg",
            },
          ],
        },
      },
      {
        key: "pushButton",
        label: "Push Button",
        modal: {
          title: "Push Button",
          description:
            "As the global leader in push buttons, switches, and pilot lights, our products are the benchmark for operator efficiency and effectiveness – ensuring a robust, safe, ergonomic, and simple way to control machines and manufacturing lines.",
          type: ModalTypes.single,
          content: [
            {
              title: "Push Button",
              description:
                "The Harmony XB4 & XB5 Ø 22mm metal and plastic control and signaling units combine simplicity of installation, modern standard and flush mounted design, flexibility, and robustness with a high level of customization to meet most industrial applications.",
              imageUrl:
                "https://nu-platform-prod-2.storage.googleapis.com/gallery/ffc2828df5384d5a98a0f0b10dbce9bc.jpeg",
            },
          ],
        },
      },
      {
        key: "towerLight",
        label: "Tower Light",
        modal: {
          title: "Tower Light",
          description:
            "Designed to alert operators to faults, our signaling devices help users rectify problems quickly. Our signaling devices offer a clean and innovative design that enhances a machine's appearance, and are suitable for a range of different application.",
          type: ModalTypes.single,
          content: [
            {
              title: "Tower Light",
              description:
                "The Harmony XVU Ø 60 mm tower lights and sounders offer multiple lighting patterns, mounting flexibility, modern aesthetics, unrivaled brightness, and clarity of machine status at 360°.",
              imageUrl:
                "https://nu-platform-prod-2.storage.googleapis.com/gallery/d385498ad1ef4d39b78311193331b063.jpeg",
            },
          ],
        },
      },
    ],
    position: {
      top: 65,
      left: 150,
    },
    shapes: [
    ],
  },
  {
    key: "motorControlSolution",
    label: "Motor Control Solution",
    modal: {
      title: "Motor Control Solution",
      description:
        "Gain a competitive advantage with a faster time-to-market using our efficient motor control solutions that include compact machine design and less assembly, installation, and commissioning.",
      type: ModalTypes.multi,
      content: [
        {
          title: "Variable Speed Drives",
          imageUrl:
            "https://nu-platform-prod-2.storage.googleapis.com/gallery/e4e2aaa252f74dbea101194cff9392a6.jpeg",
          description:
            "Altivar Machine ATV320, a variable speed drive designed for Original Equipment Manufacturers (OEMs) that meets simple and advanced application requirements for 3-phase synchronous and asynchronous motors.",
        },
        {
          title: "Industrial Contactors",
          imageUrl:
            "https://nu-platform-prod-2.storage.googleapis.com/gallery/6aae892469744e1399c962a89c166772.png",
          description:
            "TeSys Deca contactors and reversing contactors are available from 9 A to 150 A (inductive motor), and from 20 A to 200 A (resistive loads) in 3-pole and 4-pole versions.",
        },
        {
          title: "Motor Protection Circuit-Breakers",
          imageUrl:
            "https://nu-platform-prod-2.storage.googleapis.com/gallery/83347eda680e406dbca43af71a7193ee.png",
          description:
            "TeSys Deca GV2 & GV3 motor circuit breakers provide isolation, motor control, and thermal overcurrent protection in 45mm and 55mm standard width units.",
        },
      ],
    },
    position: {
      top: 440,
      left: 720,
    },
    shapes: [
    ],
  },
  {
    key: "motionControlSolution",
    label: "Motion Control Solution",
    position: {
      top: 525,
      left: 580,
    },
    shapes: [
    ],

    modal: {
      title: "Motion Control Solution",
      description:
        "Our complete range of motion control products and solutions suitable for even the most specialized applications.",
      type: ModalTypes.multi,
      content: [
        {
          title: "Servo Motors & Drives",
          description:
            "The Lexium 28 & 32 servo drive and motor portfolio includes a wide variety of ranges, with a power range of up to 24kW for independent or synchronized motion control. Lexium products can adapt to demand for high performance, power and simplicity of use in motion control applications.",
          imageUrl:
            "https://nu-platform-prod-2.storage.googleapis.com/gallery/2ddf00d6aa254761b74f7c77a772a588.jpeg",
        },
        {
          title: "Industrial Contactors",
          description:
            "TeSys Deca contactors and reversing contactors are available from 9A to 150A (inductive motor), and from 20A to 200A (resistive loads) in 3-pole and 4-pole versions.",
          imageUrl:
            "https://nu-platform-prod-2.storage.googleapis.com/gallery/6aae892469744e1399c962a89c166772.png",
        },
        {
          title: "Motor Protection Circuit-Breakers",
          description:
            "TeSys Deca GV2 & GV3 motor circuit breaker provides isolation, motor control and thermal overcurrent protection in 45mm and 55 mm standard width unit respectively.",
          imageUrl:
            "https://nu-platform-prod-2.storage.googleapis.com/gallery/83347eda680e406dbca43af71a7193ee.png",
        },
      ],
    },
  },
  {
    key: "controlCabinet",
    label: "Control Cabinet",
    position: {
      top: 135,
      left: 910,
    },
    shapes: [
    ],
    menuItems: [
      {
        key: "automationControl",
        label: "Automation Control",
        fileName: "Automation Solution",
        linkedQuiz: {
          key: "automationControlModuleIO",
          title: "Do you want to add Automation Control IO items?",
        },
        modal: {
          title: "Automation Control",
          description:
            "As pioneers in smart automation solutions, we make it easier for you to offer your customers safer, better connected, more flexible, and more efficient machines, through advanced digital technologies and open standards.",
          type: ModalTypes.multi,
          content: [
            {
              title: "Logic/Motion Controller",
              description:
                "The Modicon M221, M241 & M262 offer a full range of innovative solutions to meet your automation needs. From small automated machines to advanced machine automation, our robust range of trusted automation solutions improve machines and processes across manufacturing industries.",
              imageUrl:
                "https://nu-platform-prod-2.storage.googleapis.com/gallery/2084b09cfe8d490db53cd1d3f6d2dc71.jpeg",
            },
            {
              title: "I/O Module",
              description:
                "Boost the performance of your controller with the Modicon TM3 I/O system specially designed for the Modicon M221, M241, M251 and M262 logic controllers!",
              imageUrl:
                "https://nu-platform-prod-2.storage.googleapis.com/gallery/d6823f31cdb9473286856b0262328a3b.jpeg",
            },
            {
              title: "Power Supply",
              description:
                "The Modicon ABLS Optimized are regulated power supplies designed to supply control circuits in industrial applications from 50W up to 480W.",
              imageUrl:
                "https://nu-platform-prod-2.storage.googleapis.com/gallery/06d86501c4444d32befcf7f1205eed70.jpeg",
            },
          ],
        },
      },
      {
        key: "uninterruptiblePowerSupply",
        label: "Uninterruptible Power Supply",
        modal: {
          title: "Uninterruptible Power Supply",
          description:
            "Fully integrated uninterruptible power supply solutions can provide your business with the highest levels of availability, reduce your total cost of ownership (TCO), and improved energy efficiency.",
          type: ModalTypes.single,
          content: [
            {
              title: "Uninterruptible Power Supply",
              description:
                "The Easy UPS 1 Ph On-Line is a versatile, high quality, cost competitive UPS developed to handle a wide voltage range and inconsistent power conditions.",
              imageUrl:
                "https://nu-platform-prod-2.storage.googleapis.com/gallery/6bbbb6543bfa47b68d3e093bd588822f.jpeg",
            },
          ],
        },
      },
      {
        key: "enclosure",
        label: "Enclosure",
        modal: {
          title: "Enclosure",
          description:
            "Protect the reliability and efficiency of your processes with our complete range of high-quality standard and custom enclosures for industrial automation applications.",
          type: ModalTypes.single,
          content: [
            {
              title: "Enclosure",
              description:
                "The range of Spacial CRN enclosures from Schneider Electric have a steel monobloc construction with a punched steel door. The sides are made from a single folded section and the back is welded with a double profile to form a protected sealed area. These enclosures are painted externally and internally with a textured light grey RAL 7035 epoxy-polyester resin.",
              imageUrl:
                "https://nu-platform-prod-2.storage.googleapis.com/gallery/acee9539f7884062ad93014d988a389d.jpeg",
            },
          ],
        },
      },
      {
        key: "moldedCaseCircuitBreaker",
        label: "Mold Case Circuit Breaker",
        fileName: "Molded Case Circuit Breaker",
        modal: {
          title: "Mold Case Circuit Breaker",
          description:
            "The foundation of safe power distributions and the main protection from electrically-caused fires. Protect each line carrying energy or supplying appliances by choosing the correct sized circuit breaker, that will automatically disconnect in the event of a short circuit, or a cable overload.",
          type: ModalTypes.single,
          content: [
            {
              title: "Mold Case Circuit Breaker",
              description:
                "ComPact NSXm is the smallest size molded case circuit breaker (MCCB) in the ComPact NSX range. With its convenient design, it can easily accommodate applications from 16A to 160A. Available with integrated earth leakage protection.",
              imageUrl:
                "https://nu-platform-prod-2.storage.googleapis.com/gallery/3508a624efda44068429246984a79448.jpeg",
            },
          ],
        },
      },
      {
        key: "miniatureCircuitBreaker",
        label: "Miniature Circuit Breaker",
        fileName: "Miniature Circuit Breaker",
        modal: {
          title: "Miniature Circuit Breaker",
          description:
            "Our multi-standard miniature circuit breakers are designed to protect your lines against short circuits or overloads, and are built to global OEM standards.",
          type: ModalTypes.single,
          content: [
            {
              title: "Miniature Circuit Breaker",
              description:
                "Multi9 has been designed to offer enhanced protection by preventing and protecting people and equipment from electrical threats such as short circuits, earth leakages, overloads and more.",
              imageUrl:
                "https://nu-platform-prod-2.storage.googleapis.com/gallery/7d86beaeb74a40829d182fabda1b5df5.jpeg",
            },
          ],
        },
      },
      {
        key: "timingAndMonitoringRelay",
        label: "Timing & Monitoring Relay",
        modal: {
          title: "Timing & Monitoring Relay",
          description:
            "Enhance operational efficiency and optimize equipment availability with our widest range of relays.",
          type: ModalTypes.cart,
          content: [
            {
              title: "Speed Control Relay",
              id: "RM35S0MW",
              description:
                "Our dedicated RM35S0MW Speed Control Relay is used to monitor the underspeed and overspeed of conveyor belts.",
              imageUrl:
                "https://nu-platform-prod-2.storage.googleapis.com/gallery/e54deed7519944839b3762ea71038aea.jpeg",
            },
            {
              id: "RMNF22TB30",
              title: "NFC Control Relay",
              description:
                "Our Zelio NFC Control Relay monitors phase loss and phase sequence – as well as asymmetry – to prevent the directional change of a conveyor belt, or any jam. An alarm triggers in the event of an overvoltage or undervoltage event.",
              imageUrl:
                "https://nu-platform-prod-2.storage.googleapis.com/gallery/9b627d894dcb438ba648badb119ab23c.jpeg",
            },
            {
              id: "RENF22R2MMW",
              title: "NFC Timer Relay",
              description:
                "Our Zelio NFC Timer Relay provides effective and accurate on/off delays during the sealing process to ensure smooth system operations. It includes timing ranges of 0.001s to 999h, and 28 timing functions for easy customization.",
              imageUrl:
                "https://nu-platform-prod-2.storage.googleapis.com/gallery/3546d952e5ff46efb8d0859ab7accad2.jpeg",
            },
          ],
        },
      },
    ],
  },
  {
    key: "safetyControl",
    label: "Safety Control",
    position: {
      top: 180,
      left: 155,
    },
    menuItems: [
      {
        key: "emergencyPushButton",
        label: "Emergency Push Button",
        modal: {
          title: "Emergency Push Button",
          description:
            "A fail-safe control switch that provides safety for the machinery and the operator.",
          type: ModalTypes.multi,
          content: [
            {
              title: "Emergency Push Buttons",
              description:
                "The Harmony XB4 & XB5 range of modular emergency stop push buttons offers you the combined reassurance of quality, efficiency, and simplicity. Designed with robust and durable material, you can use this E-Stop button in a variety of different scenarios and industrial applications.",
              imageUrl:
                "https://nu-platform-prod-2.storage.googleapis.com/gallery/e5a91d9e741f46f2a39672544a6b388a.jpeg",
            },
            {
              title: "Safety Modules",
              description:
                "Preventa XPS safety modules are the first choice for managing single safety functions easily, typically in machines of simple to medium complexity.",
              imageUrl:
                "https://nu-platform-prod-2.storage.googleapis.com/gallery/95973fbcd13e4a6cb20c69a66e9204e0.jpeg",
            },
          ],
        },
      },
      {
        key: "doorAndGateSafetyControl",
        label: "Door and Gate Safety Control",
        modal: {
          title: "Door and Gate Safety Control",
          description:
            "Control the safe opening and interlocking of guards, covers, and doors.",
          type: ModalTypes.multi,
          content: [
            {
              title: "Safety Switches",
              description:
                "Preventa XCS safety interlock switches protect machine operators by stopping the machine when the guard or door opens, and withdrawing the actuator attached to the machine guard from the head of the switch.",
              imageUrl:
                "https://nu-platform-prod-2.storage.googleapis.com/gallery/4cd433ca8a4148c99850d92fea517484.jpeg",
            },
            {
              title: "Safety Modules",
              description:
                "Preventa XPS safety modules are the first choice for managing single safety functions easily, typically in machines of simple to medium complexity.",
              imageUrl:
                "https://nu-platform-prod-2.storage.googleapis.com/gallery/95973fbcd13e4a6cb20c69a66e9204e0.jpeg",
            },
          ],
        },
      },
    ],
  },
];

export default PartDefitions;

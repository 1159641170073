import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import "./styles.scss";

function QuizQuestion({
  title,
  id,
  ts,
  index,
  onQuestionAnswered,
  validOptions,
}) {
  const { t } = useTranslation();
  return (
    <div className="quiz-question">
      <div className="quiz-question-title">{t(title)}</div>
      <div className="quiz-question-option-container">
        {validOptions.map((o, optionIndex) => (
          <div
            className="quiz-question-option-item"
            key={`question-${id}-${index}-${o.value}`}
          >
            <input
              id={`question-${id}-${index}-${optionIndex}`}
              name={`${id}-${index}`}
              type="radio"
              value={o.value}
              onChange={(e) => {
                onQuestionAnswered({ id, ts }, e.target.value);
              }}
            />
            <label htmlFor={`question-${id}-${index}-${optionIndex}`}>
              {t(o.label)}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
}

QuizQuestion.defaultProps = {
  title: "",
  id: 0,
  index: 0,
  onQuestionAnswered: () => {},
  ts: 0,
  validOptions: [],
};

QuizQuestion.propTypes = {
  title: PropTypes.string,
  id: PropTypes.number,
  index: PropTypes.number,
  onQuestionAnswered: PropTypes.func,
  ts: PropTypes.number,
  validOptions: PropTypes.array,
};

export default QuizQuestion;

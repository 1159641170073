import PropTypes from "prop-types";

import {
  IconDelete,
  IconPage,
  IconDownload,
  IconChecked,
  IconUnchecked,
  Add,
  Return,
} from "./icons";

function Icon({ type, color }) {
  switch (type) {
    case "delete":
      return <IconDelete color={color} />;
    case "page":
      return <IconPage color={color} />;
    case "download":
      return <IconDownload color={color} />;
    case "checked":
      return <IconChecked color={color} />;
    case "unchecked":
      return <IconUnchecked color={color} />;
    case "return":
      return <Return color={color} />;
    case "add":
      return <Add color={color} />;
    default:
      return null;
  }
}

Icon.propTypes = {
  type: PropTypes.string,
  color: PropTypes.string,
};

Icon.defaultProps = {
  type: null,
  color: "#000",
};

export default Icon;

import PropTypes from 'prop-types';

function IconAdd({ color }) {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 1606 1606"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      // xmlns:xlink="http://www.w3.org/1999/xlink"
      // xml:space="preserve"
      // xmlns:serif="http://www.serif.com/"
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
    >
      <g transform="matrix(1,0,0,1,-14368.5,-159.137)">
        <g transform="matrix(1,0,0,1,968,3035)">
          <path
            d="M14203.3,-2875.86C14426.3,-2875.86 14615.8,-2797.12 14771.9,-2639.63C14928,-2482.14 15006.1,-2292.6 15006.1,-2071.01C15006.1,-1849.41 14927.3,-1660.56 14769.8,-1504.47C14612.3,-1348.38 14423.5,-1270.33 14203.3,-1270.33C13983.1,-1270.33 13794.2,-1348.38 13636.8,-1504.47C13479.3,-1660.56 13400.5,-1849.41 13400.5,-2071.01C13400.5,-2292.6 13478.6,-2482.14 13634.7,-2639.63C13790.8,-2797.12 13980.3,-2875.86 14203.3,-2875.86ZM14203.3,-1592.27C14222.8,-1592.27 14239.5,-1599.24 14253.5,-1613.18C14267.4,-1627.11 14274.4,-1643.84 14274.4,-1663.35L14274.4,-2002.02L14613,-2002.02C14632.5,-2002.02 14649.3,-2008.99 14663.2,-2022.92C14677.1,-2036.86 14684.1,-2053.58 14684.1,-2073.1C14684.1,-2092.61 14677.1,-2109.33 14663.2,-2123.27C14649.3,-2137.21 14632.5,-2144.17 14613,-2144.17L14274.4,-2144.17L14274.4,-2482.84C14274.4,-2502.35 14267.4,-2519.08 14253.5,-2533.01C14239.5,-2546.95 14222.8,-2553.92 14203.3,-2553.92C14183.8,-2553.92 14167.1,-2546.95 14153.1,-2533.01C14139.2,-2519.08 14132.2,-2502.35 14132.2,-2482.84L14132.2,-2144.17L13793.5,-2144.17C13774,-2144.17 13757.3,-2137.21 13743.4,-2123.27C13729.4,-2109.33 13722.5,-2092.61 13722.5,-2073.1C13722.5,-2053.58 13729.4,-2036.86 13743.4,-2022.92C13757.3,-2008.99 13774,-2002.02 13793.5,-2002.02L14132.2,-2002.02L14132.2,-1663.35C14132.2,-1652.2 14135,-1641.05 14140.6,-1629.9C14146.1,-1618.75 14154.5,-1609.69 14165.7,-1602.72C14176.8,-1595.76 14189.4,-1592.27 14203.3,-1592.27Z"
            style={{ fillRule: 'nonzero' }}
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
}

IconAdd.propTypes = {
  color: PropTypes.string,
};

IconAdd.defaultProps = {
  color: '#3ab85d',
};

export default IconAdd;

import PropTypes from 'prop-types';
import './styles.scss';

const Loader = ({ text, color }) => (
  <div className="Loader">
    <div className="Loader-spinner" style={{ borderTopColor: color }} />
    {text && (
      <div className="Loader-text">{text}</div>
    )}
  </div>
);

Loader.propTypes = {
  text: PropTypes.string,
  color: PropTypes.string,
};

Loader.defaultProps = {
  color: '#57677C',
  text: '',
};

export default Loader;

/* eslint-disable */

const TowerLight = [
  {
    'id': 0,
    'fieldName': 'Input Option',
    'title': 'Please select your input option.',
    'options': [
      {
        'label': 'Pulse',
        'value': 'Pulse',
        'nextQuestions': [
          1
        ]
      },
      {
        'label': 'Digital I/O',
        'value': 'Digital I/O',
        'nextQuestions': [
          4
        ]
      }
    ]
  },
  {
    'id': 1,
    'fieldName': 'Audible Unit',
    'title': 'Do you need a sounder or buzzer?',
    'options': [
      {
        'label': 'Sounder',
        'value': 'Sounder',
        'nextQuestions': [
          2
        ]
      },
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          3
        ]
      }
    ]
  },
  {
    'id': 2,
    'fieldName': 'Mouting',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': 'Direct mount',
        'value': 'Direct mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with pole',
        'value': 'Fixing plate with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with adjustable pole',
        'value': 'Fixing plate with adjustable pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Metal L bracket with pole',
        'value': 'Metal L bracket with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Wall mount',
        'value': 'Wall mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '1/2" NPT for customer supplied pole',
        'value': '1/2" NPT for customer supplied pole',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 3,
    'fieldName': 'Mouting',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': 'Direct mount',
        'value': 'Direct mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with pole',
        'value': 'Fixing plate with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with adjustable pole',
        'value': 'Fixing plate with adjustable pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Metal L bracket with pole',
        'value': 'Metal L bracket with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Wall mount',
        'value': 'Wall mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '1/2" NPT for customer supplied pole',
        'value': '1/2" NPT for customer supplied pole',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 4,
    'fieldName': 'Audible Unit',
    'title': 'Do you need a sounder or buzzer?',
    'options': [
      {
        'label': 'Sounder',
        'value': 'Sounder',
        'nextQuestions': [
          71
        ]
      },
      {
        'label': 'Buzzer',
        'value': 'Buzzer',
        'nextQuestions': [
          72
        ]
      },
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          73
        ]
      }
    ]
  },
  {
    'id': 5,
    'fieldName': 'Signalling Type',
    'title': 'Please select your signalling type.',
    isLoopStart: true,
    'options': [
      {
        'label': 'Steady',
        'value': 'Steady',
        'nextQuestions': [
          6
        ]
      },
      {
        'label': 'Blinking',
        'value': 'Blinking',
        'nextQuestions': [
          13
        ]
      },
      {
        'label': 'High Flashing',
        'value': 'High Flashing',
        'nextQuestions': [
          20
        ]
      }
    ]
  },
  {
    'id': 6,
    'fieldName': 'Color',
    'title': 'Please select your lightning color',
    isLoopEnd: true,
    isLoopFinished: (data) => {
      const {
        strictFilters,
        loopIndex,
      } = data;

      return parseInt(strictFilters['Lightning layers'].value, 10) === loopIndex;
    },
    loopStartId: 5,
    'options': [
      {
        'label': 'Green',
        'value': 'Green',
        'nextQuestions': [
          7
        ]
      },
      {
        'label': 'Red',
        'value': 'Red',
        'nextQuestions': [
          8
        ]
      },
      {
        'label': 'Orange',
        'value': 'Orange',
        'nextQuestions': [
          9
        ]
      },
      {
        'label': 'Blue',
        'value': 'Blue',
        'nextQuestions': [
          10
        ]
      },
      {
        'label': 'White',
        'value': 'White',
        'nextQuestions': [
          11
        ]
      },
      {
        'label': 'Yellow',
        'value': 'Yellow',
        'nextQuestions': [
          12
        ]
      }
    ]
  },
  {
    'id': 7,
    'fieldName': 'Mouting',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': 'Direct mount',
        'value': 'Direct mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with pole',
        'value': 'Fixing plate with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with adjustable pole',
        'value': 'Fixing plate with adjustable pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Metal L bracket with pole',
        'value': 'Metal L bracket with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Wall mount',
        'value': 'Wall mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '1/2" NPT for customer supplied pole',
        'value': '1/2" NPT for customer supplied pole',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 8,
    'fieldName': 'Mouting',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': 'Direct mount',
        'value': 'Direct mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with pole',
        'value': 'Fixing plate with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with adjustable pole',
        'value': 'Fixing plate with adjustable pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Metal L bracket with pole',
        'value': 'Metal L bracket with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Wall mount',
        'value': 'Wall mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '1/2" NPT for customer supplied pole',
        'value': '1/2" NPT for customer supplied pole',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 9,
    'fieldName': 'Mouting',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': 'Direct mount',
        'value': 'Direct mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with pole',
        'value': 'Fixing plate with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with adjustable pole',
        'value': 'Fixing plate with adjustable pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Metal L bracket with pole',
        'value': 'Metal L bracket with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Wall mount',
        'value': 'Wall mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '1/2" NPT for customer supplied pole',
        'value': '1/2" NPT for customer supplied pole',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 10,
    'fieldName': 'Mouting',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': 'Direct mount',
        'value': 'Direct mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with pole',
        'value': 'Fixing plate with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with adjustable pole',
        'value': 'Fixing plate with adjustable pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Metal L bracket with pole',
        'value': 'Metal L bracket with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Wall mount',
        'value': 'Wall mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '1/2" NPT for customer supplied pole',
        'value': '1/2" NPT for customer supplied pole',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 11,
    'fieldName': 'Mouting',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': 'Direct mount',
        'value': 'Direct mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with pole',
        'value': 'Fixing plate with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with adjustable pole',
        'value': 'Fixing plate with adjustable pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Metal L bracket with pole',
        'value': 'Metal L bracket with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Wall mount',
        'value': 'Wall mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '1/2" NPT for customer supplied pole',
        'value': '1/2" NPT for customer supplied pole',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 12,
    'fieldName': 'Mouting',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': 'Direct mount',
        'value': 'Direct mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with pole',
        'value': 'Fixing plate with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with adjustable pole',
        'value': 'Fixing plate with adjustable pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Metal L bracket with pole',
        'value': 'Metal L bracket with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Wall mount',
        'value': 'Wall mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '1/2" NPT for customer supplied pole',
        'value': '1/2" NPT for customer supplied pole',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 13,
    'fieldName': 'Color',
    'title': 'Please select your lightning color',
    'options': [
      {
        'label': 'Green',
        'value': 'Green',
        'nextQuestions': [
          14
        ]
      },
      {
        'label': 'Red',
        'value': 'Red',
        'nextQuestions': [
          15
        ]
      },
      {
        'label': 'Orange',
        'value': 'Orange',
        'nextQuestions': [
          16
        ]
      },
      {
        'label': 'Blue',
        'value': 'Blue',
        'nextQuestions': [
          17
        ]
      },
      {
        'label': 'White',
        'value': 'White',
        'nextQuestions': [
          18
        ]
      },
      {
        'label': 'Yellow',
        'value': 'Yellow',
        'nextQuestions': [
          19
        ]
      }
    ]
  },
  {
    'id': 14,
    'fieldName': 'Mouting',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': 'Direct mount',
        'value': 'Direct mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with pole',
        'value': 'Fixing plate with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with adjustable pole',
        'value': 'Fixing plate with adjustable pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Metal L bracket with pole',
        'value': 'Metal L bracket with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Wall mount',
        'value': 'Wall mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '1/2" NPT for customer supplied pole',
        'value': '1/2" NPT for customer supplied pole',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 15,
    'fieldName': 'Mouting',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': 'Direct mount',
        'value': 'Direct mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with pole',
        'value': 'Fixing plate with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with adjustable pole',
        'value': 'Fixing plate with adjustable pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Metal L bracket with pole',
        'value': 'Metal L bracket with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Wall mount',
        'value': 'Wall mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '1/2" NPT for customer supplied pole',
        'value': '1/2" NPT for customer supplied pole',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 16,
    'fieldName': 'Mouting',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': 'Direct mount',
        'value': 'Direct mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with pole',
        'value': 'Fixing plate with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with adjustable pole',
        'value': 'Fixing plate with adjustable pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Metal L bracket with pole',
        'value': 'Metal L bracket with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Wall mount',
        'value': 'Wall mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '1/2" NPT for customer supplied pole',
        'value': '1/2" NPT for customer supplied pole',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 17,
    'fieldName': 'Mouting',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': 'Direct mount',
        'value': 'Direct mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with pole',
        'value': 'Fixing plate with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with adjustable pole',
        'value': 'Fixing plate with adjustable pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Metal L bracket with pole',
        'value': 'Metal L bracket with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Wall mount',
        'value': 'Wall mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '1/2" NPT for customer supplied pole',
        'value': '1/2" NPT for customer supplied pole',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 18,
    'fieldName': 'Mouting',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': 'Direct mount',
        'value': 'Direct mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with pole',
        'value': 'Fixing plate with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with adjustable pole',
        'value': 'Fixing plate with adjustable pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Metal L bracket with pole',
        'value': 'Metal L bracket with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Wall mount',
        'value': 'Wall mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '1/2" NPT for customer supplied pole',
        'value': '1/2" NPT for customer supplied pole',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 19,
    'fieldName': 'Mouting',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': 'Direct mount',
        'value': 'Direct mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with pole',
        'value': 'Fixing plate with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with adjustable pole',
        'value': 'Fixing plate with adjustable pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Metal L bracket with pole',
        'value': 'Metal L bracket with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Wall mount',
        'value': 'Wall mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '1/2" NPT for customer supplied pole',
        'value': '1/2" NPT for customer supplied pole',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 20,
    'fieldName': 'Color',
    'title': 'Please select your lightning color',
    'options': [
      {
        'label': 'Green',
        'value': 'Green',
        'nextQuestions': [
          21
        ]
      },
      {
        'label': 'Red',
        'value': 'Red',
        'nextQuestions': [
          22
        ]
      },
      {
        'label': 'Orange',
        'value': 'Orange',
        'nextQuestions': [
          23
        ]
      },
      {
        'label': 'Blue',
        'value': 'Blue',
        'nextQuestions': [
          24
        ]
      },
      {
        'label': 'White',
        'value': 'White',
        'nextQuestions': [
          25
        ]
      },
      {
        'label': 'Yellow',
        'value': 'Yellow',
        'nextQuestions': [
          26
        ]
      }
    ]
  },
  {
    'id': 21,
    'fieldName': 'Mouting',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': 'Direct mount',
        'value': 'Direct mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with pole',
        'value': 'Fixing plate with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with adjustable pole',
        'value': 'Fixing plate with adjustable pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Metal L bracket with pole',
        'value': 'Metal L bracket with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Wall mount',
        'value': 'Wall mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '1/2" NPT for customer supplied pole',
        'value': '1/2" NPT for customer supplied pole',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 22,
    'fieldName': 'Mouting',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': 'Direct mount',
        'value': 'Direct mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with pole',
        'value': 'Fixing plate with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with adjustable pole',
        'value': 'Fixing plate with adjustable pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Metal L bracket with pole',
        'value': 'Metal L bracket with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Wall mount',
        'value': 'Wall mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '1/2" NPT for customer supplied pole',
        'value': '1/2" NPT for customer supplied pole',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 23,
    'fieldName': 'Mouting',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': 'Direct mount',
        'value': 'Direct mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with pole',
        'value': 'Fixing plate with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with adjustable pole',
        'value': 'Fixing plate with adjustable pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Metal L bracket with pole',
        'value': 'Metal L bracket with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Wall mount',
        'value': 'Wall mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '1/2" NPT for customer supplied pole',
        'value': '1/2" NPT for customer supplied pole',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 24,
    'fieldName': 'Mouting',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': 'Direct mount',
        'value': 'Direct mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with pole',
        'value': 'Fixing plate with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with adjustable pole',
        'value': 'Fixing plate with adjustable pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Metal L bracket with pole',
        'value': 'Metal L bracket with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Wall mount',
        'value': 'Wall mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '1/2" NPT for customer supplied pole',
        'value': '1/2" NPT for customer supplied pole',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 25,
    'fieldName': 'Mouting',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': 'Direct mount',
        'value': 'Direct mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with pole',
        'value': 'Fixing plate with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with adjustable pole',
        'value': 'Fixing plate with adjustable pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Metal L bracket with pole',
        'value': 'Metal L bracket with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Wall mount',
        'value': 'Wall mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '1/2" NPT for customer supplied pole',
        'value': '1/2" NPT for customer supplied pole',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 26,
    'fieldName': 'Mouting',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': 'Direct mount',
        'value': 'Direct mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with pole',
        'value': 'Fixing plate with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with adjustable pole',
        'value': 'Fixing plate with adjustable pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Metal L bracket with pole',
        'value': 'Metal L bracket with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Wall mount',
        'value': 'Wall mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '1/2" NPT for customer supplied pole',
        'value': '1/2" NPT for customer supplied pole',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 27,
    'fieldName': 'Signalling Type',
    'title': 'Please select your signalling type.',
    isLoopStart: true,
    'options': [
      {
        'label': 'Steady',
        'value': 'Steady',
        'nextQuestions': [
          28
        ]
      },
      {
        'label': 'Blinking',
        'value': 'Blinking',
        'nextQuestions': [
          35
        ]
      },
      {
        'label': 'High Flashing',
        'value': 'High Flashing',
        'nextQuestions': [
          42
        ]
      }
    ]
  },
  {
    'id': 28,
    'fieldName': 'Color',
    'title': 'Please select your lightning color',
    isLoopEnd: true,
    isLoopFinished: (data) => {
      const {
        strictFilters,
        loopIndex,
      } = data;

      return parseInt(strictFilters['Lightning layers'].value, 10) === loopIndex;
    },
    loopStartId: 27,
    'options': [
      {
        'label': 'Green',
        'value': 'Green',
        'nextQuestions': [
          29
        ]
      },
      {
        'label': 'Red',
        'value': 'Red',
        'nextQuestions': [
          30
        ]
      },
      {
        'label': 'Orange',
        'value': 'Orange',
        'nextQuestions': [
          31
        ]
      },
      {
        'label': 'Blue',
        'value': 'Blue',
        'nextQuestions': [
          32
        ]
      },
      {
        'label': 'White',
        'value': 'White',
        'nextQuestions': [
          33
        ]
      },
      {
        'label': 'Yellow',
        'value': 'Yellow',
        'nextQuestions': [
          34
        ]
      }
    ]
  },
  {
    'id': 29,
    'fieldName': 'Mouting',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': 'Direct mount',
        'value': 'Direct mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with pole',
        'value': 'Fixing plate with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with adjustable pole',
        'value': 'Fixing plate with adjustable pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Metal L bracket with pole',
        'value': 'Metal L bracket with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Wall mount',
        'value': 'Wall mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '1/2" NPT for customer supplied pole',
        'value': '1/2" NPT for customer supplied pole',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 30,
    'fieldName': 'Mouting',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': 'Direct mount',
        'value': 'Direct mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with pole',
        'value': 'Fixing plate with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with adjustable pole',
        'value': 'Fixing plate with adjustable pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Metal L bracket with pole',
        'value': 'Metal L bracket with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Wall mount',
        'value': 'Wall mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '1/2" NPT for customer supplied pole',
        'value': '1/2" NPT for customer supplied pole',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 31,
    'fieldName': 'Mouting',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': 'Direct mount',
        'value': 'Direct mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with pole',
        'value': 'Fixing plate with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with adjustable pole',
        'value': 'Fixing plate with adjustable pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Metal L bracket with pole',
        'value': 'Metal L bracket with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Wall mount',
        'value': 'Wall mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '1/2" NPT for customer supplied pole',
        'value': '1/2" NPT for customer supplied pole',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 32,
    'fieldName': 'Mouting',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': 'Direct mount',
        'value': 'Direct mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with pole',
        'value': 'Fixing plate with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with adjustable pole',
        'value': 'Fixing plate with adjustable pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Metal L bracket with pole',
        'value': 'Metal L bracket with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Wall mount',
        'value': 'Wall mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '1/2" NPT for customer supplied pole',
        'value': '1/2" NPT for customer supplied pole',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 33,
    'fieldName': 'Mouting',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': 'Direct mount',
        'value': 'Direct mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with pole',
        'value': 'Fixing plate with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with adjustable pole',
        'value': 'Fixing plate with adjustable pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Metal L bracket with pole',
        'value': 'Metal L bracket with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Wall mount',
        'value': 'Wall mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '1/2" NPT for customer supplied pole',
        'value': '1/2" NPT for customer supplied pole',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 34,
    'fieldName': 'Mouting',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': 'Direct mount',
        'value': 'Direct mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with pole',
        'value': 'Fixing plate with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with adjustable pole',
        'value': 'Fixing plate with adjustable pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Metal L bracket with pole',
        'value': 'Metal L bracket with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Wall mount',
        'value': 'Wall mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '1/2" NPT for customer supplied pole',
        'value': '1/2" NPT for customer supplied pole',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 35,
    'fieldName': 'Color',
    'title': 'Please select your lightning color',
    isLoopEnd: true,
    isLoopFinished: (data) => {
      const {
        strictFilters,
        loopIndex,
      } = data;

      return parseInt(strictFilters['Lightning layers'].value, 10) === loopIndex;
    },
    loopStartId: 27,
    'options': [
      {
        'label': 'Green',
        'value': 'Green',
        'nextQuestions': [
          36
        ]
      },
      {
        'label': 'Red',
        'value': 'Red',
        'nextQuestions': [
          37
        ]
      },
      {
        'label': 'Orange',
        'value': 'Orange',
        'nextQuestions': [
          38
        ]
      },
      {
        'label': 'Blue',
        'value': 'Blue',
        'nextQuestions': [
          39
        ]
      },
      {
        'label': 'White',
        'value': 'White',
        'nextQuestions': [
          40
        ]
      },
      {
        'label': 'Yellow',
        'value': 'Yellow',
        'nextQuestions': [
          41
        ]
      }
    ]
  },
  {
    'id': 36,
    'fieldName': 'Mouting',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': 'Direct mount',
        'value': 'Direct mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with pole',
        'value': 'Fixing plate with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with adjustable pole',
        'value': 'Fixing plate with adjustable pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Metal L bracket with pole',
        'value': 'Metal L bracket with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Wall mount',
        'value': 'Wall mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '1/2" NPT for customer supplied pole',
        'value': '1/2" NPT for customer supplied pole',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 37,
    'fieldName': 'Mouting',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': 'Direct mount',
        'value': 'Direct mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with pole',
        'value': 'Fixing plate with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with adjustable pole',
        'value': 'Fixing plate with adjustable pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Metal L bracket with pole',
        'value': 'Metal L bracket with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Wall mount',
        'value': 'Wall mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '1/2" NPT for customer supplied pole',
        'value': '1/2" NPT for customer supplied pole',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 38,
    'fieldName': 'Mouting',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': 'Direct mount',
        'value': 'Direct mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with pole',
        'value': 'Fixing plate with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with adjustable pole',
        'value': 'Fixing plate with adjustable pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Metal L bracket with pole',
        'value': 'Metal L bracket with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Wall mount',
        'value': 'Wall mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '1/2" NPT for customer supplied pole',
        'value': '1/2" NPT for customer supplied pole',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 39,
    'fieldName': 'Mouting',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': 'Direct mount',
        'value': 'Direct mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with pole',
        'value': 'Fixing plate with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with adjustable pole',
        'value': 'Fixing plate with adjustable pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Metal L bracket with pole',
        'value': 'Metal L bracket with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Wall mount',
        'value': 'Wall mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '1/2" NPT for customer supplied pole',
        'value': '1/2" NPT for customer supplied pole',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 40,
    'fieldName': 'Mouting',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': 'Direct mount',
        'value': 'Direct mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with pole',
        'value': 'Fixing plate with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with adjustable pole',
        'value': 'Fixing plate with adjustable pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Metal L bracket with pole',
        'value': 'Metal L bracket with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Wall mount',
        'value': 'Wall mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '1/2" NPT for customer supplied pole',
        'value': '1/2" NPT for customer supplied pole',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 41,
    'fieldName': 'Mouting',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': 'Direct mount',
        'value': 'Direct mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with pole',
        'value': 'Fixing plate with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with adjustable pole',
        'value': 'Fixing plate with adjustable pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Metal L bracket with pole',
        'value': 'Metal L bracket with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Wall mount',
        'value': 'Wall mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '1/2" NPT for customer supplied pole',
        'value': '1/2" NPT for customer supplied pole',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 42,
    'fieldName': 'Color',
    'title': 'Please select your lightning color',
    isLoopEnd: true,
    isLoopFinished: (data) => {
      const {
        strictFilters,
        loopIndex,
      } = data;

      return parseInt(strictFilters['Lightning layers'].value, 10) === loopIndex;
    },
    loopStartId: 27,
    'options': [
      {
        'label': 'Green',
        'value': 'Green',
        'nextQuestions': [
          43
        ]
      },
      {
        'label': 'Red',
        'value': 'Red',
        'nextQuestions': [
          44
        ]
      },
      {
        'label': 'Orange',
        'value': 'Orange',
        'nextQuestions': [
          45
        ]
      },
      {
        'label': 'Blue',
        'value': 'Blue',
        'nextQuestions': [
          46
        ]
      },
      {
        'label': 'White',
        'value': 'White',
        'nextQuestions': [
          47
        ]
      },
      {
        'label': 'Yellow',
        'value': 'Yellow',
        'nextQuestions': [
          48
        ]
      }
    ]
  },
  {
    'id': 43,
    'fieldName': 'Mouting',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': 'Direct mount',
        'value': 'Direct mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with pole',
        'value': 'Fixing plate with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with adjustable pole',
        'value': 'Fixing plate with adjustable pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Metal L bracket with pole',
        'value': 'Metal L bracket with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Wall mount',
        'value': 'Wall mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '1/2" NPT for customer supplied pole',
        'value': '1/2" NPT for customer supplied pole',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 44,
    'fieldName': 'Mouting',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': 'Direct mount',
        'value': 'Direct mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with pole',
        'value': 'Fixing plate with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with adjustable pole',
        'value': 'Fixing plate with adjustable pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Metal L bracket with pole',
        'value': 'Metal L bracket with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Wall mount',
        'value': 'Wall mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '1/2" NPT for customer supplied pole',
        'value': '1/2" NPT for customer supplied pole',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 45,
    'fieldName': 'Mouting',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': 'Direct mount',
        'value': 'Direct mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with pole',
        'value': 'Fixing plate with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with adjustable pole',
        'value': 'Fixing plate with adjustable pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Metal L bracket with pole',
        'value': 'Metal L bracket with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Wall mount',
        'value': 'Wall mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '1/2" NPT for customer supplied pole',
        'value': '1/2" NPT for customer supplied pole',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 46,
    'fieldName': 'Mouting',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': 'Direct mount',
        'value': 'Direct mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with pole',
        'value': 'Fixing plate with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with adjustable pole',
        'value': 'Fixing plate with adjustable pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Metal L bracket with pole',
        'value': 'Metal L bracket with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Wall mount',
        'value': 'Wall mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '1/2" NPT for customer supplied pole',
        'value': '1/2" NPT for customer supplied pole',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 47,
    'fieldName': 'Mouting',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': 'Direct mount',
        'value': 'Direct mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with pole',
        'value': 'Fixing plate with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with adjustable pole',
        'value': 'Fixing plate with adjustable pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Metal L bracket with pole',
        'value': 'Metal L bracket with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Wall mount',
        'value': 'Wall mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '1/2" NPT for customer supplied pole',
        'value': '1/2" NPT for customer supplied pole',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 48,
    'fieldName': 'Mouting',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': 'Direct mount',
        'value': 'Direct mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with pole',
        'value': 'Fixing plate with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with adjustable pole',
        'value': 'Fixing plate with adjustable pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Metal L bracket with pole',
        'value': 'Metal L bracket with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Wall mount',
        'value': 'Wall mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '1/2" NPT for customer supplied pole',
        'value': '1/2" NPT for customer supplied pole',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 49,
    'fieldName': 'Signalling Type',
    'title': 'Please select your signalling type.',
    isLoopStart: true,
    'options': [
      {
        'label': 'Steady',
        'value': 'Steady',
        'nextQuestions': [
          50
        ]
      },
      {
        'label': 'Blinking',
        'value': 'Blinking',
        'nextQuestions': [
          57
        ]
      },
      {
        'label': 'High Flashing',
        'value': 'High Flashing',
        'nextQuestions': [
          64
        ]
      }
    ]
  },
  {
    'id': 50,
    'fieldName': 'Color',
    'title': 'Please select your lightning color',
    isLoopEnd: true,
    isLoopFinished: (data) => {
      const {
        strictFilters,
        loopIndex,
      } = data;

      return parseInt(strictFilters['Lightning layers'].value, 10) === loopIndex;
    },
    loopStartId: 49,
    'options': [
      {
        'label': 'Green',
        'value': 'Green',
        'nextQuestions': [
          51
        ]
      },
      {
        'label': 'Red',
        'value': 'Red',
        'nextQuestions': [
          52
        ]
      },
      {
        'label': 'Orange',
        'value': 'Orange',
        'nextQuestions': [
          53
        ]
      },
      {
        'label': 'Blue',
        'value': 'Blue',
        'nextQuestions': [
          54
        ]
      },
      {
        'label': 'White',
        'value': 'White',
        'nextQuestions': [
          55
        ]
      },
      {
        'label': 'Yellow',
        'value': 'Yellow',
        'nextQuestions': [
          56
        ]
      }
    ]
  },
  {
    'id': 51,
    'fieldName': 'Mouting',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': 'Direct mount',
        'value': 'Direct mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with pole',
        'value': 'Fixing plate with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with adjustable pole',
        'value': 'Fixing plate with adjustable pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Metal L bracket with pole',
        'value': 'Metal L bracket with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Wall mount',
        'value': 'Wall mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '1/2" NPT for customer supplied pole',
        'value': '1/2" NPT for customer supplied pole',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 52,
    'fieldName': 'Mouting',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': 'Direct mount',
        'value': 'Direct mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with pole',
        'value': 'Fixing plate with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with adjustable pole',
        'value': 'Fixing plate with adjustable pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Metal L bracket with pole',
        'value': 'Metal L bracket with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Wall mount',
        'value': 'Wall mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '1/2" NPT for customer supplied pole',
        'value': '1/2" NPT for customer supplied pole',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 53,
    'fieldName': 'Mouting',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': 'Direct mount',
        'value': 'Direct mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with pole',
        'value': 'Fixing plate with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with adjustable pole',
        'value': 'Fixing plate with adjustable pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Metal L bracket with pole',
        'value': 'Metal L bracket with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Wall mount',
        'value': 'Wall mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '1/2" NPT for customer supplied pole',
        'value': '1/2" NPT for customer supplied pole',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 54,
    'fieldName': 'Mouting',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': 'Direct mount',
        'value': 'Direct mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with pole',
        'value': 'Fixing plate with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with adjustable pole',
        'value': 'Fixing plate with adjustable pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Metal L bracket with pole',
        'value': 'Metal L bracket with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Wall mount',
        'value': 'Wall mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '1/2" NPT for customer supplied pole',
        'value': '1/2" NPT for customer supplied pole',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 55,
    'fieldName': 'Mouting',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': 'Direct mount',
        'value': 'Direct mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with pole',
        'value': 'Fixing plate with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with adjustable pole',
        'value': 'Fixing plate with adjustable pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Metal L bracket with pole',
        'value': 'Metal L bracket with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Wall mount',
        'value': 'Wall mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '1/2" NPT for customer supplied pole',
        'value': '1/2" NPT for customer supplied pole',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 56,
    'fieldName': 'Mouting',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': 'Direct mount',
        'value': 'Direct mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with pole',
        'value': 'Fixing plate with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with adjustable pole',
        'value': 'Fixing plate with adjustable pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Metal L bracket with pole',
        'value': 'Metal L bracket with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Wall mount',
        'value': 'Wall mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '1/2" NPT for customer supplied pole',
        'value': '1/2" NPT for customer supplied pole',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 57,
    'fieldName': 'Color',
    'title': 'Please select your lightning color',
    isLoopEnd: true,
    isLoopFinished: (data) => {
      const {
        strictFilters,
        loopIndex,
      } = data;

      return parseInt(strictFilters['Lightning layers'].value, 10) === loopIndex;
    },
    loopStartId: 49,
    'options': [
      {
        'label': 'Green',
        'value': 'Green',
        'nextQuestions': [
          58
        ]
      },
      {
        'label': 'Red',
        'value': 'Red',
        'nextQuestions': [
          59
        ]
      },
      {
        'label': 'Orange',
        'value': 'Orange',
        'nextQuestions': [
          60
        ]
      },
      {
        'label': 'Blue',
        'value': 'Blue',
        'nextQuestions': [
          61
        ]
      },
      {
        'label': 'White',
        'value': 'White',
        'nextQuestions': [
          62
        ]
      },
      {
        'label': 'Yellow',
        'value': 'Yellow',
        'nextQuestions': [
          63
        ]
      }
    ]
  },
  {
    'id': 58,
    'fieldName': 'Mouting',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': 'Direct mount',
        'value': 'Direct mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with pole',
        'value': 'Fixing plate with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with adjustable pole',
        'value': 'Fixing plate with adjustable pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Metal L bracket with pole',
        'value': 'Metal L bracket with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Wall mount',
        'value': 'Wall mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '1/2" NPT for customer supplied pole',
        'value': '1/2" NPT for customer supplied pole',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 59,
    'fieldName': 'Mouting',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': 'Direct mount',
        'value': 'Direct mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with pole',
        'value': 'Fixing plate with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with adjustable pole',
        'value': 'Fixing plate with adjustable pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Metal L bracket with pole',
        'value': 'Metal L bracket with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Wall mount',
        'value': 'Wall mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '1/2" NPT for customer supplied pole',
        'value': '1/2" NPT for customer supplied pole',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 60,
    'fieldName': 'Mouting',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': 'Direct mount',
        'value': 'Direct mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with pole',
        'value': 'Fixing plate with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with adjustable pole',
        'value': 'Fixing plate with adjustable pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Metal L bracket with pole',
        'value': 'Metal L bracket with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Wall mount',
        'value': 'Wall mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '1/2" NPT for customer supplied pole',
        'value': '1/2" NPT for customer supplied pole',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 61,
    'fieldName': 'Mouting',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': 'Direct mount',
        'value': 'Direct mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with pole',
        'value': 'Fixing plate with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with adjustable pole',
        'value': 'Fixing plate with adjustable pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Metal L bracket with pole',
        'value': 'Metal L bracket with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Wall mount',
        'value': 'Wall mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '1/2" NPT for customer supplied pole',
        'value': '1/2" NPT for customer supplied pole',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 62,
    'fieldName': 'Mouting',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': 'Direct mount',
        'value': 'Direct mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with pole',
        'value': 'Fixing plate with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with adjustable pole',
        'value': 'Fixing plate with adjustable pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Metal L bracket with pole',
        'value': 'Metal L bracket with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Wall mount',
        'value': 'Wall mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '1/2" NPT for customer supplied pole',
        'value': '1/2" NPT for customer supplied pole',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 63,
    'fieldName': 'Mouting',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': 'Direct mount',
        'value': 'Direct mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with pole',
        'value': 'Fixing plate with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with adjustable pole',
        'value': 'Fixing plate with adjustable pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Metal L bracket with pole',
        'value': 'Metal L bracket with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Wall mount',
        'value': 'Wall mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '1/2" NPT for customer supplied pole',
        'value': '1/2" NPT for customer supplied pole',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 64,
    'fieldName': 'Color',
    'title': 'Please select your lightning color',
    isLoopEnd: true,
    isLoopFinished: (data) => {
      const {
        strictFilters,
        loopIndex,
      } = data;

      return parseInt(strictFilters['Lightning layers'].value, 10) === loopIndex;
    },
    loopStartId: 49,
    'options': [
      {
        'label': 'Green',
        'value': 'Green',
        'nextQuestions': [
          65
        ]
      },
      {
        'label': 'Red',
        'value': 'Red',
        'nextQuestions': [
          66
        ]
      },
      {
        'label': 'Orange',
        'value': 'Orange',
        'nextQuestions': [
          67
        ]
      },
      {
        'label': 'Blue',
        'value': 'Blue',
        'nextQuestions': [
          68
        ]
      },
      {
        'label': 'White',
        'value': 'White',
        'nextQuestions': [
          69
        ]
      },
      {
        'label': 'Yellow',
        'value': 'Yellow',
        'nextQuestions': [
          70
        ]
      }
    ]
  },
  {
    'id': 65,
    'fieldName': 'Mouting',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': 'Direct mount',
        'value': 'Direct mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with pole',
        'value': 'Fixing plate with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with adjustable pole',
        'value': 'Fixing plate with adjustable pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Metal L bracket with pole',
        'value': 'Metal L bracket with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Wall mount',
        'value': 'Wall mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '1/2" NPT for customer supplied pole',
        'value': '1/2" NPT for customer supplied pole',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 66,
    'fieldName': 'Mouting',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': 'Direct mount',
        'value': 'Direct mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with pole',
        'value': 'Fixing plate with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with adjustable pole',
        'value': 'Fixing plate with adjustable pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Metal L bracket with pole',
        'value': 'Metal L bracket with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Wall mount',
        'value': 'Wall mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '1/2" NPT for customer supplied pole',
        'value': '1/2" NPT for customer supplied pole',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 67,
    'fieldName': 'Mouting',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': 'Direct mount',
        'value': 'Direct mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with pole',
        'value': 'Fixing plate with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with adjustable pole',
        'value': 'Fixing plate with adjustable pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Metal L bracket with pole',
        'value': 'Metal L bracket with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Wall mount',
        'value': 'Wall mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '1/2" NPT for customer supplied pole',
        'value': '1/2" NPT for customer supplied pole',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 68,
    'fieldName': 'Mouting',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': 'Direct mount',
        'value': 'Direct mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with pole',
        'value': 'Fixing plate with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with adjustable pole',
        'value': 'Fixing plate with adjustable pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Metal L bracket with pole',
        'value': 'Metal L bracket with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Wall mount',
        'value': 'Wall mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '1/2" NPT for customer supplied pole',
        'value': '1/2" NPT for customer supplied pole',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 69,
    'fieldName': 'Mouting',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': 'Direct mount',
        'value': 'Direct mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with pole',
        'value': 'Fixing plate with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with adjustable pole',
        'value': 'Fixing plate with adjustable pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Metal L bracket with pole',
        'value': 'Metal L bracket with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Wall mount',
        'value': 'Wall mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '1/2" NPT for customer supplied pole',
        'value': '1/2" NPT for customer supplied pole',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 70,
    'fieldName': 'Mouting',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': 'Direct mount',
        'value': 'Direct mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with pole',
        'value': 'Fixing plate with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Fixing plate with adjustable pole',
        'value': 'Fixing plate with adjustable pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Metal L bracket with pole',
        'value': 'Metal L bracket with pole',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': 'Wall mount',
        'value': 'Wall mount',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '1/2" NPT for customer supplied pole',
        'value': '1/2" NPT for customer supplied pole',
        'nextQuestions': [
          -1
        ]
      }
    ]
  }, {
    id: 71,
    fieldName: 'Lightning layers',
    title: 'Number of lighting layers required.',
    'options': [
      {
        'label': '1',
        'value': '1',
      },
      {
        'label': '2',
        'value': '2',
      },
      {
        'label': '3',
        'value': '3',
      },
      {
        'label': '4',
        'value': '4',
      },
    ],
    nextQuestions: [5]
  }, {
    id: 72,
    fieldName: 'Lightning layers',
    title: 'Number of lighting layers required.',
    'options': [
      {
        'label': '1',
        'value': '1',
      },
      {
        'label': '2',
        'value': '2',
      },
      {
        'label': '3',
        'value': '3',
      },
      {
        'label': '4',
        'value': '4',
      },
    ],
    nextQuestions: [27]
  }, {
    id: 73,
    fieldName: 'Lightning layers',
    title: 'Number of lighting layers required.',
    'options': [
      {
        'label': '1',
        'value': '1',
      },
      {
        'label': '2',
        'value': '2',
      },
      {
        'label': '3',
        'value': '3',
      },
      {
        'label': '4',
        'value': '4',
      },
    ],
    nextQuestions: [49]
  },
];

export default TowerLight;

import PropTypes from "prop-types";
import { useState, useEffect, useRef, useContext } from "react";
import axios from "axios";
import ReactGA from "react-ga4";
import {
  Button,
  ConfiguratorDropdown,
  SlidePane,
  QuizContainer,
} from "shared/components";
import { useTranslation } from "react-i18next";
import MultiCategoryModal from "shared/components/dropdownModals/MultiCategoryModal";
import SingleCategoryModal from "shared/components/dropdownModals/SingleCategoryModal";
import { nextuser } from "shared/tracking/nextuser";
import AppContext from "AppContainer/AppContext/context";
import { gaActions, ModalTypes, MachineTypeShorts } from "shared/constants";
import { useElementSize } from "shared/hooks/index";
import ConfirmationDialog from "../QuizContainer/components/ConfirmationDialog";

import "./styles.scss";

const CloseIcon = (
  <div className="svg-container">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="62.439"
      viewBox="0 0 40 62.439"
    >
      <g id="Group_1" data-name="Group 1" transform="translate(0 10)">
        <path
          id="Path_1"
          data-name="Path 1"
          d="M40-.244V.731a8.2,8.2,0,0,1-3.171,5.854L21.951,21.219l15.61,15.61A8.273,8.273,0,0,1,40,42.927a8.273,8.273,0,0,1-2.439,6.1q-3.17,3.415-6.829,3.415A7.459,7.459,0,0,1,25.366,50L5.609,32.195A14.657,14.657,0,0,1,0,21.463,14.069,14.069,0,0,1,5.854,10.487L25.61-7.561A7.318,7.318,0,0,1,30.732-10q3.415,0,6.829,3.415l.732.975A8.476,8.476,0,0,1,40-.244ZM16.586,21.463a7.732,7.732,0,0,1,.488-2.2,3.815,3.815,0,0,1,.732-.976l15.61-15.61A4.5,4.5,0,0,0,35.122.244a4.541,4.541,0,0,0-.975-2.926.7.7,0,0,0-.488-.244Q32.2-4.878,30.732-4.634l-1.464.488-20,18.536a9.791,9.791,0,0,0-4.147,7.074,9.716,9.716,0,0,0,3.9,6.585L29.268,46.585a2.109,2.109,0,0,0,1.464.732,4.021,4.021,0,0,0,2.926-1.707q2.683-2.683.244-5.122L17.8,24.39a2.831,2.831,0,0,1-.488-.732A3.566,3.566,0,0,1,16.586,21.463Z"
          transform="translate(0 0)"
          fill="#18ca63"
        />
      </g>
    </svg>
    <span>Back</span>
  </div>
);

const filterMenuItems = (pd, mapping) => {
  if (!pd.menuItems) {
    return [];
  }
  if (!mapping) {
    return pd.menuItems;
  }

  const menuItems = pd.menuItems || [];

  return menuItems.filter(
    (mi) =>
      Object.keys(mapping).indexOf(mi.label) >= 0 ||
      Object.keys(mapping).indexOf(mi.fileName) >= 0
  );
};

const countryFilter = (mapping) => (pd) => {
  if (!mapping) {
    return pd;
  }

  return (
    (!pd.menuItems &&
      (Object.keys(mapping).indexOf(pd.label) >= 0 ||
        Object.keys(mapping).indexOf(pd.fileName) >= 0)) ||
    (!!pd.menuItems && filterMenuItems(pd, mapping).length > 0)
  );
};

const MachineContainer = ({
  MachineType,
  PartDefinitions,
  ImageBaseWidth,
  ImageBaseHeight,
  MachineContainerImage,
}) => {
  const {
    state: { country },
    setDimensions,
  } = useContext(AppContext);
  const [activePart, setActivePart] = useState();
  const [configuratorContainerHeight, setConfiguratorContainerHeight] =
    useState(null);
  const [configuratorContainerWidth, setConfiguratorContainerWidth] =
    useState(null);
  const [isQuizContainerOpen, setIsQuizContainerOpen] = useState(false);
  const [linkedModalOpen, setLinkedModalOpen] = useState(false);
  // const [activePartDefinitionLabel, setActivePartDefinitionLabel] =
  //   useState(null);
  const [hoveredPartDefinitionLabel, setHoveredPartDefinitionLabel] =
    useState(null);
  const [mapping, setMapping] = useState(null);

  const elementDom = useRef(null);
  const [size] = useElementSize(elementDom);
  const imageRef = useRef(null);
  // const [imageContainerSize] = useElementSize(imageRef);

  const scaleHeight = (height) =>
    height * (configuratorContainerHeight / ImageBaseHeight);

  const scaleWidth = (width) =>
    width * (configuratorContainerWidth / ImageBaseWidth);

  const imageAspectRatio = ImageBaseWidth / ImageBaseHeight;

  const windowWidth = size?.width;
  const windowHeight = size?.height;

  const screenAspectRatio = windowWidth / windowHeight;
  let containerWidth;
  let containerHeight;

  // const [isModalOpen, setModalOpen] = useState(true);

  if (screenAspectRatio < 0) {
    if (screenAspectRatio > imageAspectRatio) {
      containerHeight = "100%";
      containerWidth = windowHeight / imageAspectRatio;
    } else {
      containerWidth = "100%";
      containerHeight = windowHeight / imageAspectRatio;
    }
  } else if (screenAspectRatio > imageAspectRatio) {
    containerHeight = "100%";
    containerWidth = imageAspectRatio * windowHeight;
  } else {
    containerWidth = "100%";
    containerHeight = windowWidth / imageAspectRatio;
  }

  const fetchAvailableOptions = () =>
    axios
      .get(
        `https://industrial.sesmartconfig.com/filter/filenames/features?country=${country}`
      )
      .then((res) => {
        if (country && country === "NL") {
          const hack = res.data || {};
          delete hack["Motion Control Solution"];
        }
        setMapping(res.data);
      });

  useEffect(() => {
    if (!country) {
      return;
    }
    fetchAvailableOptions();
  }, [country]);

  useEffect(() => {
    setDimensions(containerWidth, containerHeight);
  }, [containerWidth, containerHeight]);

  useEffect(() => {
    // Update the document title using the browser API
    const $container = document.querySelector(".image-container");

    setConfiguratorContainerHeight($container.clientHeight);
    setConfiguratorContainerWidth($container.clientWidth);
  }, [screenAspectRatio]);

  const renderDropdownModalContent = (partDefinition) => {
    if (!partDefinition) {
      return null;
    }

    if (partDefinition.modal.type === ModalTypes.multi) {
      return (
        <MultiCategoryModal
          MachineType={MachineType}
          FeatureType={partDefinition.modal.title}
          products={partDefinition.modal.content}
        />
      );
    }

    if (partDefinition.modal.type === ModalTypes.cart) {
      return (
        <MultiCategoryModal
          MachineType={MachineType}
          FeatureType={partDefinition.modal.title}
          products={partDefinition.modal.content}
          cartEnabled
        />
      );
    }

    if (partDefinition.modal.type === ModalTypes.single) {
      return (
        <SingleCategoryModal
          product={partDefinition.modal.content[0]}
          // footer
        />
      );
    }

    return null;
  };

  const showLinkedModal = () => {
    setLinkedModalOpen(true);
  };

  const flatPartDefinitions = [];
  PartDefinitions.forEach((pd) => {
    flatPartDefinitions.push(pd);
    if (pd.menuItems && pd.menuItems.length > 0) {
      pd.menuItems.forEach((pdmi) => flatPartDefinitions.push(pdmi));
    }
  });

  const activePartDefinition = flatPartDefinitions.find(
    (x) => x.key === activePart
  );

  const { t } = useTranslation();

  const onPointHover = (key) => (state) => {
    setHoveredPartDefinitionLabel(state ? key : null);
  };

  const getFeatureType = () =>
    activePartDefinition.label.toLowerCase().replace(/ /g, "_") || "";

  const displayQuizContainer = () => {
    const featureType = getFeatureType();
    const selectedOptionsString = `${gaActions.clickFeatureTypeLaunch}_${MachineTypeShorts[MachineType]}_${featureType}`;
    nextuser(`display_quiz_container_${selectedOptionsString}`);
    ReactGA.event({
      category: "IndustrialTracker.machineContainer",
      action: "display",
      label: selectedOptionsString,
    });
    setIsQuizContainerOpen(true);
  };

  return (
    <div ref={elementDom} className="MachineContainer">
      <div
        ref={imageRef}
        className="image-container"
        style={{
          width: containerWidth || "100%",
          height: containerHeight || "100%",
        }}
      >
        <img src={MachineContainerImage} alt="vertical packaging machine" />
        {PartDefinitions.filter(countryFilter(mapping))
          .filter((pd) => !pd.hidden)
          .map((pd) => (
            <ConfiguratorDropdown
              MachineType={MachineType}
              FeatureType={pd.label}
              title={t(pd.label)}
              key={pd.key}
              top={scaleHeight(pd.position.top)}
              left={scaleWidth(pd.position.left)}
              menuItems={filterMenuItems(pd, mapping)}
              activatePart={setActivePart}
              partKey={pd.key}
              onHover={onPointHover(pd.key)}
              isVisible={hoveredPartDefinitionLabel === pd.key}
            />
          ))}
      </div>
      {/* Motion Control Solution - Start */}
      <SlidePane
        closeCb={() => setActivePart(null)}
        isOpen={!!activePart}
        size="md"
        closeIcon={CloseIcon}
        title={t(activePartDefinition?.modal?.title)}
        subtitle={t(activePartDefinition?.modal?.description)}
        footer={
          activePartDefinition?.modal?.type !== ModalTypes.cart && (
            <Button onClick={displayQuizContainer}>
              {t("Select your solution now")}
            </Button>
          )
        }
      >
        {renderDropdownModalContent(activePartDefinition)}
      </SlidePane>
      {/* Motion Control Solution - End */}
      {/* QuizContainer - Start */}
      {isQuizContainerOpen && (
        <QuizContainer
          MachineType={MachineType}
          mapping={mapping}
          selectedPart={activePartDefinition}
          isOpen={isQuizContainerOpen}
          showLinkedModal={showLinkedModal}
          onClose={() => {
            setIsQuizContainerOpen(false);
            setActivePart(null);
          }}
        />
      )}
      {/* QuizContainer - End */}
      {/* LinkQuizModal - Start */}
      <ConfirmationDialog
        isOpen={linkedModalOpen}
        title={t(activePartDefinition?.linkedQuiz?.title)}
        onCancel={() => setLinkedModalOpen(false)}
        onConfirm={() => setActivePart(activePartDefinition.linkedQuiz.key)}
      />
      {/* LinkQuizModal - End */}
    </div>
  );
};

MachineContainer.propTypes = {
  MachineType: PropTypes.string,
  PartDefinitions: PropTypes.array,
  ImageBaseWidth: PropTypes.number,
  ImageBaseHeight: PropTypes.number,
  MachineContainerImage: PropTypes.string,
};

MachineContainer.defaultProps = {
  MachineType: "Machine_Type",
  PartDefinitions: [],
  ImageBaseWidth: 0,
  ImageBaseHeight: 0,
  MachineContainerImage: null,
};

export default MachineContainer;

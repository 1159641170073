/* eslint-disable */

const Enclosure = [
  {
    "id": 0,
    "fieldName": "Height",
    "title": "Please select the cabinet height.",
    "options": [
      {
        "label": "200 mm",
        "value": "200 mm",
        "nextQuestions": [
          1
        ]
      },
      {
        "label": "250 mm",
        "value": "250 mm",
        "nextQuestions": [
          6
        ]
      },
      {
        "label": "300 mm",
        "value": "300 mm",
        "nextQuestions": [
          9
        ]
      },
      {
        "label": "400 mm",
        "value": "400 mm",
        "nextQuestions": [
          20
        ]
      },
      {
        "label": "500 mm",
        "value": "500 mm",
        "nextQuestions": [
          29
        ]
      },
      {
        "label": "600 mm",
        "value": "600 mm",
        "nextQuestions": [
          36
        ]
      },
      {
        "label": "700 mm",
        "value": "700 mm",
        "nextQuestions": [
          51
        ]
      },
      {
        "label": "800 mm",
        "value": "800 mm",
        "nextQuestions": [
          55
        ]
      },
      {
        "label": "1000 mm",
        "value": "1000 mm",
        "nextQuestions": [
          68
        ]
      },
      {
        "label": "1200 mm",
        "value": "1200 mm",
        "nextQuestions": [
          82
        ]
      },
      {
        "label": "1400 mm",
        "value": "1400 mm",
        "nextQuestions": [
          95
        ]
      }
    ]
  },
  {
    "id": 1,
    "fieldName": "Width",
    "title": "Please select the cabinet width.",
    "options": [
      {
        "label": "200 mm",
        "value": "200 mm",
        "nextQuestions": [
          2
        ]
      },
      {
        "label": "300 mm",
        "value": "300 mm",
        "nextQuestions": [
          4
        ]
      }
    ]
  },
  {
    "id": 2,
    "fieldName": "Depth",
    "title": "Please select the cabinet depth.",
    "options": [
      {
        "label": "150 mm",
        "value": "150 mm",
        "nextQuestions": [
          3
        ]
      }
    ]
  },
  {
    "id": 3,
    "fieldName": "Door Type",
    "title": "Please select the door type.",
    "options": [
      {
        "label": "Plain Door",
        "value": "Plain Door",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 4,
    "fieldName": "Depth",
    "title": "Please select the cabinet depth.",
    "options": [
      {
        "label": "150 mm",
        "value": "150 mm",
        "nextQuestions": [
          5
        ]
      }
    ]
  },
  {
    "id": 5,
    "fieldName": "Door Type",
    "title": "Please select the door type.",
    "options": [
      {
        "label": "Plain Door",
        "value": "Plain Door",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 6,
    "fieldName": "Width",
    "title": "Please select the cabinet width.",
    "options": [
      {
        "label": "200 mm",
        "value": "200 mm",
        "nextQuestions": [
          7
        ]
      }
    ]
  },
  {
    "id": 7,
    "fieldName": "Depth",
    "title": "Please select the cabinet depth.",
    "options": [
      {
        "label": "150 mm",
        "value": "150 mm",
        "nextQuestions": [
          8
        ]
      }
    ]
  },
  {
    "id": 8,
    "fieldName": "Door Type",
    "title": "Please select the door type.",
    "options": [
      {
        "label": "Plain Door",
        "value": "Plain Door",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 9,
    "fieldName": "Width",
    "title": "Please select the cabinet width.",
    "options": [
      {
        "label": "250 mm",
        "value": "250 mm",
        "nextQuestions": [
          10
        ]
      },
      {
        "label": "300 mm",
        "value": "300 mm",
        "nextQuestions": [
          13
        ]
      },
      {
        "label": "400 mm",
        "value": "400 mm",
        "nextQuestions": [
          16
        ]
      },
      {
        "label": "450 mm",
        "value": "450 mm",
        "nextQuestions": [
          18
        ]
      }
    ]
  },
  {
    "id": 10,
    "fieldName": "Depth",
    "title": "Please select the cabinet depth.",
    "options": [
      {
        "label": "150 mm",
        "value": "150 mm",
        "nextQuestions": [
          11
        ]
      },
      {
        "label": "200 mm",
        "value": "200 mm",
        "nextQuestions": [
          12
        ]
      }
    ]
  },
  {
    "id": 11,
    "fieldName": "Door Type",
    "title": "Please select the door type.",
    "options": [
      {
        "label": "Plain Door",
        "value": "Plain Door",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "Glazed Door",
        "value": "Glazed Door",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 12,
    "fieldName": "Door Type",
    "title": "Please select the door type.",
    "options": [
      {
        "label": "Plain Door",
        "value": "Plain Door",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "Glazed Door",
        "value": "Glazed Door",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 13,
    "fieldName": "Depth",
    "title": "Please select the cabinet depth.",
    "options": [
      {
        "label": "150 mm",
        "value": "150 mm",
        "nextQuestions": [
          14
        ]
      },
      {
        "label": "200 mm",
        "value": "200 mm",
        "nextQuestions": [
          15
        ]
      }
    ]
  },
  {
    "id": 14,
    "fieldName": "Door Type",
    "title": "Please select the door type.",
    "options": [
      {
        "label": "Plain Door",
        "value": "Plain Door",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "Glazed Door",
        "value": "Glazed Door",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 15,
    "fieldName": "Door Type",
    "title": "Please select the door type.",
    "options": [
      {
        "label": "Plain Door",
        "value": "Plain Door",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "Glazed Door",
        "value": "Glazed Door",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 16,
    "fieldName": "Depth",
    "title": "Please select the cabinet depth.",
    "options": [
      {
        "label": "200 mm",
        "value": "200 mm",
        "nextQuestions": [
          17
        ]
      }
    ]
  },
  {
    "id": 17,
    "fieldName": "Door Type",
    "title": "Please select the door type.",
    "options": [
      {
        "label": "Plain Door",
        "value": "Plain Door",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "Glazed Door",
        "value": "Glazed Door",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 18,
    "fieldName": "Depth",
    "title": "Please select the cabinet depth.",
    "options": [
      {
        "label": "150 mm",
        "value": "150 mm",
        "nextQuestions": [
          19
        ]
      }
    ]
  },
  {
    "id": 19,
    "fieldName": "Door Type",
    "title": "Please select the door type.",
    "options": [
      {
        "label": "Plain Door",
        "value": "Plain Door",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 20,
    "fieldName": "Width",
    "title": "Please select the cabinet width.",
    "options": [
      {
        "label": "300 mm",
        "value": "300 mm",
        "nextQuestions": [
          21
        ]
      },
      {
        "label": "400 mm",
        "value": "400 mm",
        "nextQuestions": [
          24
        ]
      },
      {
        "label": "600 mm",
        "value": "600 mm",
        "nextQuestions": [
          26
        ]
      }
    ]
  },
  {
    "id": 21,
    "fieldName": "Depth",
    "title": "Please select the cabinet depth.",
    "options": [
      {
        "label": "150 mm",
        "value": "150 mm",
        "nextQuestions": [
          22
        ]
      },
      {
        "label": "200 mm",
        "value": "200 mm",
        "nextQuestions": [
          23
        ]
      }
    ]
  },
  {
    "id": 22,
    "fieldName": "Door Type",
    "title": "Please select the door type.",
    "options": [
      {
        "label": "Plain Door",
        "value": "Plain Door",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "Glazed Door",
        "value": "Glazed Door",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 23,
    "fieldName": "Door Type",
    "title": "Please select the door type.",
    "options": [
      {
        "label": "Plain Door",
        "value": "Plain Door",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "Glazed Door",
        "value": "Glazed Door",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 24,
    "fieldName": "Depth",
    "title": "Please select the cabinet depth.",
    "options": [
      {
        "label": "200 mm",
        "value": "200 mm",
        "nextQuestions": [
          25
        ]
      }
    ]
  },
  {
    "id": 25,
    "fieldName": "Door Type",
    "title": "Please select the door type.",
    "options": [
      {
        "label": "Plain Door",
        "value": "Plain Door",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "Glazed Door",
        "value": "Glazed Door",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 26,
    "fieldName": "Depth",
    "title": "Please select the cabinet depth.",
    "options": [
      {
        "label": "250 mm",
        "value": "250 mm",
        "nextQuestions": [
          27
        ]
      },
      {
        "label": "300 mm",
        "value": "300 mm",
        "nextQuestions": [
          28
        ]
      }
    ]
  },
  {
    "id": 27,
    "fieldName": "Door Type",
    "title": "Please select the door type.",
    "options": [
      {
        "label": "Plain Door",
        "value": "Plain Door",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "Glazed Door",
        "value": "Glazed Door",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 28,
    "fieldName": "Door Type",
    "title": "Please select the door type.",
    "options": [
      {
        "label": "Plain Door",
        "value": "Plain Door",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "Glazed Door",
        "value": "Glazed Door",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 29,
    "fieldName": "Width",
    "title": "Please select the cabinet width.",
    "options": [
      {
        "label": "400 mm",
        "value": "400 mm",
        "nextQuestions": [
          30
        ]
      },
      {
        "label": "500 mm",
        "value": "500 mm",
        "nextQuestions": [
          34
        ]
      }
    ]
  },
  {
    "id": 30,
    "fieldName": "Depth",
    "title": "Please select the cabinet depth.",
    "options": [
      {
        "label": "150 mm",
        "value": "150 mm",
        "nextQuestions": [
          31
        ]
      },
      {
        "label": "200 mm",
        "value": "200 mm",
        "nextQuestions": [
          32
        ]
      },
      {
        "label": "250 mm",
        "value": "250 mm",
        "nextQuestions": [
          33
        ]
      }
    ]
  },
  {
    "id": 31,
    "fieldName": "Door Type",
    "title": "Please select the door type.",
    "options": [
      {
        "label": "Plain Door",
        "value": "Plain Door",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "Glazed Door",
        "value": "Glazed Door",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 32,
    "fieldName": "Door Type",
    "title": "Please select the door type.",
    "options": [
      {
        "label": "Plain Door",
        "value": "Plain Door",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "Glazed Door",
        "value": "Glazed Door",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 33,
    "fieldName": "Door Type",
    "title": "Please select the door type.",
    "options": [
      {
        "label": "Plain Door",
        "value": "Plain Door",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "Glazed Door",
        "value": "Glazed Door",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 34,
    "fieldName": "Depth",
    "title": "Please select the cabinet depth.",
    "options": [
      {
        "label": "250 mm",
        "value": "250 mm",
        "nextQuestions": [
          35
        ]
      }
    ]
  },
  {
    "id": 35,
    "fieldName": "Door Type",
    "title": "Please select the door type.",
    "options": [
      {
        "label": "Plain Door",
        "value": "Plain Door",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "Glazed Door",
        "value": "Glazed Door",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 36,
    "fieldName": "Width",
    "title": "Please select the cabinet width.",
    "options": [
      {
        "label": "400 mm",
        "value": "400 mm",
        "nextQuestions": [
          37
        ]
      },
      {
        "label": "500 mm",
        "value": "500 mm",
        "nextQuestions": [
          41
        ]
      },
      {
        "label": "600 mm",
        "value": "600 mm",
        "nextQuestions": [
          45
        ]
      },
      {
        "label": "800 mm",
        "value": "800 mm",
        "nextQuestions": [
          49
        ]
      }
    ]
  },
  {
    "id": 37,
    "fieldName": "Depth",
    "title": "Please select the cabinet depth.",
    "options": [
      {
        "label": "150 mm",
        "value": "150 mm",
        "nextQuestions": [
          38
        ]
      },
      {
        "label": "200 mm",
        "value": "200 mm",
        "nextQuestions": [
          39
        ]
      },
      {
        "label": "250 mm",
        "value": "250 mm",
        "nextQuestions": [
          40
        ]
      }
    ]
  },
  {
    "id": 38,
    "fieldName": "Door Type",
    "title": "Please select the door type.",
    "options": [
      {
        "label": "Plain Door",
        "value": "Plain Door",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "Glazed Door",
        "value": "Glazed Door",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 39,
    "fieldName": "Door Type",
    "title": "Please select the door type.",
    "options": [
      {
        "label": "Plain Door",
        "value": "Plain Door",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "Glazed Door",
        "value": "Glazed Door",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 40,
    "fieldName": "Door Type",
    "title": "Please select the door type.",
    "options": [
      {
        "label": "Plain Door",
        "value": "Plain Door",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "Glazed Door",
        "value": "Glazed Door",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 41,
    "fieldName": "Depth",
    "title": "Please select the cabinet depth.",
    "options": [
      {
        "label": "150 mm",
        "value": "150 mm",
        "nextQuestions": [
          42
        ]
      },
      {
        "label": "200 mm",
        "value": "200 mm",
        "nextQuestions": [
          43
        ]
      },
      {
        "label": "250 mm",
        "value": "250 mm",
        "nextQuestions": [
          44
        ]
      }
    ]
  },
  {
    "id": 42,
    "fieldName": "Door Type",
    "title": "Please select the door type.",
    "options": [
      {
        "label": "Plain Door",
        "value": "Plain Door",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "Glazed Door",
        "value": "Glazed Door",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 43,
    "fieldName": "Door Type",
    "title": "Please select the door type.",
    "options": [
      {
        "label": "Plain Door",
        "value": "Plain Door",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "Glazed Door",
        "value": "Glazed Door",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 44,
    "fieldName": "Door Type",
    "title": "Please select the door type.",
    "options": [
      {
        "label": "Plain Door",
        "value": "Plain Door",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "Glazed Door",
        "value": "Glazed Door",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 45,
    "fieldName": "Depth",
    "title": "Please select the cabinet depth.",
    "options": [
      {
        "label": "200 mm",
        "value": "200 mm",
        "nextQuestions": [
          46
        ]
      },
      {
        "label": "250 mm",
        "value": "250 mm",
        "nextQuestions": [
          47
        ]
      },
      {
        "label": "300 mm",
        "value": "300 mm",
        "nextQuestions": [
          48
        ]
      }
    ]
  },
  {
    "id": 46,
    "fieldName": "Door Type",
    "title": "Please select the door type.",
    "options": [
      {
        "label": "Plain Door",
        "value": "Plain Door",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "Glazed Door",
        "value": "Glazed Door",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 47,
    "fieldName": "Door Type",
    "title": "Please select the door type.",
    "options": [
      {
        "label": "Plain Door",
        "value": "Plain Door",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "Glazed Door",
        "value": "Glazed Door",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 48,
    "fieldName": "Door Type",
    "title": "Please select the door type.",
    "options": [
      {
        "label": "Plain Door",
        "value": "Plain Door",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "Glazed Door",
        "value": "Glazed Door",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 49,
    "fieldName": "Depth",
    "title": "Please select the cabinet depth.",
    "options": [
      {
        "label": "300 mm",
        "value": "300 mm",
        "nextQuestions": [
          50
        ]
      }
    ]
  },
  {
    "id": 50,
    "fieldName": "Door Type",
    "title": "Please select the door type.",
    "options": [
      {
        "label": "Plain Door",
        "value": "Plain Door",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "Glazed Door",
        "value": "Glazed Door",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 51,
    "fieldName": "Width",
    "title": "Please select the cabinet width.",
    "options": [
      {
        "label": "500 mm",
        "value": "500 mm",
        "nextQuestions": [
          52
        ]
      }
    ]
  },
  {
    "id": 52,
    "fieldName": "Depth",
    "title": "Please select the cabinet depth.",
    "options": [
      {
        "label": "200 mm",
        "value": "200 mm",
        "nextQuestions": [
          53
        ]
      },
      {
        "label": "250 mm",
        "value": "250 mm",
        "nextQuestions": [
          54
        ]
      }
    ]
  },
  {
    "id": 53,
    "fieldName": "Door Type",
    "title": "Please select the door type.",
    "options": [
      {
        "label": "Plain Door",
        "value": "Plain Door",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "Glazed Door",
        "value": "Glazed Door",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 54,
    "fieldName": "Door Type",
    "title": "Please select the door type.",
    "options": [
      {
        "label": "Plain Door",
        "value": "Plain Door",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "Glazed Door",
        "value": "Glazed Door",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 55,
    "fieldName": "Width",
    "title": "Please select the cabinet width.",
    "options": [
      {
        "label": "600 mm",
        "value": "600 mm",
        "nextQuestions": [
          56
        ]
      },
      {
        "label": "800 mm",
        "value": "800 mm",
        "nextQuestions": [
          61
        ]
      },
      {
        "label": "1000 mm",
        "value": "1000 mm",
        "nextQuestions": [
          64
        ]
      },
      {
        "label": "1200 mm",
        "value": "1200 mm",
        "nextQuestions": [
          66
        ]
      }
    ]
  },
  {
    "id": 56,
    "fieldName": "Depth",
    "title": "Please select the cabinet depth.",
    "options": [
      {
        "label": "200 mm",
        "value": "200 mm",
        "nextQuestions": [
          57
        ]
      },
      {
        "label": "250 mm",
        "value": "250 mm",
        "nextQuestions": [
          58
        ]
      },
      {
        "label": "300 mm",
        "value": "300 mm",
        "nextQuestions": [
          59
        ]
      },
      {
        "label": "400 mm",
        "value": "400 mm",
        "nextQuestions": [
          60
        ]
      }
    ]
  },
  {
    "id": 57,
    "fieldName": "Door Type",
    "title": "Please select the door type.",
    "options": [
      {
        "label": "Plain Door",
        "value": "Plain Door",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "Glazed Door",
        "value": "Glazed Door",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 58,
    "fieldName": "Door Type",
    "title": "Please select the door type.",
    "options": [
      {
        "label": "Plain Door",
        "value": "Plain Door",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "Glazed Door",
        "value": "Glazed Door",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 59,
    "fieldName": "Door Type",
    "title": "Please select the door type.",
    "options": [
      {
        "label": "Plain Door",
        "value": "Plain Door",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "Glazed Door",
        "value": "Glazed Door",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 60,
    "fieldName": "Door Type",
    "title": "Please select the door type.",
    "options": [
      {
        "label": "Plain Door",
        "value": "Plain Door",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "Glazed Door",
        "value": "Glazed Door",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 61,
    "fieldName": "Depth",
    "title": "Please select the cabinet depth.",
    "options": [
      {
        "label": "200 mm",
        "value": "200 mm",
        "nextQuestions": [
          62
        ]
      },
      {
        "label": "300 mm",
        "value": "300 mm",
        "nextQuestions": [
          63
        ]
      }
    ]
  },
  {
    "id": 62,
    "fieldName": "Door Type",
    "title": "Please select the door type.",
    "options": [
      {
        "label": "Plain Door",
        "value": "Plain Door",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "Glazed Door",
        "value": "Glazed Door",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 63,
    "fieldName": "Door Type",
    "title": "Please select the door type.",
    "options": [
      {
        "label": "Plain Door",
        "value": "Plain Door",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "Glazed Door",
        "value": "Glazed Door",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 64,
    "fieldName": "Depth",
    "title": "Please select the cabinet depth.",
    "options": [
      {
        "label": "300 mm",
        "value": "300 mm",
        "nextQuestions": [
          65
        ]
      }
    ]
  },
  {
    "id": 65,
    "fieldName": "Door Type",
    "title": "Please select the door type.",
    "options": [
      {
        "label": "Plain Door",
        "value": "Plain Door",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 66,
    "fieldName": "Depth",
    "title": "Please select the cabinet depth.",
    "options": [
      {
        "label": "300 mm",
        "value": "300 mm",
        "nextQuestions": [
          67
        ]
      }
    ]
  },
  {
    "id": 67,
    "fieldName": "Door Type",
    "title": "Please select the door type.",
    "options": [
      {
        "label": "Plain Door",
        "value": "Plain Door",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 68,
    "fieldName": "Width",
    "title": "Please select the cabinet width.",
    "options": [
      {
        "label": "600 mm",
        "value": "600 mm",
        "nextQuestions": [
          69
        ]
      },
      {
        "label": "800 mm",
        "value": "800 mm",
        "nextQuestions": [
          73
        ]
      },
      {
        "label": "1000 mm",
        "value": "1000 mm",
        "nextQuestions": [
          77
        ]
      },
      {
        "label": "1200 mm",
        "value": "1200 mm",
        "nextQuestions": [
          79
        ]
      }
    ]
  },
  {
    "id": 69,
    "fieldName": "Depth",
    "title": "Please select the cabinet depth.",
    "options": [
      {
        "label": "250 mm",
        "value": "250 mm",
        "nextQuestions": [
          70
        ]
      },
      {
        "label": "300 mm",
        "value": "300 mm",
        "nextQuestions": [
          71
        ]
      },
      {
        "label": "400 mm",
        "value": "400 mm",
        "nextQuestions": [
          72
        ]
      }
    ]
  },
  {
    "id": 70,
    "fieldName": "Door Type",
    "title": "Please select the door type.",
    "options": [
      {
        "label": "Plain Door",
        "value": "Plain Door",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "Glazed Door",
        "value": "Glazed Door",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 71,
    "fieldName": "Door Type",
    "title": "Please select the door type.",
    "options": [
      {
        "label": "Plain Door",
        "value": "Plain Door",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "Glazed Door",
        "value": "Glazed Door",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 72,
    "fieldName": "Door Type",
    "title": "Please select the door type.",
    "options": [
      {
        "label": "Plain Door",
        "value": "Plain Door",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "Glazed Door",
        "value": "Glazed Door",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 73,
    "fieldName": "Depth",
    "title": "Please select the cabinet depth.",
    "options": [
      {
        "label": "250 mm",
        "value": "250 mm",
        "nextQuestions": [
          74
        ]
      },
      {
        "label": "300 mm",
        "value": "300 mm",
        "nextQuestions": [
          75
        ]
      },
      {
        "label": "400 mm",
        "value": "400 mm",
        "nextQuestions": [
          76
        ]
      }
    ]
  },
  {
    "id": 74,
    "fieldName": "Door Type",
    "title": "Please select the door type.",
    "options": [
      {
        "label": "Plain Door",
        "value": "Plain Door",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "Glazed Door",
        "value": "Glazed Door",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 75,
    "fieldName": "Door Type",
    "title": "Please select the door type.",
    "options": [
      {
        "label": "Plain Door",
        "value": "Plain Door",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "Glazed Door",
        "value": "Glazed Door",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 76,
    "fieldName": "Door Type",
    "title": "Please select the door type.",
    "options": [
      {
        "label": "Plain Door",
        "value": "Plain Door",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "Glazed Door",
        "value": "Glazed Door",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 77,
    "fieldName": "Depth",
    "title": "Please select the cabinet depth.",
    "options": [
      {
        "label": "300 mm",
        "value": "300 mm",
        "nextQuestions": [
          78
        ]
      }
    ]
  },
  {
    "id": 78,
    "fieldName": "Door Type",
    "title": "Please select the door type.",
    "options": [
      {
        "label": "Plain Door",
        "value": "Plain Door",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "Glazed Door",
        "value": "Glazed Door",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 79,
    "fieldName": "Depth",
    "title": "Please select the cabinet depth.",
    "options": [
      {
        "label": "300 mm",
        "value": "300 mm",
        "nextQuestions": [
          80
        ]
      },
      {
        "label": "400 mm",
        "value": "400 mm",
        "nextQuestions": [
          81
        ]
      }
    ]
  },
  {
    "id": 80,
    "fieldName": "Door Type",
    "title": "Please select the door type.",
    "options": [
      {
        "label": "Plain Door",
        "value": "Plain Door",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 81,
    "fieldName": "Door Type",
    "title": "Please select the door type.",
    "options": [
      {
        "label": "Plain Door",
        "value": "Plain Door",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 82,
    "fieldName": "Width",
    "title": "Please select the cabinet width.",
    "options": [
      {
        "label": "600 mm",
        "value": "600 mm",
        "nextQuestions": [
          83
        ]
      },
      {
        "label": "800 mm",
        "value": "800 mm",
        "nextQuestions": [
          86
        ]
      },
      {
        "label": "1000 mm",
        "value": "1000 mm",
        "nextQuestions": [
          89
        ]
      },
      {
        "label": "1200 mm",
        "value": "1200 mm",
        "nextQuestions": [
          92
        ]
      }
    ]
  },
  {
    "id": 83,
    "fieldName": "Depth",
    "title": "Please select the cabinet depth.",
    "options": [
      {
        "label": "300 mm",
        "value": "300 mm",
        "nextQuestions": [
          84
        ]
      },
      {
        "label": "400 mm",
        "value": "400 mm",
        "nextQuestions": [
          85
        ]
      }
    ]
  },
  {
    "id": 84,
    "fieldName": "Door Type",
    "title": "Please select the door type.",
    "options": [
      {
        "label": "Plain Door",
        "value": "Plain Door",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "Glazed Door",
        "value": "Glazed Door",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 85,
    "fieldName": "Door Type",
    "title": "Please select the door type.",
    "options": [
      {
        "label": "Plain Door",
        "value": "Plain Door",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "Glazed Door",
        "value": "Glazed Door",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 86,
    "fieldName": "Depth",
    "title": "Please select the cabinet depth.",
    "options": [
      {
        "label": "300 mm",
        "value": "300 mm",
        "nextQuestions": [
          87
        ]
      },
      {
        "label": "400 mm",
        "value": "400 mm",
        "nextQuestions": [
          88
        ]
      }
    ]
  },
  {
    "id": 87,
    "fieldName": "Door Type",
    "title": "Please select the door type.",
    "options": [
      {
        "label": "Plain Door",
        "value": "Plain Door",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "Glazed Door",
        "value": "Glazed Door",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 88,
    "fieldName": "Door Type",
    "title": "Please select the door type.",
    "options": [
      {
        "label": "Plain Door",
        "value": "Plain Door",
        "nextQuestions": [
          -1
        ]
      },
      {
        "label": "Glazed Door",
        "value": "Glazed Door",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 89,
    "fieldName": "Depth",
    "title": "Please select the cabinet depth.",
    "options": [
      {
        "label": "300 mm",
        "value": "300 mm",
        "nextQuestions": [
          90
        ]
      },
      {
        "label": "400 mm",
        "value": "400 mm",
        "nextQuestions": [
          91
        ]
      }
    ]
  },
  {
    "id": 90,
    "fieldName": "Door Type",
    "title": "Please select the door type.",
    "options": [
      {
        "label": "Plain Door",
        "value": "Plain Door",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 91,
    "fieldName": "Door Type",
    "title": "Please select the door type.",
    "options": [
      {
        "label": "Plain Door",
        "value": "Plain Door",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 92,
    "fieldName": "Depth",
    "title": "Please select the cabinet depth.",
    "options": [
      {
        "label": "300 mm",
        "value": "300 mm",
        "nextQuestions": [
          93
        ]
      },
      {
        "label": "400 mm",
        "value": "400 mm",
        "nextQuestions": [
          94
        ]
      }
    ]
  },
  {
    "id": 93,
    "fieldName": "Door Type",
    "title": "Please select the door type.",
    "options": [
      {
        "label": "Plain Door",
        "value": "Plain Door",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 94,
    "fieldName": "Door Type",
    "title": "Please select the door type.",
    "options": [
      {
        "label": "Plain Door",
        "value": "Plain Door",
        "nextQuestions": [
          -1
        ]
      }
    ]
  },
  {
    "id": 95,
    "fieldName": "Width",
    "title": "Please select the cabinet width.",
    "options": [
      {
        "label": "1000 mm",
        "value": "1000 mm",
        "nextQuestions": [
          96
        ]
      }
    ]
  },
  {
    "id": 96,
    "fieldName": "Depth",
    "title": "Please select the cabinet depth.",
    "options": [
      {
        "label": "300 mm",
        "value": "300 mm",
        "nextQuestions": [
          97
        ]
      }
    ]
  },
  {
    "id": 97,
    "fieldName": "Door Type",
    "title": "Please select the door type.",
    "options": [
      {
        "label": "Plain Door",
        "value": "Plain Door",
        "nextQuestions": [
          -1
        ]
      }
    ]
  }
];

export default Enclosure;

import { useEffect, useState } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import PulseLoader from "react-spinners/PulseLoader";
import { useTranslation } from "react-i18next";
import { ProductItemRow } from "./components";
import "./styles.scss";

function ProductItemContainer({
  MachineType,
  FeatureType,
  products,
  cartEnabled,
}) {
  const { t } = useTranslation();
  const [validProductIds, setValidProductIds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!cartEnabled) {
      return null;
    }

    setIsLoading(true);
    const references = products.map((x) => `&referenceId=${x.id}`).join("");
    axios
      .get(
        `https://industrial.sesmartconfig.com/filter/available?${references}`
      )
      .then((res) => {
        setValidProductIds(res.data.map((x) => x.id));
        setIsLoading(false);
      })
      .catch(() => {
        setValidProductIds([]);
        setIsLoading(false);
      });
    return () => {
      setValidProductIds([]);
      setIsLoading(false);
    };
  }, [JSON.stringify(products)]);

  return (
    <div className="product-item-container">
      {isLoading && (
        <div className="loading-container">
          <PulseLoader color="#3ab85d" loading={isLoading} />
        </div>
      )}
      {!isLoading &&
        products
          .filter(
            (p) => true || !cartEnabled || validProductIds.indexOf(p.id) >= 0
          )
          .map((product) => (
            <ProductItemRow
              MachineType={MachineType}
              FeatureType={FeatureType}
              key={`product-item-${product.title}`}
              title={t(product.title)}
              id={product.id}
              imageUrl={product.imageUrl}
              description={t(product.description)}
              showCartOption={cartEnabled}
            />
          ))}
    </div>
  );
}

ProductItemContainer.propTypes = {
  MachineType: PropTypes.string,
  FeatureType: PropTypes.string,
  products: PropTypes.array,
  cartEnabled: PropTypes.bool,
};

ProductItemContainer.defaultProps = {
  MachineType: "Machine_type",
  FeatureType: "Feature_type",
  products: [],
  cartEnabled: false,
};

export default ProductItemContainer;

/* eslint-disable */

const MoldedCaseCircuitBreaker = [
    {
        "id": 0,
        "fieldName": "Supply Voltage",
        "title": "Please select the supply voltage.",
        "options": [
            {
                "label": "380-415 VAC",
                "value": "380-415 VAC",
                "nextQuestions": [
                    1
                ]
            },
            {
                "label": "220-240 VAC",
                "value": "220-240 VAC",
                "nextQuestions": [
                    207
                ]
            },
            {
                "label": "440 VAC",
                "value": "440 VAC",
                "nextQuestions": [
                    413
                ]
            },
            {
                "label": "500 VAC",
                "value": "500 VAC",
                "nextQuestions": [
                    619
                ]
            },
            {
                "label": "525 VAC",
                "value": "525 VAC",
                "nextQuestions": [
                    745
                ]
            },
            {
                "label": "660-690 VAC",
                "value": "660-690 VAC",
                "nextQuestions": [
                    821
                ]
            }
        ]
    },
    {
        "id": 1,
        "fieldName": "Breaking Capacity",
        "title": "Please select the breaking capacity.",
        "options": [
            {
                "label": "16 kA",
                "value": "16 kA",
                "nextQuestions": [
                    2
                ]
            },
            {
                "label": "25 kA",
                "value": "25 kA",
                "nextQuestions": [
                    43
                ]
            },
            {
                "label": "36 kA",
                "value": "36 kA",
                "nextQuestions": [
                    84
                ]
            },
            {
                "label": "50 kA",
                "value": "50 kA",
                "nextQuestions": [
                    125
                ]
            },
            {
                "label": "70 kA",
                "value": "70 kA",
                "nextQuestions": [
                    166
                ]
            }
        ]
    },
    {
        "id": 2,
        "fieldName": "Current Rating",
        "title": "Please select the current rating.",
        "options": [
            {
                "label": "16 A",
                "value": "16 A",
                "nextQuestions": [
                    3
                ]
            },
            {
                "label": "25 A",
                "value": "25 A",
                "nextQuestions": [
                    7
                ]
            },
            {
                "label": "32 A",
                "value": "32 A",
                "nextQuestions": [
                    11
                ]
            },
            {
                "label": "40 A",
                "value": "40 A",
                "nextQuestions": [
                    15
                ]
            },
            {
                "label": "50 A",
                "value": "50 A",
                "nextQuestions": [
                    19
                ]
            },
            {
                "label": "63 A",
                "value": "63 A",
                "nextQuestions": [
                    23
                ]
            },
            {
                "label": "80 A",
                "value": "80 A",
                "nextQuestions": [
                    27
                ]
            },
            {
                "label": "100 A",
                "value": "100 A",
                "nextQuestions": [
                    31
                ]
            },
            {
                "label": "125 A",
                "value": "125 A",
                "nextQuestions": [
                    35
                ]
            },
            {
                "label": "160 A",
                "value": "160 A",
                "nextQuestions": [
                    39
                ]
            }
        ]
    },
    {
        "id": 3,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    4
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    5
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    6
                ]
            }
        ]
    },
    {
        "id": 4,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 5,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 6,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 7,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    8
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    9
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    10
                ]
            }
        ]
    },
    {
        "id": 8,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 9,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 10,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 11,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    12
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    13
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    14
                ]
            }
        ]
    },
    {
        "id": 12,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 13,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 14,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 15,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    16
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    17
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    18
                ]
            }
        ]
    },
    {
        "id": 16,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 17,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 18,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 19,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    20
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    21
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    22
                ]
            }
        ]
    },
    {
        "id": 20,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 21,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 22,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 23,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    24
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    25
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    26
                ]
            }
        ]
    },
    {
        "id": 24,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 25,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 26,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 27,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    28
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    29
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    30
                ]
            }
        ]
    },
    {
        "id": 28,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 29,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 30,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 31,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    32
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    33
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    34
                ]
            }
        ]
    },
    {
        "id": 32,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 33,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 34,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 35,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    36
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    37
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    38
                ]
            }
        ]
    },
    {
        "id": 36,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 37,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 38,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 39,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    40
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    41
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    42
                ]
            }
        ]
    },
    {
        "id": 40,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 41,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 42,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 43,
        "fieldName": "Current Rating",
        "title": "Please select the current rating.",
        "options": [
            {
                "label": "16 A",
                "value": "16 A",
                "nextQuestions": [
                    44
                ]
            },
            {
                "label": "25 A",
                "value": "25 A",
                "nextQuestions": [
                    48
                ]
            },
            {
                "label": "32 A",
                "value": "32 A",
                "nextQuestions": [
                    52
                ]
            },
            {
                "label": "40 A",
                "value": "40 A",
                "nextQuestions": [
                    56
                ]
            },
            {
                "label": "50 A",
                "value": "50 A",
                "nextQuestions": [
                    60
                ]
            },
            {
                "label": "63 A",
                "value": "63 A",
                "nextQuestions": [
                    64
                ]
            },
            {
                "label": "80 A",
                "value": "80 A",
                "nextQuestions": [
                    68
                ]
            },
            {
                "label": "100 A",
                "value": "100 A",
                "nextQuestions": [
                    72
                ]
            },
            {
                "label": "125 A",
                "value": "125 A",
                "nextQuestions": [
                    76
                ]
            },
            {
                "label": "160 A",
                "value": "160 A",
                "nextQuestions": [
                    80
                ]
            }
        ]
    },
    {
        "id": 44,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    45
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    46
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    47
                ]
            }
        ]
    },
    {
        "id": 45,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 46,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 47,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 48,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    49
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    50
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    51
                ]
            }
        ]
    },
    {
        "id": 49,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 50,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 51,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 52,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    53
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    54
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    55
                ]
            }
        ]
    },
    {
        "id": 53,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 54,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 55,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 56,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    57
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    58
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    59
                ]
            }
        ]
    },
    {
        "id": 57,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 58,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 59,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 60,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    61
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    62
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    63
                ]
            }
        ]
    },
    {
        "id": 61,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 62,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 63,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 64,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    65
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    66
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    67
                ]
            }
        ]
    },
    {
        "id": 65,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 66,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 67,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 68,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    69
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    70
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    71
                ]
            }
        ]
    },
    {
        "id": 69,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 70,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 71,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 72,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    73
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    74
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    75
                ]
            }
        ]
    },
    {
        "id": 73,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 74,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 75,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 76,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    77
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    78
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    79
                ]
            }
        ]
    },
    {
        "id": 77,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 78,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 79,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 80,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    81
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    82
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    83
                ]
            }
        ]
    },
    {
        "id": 81,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 82,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 83,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 84,
        "fieldName": "Current Rating",
        "title": "Please select the current rating.",
        "options": [
            {
                "label": "16 A",
                "value": "16 A",
                "nextQuestions": [
                    85
                ]
            },
            {
                "label": "25 A",
                "value": "25 A",
                "nextQuestions": [
                    89
                ]
            },
            {
                "label": "32 A",
                "value": "32 A",
                "nextQuestions": [
                    93
                ]
            },
            {
                "label": "40 A",
                "value": "40 A",
                "nextQuestions": [
                    97
                ]
            },
            {
                "label": "50 A",
                "value": "50 A",
                "nextQuestions": [
                    101
                ]
            },
            {
                "label": "63 A",
                "value": "63 A",
                "nextQuestions": [
                    105
                ]
            },
            {
                "label": "80 A",
                "value": "80 A",
                "nextQuestions": [
                    109
                ]
            },
            {
                "label": "100 A",
                "value": "100 A",
                "nextQuestions": [
                    113
                ]
            },
            {
                "label": "125 A",
                "value": "125 A",
                "nextQuestions": [
                    117
                ]
            },
            {
                "label": "160 A",
                "value": "160 A",
                "nextQuestions": [
                    121
                ]
            }
        ]
    },
    {
        "id": 85,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    86
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    87
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    88
                ]
            }
        ]
    },
    {
        "id": 86,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 87,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 88,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 89,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    90
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    91
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    92
                ]
            }
        ]
    },
    {
        "id": 90,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 91,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 92,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 93,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    94
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    95
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    96
                ]
            }
        ]
    },
    {
        "id": 94,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 95,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 96,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 97,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    98
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    99
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    100
                ]
            }
        ]
    },
    {
        "id": 98,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 99,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 100,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 101,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    102
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    103
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    104
                ]
            }
        ]
    },
    {
        "id": 102,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 103,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 104,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 105,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    106
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    107
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    108
                ]
            }
        ]
    },
    {
        "id": 106,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 107,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 108,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 109,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    110
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    111
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    112
                ]
            }
        ]
    },
    {
        "id": 110,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 111,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 112,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 113,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    114
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    115
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    116
                ]
            }
        ]
    },
    {
        "id": 114,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 115,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 116,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 117,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    118
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    119
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    120
                ]
            }
        ]
    },
    {
        "id": 118,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 119,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 120,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 121,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    122
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    123
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    124
                ]
            }
        ]
    },
    {
        "id": 122,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 123,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 124,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 125,
        "fieldName": "Current Rating",
        "title": "Please select the current rating.",
        "options": [
            {
                "label": "16 A",
                "value": "16 A",
                "nextQuestions": [
                    126
                ]
            },
            {
                "label": "25 A",
                "value": "25 A",
                "nextQuestions": [
                    130
                ]
            },
            {
                "label": "32 A",
                "value": "32 A",
                "nextQuestions": [
                    134
                ]
            },
            {
                "label": "40 A",
                "value": "40 A",
                "nextQuestions": [
                    138
                ]
            },
            {
                "label": "50 A",
                "value": "50 A",
                "nextQuestions": [
                    142
                ]
            },
            {
                "label": "63 A",
                "value": "63 A",
                "nextQuestions": [
                    146
                ]
            },
            {
                "label": "80 A",
                "value": "80 A",
                "nextQuestions": [
                    150
                ]
            },
            {
                "label": "100 A",
                "value": "100 A",
                "nextQuestions": [
                    154
                ]
            },
            {
                "label": "125 A",
                "value": "125 A",
                "nextQuestions": [
                    158
                ]
            },
            {
                "label": "160 A",
                "value": "160 A",
                "nextQuestions": [
                    162
                ]
            }
        ]
    },
    {
        "id": 126,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    127
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    128
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    129
                ]
            }
        ]
    },
    {
        "id": 127,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 128,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 129,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 130,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    131
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    132
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    133
                ]
            }
        ]
    },
    {
        "id": 131,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 132,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 133,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 134,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    135
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    136
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    137
                ]
            }
        ]
    },
    {
        "id": 135,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 136,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 137,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 138,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    139
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    140
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    141
                ]
            }
        ]
    },
    {
        "id": 139,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 140,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 141,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 142,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    143
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    144
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    145
                ]
            }
        ]
    },
    {
        "id": 143,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 144,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 145,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 146,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    147
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    148
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    149
                ]
            }
        ]
    },
    {
        "id": 147,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 148,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 149,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 150,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    151
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    152
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    153
                ]
            }
        ]
    },
    {
        "id": 151,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 152,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 153,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 154,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    155
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    156
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    157
                ]
            }
        ]
    },
    {
        "id": 155,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 156,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 157,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 158,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    159
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    160
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    161
                ]
            }
        ]
    },
    {
        "id": 159,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 160,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 161,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 162,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    163
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    164
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    165
                ]
            }
        ]
    },
    {
        "id": 163,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 164,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 165,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 166,
        "fieldName": "Current Rating",
        "title": "Please select the current rating.",
        "options": [
            {
                "label": "16 A",
                "value": "16 A",
                "nextQuestions": [
                    167
                ]
            },
            {
                "label": "25 A",
                "value": "25 A",
                "nextQuestions": [
                    171
                ]
            },
            {
                "label": "32 A",
                "value": "32 A",
                "nextQuestions": [
                    175
                ]
            },
            {
                "label": "40 A",
                "value": "40 A",
                "nextQuestions": [
                    179
                ]
            },
            {
                "label": "50 A",
                "value": "50 A",
                "nextQuestions": [
                    183
                ]
            },
            {
                "label": "63 A",
                "value": "63 A",
                "nextQuestions": [
                    187
                ]
            },
            {
                "label": "80 A",
                "value": "80 A",
                "nextQuestions": [
                    191
                ]
            },
            {
                "label": "100 A",
                "value": "100 A",
                "nextQuestions": [
                    195
                ]
            },
            {
                "label": "125 A",
                "value": "125 A",
                "nextQuestions": [
                    199
                ]
            },
            {
                "label": "160 A",
                "value": "160 A",
                "nextQuestions": [
                    203
                ]
            }
        ]
    },
    {
        "id": 167,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    168
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    169
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    170
                ]
            }
        ]
    },
    {
        "id": 168,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 169,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 170,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 171,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    172
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    173
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    174
                ]
            }
        ]
    },
    {
        "id": 172,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 173,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 174,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 175,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    176
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    177
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    178
                ]
            }
        ]
    },
    {
        "id": 176,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 177,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 178,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 179,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    180
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    181
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    182
                ]
            }
        ]
    },
    {
        "id": 180,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 181,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 182,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 183,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    184
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    185
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    186
                ]
            }
        ]
    },
    {
        "id": 184,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 185,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 186,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 187,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    188
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    189
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    190
                ]
            }
        ]
    },
    {
        "id": 188,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 189,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 190,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 191,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    192
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    193
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    194
                ]
            }
        ]
    },
    {
        "id": 192,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 193,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 194,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 195,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    196
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    197
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    198
                ]
            }
        ]
    },
    {
        "id": 196,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 197,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 198,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 199,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    200
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    201
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    202
                ]
            }
        ]
    },
    {
        "id": 200,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 201,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 202,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 203,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    204
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    205
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    206
                ]
            }
        ]
    },
    {
        "id": 204,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 205,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 206,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 207,
        "fieldName": "Breaking Capacity",
        "title": "Please select the breaking capacity.",
        "options": [
            {
                "label": "25 kA",
                "value": "25 kA",
                "nextQuestions": [
                    208
                ]
            },
            {
                "label": "50 kA",
                "value": "50 kA",
                "nextQuestions": [
                    249
                ]
            },
            {
                "label": "85 kA",
                "value": "85 kA",
                "nextQuestions": [
                    290
                ]
            },
            {
                "label": "90 kA",
                "value": "90 kA",
                "nextQuestions": [
                    331
                ]
            },
            {
                "label": "100 kA",
                "value": "100 kA",
                "nextQuestions": [
                    372
                ]
            }
        ]
    },
    {
        "id": 208,
        "fieldName": "Current Rating",
        "title": "Please select the current rating.",
        "options": [
            {
                "label": "16 A",
                "value": "16 A",
                "nextQuestions": [
                    209
                ]
            },
            {
                "label": "25 A",
                "value": "25 A",
                "nextQuestions": [
                    213
                ]
            },
            {
                "label": "32 A",
                "value": "32 A",
                "nextQuestions": [
                    217
                ]
            },
            {
                "label": "40 A",
                "value": "40 A",
                "nextQuestions": [
                    221
                ]
            },
            {
                "label": "50 A",
                "value": "50 A",
                "nextQuestions": [
                    225
                ]
            },
            {
                "label": "63 A",
                "value": "63 A",
                "nextQuestions": [
                    229
                ]
            },
            {
                "label": "80 A",
                "value": "80 A",
                "nextQuestions": [
                    233
                ]
            },
            {
                "label": "100 A",
                "value": "100 A",
                "nextQuestions": [
                    237
                ]
            },
            {
                "label": "125 A",
                "value": "125 A",
                "nextQuestions": [
                    241
                ]
            },
            {
                "label": "160 A",
                "value": "160 A",
                "nextQuestions": [
                    245
                ]
            }
        ]
    },
    {
        "id": 209,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    210
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    211
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    212
                ]
            }
        ]
    },
    {
        "id": 210,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 211,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 212,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 213,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    214
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    215
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    216
                ]
            }
        ]
    },
    {
        "id": 214,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 215,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 216,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 217,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    218
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    219
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    220
                ]
            }
        ]
    },
    {
        "id": 218,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 219,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 220,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 221,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    222
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    223
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    224
                ]
            }
        ]
    },
    {
        "id": 222,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 223,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 224,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 225,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    226
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    227
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    228
                ]
            }
        ]
    },
    {
        "id": 226,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 227,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 228,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 229,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    230
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    231
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    232
                ]
            }
        ]
    },
    {
        "id": 230,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 231,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 232,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 233,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    234
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    235
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    236
                ]
            }
        ]
    },
    {
        "id": 234,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 235,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 236,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 237,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    238
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    239
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    240
                ]
            }
        ]
    },
    {
        "id": 238,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 239,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 240,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 241,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    242
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    243
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    244
                ]
            }
        ]
    },
    {
        "id": 242,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 243,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 244,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 245,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    246
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    247
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    248
                ]
            }
        ]
    },
    {
        "id": 246,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 247,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 248,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 249,
        "fieldName": "Current Rating",
        "title": "Please select the current rating.",
        "options": [
            {
                "label": "16 A",
                "value": "16 A",
                "nextQuestions": [
                    250
                ]
            },
            {
                "label": "25 A",
                "value": "25 A",
                "nextQuestions": [
                    254
                ]
            },
            {
                "label": "32 A",
                "value": "32 A",
                "nextQuestions": [
                    258
                ]
            },
            {
                "label": "40 A",
                "value": "40 A",
                "nextQuestions": [
                    262
                ]
            },
            {
                "label": "50 A",
                "value": "50 A",
                "nextQuestions": [
                    266
                ]
            },
            {
                "label": "63 A",
                "value": "63 A",
                "nextQuestions": [
                    270
                ]
            },
            {
                "label": "80 A",
                "value": "80 A",
                "nextQuestions": [
                    274
                ]
            },
            {
                "label": "100 A",
                "value": "100 A",
                "nextQuestions": [
                    278
                ]
            },
            {
                "label": "125 A",
                "value": "125 A",
                "nextQuestions": [
                    282
                ]
            },
            {
                "label": "160 A",
                "value": "160 A",
                "nextQuestions": [
                    286
                ]
            }
        ]
    },
    {
        "id": 250,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    251
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    252
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    253
                ]
            }
        ]
    },
    {
        "id": 251,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 252,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 253,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 254,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    255
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    256
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    257
                ]
            }
        ]
    },
    {
        "id": 255,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 256,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 257,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 258,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    259
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    260
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    261
                ]
            }
        ]
    },
    {
        "id": 259,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 260,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 261,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 262,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    263
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    264
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    265
                ]
            }
        ]
    },
    {
        "id": 263,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 264,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 265,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 266,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    267
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    268
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    269
                ]
            }
        ]
    },
    {
        "id": 267,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 268,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 269,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 270,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    271
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    272
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    273
                ]
            }
        ]
    },
    {
        "id": 271,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 272,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 273,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 274,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    275
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    276
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    277
                ]
            }
        ]
    },
    {
        "id": 275,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 276,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 277,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 278,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    279
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    280
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    281
                ]
            }
        ]
    },
    {
        "id": 279,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 280,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 281,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 282,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    283
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    284
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    285
                ]
            }
        ]
    },
    {
        "id": 283,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 284,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 285,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 286,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    287
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    288
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    289
                ]
            }
        ]
    },
    {
        "id": 287,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 288,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 289,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 290,
        "fieldName": "Current Rating",
        "title": "Please select the current rating.",
        "options": [
            {
                "label": "16 A",
                "value": "16 A",
                "nextQuestions": [
                    291
                ]
            },
            {
                "label": "25 A",
                "value": "25 A",
                "nextQuestions": [
                    295
                ]
            },
            {
                "label": "32 A",
                "value": "32 A",
                "nextQuestions": [
                    299
                ]
            },
            {
                "label": "40 A",
                "value": "40 A",
                "nextQuestions": [
                    303
                ]
            },
            {
                "label": "50 A",
                "value": "50 A",
                "nextQuestions": [
                    307
                ]
            },
            {
                "label": "63 A",
                "value": "63 A",
                "nextQuestions": [
                    311
                ]
            },
            {
                "label": "80 A",
                "value": "80 A",
                "nextQuestions": [
                    315
                ]
            },
            {
                "label": "100 A",
                "value": "100 A",
                "nextQuestions": [
                    319
                ]
            },
            {
                "label": "125 A",
                "value": "125 A",
                "nextQuestions": [
                    323
                ]
            },
            {
                "label": "160 A",
                "value": "160 A",
                "nextQuestions": [
                    327
                ]
            }
        ]
    },
    {
        "id": 291,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    292
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    293
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    294
                ]
            }
        ]
    },
    {
        "id": 292,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 293,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 294,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 295,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    296
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    297
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    298
                ]
            }
        ]
    },
    {
        "id": 296,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 297,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 298,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 299,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    300
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    301
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    302
                ]
            }
        ]
    },
    {
        "id": 300,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 301,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 302,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 303,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    304
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    305
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    306
                ]
            }
        ]
    },
    {
        "id": 304,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 305,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 306,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 307,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    308
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    309
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    310
                ]
            }
        ]
    },
    {
        "id": 308,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 309,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 310,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 311,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    312
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    313
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    314
                ]
            }
        ]
    },
    {
        "id": 312,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 313,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 314,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 315,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    316
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    317
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    318
                ]
            }
        ]
    },
    {
        "id": 316,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 317,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 318,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 319,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    320
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    321
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    322
                ]
            }
        ]
    },
    {
        "id": 320,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 321,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 322,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 323,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    324
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    325
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    326
                ]
            }
        ]
    },
    {
        "id": 324,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 325,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 326,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 327,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    328
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    329
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    330
                ]
            }
        ]
    },
    {
        "id": 328,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 329,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 330,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 331,
        "fieldName": "Current Rating",
        "title": "Please select the current rating.",
        "options": [
            {
                "label": "16 A",
                "value": "16 A",
                "nextQuestions": [
                    332
                ]
            },
            {
                "label": "25 A",
                "value": "25 A",
                "nextQuestions": [
                    336
                ]
            },
            {
                "label": "32 A",
                "value": "32 A",
                "nextQuestions": [
                    340
                ]
            },
            {
                "label": "40 A",
                "value": "40 A",
                "nextQuestions": [
                    344
                ]
            },
            {
                "label": "50 A",
                "value": "50 A",
                "nextQuestions": [
                    348
                ]
            },
            {
                "label": "63 A",
                "value": "63 A",
                "nextQuestions": [
                    352
                ]
            },
            {
                "label": "80 A",
                "value": "80 A",
                "nextQuestions": [
                    356
                ]
            },
            {
                "label": "100 A",
                "value": "100 A",
                "nextQuestions": [
                    360
                ]
            },
            {
                "label": "125 A",
                "value": "125 A",
                "nextQuestions": [
                    364
                ]
            },
            {
                "label": "160 A",
                "value": "160 A",
                "nextQuestions": [
                    368
                ]
            }
        ]
    },
    {
        "id": 332,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    333
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    334
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    335
                ]
            }
        ]
    },
    {
        "id": 333,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 334,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 335,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 336,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    337
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    338
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    339
                ]
            }
        ]
    },
    {
        "id": 337,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 338,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 339,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 340,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    341
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    342
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    343
                ]
            }
        ]
    },
    {
        "id": 341,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 342,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 343,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 344,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    345
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    346
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    347
                ]
            }
        ]
    },
    {
        "id": 345,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 346,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 347,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 348,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    349
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    350
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    351
                ]
            }
        ]
    },
    {
        "id": 349,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 350,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 351,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 352,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    353
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    354
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    355
                ]
            }
        ]
    },
    {
        "id": 353,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 354,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 355,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 356,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    357
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    358
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    359
                ]
            }
        ]
    },
    {
        "id": 357,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 358,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 359,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 360,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    361
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    362
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    363
                ]
            }
        ]
    },
    {
        "id": 361,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 362,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 363,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 364,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    365
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    366
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    367
                ]
            }
        ]
    },
    {
        "id": 365,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 366,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 367,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 368,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    369
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    370
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    371
                ]
            }
        ]
    },
    {
        "id": 369,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 370,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 371,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 372,
        "fieldName": "Current Rating",
        "title": "Please select the current rating.",
        "options": [
            {
                "label": "16 A",
                "value": "16 A",
                "nextQuestions": [
                    373
                ]
            },
            {
                "label": "25 A",
                "value": "25 A",
                "nextQuestions": [
                    377
                ]
            },
            {
                "label": "32 A",
                "value": "32 A",
                "nextQuestions": [
                    381
                ]
            },
            {
                "label": "40 A",
                "value": "40 A",
                "nextQuestions": [
                    385
                ]
            },
            {
                "label": "50 A",
                "value": "50 A",
                "nextQuestions": [
                    389
                ]
            },
            {
                "label": "63 A",
                "value": "63 A",
                "nextQuestions": [
                    393
                ]
            },
            {
                "label": "80 A",
                "value": "80 A",
                "nextQuestions": [
                    397
                ]
            },
            {
                "label": "100 A",
                "value": "100 A",
                "nextQuestions": [
                    401
                ]
            },
            {
                "label": "125 A",
                "value": "125 A",
                "nextQuestions": [
                    405
                ]
            },
            {
                "label": "160 A",
                "value": "160 A",
                "nextQuestions": [
                    409
                ]
            }
        ]
    },
    {
        "id": 373,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    374
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    375
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    376
                ]
            }
        ]
    },
    {
        "id": 374,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 375,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 376,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 377,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    378
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    379
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    380
                ]
            }
        ]
    },
    {
        "id": 378,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 379,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 380,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 381,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    382
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    383
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    384
                ]
            }
        ]
    },
    {
        "id": 382,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 383,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 384,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 385,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    386
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    387
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    388
                ]
            }
        ]
    },
    {
        "id": 386,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 387,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 388,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 389,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    390
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    391
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    392
                ]
            }
        ]
    },
    {
        "id": 390,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 391,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 392,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 393,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    394
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    395
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    396
                ]
            }
        ]
    },
    {
        "id": 394,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 395,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 396,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 397,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    398
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    399
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    400
                ]
            }
        ]
    },
    {
        "id": 398,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 399,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 400,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 401,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    402
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    403
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    404
                ]
            }
        ]
    },
    {
        "id": 402,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 403,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 404,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 405,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    406
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    407
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    408
                ]
            }
        ]
    },
    {
        "id": 406,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 407,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 408,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 409,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    410
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    411
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    412
                ]
            }
        ]
    },
    {
        "id": 410,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 411,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 412,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 413,
        "fieldName": "Breaking Capacity",
        "title": "Please select the breaking capacity.",
        "options": [
            {
                "label": "10 kA",
                "value": "10 kA",
                "nextQuestions": [
                    414
                ]
            },
            {
                "label": "20 kA",
                "value": "20 kA",
                "nextQuestions": [
                    455
                ]
            },
            {
                "label": "35 kA",
                "value": "35 kA",
                "nextQuestions": [
                    496
                ]
            },
            {
                "label": "50 kA",
                "value": "50 kA",
                "nextQuestions": [
                    537
                ]
            },
            {
                "label": "65 kA",
                "value": "65 kA",
                "nextQuestions": [
                    578
                ]
            }
        ]
    },
    {
        "id": 414,
        "fieldName": "Current Rating",
        "title": "Please select the current rating.",
        "options": [
            {
                "label": "16 A",
                "value": "16 A",
                "nextQuestions": [
                    415
                ]
            },
            {
                "label": "25 A",
                "value": "25 A",
                "nextQuestions": [
                    419
                ]
            },
            {
                "label": "32 A",
                "value": "32 A",
                "nextQuestions": [
                    423
                ]
            },
            {
                "label": "40 A",
                "value": "40 A",
                "nextQuestions": [
                    427
                ]
            },
            {
                "label": "50 A",
                "value": "50 A",
                "nextQuestions": [
                    431
                ]
            },
            {
                "label": "63 A",
                "value": "63 A",
                "nextQuestions": [
                    435
                ]
            },
            {
                "label": "80 A",
                "value": "80 A",
                "nextQuestions": [
                    439
                ]
            },
            {
                "label": "100 A",
                "value": "100 A",
                "nextQuestions": [
                    443
                ]
            },
            {
                "label": "125 A",
                "value": "125 A",
                "nextQuestions": [
                    447
                ]
            },
            {
                "label": "160 A",
                "value": "160 A",
                "nextQuestions": [
                    451
                ]
            }
        ]
    },
    {
        "id": 415,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    416
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    417
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    418
                ]
            }
        ]
    },
    {
        "id": 416,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 417,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 418,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 419,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    420
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    421
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    422
                ]
            }
        ]
    },
    {
        "id": 420,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 421,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 422,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 423,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    424
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    425
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    426
                ]
            }
        ]
    },
    {
        "id": 424,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 425,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 426,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 427,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    428
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    429
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    430
                ]
            }
        ]
    },
    {
        "id": 428,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 429,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 430,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 431,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    432
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    433
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    434
                ]
            }
        ]
    },
    {
        "id": 432,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 433,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 434,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 435,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    436
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    437
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    438
                ]
            }
        ]
    },
    {
        "id": 436,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 437,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 438,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 439,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    440
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    441
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    442
                ]
            }
        ]
    },
    {
        "id": 440,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 441,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 442,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 443,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    444
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    445
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    446
                ]
            }
        ]
    },
    {
        "id": 444,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 445,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 446,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 447,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    448
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    449
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    450
                ]
            }
        ]
    },
    {
        "id": 448,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 449,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 450,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 451,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    452
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    453
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    454
                ]
            }
        ]
    },
    {
        "id": 452,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 453,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 454,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 455,
        "fieldName": "Current Rating",
        "title": "Please select the current rating.",
        "options": [
            {
                "label": "16 A",
                "value": "16 A",
                "nextQuestions": [
                    456
                ]
            },
            {
                "label": "25 A",
                "value": "25 A",
                "nextQuestions": [
                    460
                ]
            },
            {
                "label": "32 A",
                "value": "32 A",
                "nextQuestions": [
                    464
                ]
            },
            {
                "label": "40 A",
                "value": "40 A",
                "nextQuestions": [
                    468
                ]
            },
            {
                "label": "50 A",
                "value": "50 A",
                "nextQuestions": [
                    472
                ]
            },
            {
                "label": "63 A",
                "value": "63 A",
                "nextQuestions": [
                    476
                ]
            },
            {
                "label": "80 A",
                "value": "80 A",
                "nextQuestions": [
                    480
                ]
            },
            {
                "label": "100 A",
                "value": "100 A",
                "nextQuestions": [
                    484
                ]
            },
            {
                "label": "125 A",
                "value": "125 A",
                "nextQuestions": [
                    488
                ]
            },
            {
                "label": "160 A",
                "value": "160 A",
                "nextQuestions": [
                    492
                ]
            }
        ]
    },
    {
        "id": 456,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    457
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    458
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    459
                ]
            }
        ]
    },
    {
        "id": 457,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 458,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 459,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 460,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    461
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    462
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    463
                ]
            }
        ]
    },
    {
        "id": 461,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 462,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 463,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 464,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    465
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    466
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    467
                ]
            }
        ]
    },
    {
        "id": 465,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 466,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 467,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 468,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    469
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    470
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    471
                ]
            }
        ]
    },
    {
        "id": 469,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 470,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 471,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 472,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    473
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    474
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    475
                ]
            }
        ]
    },
    {
        "id": 473,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 474,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 475,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 476,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    477
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    478
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    479
                ]
            }
        ]
    },
    {
        "id": 477,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 478,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 479,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 480,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    481
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    482
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    483
                ]
            }
        ]
    },
    {
        "id": 481,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 482,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 483,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 484,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    485
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    486
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    487
                ]
            }
        ]
    },
    {
        "id": 485,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 486,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 487,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 488,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    489
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    490
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    491
                ]
            }
        ]
    },
    {
        "id": 489,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 490,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 491,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 492,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    493
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    494
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    495
                ]
            }
        ]
    },
    {
        "id": 493,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 494,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 495,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 496,
        "fieldName": "Current Rating",
        "title": "Please select the current rating.",
        "options": [
            {
                "label": "16 A",
                "value": "16 A",
                "nextQuestions": [
                    497
                ]
            },
            {
                "label": "25 A",
                "value": "25 A",
                "nextQuestions": [
                    501
                ]
            },
            {
                "label": "32 A",
                "value": "32 A",
                "nextQuestions": [
                    505
                ]
            },
            {
                "label": "40 A",
                "value": "40 A",
                "nextQuestions": [
                    509
                ]
            },
            {
                "label": "50 A",
                "value": "50 A",
                "nextQuestions": [
                    513
                ]
            },
            {
                "label": "63 A",
                "value": "63 A",
                "nextQuestions": [
                    517
                ]
            },
            {
                "label": "80 A",
                "value": "80 A",
                "nextQuestions": [
                    521
                ]
            },
            {
                "label": "100 A",
                "value": "100 A",
                "nextQuestions": [
                    525
                ]
            },
            {
                "label": "125 A",
                "value": "125 A",
                "nextQuestions": [
                    529
                ]
            },
            {
                "label": "160 A",
                "value": "160 A",
                "nextQuestions": [
                    533
                ]
            }
        ]
    },
    {
        "id": 497,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    498
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    499
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    500
                ]
            }
        ]
    },
    {
        "id": 498,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 499,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 500,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 501,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    502
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    503
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    504
                ]
            }
        ]
    },
    {
        "id": 502,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 503,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 504,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 505,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    506
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    507
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    508
                ]
            }
        ]
    },
    {
        "id": 506,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 507,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 508,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 509,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    510
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    511
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    512
                ]
            }
        ]
    },
    {
        "id": 510,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 511,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 512,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 513,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    514
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    515
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    516
                ]
            }
        ]
    },
    {
        "id": 514,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 515,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 516,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 517,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    518
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    519
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    520
                ]
            }
        ]
    },
    {
        "id": 518,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 519,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 520,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 521,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    522
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    523
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    524
                ]
            }
        ]
    },
    {
        "id": 522,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 523,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 524,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 525,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    526
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    527
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    528
                ]
            }
        ]
    },
    {
        "id": 526,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 527,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 528,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 529,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    530
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    531
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    532
                ]
            }
        ]
    },
    {
        "id": 530,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 531,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 532,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 533,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    534
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    535
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    536
                ]
            }
        ]
    },
    {
        "id": 534,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 535,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 536,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 537,
        "fieldName": "Current Rating",
        "title": "Please select the current rating.",
        "options": [
            {
                "label": "16 A",
                "value": "16 A",
                "nextQuestions": [
                    538
                ]
            },
            {
                "label": "25 A",
                "value": "25 A",
                "nextQuestions": [
                    542
                ]
            },
            {
                "label": "32 A",
                "value": "32 A",
                "nextQuestions": [
                    546
                ]
            },
            {
                "label": "40 A",
                "value": "40 A",
                "nextQuestions": [
                    550
                ]
            },
            {
                "label": "50 A",
                "value": "50 A",
                "nextQuestions": [
                    554
                ]
            },
            {
                "label": "63 A",
                "value": "63 A",
                "nextQuestions": [
                    558
                ]
            },
            {
                "label": "80 A",
                "value": "80 A",
                "nextQuestions": [
                    562
                ]
            },
            {
                "label": "100 A",
                "value": "100 A",
                "nextQuestions": [
                    566
                ]
            },
            {
                "label": "125 A",
                "value": "125 A",
                "nextQuestions": [
                    570
                ]
            },
            {
                "label": "160 A",
                "value": "160 A",
                "nextQuestions": [
                    574
                ]
            }
        ]
    },
    {
        "id": 538,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    539
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    540
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    541
                ]
            }
        ]
    },
    {
        "id": 539,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 540,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 541,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 542,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    543
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    544
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    545
                ]
            }
        ]
    },
    {
        "id": 543,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 544,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 545,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 546,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    547
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    548
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    549
                ]
            }
        ]
    },
    {
        "id": 547,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 548,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 549,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 550,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    551
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    552
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    553
                ]
            }
        ]
    },
    {
        "id": 551,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 552,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 553,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 554,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    555
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    556
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    557
                ]
            }
        ]
    },
    {
        "id": 555,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 556,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 557,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 558,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    559
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    560
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    561
                ]
            }
        ]
    },
    {
        "id": 559,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 560,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 561,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 562,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    563
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    564
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    565
                ]
            }
        ]
    },
    {
        "id": 563,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 564,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 565,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 566,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    567
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    568
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    569
                ]
            }
        ]
    },
    {
        "id": 567,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 568,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 569,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 570,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    571
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    572
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    573
                ]
            }
        ]
    },
    {
        "id": 571,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 572,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 573,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 574,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    575
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    576
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    577
                ]
            }
        ]
    },
    {
        "id": 575,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 576,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 577,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 578,
        "fieldName": "Current Rating",
        "title": "Please select the current rating.",
        "options": [
            {
                "label": "16 A",
                "value": "16 A",
                "nextQuestions": [
                    579
                ]
            },
            {
                "label": "25 A",
                "value": "25 A",
                "nextQuestions": [
                    583
                ]
            },
            {
                "label": "32 A",
                "value": "32 A",
                "nextQuestions": [
                    587
                ]
            },
            {
                "label": "40 A",
                "value": "40 A",
                "nextQuestions": [
                    591
                ]
            },
            {
                "label": "50 A",
                "value": "50 A",
                "nextQuestions": [
                    595
                ]
            },
            {
                "label": "63 A",
                "value": "63 A",
                "nextQuestions": [
                    599
                ]
            },
            {
                "label": "80 A",
                "value": "80 A",
                "nextQuestions": [
                    603
                ]
            },
            {
                "label": "100 A",
                "value": "100 A",
                "nextQuestions": [
                    607
                ]
            },
            {
                "label": "125 A",
                "value": "125 A",
                "nextQuestions": [
                    611
                ]
            },
            {
                "label": "160 A",
                "value": "160 A",
                "nextQuestions": [
                    615
                ]
            }
        ]
    },
    {
        "id": 579,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    580
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    581
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    582
                ]
            }
        ]
    },
    {
        "id": 580,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 581,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 582,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 583,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    584
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    585
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    586
                ]
            }
        ]
    },
    {
        "id": 584,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 585,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 586,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 587,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    588
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    589
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    590
                ]
            }
        ]
    },
    {
        "id": 588,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 589,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 590,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 591,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    592
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    593
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    594
                ]
            }
        ]
    },
    {
        "id": 592,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 593,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 594,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 595,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    596
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    597
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    598
                ]
            }
        ]
    },
    {
        "id": 596,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 597,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 598,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 599,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    600
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    601
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    602
                ]
            }
        ]
    },
    {
        "id": 600,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 601,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 602,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 603,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    604
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    605
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    606
                ]
            }
        ]
    },
    {
        "id": 604,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 605,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 606,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 607,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    608
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    609
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    610
                ]
            }
        ]
    },
    {
        "id": 608,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 609,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 610,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 611,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    612
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    613
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    614
                ]
            }
        ]
    },
    {
        "id": 612,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 613,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 614,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 615,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    616
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    617
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    618
                ]
            }
        ]
    },
    {
        "id": 616,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 617,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 618,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 619,
        "fieldName": "Breaking Capacity",
        "title": "Please select the breaking capacity.",
        "options": [
            {
                "label": "8 kA",
                "value": "8 kA",
                "nextQuestions": [
                    620
                ]
            },
            {
                "label": "10 kA",
                "value": "10 kA",
                "nextQuestions": [
                    645
                ]
            },
            {
                "label": "15 kA",
                "value": "15 kA",
                "nextQuestions": [
                    670
                ]
            },
            {
                "label": "25 kA",
                "value": "25 kA",
                "nextQuestions": [
                    695
                ]
            },
            {
                "label": "30 kA",
                "value": "30 kA",
                "nextQuestions": [
                    720
                ]
            }
        ]
    },
    {
        "id": 620,
        "fieldName": "Current Rating",
        "title": "Please select the current rating.",
        "options": [
            {
                "label": "16 A",
                "value": "16 A",
                "nextQuestions": [
                    621
                ]
            },
            {
                "label": "25 A",
                "value": "25 A",
                "nextQuestions": [
                    625
                ]
            },
            {
                "label": "32 A",
                "value": "32 A",
                "nextQuestions": [
                    629
                ]
            },
            {
                "label": "40 A",
                "value": "40 A",
                "nextQuestions": [
                    633
                ]
            },
            {
                "label": "50 A",
                "value": "50 A",
                "nextQuestions": [
                    637
                ]
            },
            {
                "label": "63 A",
                "value": "63 A",
                "nextQuestions": [
                    641
                ]
            }
        ]
    },
    {
        "id": 621,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    622
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    623
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    624
                ]
            }
        ]
    },
    {
        "id": 622,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 623,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 624,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 625,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    626
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    627
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    628
                ]
            }
        ]
    },
    {
        "id": 626,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 627,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 628,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 629,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    630
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    631
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    632
                ]
            }
        ]
    },
    {
        "id": 630,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 631,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 632,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 633,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    634
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    635
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    636
                ]
            }
        ]
    },
    {
        "id": 634,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 635,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 636,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 637,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    638
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    639
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    640
                ]
            }
        ]
    },
    {
        "id": 638,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 639,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 640,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 641,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    642
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    643
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    644
                ]
            }
        ]
    },
    {
        "id": 642,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 643,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 644,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 645,
        "fieldName": "Current Rating",
        "title": "Please select the current rating.",
        "options": [
            {
                "label": "16 A",
                "value": "16 A",
                "nextQuestions": [
                    646
                ]
            },
            {
                "label": "25 A",
                "value": "25 A",
                "nextQuestions": [
                    650
                ]
            },
            {
                "label": "32 A",
                "value": "32 A",
                "nextQuestions": [
                    654
                ]
            },
            {
                "label": "40 A",
                "value": "40 A",
                "nextQuestions": [
                    658
                ]
            },
            {
                "label": "50 A",
                "value": "50 A",
                "nextQuestions": [
                    662
                ]
            },
            {
                "label": "63 A",
                "value": "63 A",
                "nextQuestions": [
                    666
                ]
            }
        ]
    },
    {
        "id": 646,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    647
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    648
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    649
                ]
            }
        ]
    },
    {
        "id": 647,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 648,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 649,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 650,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    651
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    652
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    653
                ]
            }
        ]
    },
    {
        "id": 651,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 652,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 653,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 654,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    655
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    656
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    657
                ]
            }
        ]
    },
    {
        "id": 655,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 656,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 657,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 658,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    659
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    660
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    661
                ]
            }
        ]
    },
    {
        "id": 659,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 660,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 661,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 662,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    663
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    664
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    665
                ]
            }
        ]
    },
    {
        "id": 663,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 664,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 665,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 666,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    667
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    668
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    669
                ]
            }
        ]
    },
    {
        "id": 667,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 668,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 669,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 670,
        "fieldName": "Current Rating",
        "title": "Please select the current rating.",
        "options": [
            {
                "label": "16 A",
                "value": "16 A",
                "nextQuestions": [
                    671
                ]
            },
            {
                "label": "25 A",
                "value": "25 A",
                "nextQuestions": [
                    675
                ]
            },
            {
                "label": "32 A",
                "value": "32 A",
                "nextQuestions": [
                    679
                ]
            },
            {
                "label": "40 A",
                "value": "40 A",
                "nextQuestions": [
                    683
                ]
            },
            {
                "label": "50 A",
                "value": "50 A",
                "nextQuestions": [
                    687
                ]
            },
            {
                "label": "63 A",
                "value": "63 A",
                "nextQuestions": [
                    691
                ]
            }
        ]
    },
    {
        "id": 671,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    672
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    673
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    674
                ]
            }
        ]
    },
    {
        "id": 672,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 673,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 674,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 675,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    676
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    677
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    678
                ]
            }
        ]
    },
    {
        "id": 676,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 677,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 678,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 679,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    680
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    681
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    682
                ]
            }
        ]
    },
    {
        "id": 680,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 681,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 682,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 683,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    684
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    685
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    686
                ]
            }
        ]
    },
    {
        "id": 684,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 685,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 686,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 687,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    688
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    689
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    690
                ]
            }
        ]
    },
    {
        "id": 688,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 689,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 690,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 691,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    692
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    693
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    694
                ]
            }
        ]
    },
    {
        "id": 692,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 693,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 694,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 695,
        "fieldName": "Current Rating",
        "title": "Please select the current rating.",
        "options": [
            {
                "label": "16 A",
                "value": "16 A",
                "nextQuestions": [
                    696
                ]
            },
            {
                "label": "25 A",
                "value": "25 A",
                "nextQuestions": [
                    700
                ]
            },
            {
                "label": "32 A",
                "value": "32 A",
                "nextQuestions": [
                    704
                ]
            },
            {
                "label": "40 A",
                "value": "40 A",
                "nextQuestions": [
                    708
                ]
            },
            {
                "label": "50 A",
                "value": "50 A",
                "nextQuestions": [
                    712
                ]
            },
            {
                "label": "63 A",
                "value": "63 A",
                "nextQuestions": [
                    716
                ]
            }
        ]
    },
    {
        "id": 696,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    697
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    698
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    699
                ]
            }
        ]
    },
    {
        "id": 697,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 698,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 699,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 700,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    701
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    702
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    703
                ]
            }
        ]
    },
    {
        "id": 701,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 702,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 703,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 704,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    705
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    706
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    707
                ]
            }
        ]
    },
    {
        "id": 705,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 706,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 707,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 708,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    709
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    710
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    711
                ]
            }
        ]
    },
    {
        "id": 709,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 710,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 711,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 712,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    713
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    714
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    715
                ]
            }
        ]
    },
    {
        "id": 713,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 714,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 715,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 716,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    717
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    718
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    719
                ]
            }
        ]
    },
    {
        "id": 717,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 718,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 719,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 720,
        "fieldName": "Current Rating",
        "title": "Please select the current rating.",
        "options": [
            {
                "label": "16 A",
                "value": "16 A",
                "nextQuestions": [
                    721
                ]
            },
            {
                "label": "25 A",
                "value": "25 A",
                "nextQuestions": [
                    725
                ]
            },
            {
                "label": "32 A",
                "value": "32 A",
                "nextQuestions": [
                    729
                ]
            },
            {
                "label": "40 A",
                "value": "40 A",
                "nextQuestions": [
                    733
                ]
            },
            {
                "label": "50 A",
                "value": "50 A",
                "nextQuestions": [
                    737
                ]
            },
            {
                "label": "63 A",
                "value": "63 A",
                "nextQuestions": [
                    741
                ]
            }
        ]
    },
    {
        "id": 721,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    722
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    723
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    724
                ]
            }
        ]
    },
    {
        "id": 722,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 723,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 724,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 725,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    726
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    727
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    728
                ]
            }
        ]
    },
    {
        "id": 726,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 727,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 728,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 729,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    730
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    731
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    732
                ]
            }
        ]
    },
    {
        "id": 730,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 731,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 732,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 733,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    734
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    735
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    736
                ]
            }
        ]
    },
    {
        "id": 734,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 735,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 736,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 737,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    738
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    739
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    740
                ]
            }
        ]
    },
    {
        "id": 738,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 739,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 740,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 741,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    742
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    743
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    744
                ]
            }
        ]
    },
    {
        "id": 742,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 743,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 744,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 745,
        "fieldName": "Breaking Capacity",
        "title": "Please select the breaking capacity.",
        "options": [
            {
                "label": "10 kA",
                "value": "10 kA",
                "nextQuestions": [
                    746
                ]
            },
            {
                "label": "15 kA",
                "value": "15 kA",
                "nextQuestions": [
                    771
                ]
            },
            {
                "label": "22 kA",
                "value": "22 kA",
                "nextQuestions": [
                    796
                ]
            }
        ]
    },
    {
        "id": 746,
        "fieldName": "Current Rating",
        "title": "Please select the current rating.",
        "options": [
            {
                "label": "16 A",
                "value": "16 A",
                "nextQuestions": [
                    747
                ]
            },
            {
                "label": "25 A",
                "value": "25 A",
                "nextQuestions": [
                    751
                ]
            },
            {
                "label": "32 A",
                "value": "32 A",
                "nextQuestions": [
                    755
                ]
            },
            {
                "label": "40 A",
                "value": "40 A",
                "nextQuestions": [
                    759
                ]
            },
            {
                "label": "50 A",
                "value": "50 A",
                "nextQuestions": [
                    763
                ]
            },
            {
                "label": "63 A",
                "value": "63 A",
                "nextQuestions": [
                    767
                ]
            }
        ]
    },
    {
        "id": 747,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    748
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    749
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    750
                ]
            }
        ]
    },
    {
        "id": 748,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 749,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 750,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 751,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    752
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    753
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    754
                ]
            }
        ]
    },
    {
        "id": 752,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 753,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 754,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 755,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    756
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    757
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    758
                ]
            }
        ]
    },
    {
        "id": 756,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 757,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 758,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 759,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    760
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    761
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    762
                ]
            }
        ]
    },
    {
        "id": 760,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 761,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 762,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 763,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    764
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    765
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    766
                ]
            }
        ]
    },
    {
        "id": 764,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 765,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 766,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 767,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    768
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    769
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    770
                ]
            }
        ]
    },
    {
        "id": 768,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 769,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 770,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 771,
        "fieldName": "Current Rating",
        "title": "Please select the current rating.",
        "options": [
            {
                "label": "16 A",
                "value": "16 A",
                "nextQuestions": [
                    772
                ]
            },
            {
                "label": "25 A",
                "value": "25 A",
                "nextQuestions": [
                    776
                ]
            },
            {
                "label": "32 A",
                "value": "32 A",
                "nextQuestions": [
                    780
                ]
            },
            {
                "label": "40 A",
                "value": "40 A",
                "nextQuestions": [
                    784
                ]
            },
            {
                "label": "50 A",
                "value": "50 A",
                "nextQuestions": [
                    788
                ]
            },
            {
                "label": "63 A",
                "value": "63 A",
                "nextQuestions": [
                    792
                ]
            }
        ]
    },
    {
        "id": 772,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    773
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    774
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    775
                ]
            }
        ]
    },
    {
        "id": 773,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 774,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 775,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 776,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    777
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    778
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    779
                ]
            }
        ]
    },
    {
        "id": 777,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 778,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 779,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 780,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    781
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    782
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    783
                ]
            }
        ]
    },
    {
        "id": 781,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 782,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 783,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 784,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    785
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    786
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    787
                ]
            }
        ]
    },
    {
        "id": 785,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 786,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 787,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 788,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    789
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    790
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    791
                ]
            }
        ]
    },
    {
        "id": 789,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 790,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 791,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 792,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    793
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    794
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    795
                ]
            }
        ]
    },
    {
        "id": 793,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 794,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 795,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 796,
        "fieldName": "Current Rating",
        "title": "Please select the current rating.",
        "options": [
            {
                "label": "16 A",
                "value": "16 A",
                "nextQuestions": [
                    797
                ]
            },
            {
                "label": "25 A",
                "value": "25 A",
                "nextQuestions": [
                    801
                ]
            },
            {
                "label": "32 A",
                "value": "32 A",
                "nextQuestions": [
                    805
                ]
            },
            {
                "label": "40 A",
                "value": "40 A",
                "nextQuestions": [
                    809
                ]
            },
            {
                "label": "50 A",
                "value": "50 A",
                "nextQuestions": [
                    813
                ]
            },
            {
                "label": "63 A",
                "value": "63 A",
                "nextQuestions": [
                    817
                ]
            }
        ]
    },
    {
        "id": 797,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    798
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    799
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    800
                ]
            }
        ]
    },
    {
        "id": 798,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 799,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 800,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 801,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    802
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    803
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    804
                ]
            }
        ]
    },
    {
        "id": 802,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 803,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 804,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 805,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    806
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    807
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    808
                ]
            }
        ]
    },
    {
        "id": 806,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 807,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 808,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 809,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    810
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    811
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    812
                ]
            }
        ]
    },
    {
        "id": 810,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 811,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 812,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 813,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    814
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    815
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    816
                ]
            }
        ]
    },
    {
        "id": 814,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 815,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 816,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 817,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    818
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    819
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    820
                ]
            }
        ]
    },
    {
        "id": 818,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 819,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 820,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 821,
        "fieldName": "Breaking Capacity",
        "title": "Please select the breaking capacity.",
        "options": [
            {
                "label": "10 kA",
                "value": "10 kA",
                "nextQuestions": [
                    822
                ]
            }
        ]
    },
    {
        "id": 822,
        "fieldName": "Current Rating",
        "title": "Please select the current rating.",
        "options": [
            {
                "label": "16 A",
                "value": "16 A",
                "nextQuestions": [
                    823
                ]
            },
            {
                "label": "25 A",
                "value": "25 A",
                "nextQuestions": [
                    827
                ]
            },
            {
                "label": "32 A",
                "value": "32 A",
                "nextQuestions": [
                    831
                ]
            },
            {
                "label": "40 A",
                "value": "40 A",
                "nextQuestions": [
                    835
                ]
            },
            {
                "label": "50 A",
                "value": "50 A",
                "nextQuestions": [
                    839
                ]
            },
            {
                "label": "63 A",
                "value": "63 A",
                "nextQuestions": [
                    843
                ]
            }
        ]
    },
    {
        "id": 823,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    824
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    825
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    826
                ]
            }
        ]
    },
    {
        "id": 824,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 825,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 826,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 827,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    828
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    829
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    830
                ]
            }
        ]
    },
    {
        "id": 828,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 829,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 830,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 831,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    832
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    833
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    834
                ]
            }
        ]
    },
    {
        "id": 832,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 833,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 834,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 835,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    836
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    837
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    838
                ]
            }
        ]
    },
    {
        "id": 836,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 837,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 838,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 839,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    840
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    841
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    842
                ]
            }
        ]
    },
    {
        "id": 840,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 841,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 842,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 843,
        "fieldName": "No of Poles",
        "title": "Please select the no of poles.",
        "options": [
            {
                "label": "3-Poles Frame",
                "value": "3-Poles Frame",
                "nextQuestions": [
                    844
                ]
            },
            {
                "label": "4-Poles Frame w 3-Poles Detection",
                "value": "4-Poles Frame w 3-Poles Detection",
                "nextQuestions": [
                    845
                ]
            },
            {
                "label": "4-Poles Frame w 4-Poles Detection",
                "value": "4-Poles Frame w 4-Poles Detection",
                "nextQuestions": [
                    846
                ]
            }
        ]
    },
    {
        "id": 844,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 845,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    },
    {
        "id": 846,
        "fieldName": "Connector Type",
        "title": "Please select the connector type.",
        "options": [
            {
                "label": "EverLink™ connectors",
                "value": "EverLink™ connectors",
                "nextQuestions": [
                    -1
                ]
            },
            {
                "label": "Compression lug/busbar connectors",
                "value": "Compression lug/busbar connectors",
                "nextQuestions": [
                    -1
                ]
            }
        ]
    }
];

export default MoldedCaseCircuitBreaker;

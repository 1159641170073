/* eslint-disable */

const PushButton = [
  {
    'id': 0,
    'fieldName': 'Type',
    'title': 'Please select your push button type.',
    'options': [
      {
        'label': 'Pushbutton',
        'value': 'Pushbutton',
        'nextQuestions': [
          1
        ]
      },
      {
        'label': 'Selector Switch',
        'value': 'Selector Switch',
        'nextQuestions': [
          60
        ]
      },
      {
        'label': 'Illuminated Selector Switch',
        'value': 'Illuminated Selector Switch',
        'nextQuestions': [
          91
        ]
      },
      {
        'label': 'Pilot Light',
        'value': 'Pilot Light',
        'nextQuestions': [
          138
        ]
      },
      {
        'label': 'Illuminated Pushbutton',
        'value': 'Illuminated Pushbutton',
        'nextQuestions': [
          165
        ]
      }
    ]
  },
  {
    'id': 1,
    'fieldName': 'Bazel Material',
    'title': 'Select your bezel material.',
    'options': [
      {
        'label': 'Metal',
        'value': 'Metal',
        'nextQuestions': [
          2
        ]
      },
      {
        'label': 'Plastic',
        'value': 'Plastic',
        'nextQuestions': [
          31
        ]
      }
    ]
  },
  {
    'id': 2,
    'fieldName': 'Mounting Type',
    'title': 'Select your mounting type.',
    'options': [
      {
        'label': 'Ø 22 mm Standard',
        'value': 'Ø 22 mm Standard',
        'nextQuestions': [
          3
        ]
      },
      {
        'label': 'Ø 30 mm Build-in Flush',
        'value': 'Ø 30 mm Build-in Flush',
        'nextQuestions': [
          21
        ]
      }
    ]
  },
  {
    'id': 3,
    'fieldName': 'Operator Profile',
    'title': 'Select your profile.',
    'options': [
      {
        'label': 'Flush',
        'value': 'Flush',
        'nextQuestions': [
          4
        ]
      },
      {
        'label': 'Mushroom Ø 40 mm',
        'value': 'Mushroom Ø 40 mm',
        'nextQuestions': [
          11
        ]
      },
      {
        'label': 'Projecting',
        'value': 'Projecting',
        'nextQuestions': [
          13
        ]
      },
      {
        'label': 'Booted, Clear Silicon',
        'value': 'Booted, Clear Silicon',
        'nextQuestions': [
          15
        ]
      }
    ]
  },
  {
    'id': 4,
    'fieldName': 'Color',
    'title': 'Please select your lightning color',
    'options': [
      {
        'label': 'White',
        'value': 'White',
        'nextQuestions': [
          5
        ]
      },
      {
        'label': 'Black',
        'value': 'Black',
        'nextQuestions': [
          6
        ]
      },
      {
        'label': 'Green',
        'value': 'Green',
        'nextQuestions': [
          7
        ]
      },
      {
        'label': 'Red',
        'value': 'Red',
        'nextQuestions': [
          8
        ]
      },
      {
        'label': 'Yellow',
        'value': 'Yellow',
        'nextQuestions': [
          9
        ]
      },
      {
        'label': 'Blue',
        'value': 'Blue',
        'nextQuestions': [
          10
        ]
      }
    ]
  },
  {
    'id': 5,
    'fieldName': 'Marking',
    'title': 'Do you want a specific marking?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '↑',
        'value': '↑',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 6,
    'fieldName': 'Marking',
    'title': 'Do you want a specific marking?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '↓',
        'value': '↓',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 7,
    'fieldName': 'Marking',
    'title': 'Do you want a specific marking?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '"I"',
        'value': '"I"',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 8,
    'fieldName': 'Marking',
    'title': 'Do you want a specific marking?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '"O"',
        'value': '"O"',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 9,
    'fieldName': 'Marking',
    'title': 'Do you want a specific marking?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 10,
    'fieldName': 'Marking',
    'title': 'Do you want a specific marking?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 11,
    'fieldName': 'Color',
    'title': 'Please select your lightning color',
    'options': [
      {
        'label': 'Black',
        'value': 'Black',
        'nextQuestions': [
          12
        ]
      }
    ]
  },
  {
    'id': 12,
    'fieldName': 'Marking',
    'title': 'Do you want a specific marking?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 13,
    'fieldName': 'Color',
    'title': 'Please select your lightning color',
    'options': [
      {
        'label': 'Red',
        'value': 'Red',
        'nextQuestions': [
          14
        ]
      }
    ]
  },
  {
    'id': 14,
    'fieldName': 'Marking',
    'title': 'Do you want a specific marking?',
    'options': [
      {
        'label': '"O"',
        'value': '"O"',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 15,
    'fieldName': 'Color',
    'title': 'Please select your lightning color',
    'options': [
      {
        'label': 'Black',
        'value': 'Black',
        'nextQuestions': [
          16
        ]
      },
      {
        'label': 'Green',
        'value': 'Green',
        'nextQuestions': [
          17
        ]
      },
      {
        'label': 'Red',
        'value': 'Red',
        'nextQuestions': [
          18
        ]
      },
      {
        'label': 'Yellow',
        'value': 'Yellow',
        'nextQuestions': [
          19
        ]
      },
      {
        'label': 'Blue',
        'value': 'Blue',
        'nextQuestions': [
          20
        ]
      }
    ]
  },
  {
    'id': 16,
    'fieldName': 'Marking',
    'title': 'Do you want a specific marking?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 17,
    'fieldName': 'Marking',
    'title': 'Do you want a specific marking?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 18,
    'fieldName': 'Marking',
    'title': 'Do you want a specific marking?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 19,
    'fieldName': 'Marking',
    'title': 'Do you want a specific marking?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 20,
    'fieldName': 'Marking',
    'title': 'Do you want a specific marking?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 21,
    'fieldName': 'Operator Profile',
    'title': 'Select your profile.',
    'options': [
      {
        'label': 'Flush',
        'value': 'Flush',
        'nextQuestions': [
          22
        ]
      },
      {
        'label': 'Projecting',
        'value': 'Projecting',
        'nextQuestions': [
          29
        ]
      }
    ]
  },
  {
    'id': 22,
    'fieldName': 'Color',
    'title': 'Please select your lightning color',
    'options': [
      {
        'label': 'White',
        'value': 'White',
        'nextQuestions': [
          23
        ]
      },
      {
        'label': 'Black',
        'value': 'Black',
        'nextQuestions': [
          24
        ]
      },
      {
        'label': 'Green',
        'value': 'Green',
        'nextQuestions': [
          25
        ]
      },
      {
        'label': 'Red',
        'value': 'Red',
        'nextQuestions': [
          26
        ]
      },
      {
        'label': 'Yellow',
        'value': 'Yellow',
        'nextQuestions': [
          27
        ]
      },
      {
        'label': 'Blue',
        'value': 'Blue',
        'nextQuestions': [
          28
        ]
      }
    ]
  },
  {
    'id': 23,
    'fieldName': 'Marking',
    'title': 'Do you want a specific marking?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '↑',
        'value': '↑',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 24,
    'fieldName': 'Marking',
    'title': 'Do you want a specific marking?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '↓',
        'value': '↓',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 25,
    'fieldName': 'Marking',
    'title': 'Do you want a specific marking?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '"I"',
        'value': '"I"',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 26,
    'fieldName': 'Marking',
    'title': 'Do you want a specific marking?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '"O"',
        'value': '"O"',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 27,
    'fieldName': 'Marking',
    'title': 'Do you want a specific marking?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 28,
    'fieldName': 'Marking',
    'title': 'Do you want a specific marking?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 29,
    'fieldName': 'Color',
    'title': 'Please select your lightning color',
    'options': [
      {
        'label': 'Red',
        'value': 'Red',
        'nextQuestions': [
          30
        ]
      }
    ]
  },
  {
    'id': 30,
    'fieldName': 'Marking',
    'title': 'Do you want a specific marking?',
    'options': [
      {
        'label': '"O"',
        'value': '"O"',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 31,
    'fieldName': 'Mounting Type',
    'title': 'Select your mounting type.',
    'options': [
      {
        'label': 'Ø 22 mm Standard',
        'value': 'Ø 22 mm Standard',
        'nextQuestions': [
          32
        ]
      },
      {
        'label': 'Ø 30 mm Build-in Flush',
        'value': 'Ø 30 mm Build-in Flush',
        'nextQuestions': [
          50
        ]
      }
    ]
  },
  {
    'id': 32,
    'fieldName': 'Operator Profile',
    'title': 'Select your profile.',
    'options': [
      {
        'label': 'Flush',
        'value': 'Flush',
        'nextQuestions': [
          33
        ]
      },
      {
        'label': 'Mushroom Ø 40 mm',
        'value': 'Mushroom Ø 40 mm',
        'nextQuestions': [
          40
        ]
      },
      {
        'label': 'Projecting',
        'value': 'Projecting',
        'nextQuestions': [
          42
        ]
      },
      {
        'label': 'Booted, Clear Silicon',
        'value': 'Booted, Clear Silicon',
        'nextQuestions': [
          44
        ]
      }
    ]
  },
  {
    'id': 33,
    'fieldName': 'Color',
    'title': 'Please select your lightning color',
    'options': [
      {
        'label': 'White',
        'value': 'White',
        'nextQuestions': [
          34
        ]
      },
      {
        'label': 'Black',
        'value': 'Black',
        'nextQuestions': [
          35
        ]
      },
      {
        'label': 'Green',
        'value': 'Green',
        'nextQuestions': [
          36
        ]
      },
      {
        'label': 'Red',
        'value': 'Red',
        'nextQuestions': [
          37
        ]
      },
      {
        'label': 'Yellow',
        'value': 'Yellow',
        'nextQuestions': [
          38
        ]
      },
      {
        'label': 'Blue',
        'value': 'Blue',
        'nextQuestions': [
          39
        ]
      }
    ]
  },
  {
    'id': 34,
    'fieldName': 'Marking',
    'title': 'Do you want a specific marking?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '↑',
        'value': '↑',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 35,
    'fieldName': 'Marking',
    'title': 'Do you want a specific marking?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '↓',
        'value': '↓',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 36,
    'fieldName': 'Marking',
    'title': 'Do you want a specific marking?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '"I"',
        'value': '"I"',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 37,
    'fieldName': 'Marking',
    'title': 'Do you want a specific marking?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '"O"',
        'value': '"O"',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 38,
    'fieldName': 'Marking',
    'title': 'Do you want a specific marking?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 39,
    'fieldName': 'Marking',
    'title': 'Do you want a specific marking?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 40,
    'fieldName': 'Color',
    'title': 'Please select your lightning color',
    'options': [
      {
        'label': 'Black',
        'value': 'Black',
        'nextQuestions': [
          41
        ]
      }
    ]
  },
  {
    'id': 41,
    'fieldName': 'Marking',
    'title': 'Do you want a specific marking?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 42,
    'fieldName': 'Color',
    'title': 'Please select your lightning color',
    'options': [
      {
        'label': 'Red',
        'value': 'Red',
        'nextQuestions': [
          43
        ]
      }
    ]
  },
  {
    'id': 43,
    'fieldName': 'Marking',
    'title': 'Do you want a specific marking?',
    'options': [
      {
        'label': '"O"',
        'value': '"O"',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 44,
    'fieldName': 'Color',
    'title': 'Please select your lightning color',
    'options': [
      {
        'label': 'Black',
        'value': 'Black',
        'nextQuestions': [
          45
        ]
      },
      {
        'label': 'Green',
        'value': 'Green',
        'nextQuestions': [
          46
        ]
      },
      {
        'label': 'Red',
        'value': 'Red',
        'nextQuestions': [
          47
        ]
      },
      {
        'label': 'Yellow',
        'value': 'Yellow',
        'nextQuestions': [
          48
        ]
      },
      {
        'label': 'Blue',
        'value': 'Blue',
        'nextQuestions': [
          49
        ]
      }
    ]
  },
  {
    'id': 45,
    'fieldName': 'Marking',
    'title': 'Do you want a specific marking?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 46,
    'fieldName': 'Marking',
    'title': 'Do you want a specific marking?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 47,
    'fieldName': 'Marking',
    'title': 'Do you want a specific marking?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 48,
    'fieldName': 'Marking',
    'title': 'Do you want a specific marking?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 49,
    'fieldName': 'Marking',
    'title': 'Do you want a specific marking?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 50,
    'fieldName': 'Operator Profile',
    'title': 'Select your profile.',
    'options': [
      {
        'label': 'Flush',
        'value': 'Flush',
        'nextQuestions': [
          51
        ]
      },
      {
        'label': 'Projecting',
        'value': 'Projecting',
        'nextQuestions': [
          58
        ]
      }
    ]
  },
  {
    'id': 51,
    'fieldName': 'Color',
    'title': 'Please select your lightning color',
    'options': [
      {
        'label': 'White',
        'value': 'White',
        'nextQuestions': [
          52
        ]
      },
      {
        'label': 'Black',
        'value': 'Black',
        'nextQuestions': [
          53
        ]
      },
      {
        'label': 'Green',
        'value': 'Green',
        'nextQuestions': [
          54
        ]
      },
      {
        'label': 'Red',
        'value': 'Red',
        'nextQuestions': [
          55
        ]
      },
      {
        'label': 'Yellow',
        'value': 'Yellow',
        'nextQuestions': [
          56
        ]
      },
      {
        'label': 'Blue',
        'value': 'Blue',
        'nextQuestions': [
          57
        ]
      }
    ]
  },
  {
    'id': 52,
    'fieldName': 'Marking',
    'title': 'Do you want a specific marking?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '↑',
        'value': '↑',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 53,
    'fieldName': 'Marking',
    'title': 'Do you want a specific marking?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '↓',
        'value': '↓',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 54,
    'fieldName': 'Marking',
    'title': 'Do you want a specific marking?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '"I"',
        'value': '"I"',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 55,
    'fieldName': 'Marking',
    'title': 'Do you want a specific marking?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '"O"',
        'value': '"O"',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 56,
    'fieldName': 'Marking',
    'title': 'Do you want a specific marking?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 57,
    'fieldName': 'Marking',
    'title': 'Do you want a specific marking?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 58,
    'fieldName': 'Color',
    'title': 'Please select your lightning color',
    'options': [
      {
        'label': 'Red',
        'value': 'Red',
        'nextQuestions': [
          59
        ]
      }
    ]
  },
  {
    'id': 59,
    'fieldName': 'Marking',
    'title': 'Do you want a specific marking?',
    'options': [
      {
        'label': '"O"',
        'value': '"O"',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 60,
    'fieldName': 'Bazel Material',
    'title': 'Select your bezel material.',
    'options': [
      {
        'label': 'Metal',
        'value': 'Metal',
        'nextQuestions': [
          61
        ]
      },
      {
        'label': 'Plastic',
        'value': 'Plastic',
        'nextQuestions': [
          76
        ]
      }
    ]
  },
  {
    'id': 61,
    'fieldName': 'Mounting Type',
    'title': 'Select your mounting type.',
    'options': [
      {
        'label': 'Ø 22 mm Standard',
        'value': 'Ø 22 mm Standard',
        'nextQuestions': [
          62
        ]
      },
      {
        'label': 'Ø 30 mm Build-in Flush',
        'value': 'Ø 30 mm Build-in Flush',
        'nextQuestions': [
          69
        ]
      }
    ]
  },
  {
    'id': 62,
    'fieldName': 'Operator Profile',
    'title': 'Select your profile.',
    'options': [
      {
        'label': 'Standard Handle',
        'value': 'Standard Handle',
        'nextQuestions': [
          63
        ]
      },
      {
        'label': 'Key Switch',
        'value': 'Key Switch',
        'nextQuestions': [
          65
        ]
      },
      {
        'label': 'Long Handle',
        'value': 'Long Handle',
        'nextQuestions': [
          67
        ]
      }
    ]
  },
  {
    'id': 63,
    'fieldName': 'Color',
    'title': 'Please select your lightning color',
    'options': [
      {
        'label': 'Black',
        'value': 'Black',
        'nextQuestions': [
          64
        ]
      }
    ]
  },
  {
    'id': 64,
    'fieldName': 'Switch Type',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '2 Position Stay Put',
        'value': '2 Position Stay Put',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3 Position Stay Put',
        'value': '3 Position Stay Put',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '2 Position Spring Return',
        'value': '2 Position Spring Return',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3 Position Spring Return',
        'value': '3 Position Spring Return',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 65,
    'fieldName': 'Color',
    'title': 'Please select your lightning color',
    'options': [
      {
        'label': 'Black',
        'value': 'Black',
        'nextQuestions': [
          66
        ]
      }
    ]
  },
  {
    'id': 66,
    'fieldName': 'Switch Type',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '3 Position Stay Put',
        'value': '3 Position Stay Put',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '2 Position Stay Put',
        'value': '2 Position Stay Put',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '2 Position Spring Return',
        'value': '2 Position Spring Return',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 67,
    'fieldName': 'Color',
    'title': 'Please select your lightning color',
    'options': [
      {
        'label': 'Black',
        'value': 'Black',
        'nextQuestions': [
          68
        ]
      }
    ]
  },
  {
    'id': 68,
    'fieldName': 'Switch Type',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '2 Position Stay Put',
        'value': '2 Position Stay Put',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3 Position Stay Put',
        'value': '3 Position Stay Put',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3 Position Spring Return',
        'value': '3 Position Spring Return',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 69,
    'fieldName': 'Operator Profile',
    'title': 'Select your profile.',
    'options': [
      {
        'label': 'Standard Handle',
        'value': 'Standard Handle',
        'nextQuestions': [
          70
        ]
      },
      {
        'label': 'Key Switch',
        'value': 'Key Switch',
        'nextQuestions': [
          72
        ]
      },
      {
        'label': 'Long Handle',
        'value': 'Long Handle',
        'nextQuestions': [
          74
        ]
      }
    ]
  },
  {
    'id': 70,
    'fieldName': 'Color',
    'title': 'Please select your lightning color',
    'options': [
      {
        'label': 'Black',
        'value': 'Black',
        'nextQuestions': [
          71
        ]
      }
    ]
  },
  {
    'id': 71,
    'fieldName': 'Switch Type',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '2 Position Stay Put',
        'value': '2 Position Stay Put',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3 Position Stay Put',
        'value': '3 Position Stay Put',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '2 Position Spring Return',
        'value': '2 Position Spring Return',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3 Position Spring Return',
        'value': '3 Position Spring Return',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 72,
    'fieldName': 'Color',
    'title': 'Please select your lightning color',
    'options': [
      {
        'label': 'Black',
        'value': 'Black',
        'nextQuestions': [
          73
        ]
      }
    ]
  },
  {
    'id': 73,
    'fieldName': 'Switch Type',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '3 Position Stay Put',
        'value': '3 Position Stay Put',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '2 Position Stay Put',
        'value': '2 Position Stay Put',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '2 Position Spring Return',
        'value': '2 Position Spring Return',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 74,
    'fieldName': 'Color',
    'title': 'Please select your lightning color',
    'options': [
      {
        'label': 'Black',
        'value': 'Black',
        'nextQuestions': [
          75
        ]
      }
    ]
  },
  {
    'id': 75,
    'fieldName': 'Switch Type',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '2 Position Stay Put',
        'value': '2 Position Stay Put',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3 Position Stay Put',
        'value': '3 Position Stay Put',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3 Position Spring Return',
        'value': '3 Position Spring Return',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 76,
    'fieldName': 'Mounting Type',
    'title': 'Select your mounting type.',
    'options': [
      {
        'label': 'Ø 22 mm Standard',
        'value': 'Ø 22 mm Standard',
        'nextQuestions': [
          77
        ]
      },
      {
        'label': 'Ø 30 mm Build-in Flush',
        'value': 'Ø 30 mm Build-in Flush',
        'nextQuestions': [
          84
        ]
      }
    ]
  },
  {
    'id': 77,
    'fieldName': 'Operator Profile',
    'title': 'Select your profile.',
    'options': [
      {
        'label': 'Standard Handle',
        'value': 'Standard Handle',
        'nextQuestions': [
          78
        ]
      },
      {
        'label': 'Key Switch',
        'value': 'Key Switch',
        'nextQuestions': [
          80
        ]
      },
      {
        'label': 'Long Handle',
        'value': 'Long Handle',
        'nextQuestions': [
          82
        ]
      }
    ]
  },
  {
    'id': 78,
    'fieldName': 'Color',
    'title': 'Please select your lightning color',
    'options': [
      {
        'label': 'Black',
        'value': 'Black',
        'nextQuestions': [
          79
        ]
      }
    ]
  },
  {
    'id': 79,
    'fieldName': 'Switch Type',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '2 Position Stay Put',
        'value': '2 Position Stay Put',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3 Position Stay Put',
        'value': '3 Position Stay Put',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '2 Position Spring Return',
        'value': '2 Position Spring Return',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3 Position Spring Return',
        'value': '3 Position Spring Return',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 80,
    'fieldName': 'Color',
    'title': 'Please select your lightning color',
    'options': [
      {
        'label': 'Black',
        'value': 'Black',
        'nextQuestions': [
          81
        ]
      }
    ]
  },
  {
    'id': 81,
    'fieldName': 'Switch Type',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '3 Position Stay Put',
        'value': '3 Position Stay Put',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '2 Position Stay Put',
        'value': '2 Position Stay Put',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '2 Position Spring Return',
        'value': '2 Position Spring Return',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 82,
    'fieldName': 'Color',
    'title': 'Please select your lightning color',
    'options': [
      {
        'label': 'Black',
        'value': 'Black',
        'nextQuestions': [
          83
        ]
      }
    ]
  },
  {
    'id': 83,
    'fieldName': 'Switch Type',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '2 Position Stay Put',
        'value': '2 Position Stay Put',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3 Position Stay Put',
        'value': '3 Position Stay Put',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3 Position Spring Return',
        'value': '3 Position Spring Return',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 84,
    'fieldName': 'Operator Profile',
    'title': 'Select your profile.',
    'options': [
      {
        'label': 'Standard Handle',
        'value': 'Standard Handle',
        'nextQuestions': [
          85
        ]
      },
      {
        'label': 'Key Switch',
        'value': 'Key Switch',
        'nextQuestions': [
          87
        ]
      },
      {
        'label': 'Long Handle',
        'value': 'Long Handle',
        'nextQuestions': [
          89
        ]
      }
    ]
  },
  {
    'id': 85,
    'fieldName': 'Color',
    'title': 'Please select your lightning color',
    'options': [
      {
        'label': 'Black',
        'value': 'Black',
        'nextQuestions': [
          86
        ]
      }
    ]
  },
  {
    'id': 86,
    'fieldName': 'Switch Type',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '2 Position Stay Put',
        'value': '2 Position Stay Put',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3 Position Stay Put',
        'value': '3 Position Stay Put',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '2 Position Spring Return',
        'value': '2 Position Spring Return',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3 Position Spring Return',
        'value': '3 Position Spring Return',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 87,
    'fieldName': 'Color',
    'title': 'Please select your lightning color',
    'options': [
      {
        'label': 'Black',
        'value': 'Black',
        'nextQuestions': [
          88
        ]
      }
    ]
  },
  {
    'id': 88,
    'fieldName': 'Switch Type',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '3 Position Stay Put',
        'value': '3 Position Stay Put',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '2 Position Stay Put',
        'value': '2 Position Stay Put',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '2 Position Spring Return',
        'value': '2 Position Spring Return',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 89,
    'fieldName': 'Color',
    'title': 'Please select your lightning color',
    'options': [
      {
        'label': 'Black',
        'value': 'Black',
        'nextQuestions': [
          90
        ]
      }
    ]
  },
  {
    'id': 90,
    'fieldName': 'Switch Type',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '2 Position Stay Put',
        'value': '2 Position Stay Put',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3 Position Stay Put',
        'value': '3 Position Stay Put',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '3 Position Spring Return',
        'value': '3 Position Spring Return',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 91,
    'fieldName': 'Bazel Material',
    'title': 'Select your bezel material.',
    'options': [
      {
        'label': 'Metal',
        'value': 'Metal',
        'nextQuestions': [
          92
        ]
      },
      {
        'label': 'Plastic',
        'value': 'Plastic',
        'nextQuestions': [
          115
        ]
      }
    ]
  },
  {
    'id': 92,
    'fieldName': 'Mounting Type',
    'title': 'Select your mounting type.',
    'options': [
      {
        'label': 'Ø 22 mm Standard',
        'value': 'Ø 22 mm Standard',
        'nextQuestions': [
          93
        ]
      },
      {
        'label': 'Ø 30 mm Build-in Flush',
        'value': 'Ø 30 mm Build-in Flush',
        'nextQuestions': [
          104
        ]
      }
    ]
  },
  {
    'id': 93,
    'fieldName': 'Operator Profile',
    'title': 'Select your profile.',
    'options': [
      {
        'label': 'Standard Handle',
        'value': 'Standard Handle',
        'nextQuestions': [
          94
        ]
      }
    ]
  },
  {
    'id': 94,
    'fieldName': 'Color',
    'title': 'Please select your lightning color',
    'options': [
      {
        'label': 'Green',
        'value': 'Green',
        'nextQuestions': [
          95
        ]
      },
      {
        'label': 'Red',
        'value': 'Red',
        'nextQuestions': [
          98
        ]
      },
      {
        'label': 'Orange',
        'value': 'Orange',
        'nextQuestions': [
          101
        ]
      }
    ]
  },
  {
    'id': 95,
    'fieldName': 'Switch Type',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '2 Position Stay Put',
        'value': '2 Position Stay Put',
        'nextQuestions': [
          96
        ]
      },
      {
        'label': '3 Position Stay Put',
        'value': '3 Position Stay Put',
        'nextQuestions': [
          97
        ]
      }
    ]
  },
  {
    'id': 96,
    'fieldName': 'Supply Voltage',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '24 VDC/VAC',
        'value': '24 VDC/VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '110-120 VAC',
        'value': '110-120 VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '230-240 VAC',
        'value': '230-240 VAC',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 97,
    'fieldName': 'Supply Voltage',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '24 VDC/VAC',
        'value': '24 VDC/VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '110-120 VAC',
        'value': '110-120 VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '230-240 VAC',
        'value': '230-240 VAC',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 98,
    'fieldName': 'Switch Type',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '2 Position Stay Put',
        'value': '2 Position Stay Put',
        'nextQuestions': [
          99
        ]
      },
      {
        'label': '3 Position Stay Put',
        'value': '3 Position Stay Put',
        'nextQuestions': [
          100
        ]
      }
    ]
  },
  {
    'id': 99,
    'fieldName': 'Supply Voltage',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '24 VDC/VAC',
        'value': '24 VDC/VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '110-120 VAC',
        'value': '110-120 VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '230-240 VAC',
        'value': '230-240 VAC',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 100,
    'fieldName': 'Supply Voltage',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '24 VDC/VAC',
        'value': '24 VDC/VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '110-120 VAC',
        'value': '110-120 VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '230-240 VAC',
        'value': '230-240 VAC',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 101,
    'fieldName': 'Switch Type',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '2 Position Stay Put',
        'value': '2 Position Stay Put',
        'nextQuestions': [
          102
        ]
      },
      {
        'label': '3 Position Stay Put',
        'value': '3 Position Stay Put',
        'nextQuestions': [
          103
        ]
      }
    ]
  },
  {
    'id': 102,
    'fieldName': 'Supply Voltage',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '24 VDC/VAC',
        'value': '24 VDC/VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '110-120 VAC',
        'value': '110-120 VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '230-240 VAC',
        'value': '230-240 VAC',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 103,
    'fieldName': 'Supply Voltage',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '24 VDC/VAC',
        'value': '24 VDC/VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '110-120 VAC',
        'value': '110-120 VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '230-240 VAC',
        'value': '230-240 VAC',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 104,
    'fieldName': 'Operator Profile',
    'title': 'Select your profile.',
    'options': [
      {
        'label': 'Standard Handle',
        'value': 'Standard Handle',
        'nextQuestions': [
          105
        ]
      }
    ]
  },
  {
    'id': 105,
    'fieldName': 'Color',
    'title': 'Please select your lightning color',
    'options': [
      {
        'label': 'Green',
        'value': 'Green',
        'nextQuestions': [
          106
        ]
      },
      {
        'label': 'Red',
        'value': 'Red',
        'nextQuestions': [
          109
        ]
      },
      {
        'label': 'Orange',
        'value': 'Orange',
        'nextQuestions': [
          112
        ]
      }
    ]
  },
  {
    'id': 106,
    'fieldName': 'Switch Type',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '2 Position Stay Put',
        'value': '2 Position Stay Put',
        'nextQuestions': [
          107
        ]
      },
      {
        'label': '3 Position Stay Put',
        'value': '3 Position Stay Put',
        'nextQuestions': [
          108
        ]
      }
    ]
  },
  {
    'id': 107,
    'fieldName': 'Supply Voltage',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '24 VDC/VAC',
        'value': '24 VDC/VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '110-120 VAC',
        'value': '110-120 VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '230-240 VAC',
        'value': '230-240 VAC',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 108,
    'fieldName': 'Supply Voltage',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '24 VDC/VAC',
        'value': '24 VDC/VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '110-120 VAC',
        'value': '110-120 VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '230-240 VAC',
        'value': '230-240 VAC',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 109,
    'fieldName': 'Switch Type',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '2 Position Stay Put',
        'value': '2 Position Stay Put',
        'nextQuestions': [
          110
        ]
      },
      {
        'label': '3 Position Stay Put',
        'value': '3 Position Stay Put',
        'nextQuestions': [
          111
        ]
      }
    ]
  },
  {
    'id': 110,
    'fieldName': 'Supply Voltage',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '24 VDC/VAC',
        'value': '24 VDC/VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '110-120 VAC',
        'value': '110-120 VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '230-240 VAC',
        'value': '230-240 VAC',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 111,
    'fieldName': 'Supply Voltage',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '24 VDC/VAC',
        'value': '24 VDC/VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '110-120 VAC',
        'value': '110-120 VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '230-240 VAC',
        'value': '230-240 VAC',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 112,
    'fieldName': 'Switch Type',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '2 Position Stay Put',
        'value': '2 Position Stay Put',
        'nextQuestions': [
          113
        ]
      },
      {
        'label': '3 Position Stay Put',
        'value': '3 Position Stay Put',
        'nextQuestions': [
          114
        ]
      }
    ]
  },
  {
    'id': 113,
    'fieldName': 'Supply Voltage',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '24 VDC/VAC',
        'value': '24 VDC/VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '110-120 VAC',
        'value': '110-120 VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '230-240 VAC',
        'value': '230-240 VAC',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 114,
    'fieldName': 'Supply Voltage',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '24 VDC/VAC',
        'value': '24 VDC/VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '110-120 VAC',
        'value': '110-120 VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '230-240 VAC',
        'value': '230-240 VAC',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 115,
    'fieldName': 'Mounting Type',
    'title': 'Select your mounting type.',
    'options': [
      {
        'label': 'Ø 22 mm Standard',
        'value': 'Ø 22 mm Standard',
        'nextQuestions': [
          116
        ]
      },
      {
        'label': 'Ø 30 mm Build-in Flush',
        'value': 'Ø 30 mm Build-in Flush',
        'nextQuestions': [
          127
        ]
      }
    ]
  },
  {
    'id': 116,
    'fieldName': 'Operator Profile',
    'title': 'Select your profile.',
    'options': [
      {
        'label': 'Standard Handle',
        'value': 'Standard Handle',
        'nextQuestions': [
          117
        ]
      }
    ]
  },
  {
    'id': 117,
    'fieldName': 'Color',
    'title': 'Please select your lightning color',
    'options': [
      {
        'label': 'Green',
        'value': 'Green',
        'nextQuestions': [
          118
        ]
      },
      {
        'label': 'Red',
        'value': 'Red',
        'nextQuestions': [
          121
        ]
      },
      {
        'label': 'Orange',
        'value': 'Orange',
        'nextQuestions': [
          124
        ]
      }
    ]
  },
  {
    'id': 118,
    'fieldName': 'Switch Type',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '2 Position Stay Put',
        'value': '2 Position Stay Put',
        'nextQuestions': [
          119
        ]
      },
      {
        'label': '3 Position Stay Put',
        'value': '3 Position Stay Put',
        'nextQuestions': [
          120
        ]
      }
    ]
  },
  {
    'id': 119,
    'fieldName': 'Supply Voltage',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '24 VDC/VAC',
        'value': '24 VDC/VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '110-120 VAC',
        'value': '110-120 VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '230-240 VAC',
        'value': '230-240 VAC',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 120,
    'fieldName': 'Supply Voltage',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '24 VDC/VAC',
        'value': '24 VDC/VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '110-120 VAC',
        'value': '110-120 VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '230-240 VAC',
        'value': '230-240 VAC',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 121,
    'fieldName': 'Switch Type',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '2 Position Stay Put',
        'value': '2 Position Stay Put',
        'nextQuestions': [
          122
        ]
      },
      {
        'label': '3 Position Stay Put',
        'value': '3 Position Stay Put',
        'nextQuestions': [
          123
        ]
      }
    ]
  },
  {
    'id': 122,
    'fieldName': 'Supply Voltage',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '24 VDC/VAC',
        'value': '24 VDC/VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '110-120 VAC',
        'value': '110-120 VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '230-240 VAC',
        'value': '230-240 VAC',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 123,
    'fieldName': 'Supply Voltage',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '24 VDC/VAC',
        'value': '24 VDC/VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '110-120 VAC',
        'value': '110-120 VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '230-240 VAC',
        'value': '230-240 VAC',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 124,
    'fieldName': 'Switch Type',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '2 Position Stay Put',
        'value': '2 Position Stay Put',
        'nextQuestions': [
          125
        ]
      },
      {
        'label': '3 Position Stay Put',
        'value': '3 Position Stay Put',
        'nextQuestions': [
          126
        ]
      }
    ]
  },
  {
    'id': 125,
    'fieldName': 'Supply Voltage',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '24 VDC/VAC',
        'value': '24 VDC/VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '110-120 VAC',
        'value': '110-120 VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '230-240 VAC',
        'value': '230-240 VAC',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 126,
    'fieldName': 'Supply Voltage',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '24 VDC/VAC',
        'value': '24 VDC/VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '110-120 VAC',
        'value': '110-120 VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '230-240 VAC',
        'value': '230-240 VAC',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 127,
    'fieldName': 'Operator Profile',
    'title': 'Select your profile.',
    'options': [
      {
        'label': 'Standard Handle',
        'value': 'Standard Handle',
        'nextQuestions': [
          128
        ]
      }
    ]
  },
  {
    'id': 128,
    'fieldName': 'Color',
    'title': 'Please select your lightning color',
    'options': [
      {
        'label': 'Green',
        'value': 'Green',
        'nextQuestions': [
          129
        ]
      },
      {
        'label': 'Red',
        'value': 'Red',
        'nextQuestions': [
          132
        ]
      },
      {
        'label': 'Orange',
        'value': 'Orange',
        'nextQuestions': [
          135
        ]
      }
    ]
  },
  {
    'id': 129,
    'fieldName': 'Switch Type',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '2 Position Stay Put',
        'value': '2 Position Stay Put',
        'nextQuestions': [
          130
        ]
      },
      {
        'label': '3 Position Stay Put',
        'value': '3 Position Stay Put',
        'nextQuestions': [
          131
        ]
      }
    ]
  },
  {
    'id': 130,
    'fieldName': 'Supply Voltage',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '24 VDC/VAC',
        'value': '24 VDC/VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '110-120 VAC',
        'value': '110-120 VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '230-240 VAC',
        'value': '230-240 VAC',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 131,
    'fieldName': 'Supply Voltage',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '24 VDC/VAC',
        'value': '24 VDC/VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '110-120 VAC',
        'value': '110-120 VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '230-240 VAC',
        'value': '230-240 VAC',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 132,
    'fieldName': 'Switch Type',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '2 Position Stay Put',
        'value': '2 Position Stay Put',
        'nextQuestions': [
          133
        ]
      },
      {
        'label': '3 Position Stay Put',
        'value': '3 Position Stay Put',
        'nextQuestions': [
          134
        ]
      }
    ]
  },
  {
    'id': 133,
    'fieldName': 'Supply Voltage',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '24 VDC/VAC',
        'value': '24 VDC/VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '110-120 VAC',
        'value': '110-120 VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '230-240 VAC',
        'value': '230-240 VAC',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 134,
    'fieldName': 'Supply Voltage',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '24 VDC/VAC',
        'value': '24 VDC/VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '110-120 VAC',
        'value': '110-120 VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '230-240 VAC',
        'value': '230-240 VAC',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 135,
    'fieldName': 'Switch Type',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '2 Position Stay Put',
        'value': '2 Position Stay Put',
        'nextQuestions': [
          136
        ]
      },
      {
        'label': '3 Position Stay Put',
        'value': '3 Position Stay Put',
        'nextQuestions': [
          137
        ]
      }
    ]
  },
  {
    'id': 136,
    'fieldName': 'Supply Voltage',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '24 VDC/VAC',
        'value': '24 VDC/VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '110-120 VAC',
        'value': '110-120 VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '230-240 VAC',
        'value': '230-240 VAC',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 137,
    'fieldName': 'Supply Voltage',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '24 VDC/VAC',
        'value': '24 VDC/VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '110-120 VAC',
        'value': '110-120 VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '230-240 VAC',
        'value': '230-240 VAC',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 138,
    'fieldName': 'Bazel Material',
    'title': 'Select your bezel material.',
    'options': [
      {
        'label': 'Metal',
        'value': 'Metal',
        'nextQuestions': [
          139
        ]
      },
      {
        'label': 'Plastic',
        'value': 'Plastic',
        'nextQuestions': [
          152
        ]
      }
    ]
  },
  {
    'id': 139,
    'fieldName': 'Mounting Type',
    'title': 'Select your mounting type.',
    'options': [
      {
        'label': 'Ø 22 mm Standard',
        'value': 'Ø 22 mm Standard',
        'nextQuestions': [
          140
        ]
      },
      {
        'label': 'Ø 30 mm Build-in Flush',
        'value': 'Ø 30 mm Build-in Flush',
        'nextQuestions': [
          146
        ]
      }
    ]
  },
  {
    'id': 140,
    'fieldName': 'Color',
    'title': 'Please select your lightning color',
    'options': [
      {
        'label': 'White',
        'value': 'White',
        'nextQuestions': [
          141
        ]
      },
      {
        'label': 'Green',
        'value': 'Green',
        'nextQuestions': [
          142
        ]
      },
      {
        'label': 'Red',
        'value': 'Red',
        'nextQuestions': [
          143
        ]
      },
      {
        'label': 'Orange',
        'value': 'Orange',
        'nextQuestions': [
          144
        ]
      },
      {
        'label': 'Blue',
        'value': 'Blue',
        'nextQuestions': [
          145
        ]
      }
    ]
  },
  {
    'id': 141,
    'fieldName': 'Supply Voltage',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '24 VDC/VAC',
        'value': '24 VDC/VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '110-120 VAC',
        'value': '110-120 VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '230-240 VAC',
        'value': '230-240 VAC',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 142,
    'fieldName': 'Supply Voltage',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '24 VDC/VAC',
        'value': '24 VDC/VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '110-120 VAC',
        'value': '110-120 VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '230-240 VAC',
        'value': '230-240 VAC',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 143,
    'fieldName': 'Supply Voltage',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '24 VDC/VAC',
        'value': '24 VDC/VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '110-120 VAC',
        'value': '110-120 VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '230-240 VAC',
        'value': '230-240 VAC',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 144,
    'fieldName': 'Supply Voltage',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '24 VDC/VAC',
        'value': '24 VDC/VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '110-120 VAC',
        'value': '110-120 VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '230-240 VAC',
        'value': '230-240 VAC',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 145,
    'fieldName': 'Supply Voltage',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '24 VDC/VAC',
        'value': '24 VDC/VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '110-120 VAC',
        'value': '110-120 VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '230-240 VAC',
        'value': '230-240 VAC',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 146,
    'fieldName': 'Color',
    'title': 'Please select your lightning color',
    'options': [
      {
        'label': 'White',
        'value': 'White',
        'nextQuestions': [
          147
        ]
      },
      {
        'label': 'Green',
        'value': 'Green',
        'nextQuestions': [
          148
        ]
      },
      {
        'label': 'Red',
        'value': 'Red',
        'nextQuestions': [
          149
        ]
      },
      {
        'label': 'Orange',
        'value': 'Orange',
        'nextQuestions': [
          150
        ]
      },
      {
        'label': 'Blue',
        'value': 'Blue',
        'nextQuestions': [
          151
        ]
      }
    ]
  },
  {
    'id': 147,
    'fieldName': 'Supply Voltage',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '24 VDC/VAC',
        'value': '24 VDC/VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '110-120 VAC',
        'value': '110-120 VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '230-240 VAC',
        'value': '230-240 VAC',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 148,
    'fieldName': 'Supply Voltage',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '24 VDC/VAC',
        'value': '24 VDC/VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '110-120 VAC',
        'value': '110-120 VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '230-240 VAC',
        'value': '230-240 VAC',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 149,
    'fieldName': 'Supply Voltage',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '24 VDC/VAC',
        'value': '24 VDC/VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '110-120 VAC',
        'value': '110-120 VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '230-240 VAC',
        'value': '230-240 VAC',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 150,
    'fieldName': 'Supply Voltage',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '24 VDC/VAC',
        'value': '24 VDC/VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '110-120 VAC',
        'value': '110-120 VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '230-240 VAC',
        'value': '230-240 VAC',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 151,
    'fieldName': 'Supply Voltage',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '24 VDC/VAC',
        'value': '24 VDC/VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '110-120 VAC',
        'value': '110-120 VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '230-240 VAC',
        'value': '230-240 VAC',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 152,
    'fieldName': 'Mounting Type',
    'title': 'Select your mounting type.',
    'options': [
      {
        'label': 'Ø 22 mm Standard',
        'value': 'Ø 22 mm Standard',
        'nextQuestions': [
          153
        ]
      },
      {
        'label': 'Ø 30 mm Build-in Flush',
        'value': 'Ø 30 mm Build-in Flush',
        'nextQuestions': [
          159
        ]
      }
    ]
  },
  {
    'id': 153,
    'fieldName': 'Color',
    'title': 'Please select your lightning color',
    'options': [
      {
        'label': 'White',
        'value': 'White',
        'nextQuestions': [
          154
        ]
      },
      {
        'label': 'Green',
        'value': 'Green',
        'nextQuestions': [
          155
        ]
      },
      {
        'label': 'Red',
        'value': 'Red',
        'nextQuestions': [
          156
        ]
      },
      {
        'label': 'Orange',
        'value': 'Orange',
        'nextQuestions': [
          157
        ]
      },
      {
        'label': 'Blue',
        'value': 'Blue',
        'nextQuestions': [
          158
        ]
      }
    ]
  },
  {
    'id': 154,
    'fieldName': 'Supply Voltage',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '24 VDC/VAC',
        'value': '24 VDC/VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '110-120 VAC',
        'value': '110-120 VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '230-240 VAC',
        'value': '230-240 VAC',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 155,
    'fieldName': 'Supply Voltage',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '24 VDC/VAC',
        'value': '24 VDC/VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '110-120 VAC',
        'value': '110-120 VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '230-240 VAC',
        'value': '230-240 VAC',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 156,
    'fieldName': 'Supply Voltage',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '24 VDC/VAC',
        'value': '24 VDC/VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '110-120 VAC',
        'value': '110-120 VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '230-240 VAC',
        'value': '230-240 VAC',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 157,
    'fieldName': 'Supply Voltage',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '24 VDC/VAC',
        'value': '24 VDC/VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '110-120 VAC',
        'value': '110-120 VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '230-240 VAC',
        'value': '230-240 VAC',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 158,
    'fieldName': 'Supply Voltage',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '24 VDC/VAC',
        'value': '24 VDC/VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '110-120 VAC',
        'value': '110-120 VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '230-240 VAC',
        'value': '230-240 VAC',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 159,
    'fieldName': 'Color',
    'title': 'Please select your lightning color',
    'options': [
      {
        'label': 'White',
        'value': 'White',
        'nextQuestions': [
          160
        ]
      },
      {
        'label': 'Green',
        'value': 'Green',
        'nextQuestions': [
          161
        ]
      },
      {
        'label': 'Red',
        'value': 'Red',
        'nextQuestions': [
          162
        ]
      },
      {
        'label': 'Orange',
        'value': 'Orange',
        'nextQuestions': [
          163
        ]
      },
      {
        'label': 'Blue',
        'value': 'Blue',
        'nextQuestions': [
          164
        ]
      }
    ]
  },
  {
    'id': 160,
    'fieldName': 'Supply Voltage',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '24 VDC/VAC',
        'value': '24 VDC/VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '110-120 VAC',
        'value': '110-120 VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '230-240 VAC',
        'value': '230-240 VAC',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 161,
    'fieldName': 'Supply Voltage',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '24 VDC/VAC',
        'value': '24 VDC/VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '110-120 VAC',
        'value': '110-120 VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '230-240 VAC',
        'value': '230-240 VAC',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 162,
    'fieldName': 'Supply Voltage',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '24 VDC/VAC',
        'value': '24 VDC/VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '110-120 VAC',
        'value': '110-120 VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '230-240 VAC',
        'value': '230-240 VAC',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 163,
    'fieldName': 'Supply Voltage',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '24 VDC/VAC',
        'value': '24 VDC/VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '110-120 VAC',
        'value': '110-120 VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '230-240 VAC',
        'value': '230-240 VAC',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 164,
    'fieldName': 'Supply Voltage',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '24 VDC/VAC',
        'value': '24 VDC/VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '110-120 VAC',
        'value': '110-120 VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '230-240 VAC',
        'value': '230-240 VAC',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 165,
    'fieldName': 'Bazel Material',
    'title': 'Select your bezel material.',
    'options': [
      {
        'label': 'Metal',
        'value': 'Metal',
        'nextQuestions': [
          166
        ]
      },
      {
        'label': 'Plastic',
        'value': 'Plastic',
        'nextQuestions': [
          191
        ]
      }
    ]
  },
  {
    'id': 166,
    'fieldName': 'Mounting Type',
    'title': 'Select your mounting type.',
    'options': [
      {
        'label': 'Ø 22 mm Standard',
        'value': 'Ø 22 mm Standard',
        'nextQuestions': [
          167
        ]
      },
      {
        'label': 'Ø 30 mm Build-in Flush',
        'value': 'Ø 30 mm Build-in Flush',
        'nextQuestions': [
          179
        ]
      }
    ]
  },
  {
    'id': 167,
    'fieldName': 'Operator Profile',
    'title': 'Select your profile.',
    'options': [
      {
        'label': 'Flush',
        'value': 'Flush',
        'nextQuestions': [
          168
        ]
      }
    ]
  },
  {
    'id': 168,
    'fieldName': 'Color',
    'title': 'Please select your lightning color',
    'options': [
      {
        'label': 'White',
        'value': 'White',
        'nextQuestions': [
          169
        ]
      },
      {
        'label': 'Green',
        'value': 'Green',
        'nextQuestions': [
          171
        ]
      },
      {
        'label': 'Red',
        'value': 'Red',
        'nextQuestions': [
          173
        ]
      },
      {
        'label': 'Orange',
        'value': 'Orange',
        'nextQuestions': [
          175
        ]
      },
      {
        'label': 'Blue',
        'value': 'Blue',
        'nextQuestions': [
          177
        ]
      }
    ]
  },
  {
    'id': 169,
    'fieldName': 'Marking',
    'title': 'Do you want a specific marking?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          170
        ]
      }
    ]
  },
  {
    'id': 170,
    'fieldName': 'Supply Voltage',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '24 VDC/VAC',
        'value': '24 VDC/VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '110-120 VAC',
        'value': '110-120 VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '230-240 VAC',
        'value': '230-240 VAC',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 171,
    'fieldName': 'Marking',
    'title': 'Do you want a specific marking?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          172
        ]
      }
    ]
  },
  {
    'id': 172,
    'fieldName': 'Supply Voltage',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '24 VDC/VAC',
        'value': '24 VDC/VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '110-120 VAC',
        'value': '110-120 VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '230-240 VAC',
        'value': '230-240 VAC',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 173,
    'fieldName': 'Marking',
    'title': 'Do you want a specific marking?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          174
        ]
      }
    ]
  },
  {
    'id': 174,
    'fieldName': 'Supply Voltage',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '24 VDC/VAC',
        'value': '24 VDC/VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '110-120 VAC',
        'value': '110-120 VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '230-240 VAC',
        'value': '230-240 VAC',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 175,
    'fieldName': 'Marking',
    'title': 'Do you want a specific marking?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          176
        ]
      }
    ]
  },
  {
    'id': 176,
    'fieldName': 'Supply Voltage',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '24 VDC/VAC',
        'value': '24 VDC/VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '110-120 VAC',
        'value': '110-120 VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '230-240 VAC',
        'value': '230-240 VAC',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 177,
    'fieldName': 'Marking',
    'title': 'Do you want a specific marking?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          178
        ]
      }
    ]
  },
  {
    'id': 178,
    'fieldName': 'Supply Voltage',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '24 VDC/VAC',
        'value': '24 VDC/VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '110-120 VAC',
        'value': '110-120 VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '230-240 VAC',
        'value': '230-240 VAC',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 179,
    'fieldName': 'Operator Profile',
    'title': 'Select your profile.',
    'options': [
      {
        'label': 'Flush',
        'value': 'Flush',
        'nextQuestions': [
          180
        ]
      }
    ]
  },
  {
    'id': 180,
    'fieldName': 'Color',
    'title': 'Please select your lightning color',
    'options': [
      {
        'label': 'White',
        'value': 'White',
        'nextQuestions': [
          181
        ]
      },
      {
        'label': 'Green',
        'value': 'Green',
        'nextQuestions': [
          183
        ]
      },
      {
        'label': 'Red',
        'value': 'Red',
        'nextQuestions': [
          185
        ]
      },
      {
        'label': 'Orange',
        'value': 'Orange',
        'nextQuestions': [
          187
        ]
      },
      {
        'label': 'Blue',
        'value': 'Blue',
        'nextQuestions': [
          189
        ]
      }
    ]
  },
  {
    'id': 181,
    'fieldName': 'Marking',
    'title': 'Do you want a specific marking?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          182
        ]
      }
    ]
  },
  {
    'id': 182,
    'fieldName': 'Supply Voltage',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '24 VDC/VAC',
        'value': '24 VDC/VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '110-120 VAC',
        'value': '110-120 VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '230-240 VAC',
        'value': '230-240 VAC',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 183,
    'fieldName': 'Marking',
    'title': 'Do you want a specific marking?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          184
        ]
      }
    ]
  },
  {
    'id': 184,
    'fieldName': 'Supply Voltage',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '24 VDC/VAC',
        'value': '24 VDC/VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '110-120 VAC',
        'value': '110-120 VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '230-240 VAC',
        'value': '230-240 VAC',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 185,
    'fieldName': 'Marking',
    'title': 'Do you want a specific marking?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          186
        ]
      }
    ]
  },
  {
    'id': 186,
    'fieldName': 'Supply Voltage',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '24 VDC/VAC',
        'value': '24 VDC/VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '110-120 VAC',
        'value': '110-120 VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '230-240 VAC',
        'value': '230-240 VAC',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 187,
    'fieldName': 'Marking',
    'title': 'Do you want a specific marking?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          188
        ]
      }
    ]
  },
  {
    'id': 188,
    'fieldName': 'Supply Voltage',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '24 VDC/VAC',
        'value': '24 VDC/VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '110-120 VAC',
        'value': '110-120 VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '230-240 VAC',
        'value': '230-240 VAC',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 189,
    'fieldName': 'Marking',
    'title': 'Do you want a specific marking?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          190
        ]
      }
    ]
  },
  {
    'id': 190,
    'fieldName': 'Supply Voltage',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '24 VDC/VAC',
        'value': '24 VDC/VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '110-120 VAC',
        'value': '110-120 VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '230-240 VAC',
        'value': '230-240 VAC',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 191,
    'fieldName': 'Mounting Type',
    'title': 'Select your mounting type.',
    'options': [
      {
        'label': 'Ø 22 mm Standard',
        'value': 'Ø 22 mm Standard',
        'nextQuestions': [
          192
        ]
      },
      {
        'label': 'Ø 30 mm Build-in Flush',
        'value': 'Ø 30 mm Build-in Flush',
        'nextQuestions': [
          204
        ]
      }
    ]
  },
  {
    'id': 192,
    'fieldName': 'Operator Profile',
    'title': 'Select your profile.',
    'options': [
      {
        'label': 'Flush',
        'value': 'Flush',
        'nextQuestions': [
          193
        ]
      }
    ]
  },
  {
    'id': 193,
    'fieldName': 'Color',
    'title': 'Please select your lightning color',
    'options': [
      {
        'label': 'White',
        'value': 'White',
        'nextQuestions': [
          194
        ]
      },
      {
        'label': 'Green',
        'value': 'Green',
        'nextQuestions': [
          196
        ]
      },
      {
        'label': 'Red',
        'value': 'Red',
        'nextQuestions': [
          198
        ]
      },
      {
        'label': 'Orange',
        'value': 'Orange',
        'nextQuestions': [
          200
        ]
      },
      {
        'label': 'Blue',
        'value': 'Blue',
        'nextQuestions': [
          202
        ]
      }
    ]
  },
  {
    'id': 194,
    'fieldName': 'Marking',
    'title': 'Do you want a specific marking?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          195
        ]
      }
    ]
  },
  {
    'id': 195,
    'fieldName': 'Supply Voltage',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '24 VDC/VAC',
        'value': '24 VDC/VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '110-120 VAC',
        'value': '110-120 VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '230-240 VAC',
        'value': '230-240 VAC',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 196,
    'fieldName': 'Marking',
    'title': 'Do you want a specific marking?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          197
        ]
      }
    ]
  },
  {
    'id': 197,
    'fieldName': 'Supply Voltage',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '24 VDC/VAC',
        'value': '24 VDC/VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '110-120 VAC',
        'value': '110-120 VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '230-240 VAC',
        'value': '230-240 VAC',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 198,
    'fieldName': 'Marking',
    'title': 'Do you want a specific marking?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          199
        ]
      }
    ]
  },
  {
    'id': 199,
    'fieldName': 'Supply Voltage',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '24 VDC/VAC',
        'value': '24 VDC/VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '110-120 VAC',
        'value': '110-120 VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '230-240 VAC',
        'value': '230-240 VAC',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 200,
    'fieldName': 'Marking',
    'title': 'Do you want a specific marking?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          201
        ]
      }
    ]
  },
  {
    'id': 201,
    'fieldName': 'Supply Voltage',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '24 VDC/VAC',
        'value': '24 VDC/VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '110-120 VAC',
        'value': '110-120 VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '230-240 VAC',
        'value': '230-240 VAC',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 202,
    'fieldName': 'Marking',
    'title': 'Do you want a specific marking?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          203
        ]
      }
    ]
  },
  {
    'id': 203,
    'fieldName': 'Supply Voltage',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '24 VDC/VAC',
        'value': '24 VDC/VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '110-120 VAC',
        'value': '110-120 VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '230-240 VAC',
        'value': '230-240 VAC',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 204,
    'fieldName': 'Operator Profile',
    'title': 'Select your profile.',
    'options': [
      {
        'label': 'Flush',
        'value': 'Flush',
        'nextQuestions': [
          205
        ]
      }
    ]
  },
  {
    'id': 205,
    'fieldName': 'Color',
    'title': 'Please select your lightning color',
    'options': [
      {
        'label': 'White',
        'value': 'White',
        'nextQuestions': [
          206
        ]
      },
      {
        'label': 'Green',
        'value': 'Green',
        'nextQuestions': [
          208
        ]
      },
      {
        'label': 'Red',
        'value': 'Red',
        'nextQuestions': [
          210
        ]
      },
      {
        'label': 'Orange',
        'value': 'Orange',
        'nextQuestions': [
          212
        ]
      },
      {
        'label': 'Blue',
        'value': 'Blue',
        'nextQuestions': [
          214
        ]
      }
    ]
  },
  {
    'id': 206,
    'fieldName': 'Marking',
    'title': 'Do you want a specific marking?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          207
        ]
      }
    ]
  },
  {
    'id': 207,
    'fieldName': 'Supply Voltage',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '24 VDC/VAC',
        'value': '24 VDC/VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '110-120 VAC',
        'value': '110-120 VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '230-240 VAC',
        'value': '230-240 VAC',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 208,
    'fieldName': 'Marking',
    'title': 'Do you want a specific marking?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          209
        ]
      }
    ]
  },
  {
    'id': 209,
    'fieldName': 'Supply Voltage',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '24 VDC/VAC',
        'value': '24 VDC/VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '110-120 VAC',
        'value': '110-120 VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '230-240 VAC',
        'value': '230-240 VAC',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 210,
    'fieldName': 'Marking',
    'title': 'Do you want a specific marking?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          211
        ]
      }
    ]
  },
  {
    'id': 211,
    'fieldName': 'Supply Voltage',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '24 VDC/VAC',
        'value': '24 VDC/VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '110-120 VAC',
        'value': '110-120 VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '230-240 VAC',
        'value': '230-240 VAC',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 212,
    'fieldName': 'Marking',
    'title': 'Do you want a specific marking?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          213
        ]
      }
    ]
  },
  {
    'id': 213,
    'fieldName': 'Supply Voltage',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '24 VDC/VAC',
        'value': '24 VDC/VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '110-120 VAC',
        'value': '110-120 VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '230-240 VAC',
        'value': '230-240 VAC',
        'nextQuestions': [
          -1
        ]
      }
    ]
  },
  {
    'id': 214,
    'fieldName': 'Marking',
    'title': 'Do you want a specific marking?',
    'options': [
      {
        'label': 'No',
        'value': 'No',
        'nextQuestions': [
          215
        ]
      }
    ]
  },
  {
    'id': 215,
    'fieldName': 'Supply Voltage',
    'title': 'Please select your mounting type',
    'options': [
      {
        'label': '24 VDC/VAC',
        'value': '24 VDC/VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '110-120 VAC',
        'value': '110-120 VAC',
        'nextQuestions': [
          -1
        ]
      },
      {
        'label': '230-240 VAC',
        'value': '230-240 VAC',
        'nextQuestions': [
          -1
        ]
      }
    ]
  }
];

export default PushButton;

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Modal } from "shared/components";
import "./styles.scss";

const DefaultTitle = "Do you want to add linked items?";

const ConfirmationDialog = ({ isOpen, onConfirm, onCancel, title }) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} size="sm" closeCb={onCancel}>
      <div>
        <div>{title || DefaultTitle}</div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 20,
          }}
        >
          <button
            className="ConfirmationDialog-btn cancel"
            type="button"
            onClick={onCancel}
            style={{ marginRight: 20 }}
          >
            {t("No")}
          </button>
          <button
            className="ConfirmationDialog-btn success"
            type="button"
            onClick={() => {
              onConfirm();
              onCancel();
            }}
          >
            {t("Yes")}
          </button>
        </div>
      </div>
    </Modal>
  );
};

ConfirmationDialog.defaultProps = {
  isOpen: false,
  onConfirm: () => {},
  onCancel: () => {},
  title: "",
};

ConfirmationDialog.propTypes = {
  isOpen: PropTypes.bool,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  title: PropTypes.string,
};

export default ConfirmationDialog;
